import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporate,
  updateExchangeRate,
} from "../../redux2/corporate/corporateSlice";
import Loading from "../Loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ChangeExchangeRate = ({ show, setOpen }) => {
  const { corporate, isLoading } = useSelector((state) => state.corporate);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCorporate());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      exchangeRate: corporate?.exchangeRate,
    },
    validationSchema: Yup.object({
      exchangeRate: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch(updateExchangeRate(values));
      setOpen(false);
      toast.success("Exchange Rate Updated Successfully");
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal
      show={show}
      onHide={() => setOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Exchange Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-black">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Exchange Rate (1 USD to BIRR)</Form.Label>
            <Form.Control
              type="number"
              required
              value={formik.values.exchangeRate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="exchangeRate"
              placeholder="Enter Exchange Rate"
            />
            {formik.touched.exchangeRate && formik.errors.exchangeRate ? (
              <div className="text-danger">{formik.errors.exchangeRate}</div>
            ) : null}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={formik.handleSubmit}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeExchangeRate;
