import React, { FC, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";

const NcInputNumber = ({
  className = "w-full",
  defaultValue = 0,
  min = 0,
  max,
  onChange,
  onChangeAge,
  label,
  desc,
  // setGuestsAge,
  age = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [guestsAge, setGuestsAge] = useState([]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClickDecrement = () => {
    if (min >= value) return;
    setValue((state) => {
      return state - 1;
    });
    onChange && onChange(value - 1);
    setGuestsAge((state) => {
      return state.slice(0, -1);
    });
  };
  const handleClickIncrement = () => {
    if (max && max <= value) return;
    setValue((state) => {
      return state + 1;
    });
    onChange && onChange(value + 1);
    setGuestsAge((state) => {
      return [...state, ""];
    });
  };

  const handleAgeChange = (e, index) => {
    const newGuestsAge = [...guestsAge];
    newGuestsAge[index] = e.target.value;
    setGuestsAge(newGuestsAge);
    onChangeAge && onChangeAge(newGuestsAge);
  };

  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
      data-nc-id="NcInputNumber"
    >
      {label && renderLabel()}

      <div
        className={`nc-NcInputNumber flex items-center justify-between py-2 gap-1`}
      >
        <button
          className="w-8 h-8 rounded-full flex items-center  justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickDecrement}
          disabled={min >= value}
        >
          <MinusIcon className="w-4 h-4" />
        </button>
        <span>{value}</span>
        <button
          className="w-8 h-8 rounded-full flex items-center gap-2 justify-center border border-neutral-400  bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickIncrement}
          disabled={max ? max <= value : false}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
        {/* <div className="col-md-4">
          {age && value > 0 ? (
            <>
              <div className="text-xs text-neutral-900 font-normal text-center px-1  w-full">
                Age
              </div>
              {Array.from({ length: value }, (_, i) => (
                <div className="flex flex-col justify-end w-full py-1">
                  <div className="text-xs text-neutral-900 font-normal text-center px-1  w-full">
                    ({i + 1})
                  </div>
                  <input
                    type="number"
                    className="w-12 h-7 border-2 border-neutral-400 rounded-md hover:border-neutral-700 "
                    min={1}
                    onChange={(e) => handleAgeChange(e, i)}
                    required
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="flex flex-col justify-end  w-14"></div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default NcInputNumber;
