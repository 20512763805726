import React, { Fragment, useEffect, useState } from "react";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import moment from "moment";

import useWindowSize from "../../../hooks/useWindowResize";
import ButtonSubmit from "./SubmitButton";
import useNcId from "../../../hooks/useNcId";
import { TRIP_TYPE_ROUND_TRIP } from "../../../common/constants";
import PassengerTypeModal from "../../../components/Modals/PassengerTypeModal";

const FlightDatesRangeInput = ({
  defaultDateValue,
  defaultTimeValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapFieldClassName = "flex relative",
  numberOfMonths,
  anchorDirection,
  isLocal = false,
  buttonSubmitHref = "/listing-car",
  hasButtonSubmit = true,
  tripType = TRIP_TYPE_ROUND_TRIP,
  showButton,
  prevDate,
  modal = false,
  isLabor = false,
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [stateDate, setStateDate] = useState(defaultDateValue);
  const [stateTimeRage] = useState(defaultTimeValue);
  const startDateId = useNcId();
  const endDateId = useNcId();
  const [showPassengerTypeModal, setShowPassengerTypeModal] = useState(false);

  useEffect(() => {
    setStateDate(defaultDateValue);
  }, [defaultDateValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  const windowSize = useWindowSize();

  const handleDateFocusChange = (focus) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };

  const isOutsideRange = (date) => {
    // Return true for dates before the customDate, which should be disabled
    // plus one day
    return date.isBefore(moment(prevDate).add(1, "days"), "day");
  };

  const renderPassengerTypeModal = (type = false) => {
    return (
      <div
        className={`${
          type ? "lg:mr-44" : ""
        } "pr-2 xl:pr-4 relative z-20 mt-3 "`}
      >
        <button
          type="button"
          className={`h-14 w-14 rounded-full bg-main hover:bg-main-700 flex items-center justify-center text-neutral-50 focus:outline-none ${className}`}
          onClick={() => setShowPassengerTypeModal(true)}
        >
          <span className="mr-3 md:hidden">Search</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
        <PassengerTypeModal
          show={showPassengerTypeModal}
          onHide={() => setShowPassengerTypeModal(false)}
          href={buttonSubmitHref}
          isLocal={isLocal}
        />
      </div>
    );
  };

  const renderInputpickUpDate = () => {
    const focused = focusedInput === "startDate";
    return (
      <div
        className={`flex flex-1 relative px-9 xl:w-72 py-6 items-center space-x-3 cursor-pointer ${
          focused ? "nc-hero-field-focusd" : " "
        } ${modal ? "py-2" : `${fieldClassName}`}`}
        // className={`flex flex-1 relative  ${fieldClassName} items-center space-x-3 cursor-pointer ${
        //   focused ? "nc-hero-field-focused" : " "
        // }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-1">
          <div className="absolute inset-0" />

          <div className="inline-flex items-center text-base xl:text-lg font-semibold">
            <span className="flex-shrink-0">
              {stateDate.startDate
                ? stateDate.startDate.format("DD MMM YYYY")
                : "Departure date"}
            </span>
          </div>

          <span className="block mt-1 text-sm text-neutral-400 font-light leading-none">
            {stateDate.startDate ? "Departure date" : `Add date`}
          </span>
        </div>
      </div>
    );
  };

  const renderInputdropOffDate = () => {
    const focused = focusedInput === "endDate";
    return (
      <div
        className={`flex relative flex-[1.8] items-center cursor-pointer ${
          focused ? "nc-hero-field-focused" : " "
        }`}
      >
        <div className={`flex-1 flex ${fieldClassName} space-x-3 items-center`}>
          <div className="text-neutral-300 dark:text-neutral-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nc-icon-field"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
          <div className="flex-1">
            <div className="absolute inset-0" />

            <div className="inline-flex items-center text-base xl:text-lg font-semibold">
              <span className="flex-shrink-0">
                {stateDate.endDate
                  ? stateDate.endDate.format("DD MMM YYYY")
                  : "Return date"}
              </span>
              {/* {stateDate.endDate && renderEditTime("dropOff")} */}
            </div>
            <span className="block mt-1 text-sm text-neutral-400 font-light leading-none">
              {stateDate.endDate ? "Return date" : `Add date`}
            </span>
          </div>
        </div>
        {hasButtonSubmit &&
          showButton &&
          (isLabor ? (
            <div className="pr-2 xl:pr-4 relative z-20">
              <ButtonSubmit href={buttonSubmitHref} />
            </div>
          ) : (
            renderPassengerTypeModal(false)
          ))}
        {/* renderPassengerTypeModal(false)} */}
      </div>
    );
  };

  return (
    <div
      className={`RentalCarDatesRangeInput relative flex text-black font-bold ${className} ${
        !!focusedInput ? "nc-date-focusedInput" : "nc-date-not-focusedInput"
      } ${!showButton ? "nc-date-not-showButton" : ""}`}
    >
      <div className="absolute inset-0 flex w-full">
        {tripType === TRIP_TYPE_ROUND_TRIP ? (
          <DateRangePicker
            startDate={stateDate.startDate}
            endDate={stateDate.endDate}
            focusedInput={focusedInput}
            onDatesChange={(date) => {
              setStateDate(date);
              onChange && onChange({ stateDate: date, stateTimeRage });
            }}
            onFocusChange={handleDateFocusChange}
            startDateId={startDateId}
            endDateId={endDateId}
            daySize={windowSize.width > 1279 ? 40 : 44}
            orientation={"horizontal"}
            showClearDates
            noBorder
            minimumNights={0}
            hideKeyboardShortcutsPanel
            numberOfMonths={
              numberOfMonths || (windowSize.width < 1024 ? 1 : undefined)
            }
            anchorDirection={anchorDirection}
            // isOutsideRange={(day) =>
            //   day.isBefore(moment().add(1, "days"), "day")
            // }
          />
        ) : (
          <SingleDatePicker
            date={stateDate.startDate}
            focused={focusedInput}
            focusedInput={focusedInput}
            onDateChange={(date) => {
              setStateDate({ startDate: date });
              onChange &&
                onChange({
                  stateDate: { startDate: date },
                });
            }}
            startDateId={startDateId}
            onFocusChange={(focusedInput) => {
              setFocusedInput(focusedInput);
            }}
            // onFocusChange={handleDateFocusChange}
            // id={startDateId}
            daySize={windowSize.width > 1279 ? 34 : 44}
            orientation={"horizontal"}
            noBorder
            hideKeyboardShortcutsPanel
            numberOfMonths={
              numberOfMonths || (windowSize.width < 1024 ? 1 : undefined)
            }
            onClose={() => setFocusedInput(null)}
            anchorDirection={anchorDirection}
            //disable input date using custom function
            isOutsideRange={isOutsideRange}

            // isOutsideRange={(day) => {
            //   return day.isBefore(moment().add(1, "days"), "day");
            //   // day.isAfter(moment().add(1, "years"))
            // }}
          />
        )}
      </div>

      <div className={`flex-1 ${wrapFieldClassName}`}>
        {tripType === TRIP_TYPE_ROUND_TRIP ? (
          <>
            {renderInputpickUpDate()}
            {renderInputdropOffDate()}
          </>
        ) : (
          <>
            {renderInputpickUpDate()}
            {showButton &&
              (isLabor ? (
                <div className="pr-2 xl:pr-4 relative z-20 mt-3 lg:mr-44">
                  <ButtonSubmit href={buttonSubmitHref} />
                </div>
              ) : (
                renderPassengerTypeModal(true)
              ))}
            {/* renderPassengerTypeModal(true)} */}
          </>
        )}
      </div>
    </div>
  );
};

export default FlightDatesRangeInput;
