import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { Pagination, Typography } from "@mui/material";
import {
  deleteRequest,
  getRequests,
  updateRequestSeen,
} from "../../redux2/request/requestSlice";
import RequestList from "../../components/Request/RequestList";
import Loading from "../../components/Loading";
import { USER_ROLE_MEMBER } from "../../common/constants";
import confirmModal from "../../utils/confirmAlert";

const RequestPage = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const { requests, isLoading } = useSelector((state) => state.request);
  const { user } = useSelector((state) => state.auth);

  if (user?.role === USER_ROLE_MEMBER) {
    window.location.href = "/";
  }

  const [currentPage, setCurrentPage] = useState(1);

  const query = useMemo(() => ({}), []);

  query.page = currentPage || 1;

  useEffect(() => {
    query.page = search ? 1 : currentPage || 1;
    query.search = search || "";

    dispatch(getRequests(query));

    if (search) {
      const timeOutId = setTimeout(() => getRequests(query), 1000);
      return () => clearTimeout(timeOutId);
    } else {
      getRequests(query);
    }
  }, [dispatch, query, currentPage, search]);

  useEffect(() => {
    dispatch(updateRequestSeen());
  }, [dispatch]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = (id) => {
    console.log(id);
    confirmModal(
      "Delete Request",
      "Are you sure you want to delete this request?",
      () => {
        dispatch(deleteRequest(id));
      },
      "Cancel",
      "Delete Request"
    );
  };

  if (isLoading) return <Loading />;
  return (
    // <div>
    //   <div className="max-w-4xl mx-auto  p-5">
    //     <h2 className="text-2xl font-bold mb-4 text-center">Requests</h2>
    //     <ul>
    //       {requests?.data?.map((request) => (
    //         <RequestList key={request.id} request={request} />
    //       ))}
    //     </ul>
    //   </div>
    // </div>
    <div className="row lg:mx-10 md:mx-12 px-5 py-2">
      <div className="table-search">
        <div className="input-group search-area mb-xxl-0 mb-4">
          <input
            type="text"
            className="form-control ring-1 ring-gray-300 focus:ring-2 focus:ring-main focus:outline-none"
            placeholder="Search by Ticket Number"
            // onChange={handleSearch}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="input-group-text">
            <button>
              <Search size={35} className="text-main" />
              {/* <i className="flaticon-381-search-2 text-main"></i> */}
            </button>
          </span>
        </div>
      </div>
      <div className="grid-cols-12">
        <div className="table-responsive">
          <div id="example2_wrapper" className="dataTables_wrapper no-footer">
            <table
              id="exampl2"
              className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
            >
              <thead>
                <tr role="row">
                  <th className="sorting">#</th>
                  <th>Agent Name</th>
                  <th>Passenger Name</th>
                  <th>Company Name</th>
                  <th>Request Type</th>
                  <th>Ticket Number</th>
                  <th>Status</th>
                  <th className="sorting">Created At</th>
                  <th className="sorting">Action</th>
                  <th className="sorting"></th>
                </tr>
              </thead>
              <tbody>
                {requests?.data?.length === 0 ? (
                  <tr>
                    <td colSpan={11}>
                      <Typography variant="h5">No Request Found</Typography>
                    </td>
                  </tr>
                ) : (
                  requests?.data?.map((request, index) => (
                    <RequestList
                      key={request._id}
                      request={request}
                      index={index}
                      currentPage={currentPage}
                      handleDelete={handleDelete}
                    />
                  ))
                )}
              </tbody>
            </table>
            <div className="flex items-center justify-center my-5">
              <Pagination
                // count={Math.ceil(bookings?.length / bookingPerPage)}
                count={requests?.totalPages}
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={4}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPage;
