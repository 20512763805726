import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../redux2/user/userSlice";
import EditEmployee from "./EditEmployee";

const Edit = ({ employee }) => {
  const [openEmployee, setOpenEmployee] = useState(false);

  const dispatch = useDispatch();

  const handleOpenEmployee = () => {
    setOpenEmployee(true);
  };

  const handleEmployee = (values) => {
    setOpenEmployee(false);

    const user = {
      ...values,
      id: employee?._id,
    };

    dispatch(updateUser(user));
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={handleOpenEmployee}>
        <i className="fas fa-edit me-2"></i>
        Edit
      </div>
      <EditEmployee
        openEmployee={openEmployee}
        setOpenEmployee={setOpenEmployee}
        handleEmployee={handleEmployee}
        employee={employee}
      />
    </div>
  );
};

export default Edit;
