import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { editBillingTransaction } from "../../redux2/billing/billingSlice";

const EditBilling = ({ billing }) => {
  const [openModal, setOpenModal] = useState(false);
  const [values, setValues] = useState({
    bank: billing?.bank,
    transaction: billing?.transaction,
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        bank: values.bank,
        transaction: values.transaction,
        id: billing?._id,
      };

      dispatch(editBillingTransaction(data));
      toast.success("Billing edited successfully");
      setOpenModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        className="w-full text-black px-2 cursor-pointer"
        onClick={handleClick}
      >
        Edit
      </div>
      <Modal
        show={openModal}
        className="modal fade"
        onHide={() => {
          setOpenModal(false);
        }}
        size="lg"
        centered
      >
        <div className="">
          <div className="modal-header text-black rounded-t-2xl">
            <h1 className="text-black font-bold text-xl">Edit Billing</h1>
            <button
              type="button"
              className="font-bold text-3xl text-red-500 rounded-full hover:outline-none border-none hover:ring-2 px-2 hover:ring-red-300"
              onClick={() => setOpenModal(false)}
            >
              &times;
            </button>
          </div>
          <div className="modal-body text-black">
            <div className="mt-1">
              <form className="w-full" onSubmit={handleSubmit}>
                <h6 className=" pb-2 font-semibold">Transaction ID:</h6>
                <div className="flex justify-between ">
                  <input
                    id="outlined-basic"
                    label="Transaction"
                    name="transaction"
                    variant="outlined"
                    className="w-1/2 border border-black rounded-md px-4 py-3 mb-4 font-bold"
                    placeholder="transaction ID"
                    size="medium"
                    value={values?.transaction}
                    disabled={!values?.transaction}
                    onChange={handleChange}
                  />
                </div>
                <h6 className=" pb-2 font-semibold">Transaction ID:</h6>
                <select
                  className="w-full border border-black rounded-md px-4 py-3 mb-4 font-bold"
                  name="bank"
                  value={values?.bank}
                  onChange={handleChange}
                  required
                >
                  <option value="credit">Credit</option>
                  <option value="cbe">CBE</option>
                  <option value="awash">Awash Bank</option>
                  <option value="bunna">Bunna Bank</option>
                  <option value="wegagen">Wegagen Bank</option>
                  <option value="abyssinia">Abyssinia Bank</option>
                  <option value="amhara">Amhara Bank</option>
                  <option value="wallet">Wallet</option>
                </select>

                {/* <LoadingButton
                  loading={isLoading}
                  className={`w-full bg-primary hover:bg-main-700 text-white py-3 px-4 rounded-md focus:outline-none my-1 "
                  }`}
                  type="submit"
                  disabled
                >
                  Edit
                </LoadingButton> */}
                <button className="btn text-white bg-primary hover:bg-main-900 w-full rounded-md">
                  Edit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditBilling;
