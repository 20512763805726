import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../redux2";
import Loading from "../Loading";

const CheckTTL = ({ booking }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ttl, setTtl] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (!booking?.flight?.retrievalPnr || !show) return;
      setTtl(null);

      setLoading(true);
      const res = await axios.post(API_URL + "flight/retrieve-pnr", {
        PNR: booking?.flight?.retrievalPnr,
      });
      setTtl(
        res.data?.UniversalRecordRetrieveRsp?.UniversalRecord?.GeneralRemark ||
          []
      );
      setLoading(false);
    }
    fetchData();
  }, [booking?.flight?.retrievalPnr, show]);

  const handleOpenDetail = () => {
    setShow(true);
  };

  const onHide = () => {
    setShow(false);
    setTtl(null);
  };

  // if (Array.isArray(ttl) && ttl.length === 0) {
  //   onHide();
  // }

  return (
    <div className="">
      <div className="cursor-pointer" onClick={handleOpenDetail}>
        Check TTL
      </div>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-right"
      >
        <Modal.Body>
          <div className="max-w-lg w-full mx-auto">
            <h5 className="text-xl font-semibold mb-6 text-center text-gray-800">
              Check Ticket Time Limit
            </h5>
            {loading ? (
              <Loading search />
            ) : (
              <div className="flex items-center justify-between mb-4 p-4 rounded-md bg-gray-100 hover:bg-gray-200 transition duration-150 ease-in-out">
                <div className="flex items-center text-black">
                  <h1>
                    {Array.isArray(ttl) && ttl.length === 0 ? (
                      <p className="text-red-500">No TTL found.</p>
                    ) : (
                      "* " + ttl?.[0]?.RemarkData?._text ||
                      ttl?.RemarkData?._text
                    )}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end mt-2 gap-3">
            <button
              type="button"
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out mr-2"
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckTTL;
