import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";

import convertNumbThousand from "../../utils/convertNumbThousand";
import { currencyType, getCarrierLogo } from "../../utils/flightInfo";
import ButtonPrimary from "../Button/ButtonPrimary";
import NcImage from "../NcImage/NcImage";
import NcModal from "../NcModal/NcModal";
import API_URL from "../../redux2";
import Loading from "../Loading";
import {
  getBookingById,
  updateBooking,
  updateWallet,
} from "../../redux2/booking/bookingSlice";
import CorporateList from "./CorporateList";
import customTime from "../../utils/customTime";
import { convertToNumber } from "../../utils/convertToNumber";
import { getMyEnterprise } from "../../redux2/enterprise/enterpriseSlice";
import {
  NON_DOCUMENT_HOLDER,
  ONE_WEEK_CREDIT,
  USER_ROLE_ENTERPRISE,
  WALLET_MINIMUM_AMOUNT,
} from "../../common/constants";
import { getMyCompany } from "../../redux2/user/userSlice";
import PaymentMethodsList from "../PaymentMethods/PaymentMethodsList";
import { paymentMethods } from "../../utils/paymentMethods";
import { SSRStatus, SSRTypes } from "../../utils/SSR";

const CheckOutPage = ({ className = "" }) => {
  const [error, setError] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [showCorporateList, setShowCorporateList] = useState(false);
  const [showSSR, setShowSSR] = useState(true);
  const [payment, setPayment] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const passengerTypeState = query.get("passengerTypeState");

  const {
    airReservation,
    reservation,
    travelers,
    charges,
    serviceCharge,
    systemFee,
    retrievalPnr,
    airPrice,
    queryURL,
    currency,
    creditDue,
  } = location.state;

  const { booking, isLoading } = useSelector((state) => state.booking);
  const { company } = useSelector((state) => state.user);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { user } = useSelector((state) => state.auth);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getBookingById(id));
    dispatch(getMyCompany());
  }, [dispatch, id]);

  useEffect(() => {
    if (user?.role === USER_ROLE_ENTERPRISE) {
      dispatch(getMyEnterprise());
      setPaymentMethod("webirr_cbe");
    }

    if (
      company &&
      currencyType(currency) === "USD" &&
      company?.USDCreditLimit > totalPrice
    ) {
      setPaymentMethod("USDCredit");
    } else {
      setPaymentMethod("");
    }

    if (
      company &&
      company.wallet < WALLET_MINIMUM_AMOUNT + priceToNumber(totalPrice()) &&
      currencyType(currency) === "USD"
      // passengerTypeState !== NON_DOCUMENT_HOLDER
    ) {
      setPaymentMethod("webirr_cbe");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.role, company]);

  // useEffect(() => {
  //   if (booking?.data?.paymentStatus === "paid") {
  //     toast.success("Booking Successful");

  //     navigate(`/pay-done/${id}?${queryURL}`, {
  //       state: {
  //         ticket: ticket,
  //         airReservation,
  //         travelers,
  //         charges,
  //         serviceCharge,
  //         airPrice,
  //         queryURL,
  //       },
  //     });
  //     return;
  //   }
  // }, [
  //   airReservation,
  //   booking?.data?.paymentStatus,
  //   id,
  //   navigate,
  //   ticket,
  //   travelers,
  //   charges,
  //   serviceCharge,
  //   airPrice,
  //   queryURL,
  // ]);

  const currentDate = new Date();

  const diffTime = Math.abs(
    new Date(currentDate) - new Date(creditDue?.createdAt)
  );
  let diffDate = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const handleClick = (e) => {
    e.preventDefault();

    if (
      company &&
      paymentMethod === "wallet" &&
      company.wallet < WALLET_MINIMUM_AMOUNT + priceToNumber(totalPrice())
    ) {
      toast.error(
        `You can't use wallet for payment. You don't have enough balance. Please use other payment method.`
      );
      return;
    }

    if (!company && paymentMethod === "credit") {
      if (!selectedCorporate) {
        toast.error("Please select corporate");
        return;
      }
    }

    setLoading(true);
    getTicket()
      .then((res) => {
        if (res.TicketFailureInfo) {
          console.log(res);
          setError(res?.TicketFailureInfo?._attributes?.Message);
          setLoading(false);
        } else {
          // console.log(res);
          // const data = {
          //   id,
          //   PNR: airReservation._attributes.LocatorCode,
          //   RetrievalPnr: retrievalPnr,
          //   payment: paymentMethod || "wallet",
          //   corporateName: selectedCorporate || null,
          //   ticketNumber:
          //     res?.ETR?.Ticket?._attributes?.TicketNumber || res.ETR,
          //   serviceCharge: charges !== 0 ? charges : serviceCharge,
          //   systemFee: systemFee,
          // };

          // dispatch(updateWallet(data));

          setLoading(false);
          toast.success("Booking Successful");

          navigate(`/pay-done/${id}?${queryURL}`, {
            state: {
              ticket: res,
              airReservation,
              travelers,
              charges,
              serviceCharge,
              airPrice,
              reservation,
              queryURL,
              currency,
            },
          });
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  let issueAt = moment(currentDate).format("YYYY-MM-DDTHH:mm:ss");

  const getTicket = () =>
    new Promise((resolve, reject) => {
      let data = {
        id,
        PNR: airReservation._attributes.LocatorCode,
        payment: paymentMethod || "wallet",
        corporateName: selectedCorporate || null,
        // serviceCharge: charges !== 0 ? charges : serviceCharge,
        // systemFee: systemFee,
        userId: user?._id,
        companyId: company?._id,
        issuedAt: issueAt,
      };

      axios
        .post(API_URL + "flight/ticket", data)
        .then((res) => {
          resolve(res.data?.AirTicketingRsp);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });

  const handleWithoutPayment = (e) => {
    e.preventDefault();

    const data = {
      id,
      PNR: airReservation._attributes.LocatorCode,
      RetrievalPnr: retrievalPnr,
      payment: "none",
      paymentStatus: "pending",
      status: "pending",
      serviceCharge: charges !== 0 ? charges : serviceCharge,
      systemFee: systemFee,
    };

    dispatch(updateBooking(data));
    // toast.success("Booking Success");
    navigate("/");
  };

  const initiatePayment = async () => {
    const data = {
      pnr: airReservation._attributes.LocatorCode,
      paymentMethod,
    };

    setLoading(true);
    try {
      const res = await axios.post(API_URL + "booking/payment/initiate", data);

      setPayment(res?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCorporateSubmit = (selectedCorporate) => {
    setShowCorporateList(false);
    setSelectedCorporate(selectedCorporate);
  };

  if (loading || isLoading) {
    return <Loading />;
  }

  function totalPrice() {
    let airPriceTotal = airPrice._attributes.TotalPrice?.toString().slice(3);
    airPriceTotal = parseFloat(airPriceTotal);

    let result =
      airPriceTotal +
      parseFloat(booking?.commission || 0) +
      charges +
      parseFloat(serviceCharge || 0);

    if (creditDue && paymentMethod === "credit") {
      result =
        booking?.totalPrice +
        +((booking?.flight?.price + booking?.serviceCharge) * 0.0008).toFixed(
          2
        ) *
          (company?.creditType === ONE_WEEK_CREDIT
            ? 7 - (diffDate + 1)
            : 14 - (diffDate + 1)) +
        parseFloat(booking?.commission || 0);
    }
    return convertNumbThousand(result);
  }

  function priceToNumber(str) {
    let result = str?.toString().replace(/,/g, "");
    return +result;
  }

  let instructions = [];
  if (payment?.paymentInstructionsAll) {
    instructions = payment?.paymentInstructionsAll;
  } else {
    instructions = payment?.paymentInstructions;
  }
  // switch (paymentMethod) {

  //   case "cbeBirr":
  //     instructions = CBEBirr;
  //     break;
  //   case "cbe":
  //     instructions = CBE;
  //     break;
  //   case "awash":
  //     instructions = Awash;
  //     break;
  //   case "abyssinia":
  //     instructions = Abyssinia;
  //     break;
  //   default:
  //     instructions = [];
  // }

  function customDate(date) {
    let d = date?.split("T")?.[0];
    return moment(d).format("DD MMM YYYY");
  }

  const renderInstructions = () => {
    return (
      <div className="mt-2 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col tracking-wider">
          {instructions?.map((item, index) => (
            <div key={index}>
              <h1 className="text-xl text-center font-semibold underline">
                {item?.type}
              </h1>
              <div key={index} className="flex items-start">
                <div className="flex flex-col">
                  {item?.instructions?.map((instruction, i) => (
                    <span
                      className="text-sm font-semibold text-neutral-900"
                      key={i}
                    >
                      {instruction}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            {/* <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden"> */}
            <NcImage
              src={getCarrierLogo(
                airReservation?.AirSegment?._attributes?.Carrier ||
                  airReservation?.AirSegment?.[0]?._attributes?.Carrier
              )}
            />
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>
                {airReservation?.AirSegment?._attributes?.Carrier ||
                  airReservation?.AirSegment?.[0]?._attributes?.Carrier}
              </h1>
            </span>
            {airReservation?.AirSegment instanceof Array ? (
              airReservation?.AirSegment.map((segment, index) => (
                <div key={index}>
                  <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                    <h1>
                      {segment._attributes.Origin} -{" "}
                      {segment._attributes.Destination}
                    </h1>
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {customDate(segment._attributes.DepartureTime)} -
                    {customDate(segment._attributes.ArrivalTime)}
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {customTime(segment._attributes.DepartureTime)} -
                    {customTime(segment._attributes.ArrivalTime)}
                  </span>
                </div>
              ))
            ) : (
              <div>
                {/* <span className="text-lg text-neutral-500 font-semibold">
                  <h1>{airReservation?.AirSegment.Carrier}</h1>
                </span> */}
                <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                  <h1>
                    {airReservation?.AirSegment?._attributes?.Origin} -{" "}
                    {airReservation?.AirSegment?._attributes?.Destination}
                  </h1>
                </span>
                <span className="text-base font-medium mt-1 block">
                  {customDate(
                    airReservation?.AirSegment?._attributes?.DepartureTime
                  )}{" "}
                  -
                  {customDate(
                    airReservation?.AirSegment?._attributes?.ArrivalTime
                  )}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {customTime(
                    airReservation?.AirSegment?._attributes?.DepartureTime
                  )}{" "}
                  -
                  {customTime(
                    airReservation?.AirSegment?._attributes?.ArrivalTime
                  )}
                </span>
              </div>
            )}

            <div className="flex flex-row justify-between">
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {parseInt(adults)} Adult
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {children > 0 && children + " Children"}
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {infants > 0 && infants + " Infants"}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <div>
              {" "}
              {adults} *{" Adults "}
            </div>
            <div>
              {convertToNumber(
                airPrice?.AirPricingInfo?._attributes?.TotalPrice ??
                  airPrice?.AirPricingInfo[0]?._attributes?.TotalPrice
              )}{" "}
              {airPrice?.AirPricingInfo?._attributes?.TotalPrice?.substring(
                0,
                3
              ) ??
                airPrice?.AirPricingInfo[0]?._attributes?.TotalPrice?.substring(
                  0,
                  3
                )}
            </div>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {children > 0 && (
              <>
                <div>1 *{" Children "}</div>
                <div>
                  {convertToNumber(
                    airPrice?.AirPricingInfo[1]?._attributes?.TotalPrice
                  )}{" "}
                  {airPrice?.AirPricingInfo[1]?._attributes?.TotalPrice?.substring(
                    0,
                    3
                  )}
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {infants > 0 && (
              <>
                <div>1 *{" Infants "}</div>
                <div>
                  {convertToNumber(
                    airPrice?.AirPricingInfo[children > 0 ? 2 : 1]?._attributes
                      ?.TotalPrice
                  )}{" "}
                  {airPrice?.AirPricingInfo[
                    children > 0 ? 2 : 1
                  ]?._attributes?.TotalPrice?.substring(0, 3)}
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>
              {charges !== 0 ? charges : serviceCharge} {currencyType(currency)}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Agent Fee</span>
            <span>
              {booking?.commission || 0} {currencyType(currency)}
            </span>
          </div>

          {paymentMethod === "credit" && creditDue && (
            <div className="text-red-500 space-y-2  py-3 rounded-lg">
              <div className="flex justify-between ">
                <span>Due Date</span>
                <span>
                  {/* add 7 days on creditDue */}
                  {moment(creditDue?.createdAt)
                    .add(7, "days")
                    .format("DD MMM YYYY")}
                </span>
              </div>
              <hr />
              <div className="flex justify-between ">
                <span>Facilitation Fee at day one</span>
                <span>
                  {
                    +(
                      (booking?.flight?.price + booking?.serviceCharge) *
                      0.003
                    ).toFixed(2)
                  }{" "}
                </span>
              </div>

              <hr />

              <div className="flex justify-between ">
                <span>Daily Fee After day one 0.08%</span>
                <span>
                  {
                    +(
                      (booking?.flight?.price + booking?.serviceCharge) *
                      0.0008
                    ).toFixed(2)
                  }{" "}
                  {currencyType(currency)}
                </span>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <div className="">
                  <p>Payment amount on due date.</p>
                  <p className="text-green-500 text-xs w-3/4">
                    if you pay before due date payment amount will decrease
                  </p>
                </div>
                <span>
                  {convertNumbThousand(
                    booking?.totalPrice +
                      +(
                        (booking?.flight?.price + booking?.serviceCharge) *
                        0.0008
                      ).toFixed(2) *
                        (company?.creditType === ONE_WEEK_CREDIT
                          ? 7 - (diffDate + 1)
                          : 14 - (diffDate + 1))
                  )}{" "}
                </span>
              </div>
            </div>
          )}

          <div className="border-b border-neutral-200 "></div>
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span>
              {/* {convertToNumber(
                airReservation.AirPricingInfo._attributes.TotalPrice
              )}{" "} */}
              {totalPrice()} {airPrice._attributes.TotalPrice.substring(0, 3)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-5 sm:p-6 xl:p-8 shadow">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold ">Traveler Information</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div
            className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col 
          w-full sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 shadow"
          >
            <div className="px-1 md:mx-32 md:px-5 py-4  text-lg font-light">
              {travelers instanceof Array ? (
                travelers.map((travel, index) => (
                  <>
                    <h1 className="text-xl font-bold p-2">
                      Passenger {++index}
                    </h1>
                    <div className="flex flex-row justify-between px-4 space-x-12">
                      <h1 className="">Name</h1>
                      <span className="text-neutral-500 dark:text-neutral-400 ">
                        {travel?.BookingTravelerName?._attributes?.First}{" "}
                        {travel?.BookingTravelerName?._attributes?.Last}
                      </span>
                      {/* </div> */}
                    </div>
                    <div className="flex flex-row justify-between px-4 py-2 space-x-6">
                      <h1 className="">Email</h1>
                      <h1 className="text-neutral-500 dark:text-neutral-400 ">
                        {travel?.Email?._attributes?.EmailID}{" "}
                      </h1>
                    </div>

                    <div className="flex flex-row justify-between  px-4 py-2">
                      <h1 className="">PNR</h1>
                      <span className="text-neutral-500 dark:text-neutral-400 ">
                        {airReservation._attributes.LocatorCode}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between  px-4 py-2">
                      <h1 className="">Seat</h1>
                      <span className="text-neutral-500 dark:text-neutral-400">
                        {/* {travel?.AirSeatAssignment?._attributes?.Seat ||
                          "Seat Not Selected"} */}

                        {travel?.AirSeatAssignment ? (
                          travel?.AirSeatAssignment instanceof Array ? (
                            travel?.AirSeatAssignment?.map((seat, index) => (
                              <span key={index}>{seat._attributes.Seat} </span>
                            ))
                          ) : (
                            <span>
                              {travel?.AirSeatAssignment?._attributes?.Seat}
                            </span>
                          )
                        ) : (
                          <span>Seat Not Selected</span>
                        )}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between px-4 py-2 space-x-6 items-center">
                      {/* <div className="justify-between"> */}
                      <h1 className="">SSR</h1>
                      <button
                        onClick={() => {
                          setShowSSR(!showSSR);
                        }}
                        type="button"
                      >
                        <i
                          className={
                            "text-2xl text-main font-black " +
                            (showSSR
                              ? "fa fa-chevron-down"
                              : "fa fa-chevron-up")
                          }
                        ></i>
                      </button>
                    </div>
                    <div
                      // className="flex flex-col justify-between gap-2 text-black"
                      className={
                        showSSR
                          ? "hidden row border-1 border-slate-200 p-2 rounded-md"
                          : "visible flex flex-col justify-between gap-2 text-black "
                      }
                    >
                      {travel?.SSR?.length > 0
                        ? travel?.SSR?.map((ssr, index) => (
                            <div
                              key={index}
                              className="flex flex-row justify-center px-2 py-1 gap-5 border-1 border-slate-200 rounded-xl mx-3"
                            >
                              <h1 className="px-2 ">
                                {SSRTypes(ssr?._attributes?.Type)} (
                                {ssr._attributes.Type})
                              </h1>
                              <h1 className="px-2 text-gray-500">
                                {SSRStatus(ssr._attributes.Status)} (
                                {ssr._attributes.Status})
                              </h1>
                            </div>
                          ))
                        : travel?.SSR?._attributes && (
                            <div className="flex flex-row justify-center px-2 py-1 gap-5 border-1 border-slate-200 rounded-xl mx-3">
                              <h1 className="px-2 ">
                                {SSRTypes(travel?.SSR?._attributes?.Type)} (
                                {travel?.SSR?._attributes?.Type})
                              </h1>
                              <h1 className="px-2 text-gray-500">
                                {SSRStatus(travel?.SSR?._attributes?.Status)} (
                                {travel?.SSR?._attributes?.Status})
                              </h1>
                            </div>
                          )}
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="flex flex-row justify-between px-4 space-x-12">
                    {/* <div className="space-x-20"> */}
                    <h1 className="">Name</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      {travelers?.BookingTravelerName?._attributes?.First}{" "}
                      {travelers?.BookingTravelerName?._attributes?.Last}
                    </span>
                    {/* </div> */}
                  </div>
                  <div className="flex flex-row justify-between px-4 py-2 space-x-6">
                    {/* <div className="justify-between"> */}
                    <h1 className="">Email</h1>
                    <h1 className="text-neutral-500 dark:text-neutral-400 ">
                      {travelers?.Email?._attributes?.EmailID}{" "}
                    </h1>
                  </div>

                  {/* <div className="flex flex-row justify-between  px-4 py-2">
                    <h1 className="">Flight</h1>
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {
                        airReservation.AirSegment.FlightDetails._attributes
                          .Origin
                      }{" "}
                      -{" "}
                      {
                        airReservation.AirSegment.FlightDetails._attributes
                          .Destination
                      }
                    </span>
                  </div> */}

                  {/* <div className="flex flex-row justify-between  px-4 py-2">
                <h1 className="">Departure Date</h1>
                <span className="text-neutral-500 dark:text-neutral-400 ">
                  {moment(
                    airReservation.AirSegment._attributes.DepartureTime
                  ).format("DD MMM YYYY hh:mm A")}
                </span>
              </div> */}
                  {/* <div className="flex flex-row justify-between  px-4 py-2 space-x-20">
                <h1 className="">Arrival Date</h1>
                <span className="text-neutral-500 dark:text-neutral-400 ">
                  {moment(
                    airReservation.AirSegment._attributes.ArrivalTime
                  ).format("DD MMM YYYY hh:mm A")}
                </span>
              </div> */}
                  {/* <div className="flex flex-row justify-between  px-4 py-2">
                <h1 className="">Flight Number</h1>
                <span className="text-neutral-500 dark:text-neutral-400 ">
                  {airReservation.AirSegment._attributes.FlightNumber}
                </span>
              </div> */}
                  <div className="flex flex-row justify-between  px-4 py-2">
                    <h1 className="">PNR</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      {airReservation._attributes.LocatorCode}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between  px-4 py-2">
                    <h1 className="">Seat</h1>
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {/* {travelers?.AirSeatAssignment?._attributes?.Seat ||
                        "Seat Not Selected"} */}
                      {travelers?.AirSeatAssignment ? (
                        travelers?.AirSeatAssignment instanceof Array ? (
                          travelers?.AirSeatAssignment?.map((seat, index) => (
                            <span key={index}>{seat._attributes.Seat} </span>
                          ))
                        ) : (
                          <span>
                            {travelers?.AirSeatAssignment?._attributes?.Seat}
                          </span>
                        )
                      ) : (
                        <span>Seat Not Selected</span>
                      )}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between px-4 py-2 space-x-6 items-center">
                    {/* <div className="justify-between"> */}
                    <h1 className="">SSR</h1>
                    <button
                      onClick={() => {
                        setShowSSR(!showSSR);
                      }}
                      type="button"
                    >
                      <i
                        className={
                          "text-2xl text-main font-black " +
                          (showSSR ? "fa fa-chevron-down" : "fa fa-chevron-up")
                        }
                      ></i>
                    </button>
                  </div>
                  <div
                    // className="flex flex-col justify-between gap-2 text-black"
                    className={
                      showSSR
                        ? "hidden row border-1 border-slate-200 p-2 rounded-md"
                        : "visible flex flex-col justify-between gap-2 text-black "
                    }
                  >
                    {travelers?.SSR?.length > 0
                      ? travelers?.SSR?.map((ssr, index) => (
                          <div
                            key={index}
                            className="flex flex-row justify-center px-2 py-1 gap-5 border-1 border-slate-200 rounded-xl mx-3"
                          >
                            <h1 className="px-2 ">
                              {SSRTypes(ssr._attributes.Type)} (
                              {ssr._attributes.Type})
                            </h1>
                            <h1 className="px-2 text-gray-500">
                              {SSRStatus(ssr._attributes.Status)} (
                              {ssr._attributes.Status})
                            </h1>
                          </div>
                        ))
                      : travelers?.SSR?._attributes && (
                          <div className="flex flex-row justify-center px-2 py-1 gap-5 border-1 border-slate-200 rounded-xl mx-3">
                            <h1 className="px-2 ">
                              {SSRTypes(travelers?.SSR?._attributes?.Type)} (
                              {travelers?.SSR?._attributes?.Type})
                            </h1>
                            <h1 className="px-2 text-gray-500">
                              {SSRStatus(travelers?.SSR?._attributes?.Status)} (
                              {travelers?.SSR?._attributes?.Status})
                            </h1>
                          </div>
                        )}
                  </div>
                </>
              )}
            </div>

            {/* <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${guests.guestAdults || 0} Guests`}
                      </span>
                    </span>
                  </div>
                  <PencilAltIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            /> */}
          </div>
        </div>

        <div>
          {!payment?.paymentInstructions && (
            <PaymentMethodsList
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              setShowCorporateList={setShowCorporateList}
              user={user}
              enterprise={enterprise}
              company={company}
              totalPrice={priceToNumber(totalPrice())}
              setSelectedCorporate={setSelectedCorporate}
              departureDate={
                airReservation?.AirSegment?.[0]?._attributes?.DepartureTime ||
                airReservation?.AirSegment?._attributes?.DepartureTime
              }
              passengerTypeState={passengerTypeState}
              currency={currency}
              diffDate={diffDate}
            />
          )}
          {paymentMethod !== "wallet" &&
            paymentMethod !== "credit" &&
            payment && (
              <div className="border p-5 border-black shadow rounded-xl">
                <h1 className="text-2xl text-center font bold capitalize">
                  {paymentMethod}
                </h1>
                <div className="px-2 flex justify-between flex-wrap">
                  <h1 className="text-xl font-semibold">Your PNR code is:</h1>
                  <button
                    className="bg-slate-300 text-white px-2 py-1 rounded-lg cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        // airReservation._attributes.LocatorCode
                        payment.paymentReference
                      );
                      toast.success(
                        "Payment Reference code copied to clipboard"
                      );
                    }}
                  >
                    <h1 className="text-xl font-bold flex  ">
                      {payment.paymentReference}
                      {/* {airReservation._attributes.LocatorCode} */}
                      <button className="mx-2 hover:text-green-800  hover:rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                          />
                        </svg>
                      </button>
                    </h1>
                  </button>
                </div>
                {renderInstructions()}
              </div>
            )}
        </div>

        <h1 className="text-xl font-semibold">
          Selected Payment Method:{" "}
          <span className="text-main">
            {
              paymentMethods.find((method) => method.name === paymentMethod)
                ?.title
            }
          </span>
        </h1>

        {paymentMethod === "wallet" ||
        paymentMethod === "credit" ||
        paymentMethod === "USDCredit" ? (
          <>
            <div className="lg:hidden row lg:p-0 space-y-2  ">
              <div className="col-12">
                <ButtonPrimary className="bg-red-500 py-3 hover:bg-red-400 w-full">
                  Cancel Booking
                </ButtonPrimary>
              </div>
              <div className="col-12">
                <ButtonPrimary
                  className="bg-gray-400 text-white hover:bg-gray-300 w-full"
                  onClick={handleWithoutPayment}
                >
                  Continue Without Payment
                </ButtonPrimary>
              </div>
              <div className="col-12 ">
                <ButtonPrimary onClick={handleClick} className="w-full mb-3">
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>

            <div className="pt-8 lg:flex justify-between hidden gap-4">
              {/* <div className="w-full">
                <ButtonPrimary className="bg-red-500 hover:bg-red-400 py-3 w-44">
                  Cancel Booking
                </ButtonPrimary>
              </div> */}
              <div className="w-full">
                <ButtonPrimary
                  className="bg-slate-50 
                  border-1 border-gray-500 text-black hover:text-white hover:bg-gray-400
                  focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 w-full rounded-3xl"
                  onClick={handleWithoutPayment}
                >
                  {/* Continue Without Payment */}
                  Pay Later
                </ButtonPrimary>
              </div>
              <div className="w-full">
                <ButtonPrimary
                  onClick={handleClick}
                  className="w-full 
                border-1 border-main text-black bg-slate-50 hover:text-white hover:bg-main-900
                focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 rounded-3xl
                "
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>
          </>
        ) : paymentMethod === "" ? (
          <div className="row lg:p-0 space-y-3  ">
            <div className="col-12">
              <ButtonPrimary
                className="bg-gray-400 text-white hover:bg-gray-300 w-full"
                // onclick go home
                onClick={() => navigate("/")}
              >
                No Payment Method Selected
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <div className="row lg:p-0 space-y-3  ">
            <div className="col-12">
              <ButtonPrimary
                className="bg-green-500 py-3 hover:bg-green-400 w-full"
                onClick={initiatePayment}
                // onClick={handleWithoutPayment}
              >
                Proceed
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage ${className} px-1 py-4`}
      data-nc-id="CheckOutPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className=" lg:block flex-grow mb-10">{renderSidebar()}</div>
      </main>
      {paymentMethod === "credit" && (
        <CorporateList
          show={showCorporateList}
          handleClose={handleCorporateSubmit}
        />
      )}
    </div>
  );
};

export default CheckOutPage;
