import * as Yup from "yup";

export const EmployeeSchema = () =>
  Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
    gender: Yup.string().required("Gender is required"),
    role: Yup.string().required("Role is required"),
  });
