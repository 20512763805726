import { Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { getBilling } from "../../redux2/billing/billingSlice";
import { getEnterprise } from "../../redux2/enterprise/enterpriseSlice";
import StatCard from "./StatCard";
import { formatNumber } from "../../utils/convertToNumber";

const BillingEnterprise = () => {
  const { billing, isLoading } = useSelector((state) => state.billing);
  const { enterprise, isLoading: loading } = useSelector(
    (state) => state.enterprise
  );
  const { user, isLoading: loadingUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEnterprise(user?._id));
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(getBilling(enterprise?._id));
  }, [dispatch, enterprise]);

  const totalAgentFee = billing?.billing?.reduce(
    (total, item) => total + item?.commission,
    0
  );

  const statData = [
    {
      title: "Wallet Balance",
      data: formatNumber(billing?.company?.wallet || 0) + " Birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-wallet2"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "primary",
    },
    {
      title: "Total Sales Amount",
      data: formatNumber(totalAgentFee || 0) + " Birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "success",
    },
  ];

  if (isLoading || loading || loadingUser) {
    return <Loading />;
  }
  return (
    <div className="lg:mx-32 md:mx-16">
      <div className="row">
        {statData.map(({ icon, color, title, data }, index) => (
          <StatCard
            key={index}
            icon={icon}
            color={color}
            title={title}
            data={data}
          />
        ))}
      </div>

      <div className="relative mb-10 px-4 py-2">
        <div className="absolute top-0 right-0 ">
          <Link
            to={"#"}
            className="btn bg-main hover:bg-main-800 text-white border-main-100 mb-xxl-0 mb-4"
          >
            <i className="far fa-file-word me-2"></i>Generate Report
          </Link>
        </div>
      </div>

      <h3 className="text-2xl font-black text-primary">Transaction</h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body " style={{ padding: "1.25rem" }}>
              <div className="table-responsive">
                <table className="table table-sm mb-0 table-responsive-lg ">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Payment Method</th>
                      <th className="align-middle pr-7">Total Amount</th>
                      <th className="align-middle pr-7">Service Charge</th>
                      <th className="align-middle">Transaction ID</th>
                      <th className="align-middle">Created At</th>
                      <th className="no-sort" />
                    </tr>
                  </thead>
                  <tbody id="orders">
                    {billing?.billing?.length === 0 ? (
                      <tr>
                        <td colSpan={11}>
                          <Typography variant="h5">No Billing Found</Typography>
                        </td>
                      </tr>
                    ) : (
                      billing?.billing?.map((item, index) => (
                        <tr className="btn-reveal-trigger" key={index}>
                          <td>{++index}</td>
                          <td className="py-2">
                            {item?.status === "refunded" ? (
                              <span className="badge badge-secondary">
                                Refunded
                              </span>
                            ) : item?.status === "voided" ? (
                              <span className="badge badge-secondary">
                                Void
                              </span>
                            ) : item?.status === "exchanged" ? (
                              <span className="badge badge-secondary">
                                Exchanged
                              </span>
                            ) : (
                              <span className="badge badge-success">
                                Success
                                <span className="ms-1 fa fa-check" />
                              </span>
                            )}
                          </td>
                          <td className="py-2">{item.bank}</td>
                          <td className="py-2">
                            {item.deposit ||
                              item.amount + (item.serviceCharge || 0)}{" "}
                            birr
                          </td>
                          <td className="py-2">{item.serviceCharge || 0}</td>
                          <td className="py-2">{item.transaction}</td>
                          <td className="py-2">
                            {moment(item.createdAt).format("MMMM Do YYYY")}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingEnterprise;
