import React, { useEffect, useState } from "react";
import { Modal, Nav } from "react-bootstrap";
import axios from "axios";

import API_URL from "../../redux2";
import Loading from "../Loading";
import "./SeatSelection.css";

const SeatModal = ({
  seatModal,
  setSeatModal,
  reservedSeats,
  setReservedSeats,
  airSegment,
  traceId,
  index,
}) => {
  // const [reservedSeats, setReservedSeats] = useState([]);
  const [seatsData, setSeatsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  let passenger = index;

  useEffect(() => {
    let data = {
      TraceId: traceId,
      segments: [],
    };

    if (airSegment instanceof Array) {
      airSegment.forEach((item) => {
        data.segments.push({
          Key: item?._attributes?.Key,
          Group: item?._attributes?.Group,
          Carrier: item?._attributes?.Carrier,
          FlightNumber: item?._attributes?.FlightNumber,
          Origin: item?._attributes?.Origin,
          Destination: item?._attributes?.Destination,
          DepartureTime: item?._attributes?.DepartureTime,
          ArrivalTime: item?._attributes?.ArrivalTime,
          Equipment: item?._attributes?.Equipment,
          ClassOfService: item?._attributes?.ClassOfService,
          Provider: "1G",
          SegmentIndex: item?._attributes?.SegmentIndex,
        });
      });
    } else {
      data.segments.push({
        Key: airSegment.Key,
        Group: airSegment.Group,
        Carrier: airSegment.Carrier,
        FlightNumber: airSegment.FlightNumber,
        Origin: airSegment.Origin,
        Destination: airSegment.Destination,
        DepartureTime: airSegment.DepartureTime,
        ArrivalTime: airSegment.ArrivalTime,
        Equipment: airSegment.Equipment,
        ClassOfService: airSegment.ClassOfService,
        Provider: "1G",
        SegmentIndex: airSegment.SegmentIndex,
      });
    }

    setLoading(true);
    axios.post(API_URL + "flight/select-seat", data).then((res) => {
      // console.log(res.data.SeatMapRsp?.Rows?.Row);
      setSeatsData(res.data?.SeatMapRsp?.Rows);
      setLoading(false);
    });
  }, []);

  !(seatsData instanceof Array) && setSeatsData([seatsData]);

  // const handleClick = (seat) => {
  //   // if (selectedSeats.includes(seat)) {
  //   //   setSelectedSeats(selectedSeats.filter((item) => item !== seat));
  //   // }

  //   // if (selectedSeats.length === selectLimit) {
  //   //   toast.error("You can select only 1 seat");
  //   //   return;
  //   // }

  //   if (reservedSeats?.[index]?.includes(seat)) {
  //     console.log("here");
  //     setReservedSeats((prev) => {
  //       const newArray = [...prev];
  //       newArray[index] = newArray[index].filter((item) => item !== seat);
  //       return newArray;
  //     });
  //     // setReservedSeats(reservedSeats.filter((item) => item !== seat));
  //     return;
  //   }

  //   if (reservedSeats.length === selectLimit) {
  //     console.log("here2");

  //     // remove reserved seat and add new seat
  //     const reservedSeatsCopy = [...reservedSeats];
  //     reservedSeatsCopy.pop();
  //     reservedSeatsCopy.push(seat);
  //     setReservedSeats((prev) => {
  //       const newArray = [...prev];
  //       newArray[index] = reservedSeatsCopy;
  //       return newArray;
  //     });
  //     setSeatModal(false);
  //     return;
  //   }

  //   // if (reservedSeats.length === selectLimit) {
  //   //   toast.error("You can select only 1 seat");
  //   //   return;
  //   // }

  //   const reservedSeatsCopy = [...reservedSeats];
  //   const seatIndex = reservedSeatsCopy[index]?.indexOf(seat);
  //   // const seatIndex = reservedSeatsCopy.indexOf(seat);

  //   if (seatIndex === -1) {
  //     reservedSeatsCopy.push(seat);
  //     setOpen(true);
  //     setSeatModal(false);
  //   } else {
  //     reservedSeatsCopy.splice(seatIndex, 1);
  //   }

  //   setReservedSeats((prev) => {
  //     const newArray = [...prev];
  //     newArray[index] = reservedSeatsCopy;
  //     return newArray;
  //   });
  // };

  // const handleClick = (seat) => {
  //   if (reservedSeats?.[index]?.includes(seat)) {
  //     setReservedSeats((prev) => {
  //       const newArray = [...prev];
  //       newArray[index] = newArray[index].filter((item) => item !== seat);
  //       return newArray;
  //     });
  //   } else if (reservedSeats?.[index]?.length > 0) {
  //     const reservedSeatsCopy = [...reservedSeats];
  //     reservedSeatsCopy[index] = [seat];
  //     setReservedSeats(reservedSeatsCopy);
  //     setSeatModal(false);
  //   } else {
  //     const reservedSeatsCopy = [...reservedSeats];
  //     if (reservedSeatsCopy[index]) {
  //       reservedSeatsCopy[index].push(seat);
  //     } else {
  //       reservedSeatsCopy[index] = [seat];
  //     }
  //     setReservedSeats(reservedSeatsCopy);
  //     setOpen(true);
  //     setSeatModal(false);
  //   }
  // };

  // working one
  const handleClick = (seat) => {
    if (reservedSeats?.[index]?.[count]?.includes(seat)) {
      setReservedSeats((prev) => {
        const newArray = [...prev];
        newArray[index][count] = newArray[index]?.[count].filter(
          (item) => item !== seat
        );
        return newArray;
      });
    } else if (reservedSeats?.[index]?.[count]?.length > 0) {
      const reservedSeatsCopy = [...reservedSeats];
      reservedSeatsCopy[index][count] = [seat];
      setReservedSeats(reservedSeatsCopy);
      // setSeatModal(false);
    } else {
      const reservedSeatsCopy = [...reservedSeats];
      if (reservedSeatsCopy[index]?.[count]) {
        reservedSeatsCopy[index]?.[count].push(seat);
      } else {
        if (!reservedSeatsCopy[index]) reservedSeatsCopy[index] = [];
        reservedSeatsCopy[index][count] = [seat];
      }
      setReservedSeats(reservedSeatsCopy);
      // setOpen(true);
      // setSeatModal(false);
    }
  };

  function isSeatSelected() {
    return reservedSeats.map((item, i) => {
      return item;
    });
  }

  return (
    <Modal
      show={seatModal}
      onHide={() => setSeatModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content">
        <div className="modal-header">
          <div className="container">
            <div className="flex flex-col text-center items-center justify-between">
              <div className="flex justify-center">
                <div className="flex justify-between gap-5">
                  <h1 className="seat-selection-header">
                    Seat Selection Passenger {++passenger}
                  </h1>
                  {reservedSeats?.length > 0 &&
                    (count >= seatsData?.length - 1 ? (
                      <button
                        className="py-2 px-4 text-right text-white bg-main rounded-md"
                        onClick={() => setSeatModal(false)}
                      >
                        Done
                      </button>
                    ) : (
                      <button
                        className="py-2 px-4 text-right text-white bg-main rounded-md"
                        onClick={() => setCount(count + 1)}
                      >
                        Next
                      </button>
                    ))}
                </div>
              </div>

              {/* {loading && <Loading search={true} />} */}

              {airSegment instanceof Array && (
                <div className="card-tabs my-2 shadow-sm rounded">
                  {/* <h1 className="text-4xl font-bold px-4 py-2">Agents</h1> */}
                  <Nav as="ul" className="nav nav-tabs">
                    {airSegment?.map((name, index) => (
                      <Nav.Item
                        as="li"
                        key={index}
                        className="nav-item"
                        onClick={() => setCount(index)}
                      >
                        <Nav.Link
                          className="nav-link text-4xl font-bold px-4 py-2"
                          eventKey={name?.name?._attributes?.Origin}
                          active={count === index}
                        >
                          {name?._attributes?.Origin} -{" "}
                          {name?._attributes?.Destination}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              )}
            </div>
            {loading ? (
              <Loading search={true} />
            ) : (
              reservedSeats && (
                <div className="flex flex-row flex-wrap my-3" key={index}>
                  {reservedSeats?.[index]?.map((seatList, i) =>
                    seatList.map((seat) => (
                      <div
                        className="col-md-3 mb-3"
                        key={seat?._attributes?.SeatCode}
                      >
                        <h1>{seat?._attributes?.SeatCode}</h1>
                        {seat?.Characteristic instanceof Array ? (
                          seat?.Characteristic?.map((characteristic, i) => (
                            <h3 key={i} className="ms-2">
                              {"*"}
                              {characteristic?._attributes?.Value}
                            </h3>
                          ))
                        ) : (
                          <h3 className="ms-2">
                            {"* "}
                            {seat?.Characteristic?._attributes?.Value}
                          </h3>
                        )}
                      </div>
                    ))
                  )}
                </div>
              )
            )}

            <div className="seat-selection relative">
              {!loading && !seatsData?.[count]?.Row ? (
                <div className="flex justify-center items-center ">
                  <h1 className="seat-selection-header">
                    Seat map is not available.
                  </h1>
                </div>
              ) : (
                seatsData?.[count]?.Row?.map((seat, i) => {
                  return (
                    <div key={i} className="flex my-3 w-full justify-center ">
                      {seat?.Facility?.map((innerSeat, innerIndex) => (
                        <div
                          key={innerSeat._attributes.SeatCode}
                          className=" px-2  mb-3 "
                        >
                          {innerSeat._attributes.Type === "Aisle" ? (
                            <h1 className="ps-3 mt-3">
                              {
                                seatsData?.[count]?.Row?.[i]?._attributes
                                  ?.Number
                              }
                            </h1>
                          ) : (
                            <button
                              onClick={() => handleClick(innerSeat)}
                              className={
                                "seat-button w-6 h-8 md:w-9 " +
                                (innerSeat._attributes.Availability !==
                                "Available"
                                  ? "btn-reserved"
                                  : isSeatSelected()?.some((seat) => {
                                      return JSON.stringify(seat)?.includes(
                                        JSON.stringify(innerSeat)
                                      );
                                    })
                                  ? "btn-selected"
                                  : innerSeat?._attributes?.Paid === "true"
                                  ? "btn-paid"
                                  : "btn-available")
                              }
                              disabled={
                                innerSeat._attributes.Availability !==
                                  "Available" ||
                                innerSeat?._attributes?.Paid === "true"
                              }
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={innerSeat._attributes.SeatCode}
                            >
                              {innerSeat?._attributes?.SeatCode.split("-")[1]}
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  );
                })
              )}
              {seatsData?.[count]?.Row && (
                <div className="absolute top-5 -left-6">
                  <div className="flex flex-col p-2">
                    <h1 className="font-semibold text-lg mb-1">Legends</h1>
                    <div class="flex items-center">
                      <div class="w-6 h-6 bg-green-500 rounded-full"></div>
                      <p class="ml-2">Available</p>
                    </div>

                    <div class="flex items-center mt-2">
                      <div class="w-6 h-6 bg-blue-700 rounded-full"></div>
                      <p class="ml-2">Selected</p>
                    </div>

                    <div class="flex items-center mt-2">
                      <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
                      <p class="ml-2">Unavailable</p>
                    </div>

                    <div class="flex items-center mt-2">
                      <div class="w-6 h-6 bg-red-600 rounded-full"></div>
                      <p class="ml-2">Paid Seat</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {open && (
              <Modal>
                <div className="modal">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Modal title</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>Modal body text goes here.</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SeatModal;
