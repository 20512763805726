import React, { useState } from "react";
import BookingDetailModal from "./BookingDetailModal";

const BookingDetail = ({ booking }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };
  return (
    <div>
      <div className="cursor-pointer" onClick={handleOpenDetail}>
        Show Detail
      </div>
      <BookingDetailModal
        openDetail={openDetail}
        setOpenDetail={setOpenDetail}
        booking={booking}
      />
    </div>
  );
};

export default BookingDetail;
