import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getMe } from "../../redux2/user/userSlice";
import Loading from "../Loading";
import { getBookingById } from "../../redux2/booking/bookingSlice";
// import { CheckBox } from "@mui/icons-material";
import { createRequest } from "../../redux2/request/requestSlice";
import { toast } from "react-toastify";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";

const SendRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ticketNumber, setTicketNumber] = useState([]);

  const form = useRef();

  const { id } = useParams();

  const { user, isLoading } = useSelector((state) => state.user);
  const {
    booking,
    isLoading: { loading },
  } = useSelector((state) => state.booking);

  const [type, setType] = useState("");
  const [partialRefund, setPartialRefund] = useState(
    new Array(booking?.flight?.ticketNumber.length).fill(false) || []
  );

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBookingById(id));
  }, [dispatch, id]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setTicketNumber([...ticketNumber, name]);
    } else {
      setTicketNumber(ticketNumber.filter((ticket) => ticket !== name));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    const data = {
      bookingId: id,
      companyId: booking?.company?._id,
      corporateId: user?.corporate?._id,
      type: formData.get("type"),
      message: formData.get("message"),
      withdrawMethod: formData.get("withdrawMethod"),
      departureDate: formData.get("departureDate"),
      returnDate: formData.get("returnDate"),
      ticketNumbers:
        booking?.flight?.ticketNumber.length > 1
          ? ticketNumber
          : booking?.flight?.ticketNumber,
    };

    dispatch(createRequest(data));
    navigate("/booking");
    toast.success("Request Sent Successfully");
  };

  const issueDate = new Date(booking?.createdAt);
  const currentDate = new Date(); // Current date and time

  currentDate.setHours(0, 0, 0, 0);

  const isBeforeMidnight = issueDate >= currentDate;

  const error =
    booking?.flight?.ticketNumber?.length > 1 &&
    ticketNumber.filter((v) => v).length < 1;

  if (isLoading || loading) return <Loading />;

  return (
    <div className="relative">
      <section className="pt-14 mx-8">
        <div className="absolute top-10 left-10">
          <Link to="/booking">
            <button className="btn bg-main hover:bg-main-800 text-white">
              Go Back
            </button>
          </Link>
        </div>
        <form
          method="post"
          id="contactForm"
          className="max-w-3xl flex flex-col gap-2 mx-auto font-medium text-md outline-none "
          ref={form}
          onSubmit={handleSubmit}
        >
          <h3 className="font-sans uppercase text-center text-xl">
            Send Request
          </h3>

          <div className="my-1">
            <h2 className="mb-1">From Company Name</h2>
            <input
              className="contact-input-field"
              required
              type="text"
              id="name"
              name="fromCompanyName"
              disabled
              placeholder="From Company Name"
              value={
                user?.corporate?.name ||
                booking?.company?.name ||
                "Sterling travel and tour"
              }
            />
          </div>
          <div className="my-1">
            <h2 className="mb-1">Agent Name</h2>
            <input
              className="contact-input-field"
              required
              type="text"
              id="name"
              name="fromAgentName"
              placeholder="From Agent Name"
              disabled
              value={
                `${user?.firstName} ${user?.lastName}` ||
                `${booking?.agent?.firstName} ${booking?.agent?.lastName}` ||
                ""
              }
            />
          </div>

          <div className="my-1 text-black">
            <h2 className="mb-1">Request Type</h2>
            <select
              name="type"
              id="type"
              className="select-field"
              onChange={(e) => {
                setType(e.target.value);
              }}
              required
            >
              <option value="" hidden>
                Request Type
              </option>
              <option value="Refund">Refund</option>
              <option value="Exchange">Exchange</option>
            </select>
          </div>

          <Box>
            <FormControl
              required
              error={error}
              component="fieldset"
              className="flex flex-col gap-0"
              variant="standard"
            >
              <h2 className="py-2">Ticket Number *</h2>
              <FormGroup>
                {/* <div className="flex gap-0 px-2 my-1">
                  <FormControlLabel
                    className="text-black py-0 -my-2"
                    control={
                      <Checkbox
                        color="default"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            setTicketNumber(
                              booking?.flight?.ticketNumber?.map(
                                (ticket) => ticket
                              )
                            );
                          } else {
                            setTicketNumber([]);
                          }
                        }}
                      />
                    }
                    label="Check All"
                  />
                </div> */}

                {booking?.flight?.ticketNumber?.map((ticket, index) => (
                  <div className="flex gap-0 px-2 my-1" key={index}>
                    {booking?.flight?.ticketNumber.length > 1 ? (
                      (booking?.passengers?.[index].status === "pending" ||
                        booking?.passengers?.[index].status ===
                          "exchanged") && (
                        <>
                          <FormControlLabel
                            error={error}
                            required
                            className="text-black py-0 -my-2"
                            component="fieldset"
                            label={ticket}
                            control={
                              <Checkbox
                                color="default"
                                onChange={handleChange}
                                checked={ticketNumber.includes(ticket)}
                                name={ticket}
                              />
                            }
                          />
                          {/* <FormControlLabel
                          error={error}
                          required
                          className="text-black py-0 -my-2"
                          component="fieldset"
                          label="Partial Refund"
                          control={
                            <Checkbox
                              color="default"
                              name="partialRefund"
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setPartialRefund({
                                  ...partialRefund,
                                  [index]: checked,
                                });

                                if (checked) {
                                  setTicketNumber((prev) => [...prev, ticket]);
                                } else {
                                  setTicketNumber((prev) =>
                                    prev.filter((v) => v !== ticket)
                                  );
                                }
                              }}
                              checked={partialRefund?.[index]}
                            />
                          }
                        /> */}
                        </>
                      )
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            name="ticket"
                            checked={true}
                            disabled
                          />
                        }
                        label={ticket}
                      />
                    )}
                  </div>
                ))}
              </FormGroup>
              {error && (
                <FormHelperText>
                  Please select at least one ticket.
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          {type === "Refund" && (
            <div className="my-1">
              <h2 className="mb-1">WithDraw Method:</h2>
              <select
                name="withdrawMethod"
                id="withdrawMethod"
                className="select-field text-black"
                required
              >
                <option value="" hidden>
                  Withdraw By
                </option>
                <option value="wallet" selected>
                  Return to Wallet
                </option>
                <option value="cheque">Cheque</option>
              </select>
            </div>
          )}

          {type === "Exchange" && (
            <div className="my-1 flex justify-between gap-5">
              <div className="w-1/2">
                <h2 className="mb-1">New Departure Date</h2>
                <input
                  className="contact-input-field text-black"
                  required
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  placeholder="Departure Date"
                  // defaultValue={
                  //   new Date(booking?.flight?.departureTime)
                  //     .toISOString()
                  //     .split("T")[0]
                  // }
                  defaultValue={
                    booking?.flight?.routes?.[0]
                      ? new Date(
                          booking?.flight?.routes?.[0]?.departureTime?.[0] ||
                            booking?.flight?.routes?.[0]?.departureTime
                        )
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  // min={new Date().toISOString().split("T")[0]}
                />
              </div>
              {booking?.flight?.isRoundTrip && (
                <div className="w-1/2">
                  <h2 className="mb-1">New Return Date</h2>
                  <input
                    className="contact-input-field text-black"
                    required
                    type="date"
                    id="returnDate"
                    name="returnDate"
                    placeholder="Return Date"
                    // default date tomorrow
                    defaultValue={
                      booking?.flight?.routes?.[1]
                        ? new Date(
                            booking?.flight?.routes?.[1]?.departureTime?.[0] ||
                              booking?.flight?.routes?.[1]?.departureTime
                          )
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    // min={new Date().toISOString().split("T")[0]}
                  />
                </div>
              )}
            </div>
          )}

          <h2>Message</h2>
          {type === "Exchange" && (
            <p className="text-red-500 text-xs">
              To properly request ticket exchange service, kindly include the
              following information in your message: the exchange date, flight
              time preference, type of flight preference (whether direct or with
              transit) and if there is rerouting then include the destination
              city.
            </p>
          )}
          <textarea
            className="contact-message"
            required
            name="message"
            id="message"
            cols="30"
            rows="6"
            placeholder="Enter Your Message . . ."
          />
          {!error && (
            <button type="submit" className="contact-sbt-button mt-3 ">
              Send Request
            </button>
          )}
        </form>
      </section>
    </div>
  );
};

export default SendRequest;
