import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import axios from "axios";
import API_URL from "../../redux2";

const FlightDetail = () => {
  const [showModal, setShowModal] = useState(false);
  const [flightInfo, setFlightInfo] = useState([]);
  const [fareRule, setFareRule] = useState([]);
  const [flightDetails, setFlightDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // // const depart = query.get("depart");
  // const origin = query.get("origin");
  // const destionation = query.get("destionation");
  // const carrier = query.get("carrier");
  // if (depart instanceof Array) {
  //   depart.map((item) => {
  //     console.log(item);
  //   });
  // } else {
  //   console.log(depart);
  // }

  // let departArray = depart.split("$");

  // console.log(origin);
  // console.log(destionation);
  // console.log(carrier);
  const segmentData = location.state;

  let field = [];

  // add data to field with array of segment and object of passenger

  field.push({
    segments: [],
    passengers: [
      {
        Code: "ADT",
        Quantity: 1,
      },
      {
        Code: "CNN",
        Quantity: 0,
      },
      {
        Code: "INF",
        Quantity: 0,
      },
    ],
  });
  for (let item of segmentData) {
    field[field.length - 1].segments.push({
      Origin: item.Origin,
      Destination: item.Destination,
      Key: item.Key,
      DepartureTime: item.DepartureTime,
      ArrivalTime: item.ArrivalTime,
      FlightNumber: item.FlightNumber,
      Carrier: item.Carrier,
      Equipment: item.Equipment,
      Distance: item.Distance,
      FlightTime: item.FlightTime,
      ChangeOfPlane: item.ChangeOfPlane,
      Group: item.Group,
      OptionalServicesIndicator: item.OptionalServicesIndicator,
      AvailabilitySource: item.AvailabilitySource,
      ParticipantLevel: item.ParticipantLevel,
      LinkAvailability: item.LinkAvailability,
      PolledAvailabilityOption: item.PolledAvailabilityOption,
      AvailabilityDisplayType: item.AvailabilityDisplayType,
      Provider: "1G",
    });
  }

  useEffect(() => {
    setLoading(true);
    axios.post(API_URL + "flight/pricing", field[0]).then((res) => {
      // console.log(res);
      setFlightInfo(
        res?.data?.AirPriceRsp?.AirItinerary?.AirSegment?._attributes
      );
      setFareRule(
        res?.data?.AirPriceRsp?.AirPriceResult?.FareRule?.FareRuleLong
      );
      setFlightDetails(
        res?.data?.AirPriceRsp?.AirPriceResult?.AirPricingSolution
          ?.AirPricingInfo
      );
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   setFlightInfo(data?.AirPriceRsp?.AirItinerary?.AirSegment?._attributes);
  //   setFareRule(data?.AirPriceRsp?.AirPriceResult?.FareRule?.FareRuleLong);
  //   setFlightDetails(
  //     data?.AirPriceRsp?.AirPriceResult?.AirPricingSolution?.AirPricingInfo
  //   );
  // }, []);

  // console.log(fareRule);

  const openModal = () => setShowModal((prev) => !prev);

  function toHour(time) {
    let hour = Math.floor(time / 60);
    let minute = time % 60;
    return `${hour}h ${minute}m`;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen ">
        <div
          className="loader ease-linear rounded-full border-8 border-t-8 border-main h-24 w-24
        "
        ></div>
      </div>
    );
  }

  return (
    <div className="container mt-4 md:mt-8">
      <div className="row">
        <div className="col-md-9">
          <div className="card">
            <div className="row">
              <div className="card-header">
                <div className="col-md-6">
                  <h3 className="card-title">Flight Detail</h3>
                </div>
                <div className="col-md-6">
                  <div className="float-right">
                    <h2 className="card-title">
                      {flightDetails?._attributes?.TotalPrice}
                    </h2>
                    <span>Price per person(including taxes and fees)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <h1 className="text-lg mb-3">
                Depart, {moment(flightInfo.DepartureTime).format("MMMM Do")}
              </h1>
              <div className="row align-middle mb-5 mt-3">
                <div className="col-md-3">
                  <div className="form-group align-middle justify-center">
                    <div className="row">
                      <div className="col-md-5">
                        <img
                          src="https://www.logolynx.com/images/logolynx/d5/d55d1aa6350e1e5374c7e0b7ecb1a007.jpeg"
                          alt=""
                          width="60"
                          height="60"
                        />
                      </div>
                      <div className="col-md-6">
                        <h3> {flightInfo.Carrier}</h3>
                        <h2>Flight {flightInfo.FlightNumber}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <h3>
                      {flightInfo.Origin} - {flightInfo.Destination}
                    </h3>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <h1>
                      {moment(flightInfo.DepartureTime).format("MMMM Do")}
                      <h1>{moment(flightInfo?.DepartureTime).format("LT")}</h1>
                    </h1>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <h1>{moment(flightInfo.ArrivalTime).format("MMMM Do")}</h1>
                    <h1>{moment(flightInfo?.ArrivalTime).format("LT")}</h1>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group float-right">
                    <p>
                      Cabin:{" "}
                      {flightDetails?.BookingInfo?._attributes?.CabinClass}
                    </p>
                    <p>Equipment: {flightInfo.Equipment}</p>
                    <p>Distance: {flightInfo.Distance}</p>
                  </div>
                </div>
              </div>
              <div className="my-5">
                <h2 className="text-md">Baggage Allowance / No baggage fee</h2>
                <div className="row py-2 px-4">
                  <div className="col-md-3">
                    <p>AirLine</p>
                    <p>Units</p>
                    <p>No baggage fee upto</p>
                    <p>From - To / Airline</p>
                  </div>
                  <div className="col-md-3">
                    <p>{flightInfo?.Carrier}</p>
                    <p>
                      {
                        flightDetails?.BaggageAllowances?.BaggageAllowanceInfo
                          ?.TextInfo?.Text[0]?._text
                      }
                    </p>
                    <p>
                      {
                        flightDetails?.BaggageAllowances?.BaggageAllowanceInfo
                          ?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text
                          ?._text
                      }
                    </p>
                    {flightInfo.Origin} - {flightInfo.Destination}
                  </div>
                </div>
              </div>

              <div className="row align-middle my-6">
                {/* add baggage rule */}
                <div className="col-md-6 ">
                  <span>
                    Total Trip Duration: {toHour(flightInfo.TravelTime)}
                  </span>
                </div>
                <div className="col-md-6">
                  <button onClick={openModal} className="float-right">
                    Show Fare Rule
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="d-flex justify-end  ">
            <button className="btn btn-primary">Continue</button>
          </div>
        </div>

        <div className="col-md-3 h-[300px]">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Flight Cost</h3>
            </div>
            <div className="card-body">
              <div className="row text-black">
                <div className="col-md-8">
                  <p>Base Fare</p>
                  <p>Taxes and Fees</p>
                  <p>Total Price</p>
                </div>
                <div className="col-md-4">
                  <p>{flightDetails?._attributes?.ApproximateBasePrice}</p>
                  <p>{flightDetails?._attributes?.Taxes}</p>
                  {/* <p>{flightDetails?._attributes?.OtherCharges}</p> */}
                  <p>{flightDetails?._attributes?.TotalPrice}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4"></div>
      <Modal
        show={showModal}
        onHide={openModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        cancelButton={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Fare Rule</h5>
            <button
              type="button"
              className="btn-close"
              onClick={openModal}
              data-dismiss="modal"
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.854 1.646a.5.5 0 0 1 0 .708L1.707 15.854a.5.5 0 0 1-.708-.708L15.146 1.646a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M1.646 1.646a.5.5 0 0 0 0 .708L15.793 15.854a.5.5 0 0 0 .708-.708L2.354 1.646a.5.5 0 0 0-.708 0z"
                  />
                </svg>
              </i>
            </button>
          </div>
          <div className="modal-body">
            {fareRule.map((item, index) => {
              return (
                <div key={index}>
                  <p>
                    {item._attributes.Category}, - {item._text}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn "
              data-bs-dismiss="modal"
              onClick={openModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FlightDetail;
