import React from "react";
import { Formik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { EmployeeSchema } from "./employee.util";

const EditEmployee = ({
  employee,
  openEmployee,
  setOpenEmployee,
  handleEmployee,
}) => {
  return (
    <Modal
      className="modal fade"
      show={openEmployee}
      onHide={setOpenEmployee}
      size="lg"
    >
      <div className="" role="document">
        <div className="">
          <div className="modal-header">
            <h4 className="modal-title fs-20">Edit User</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpenEmployee(false)}
              data-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close text-gray"></i>
            <div className="add-contact-box">
              <Formik
                initialValues={{
                  firstName: employee?.firstName ?? "",
                  lastName: employee?.lastName ?? "",
                  email: employee?.email ?? "",
                  phone: employee?.phone?.replace("+251", "") ?? "",
                  gender: employee?.gender ?? "",
                  role: employee?.role ?? "",
                }}
                validationSchema={EmployeeSchema}
                onSubmit={handleEmployee}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="flex items-end">
                    <div className="flex flex-col">
                      <div className="row">
                        <div className="col-md-6 mb-8">
                          <TextField
                            fullWidth
                            id="firstName"
                            size="small"
                            variant="outlined"
                            name="firstName"
                            placeholder="First Name"
                            label="First Name"
                            value={values.firstName}
                            // disabled={checker?.firstName && true}
                            onChange={handleChange}
                            error={
                              touched.firstName && Boolean(errors.firstName)
                            }
                            helperText={touched.firstName && errors.firstName}
                          />
                        </div>
                        <div className="col-md-6 mb-8">
                          <TextField
                            fullWidth
                            id="lastName"
                            // disabled={checker?.lastName && true}
                            size="small"
                            variant="outlined"
                            name="lastName"
                            placeholder="Middle Name"
                            label="Middle Name"
                            value={values.lastName}
                            onChange={handleChange}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                          />
                        </div>
                        <div className="col-lg-6 mb-8">
                          <TextField
                            fullWidth
                            id="phone"
                            size="small"
                            variant="outlined"
                            name="phone"
                            disabled={values.phone && true}
                            placeholder="Phone number"
                            label="Phone number"
                            value={values.phone}
                            onChange={handleChange}
                            error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +251
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="col-md-6 mb-8">
                          <TextField
                            fullWidth
                            id="email"
                            size="small"
                            variant="outlined"
                            name="email"
                            placeholder="Email"
                            label="Email"
                            // disabled={checker?.email && true}
                            value={values.email}
                            onChange={handleChange}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </div>
                        <div className="col-md-6 mb-8">
                          <TextField
                            fullWidth
                            size="small"
                            labelid="demo-simple-select-label"
                            id="demo-simple-select"
                            name="gender"
                            value={values.gender}
                            label="Gender"
                            onChange={handleChange}
                            select
                            error={touched.gender && Boolean(errors.gender)}
                            helperText={touched.gender && errors.gender}
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                          </TextField>
                        </div>

                        <div className="col-md-6 mb-8">
                          <TextField
                            fullWidth
                            labelid="demo-simple-select-label"
                            id="demo-simple-select"
                            name="role"
                            size="small"
                            value={values.role}
                            disabled
                            label="Role"
                            onChange={handleChange}
                            select
                            error={touched.role && Boolean(errors.role)}
                            helperText={touched.role && errors.role}
                          >
                            <MenuItem value="agent">Agent</MenuItem>
                            <MenuItem value="employee">Employee</MenuItem>
                            <MenuItem value="member">Member</MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <LoadingButton
                        className="mt-8"
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: "#FBA82C",
                          color: "#fff",
                        }}
                        // loading={isLoading}
                      >
                        Submit
                      </LoadingButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              onClick={() => {
                setOpenEmployee(false);
              }}
              variant="danger"
              className="bg-red-400"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditEmployee;
