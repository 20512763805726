import moment from "moment";
import React, { useMemo } from "react";
import "./style.css";
import { flightSearchList } from "../../../../utils/flightSearchList";
import { getCarrierInfo } from "../../../../utils/flightInfo";
import planeLogo from "../../../../images/planelogo.png";
import customTime from "../../../../utils/customTime";

const TicketDetail = ({
  data,
  passengerList,
  tickets,
  flightDetail,
  fareInfo,
  index,
  seatAssignment,
}) => {
  const calculateDuration = (duration) => {
    let time = moment.duration(duration, "minutes");

    let hours = time.hours();
    let minutes = time.minutes();

    return `${hours}h ${minutes}m`;
  };

  function customDate(date) {
    let d = date?.split("T")?.[0];
    return moment(d).format("dddd DD MMM");
  }

  function getFlightName(code) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      return flightSearchList(code)?.filter((flight) => {
        return flight?.code === code;
      })?.[0]?.city;
    }, [code]);
  }

  return (
    <div
      style={{
        // pageBreakInside: index && index % 2 === 0 ? "always" : "auto",
        pageBreakBefore: index && index % 2 === 0 ? "always" : "auto",
        marginTop: index && index % 2 === 0 ? "35px" : "0px",
      }}
    >
      <div className="line"></div>
      <div className="dep-daycar mt-1 mb-2">
        <div className="flex justify-start space-x-1">
          <img src={planeLogo} alt="" style={{ width: "25px" }} />
          <div className="flex item-center space-x-4">
            <h2 className="">
              DEPARTURE:{" "}
              <span className="font-bold text-lg">
                {customDate(data?._attributes?.DepartureTime)}
              </span>
            </h2>
            <p className="gray-color mt-1">
              Please verify flight times prior to departure
            </p>
          </div>
        </div>
      </div>
      {/* end of departure date header */}
      <div className="pass-details-card ">
        <div className="pass-det gray-bg-color">
          <h2 className="text-2xl font-bolder text-gray-700 tracking-wide">
            {/* {data?._attributes?.Carrier} */}
            {getCarrierInfo(data?._attributes?.Carrier)?.Name}
          </h2>
          <span className="bold">
            {data?._attributes?.Carrier + " " + data?._attributes?.FlightNumber}
          </span>

          <div className="my-1">
            <p>Duration:</p>
            <p>{calculateDuration(flightDetail?.FlightTime)}</p>
            {/* <p>{calculateDuration(data?._attributes?.TravelTime)}</p> */}
          </div>
          <div className="my-1">
            <p>Cabin:</p>
            <p>
              {data?._attributes?.CabinClass} /{" "}
              {data?._attributes?.ClassOfService}
            </p>
          </div>
          <p className="my-1">Status: Confirmed</p>
        </div>
        <div className="pass-det-container">
          <div className="pass-det-2">
            <div className="pass-det-2-top">
              <div className="w-1/2">
                <p className="font-bolder text-lg">
                  {data?._attributes?.Origin}
                </p>
                <p className="tracking-wider">
                  {getFlightName(data?._attributes?.Origin)}
                </p>
              </div>
              <div className="w-1/2">
                <p className="font-bolder text-lg">
                  {data?._attributes?.Destination}
                </p>
                <p className="tracking-wider">
                  {getFlightName(data?._attributes?.Destination)}
                </p>
              </div>
            </div>
            <div className="pass-det-time">
              <div className="dep-time">
                <p className="tracking-wide text-gray-700">
                  Departing At: <br />
                  <span className="font-bold text-2xl">
                    {" "}
                    {customTime(data?._attributes?.DepartureTime)}
                  </span>
                </p>
                <p>Terminal:</p>
                <p className="tracking-wide">
                  {flightDetail?.OriginTerminal
                    ? "Terminal " + flightDetail?.OriginTerminal
                    : "Not Available"}
                </p>
              </div>
              <div className="arr-time">
                <p className="tracking-wide text-gray-700">
                  Arriving At: <br />
                  <span className="font-bold text-2xl">
                    {customTime(data?._attributes?.ArrivalTime)}
                  </span>
                </p>
                <p>Terminal:</p>
                <p className="tracking-wide">
                  {flightDetail?.DestinationTerminal
                    ? "Terminal " + flightDetail?.DestinationTerminal
                    : "Not Available"}
                </p>
              </div>
            </div>
          </div>
          <div className="pass-plane space-y-2 mt-2">
            <h1>Aircraft: {data?._attributes?.Equipment}</h1>
            <h1>Distance (in Miles): {data?._attributes?.Distance}</h1>
            {/* <h1>
              Baggage Allowance:{" "}
              <span className="font-semibold">
                {fareInfo?.BaggageAllowance?.NumberOfPieces?._text}{" "}
              </span>
              Piece(s) Plan
            </h1>
            <h1>Fare Basis: {fareInfo?._attributes?.FareBasis}</h1>
            <h1>
              Not Valid Before:{" "}
              {moment(fareInfo?._attributes?.NotValidBefore).format(
                "MMM DD, YYYY"
              )}
            </h1>
            <h1>
              Not Valid After:{" "}
              {moment(fareInfo?._attributes?.NotValidAfter).format(
                "MMM DD, YYYY"
              )}
            </h1> */}
            {/* <p>
              Meals: <br />
              Snack
            </p> */}
          </div>
        </div>
      </div>
      <div className="pass-bottom mt-12 sm:mt-2 ">
        <div className="bottom-pass-details gray-bg-color">
          <p>Passenger Name:</p>
          <p>Seats:</p>
          <p>eTicket Receipt(s):</p>
        </div>
        <div className="bottom-pass-details">
          <div className="flex flex-col">
            {passengerList?.map((passenger, index) =>
              passenger?.[0]?.Middle ? (
                <p key={index}>
                  {">> "}
                  {passenger?.[0]?.Prefix && passenger?.[0]?.Prefix + ". "}
                  {passenger?.[0]?.First +
                    " " +
                    passenger?.[0]?.Middle +
                    " " +
                    passenger?.[0]?.Last}
                </p>
              ) : (
                <p>
                  {">> "}
                  {passenger?.[0]?.Prefix && passenger?.[0]?.Prefix + ". "}
                  {passenger?.[0]?.First + " " + passenger?.[0]?.Last}
                </p>
              )
            )}
          </div>

          <p className={`${passengerList?.[0]?.Middle ? "-ml-24" : "ml-3"}`}>
            {/* {tickets?.map((_, i) => {
              return seatAssignment?.length > 0 ? (
                <p>
                  {seatAssignment?.[i]?.length > 1
                    ? seatAssignment[i]
                    : seatAssignment
                    ? seatAssignment
                    : "Check-In Required"}
                </p>
              ) : (
                <p>Check-In Required</p>
              );
            })} */}
            {passengerList?.map((seat, i) => {
              return seat?.[1] ? (
                seat?.[1] instanceof Array ? (
                  seat?.[1]?.map(
                    (item) =>
                      data?._attributes?.Key ===
                        item?._attributes?.SegmentRef && (
                        <p>{item?._attributes?.Seat}</p>
                      )
                  )
                ) : data?._attributes?.Key ===
                  seat?.[1]?._attributes?.SegmentRef ? (
                  <p>{seat?.[1]?._attributes?.Seat}</p>
                ) : (
                  <p>Check-In Required</p>
                )
              ) : (
                <p>Check-In Required</p>
              );
            })}
          </p>

          <p>
            {tickets?.map((ticket) => (
              <p>{ticket}</p>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

// {passengerList.length > 1
// ? passengerList?.map((_, i) =>
// airSegment instanceof Array
//   ? airSegment.map((item, index) => {
//       return FareInfoDetail(fareInfo[i]?.FareInfo[index]);
//     })
//   : FareInfoDetail(fareInfo[i]?.FareInfo)
// )
// : airSegment instanceof Array
// ? airSegment.map((item, index) => {
// return FareInfoDetail(fareInfo?.FareInfo[index]);
// })
// : FareInfoDetail(fareInfo?.FareInfo)}

export default TicketDetail;
