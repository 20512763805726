import React, { Fragment } from "react";
/// React router dom
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import logo2 from "../../images/logo2.png";

const NavHader = () => {
  return (
    <div className="absolute z-50 inline-block text-left w-80">
      <Link to="/" className="">
        <Fragment>
          <img
            src={logo}
            alt=""
            // className="px-3 py-8 mt-2 md:block p-2 lg:h-[50px] lg:w-full md:w-3/4 ml-2 m-1 lg:0 w-2/3 md:mt-8 lg:px-0"
            // className="mt-3 mx-auto"

            // className="hidden p-2 px-3 py-6 md:block "
            className="px-3 py-6 md:block p-2 lg:h-[70px] lg:w-11/12 md:w-3/4 ml-2 m-1 lg:0 w-2/3 md:mt-8 lg:px-0"
          />
        </Fragment>
      </Link>
    </div>
  );
};

export default NavHader;
