import { Skeleton } from "@mui/material";
import React from "react";

const DashboardCard = ({ data, isLoading }) => {
  const { result, flightClassCount } = data;

  const cardData = [
    {
      title: "Domestic Tickets",
      amount: result?.localCount || 0,
      percentage: result?.localPercentage || 0,
      color: "bg-slate-100",
    },
    {
      title: "International Tickets",
      amount: result?.internationalCount || 0,
      percentage: result?.internationalPercentage || 0,
      color: "bg-slate-100",
    },
    {
      title: "Economy Tickets",
      amount: flightClassCount?.economyCount || 0,
      percentage: 100 - flightClassCount?.businessPercentage?.toFixed(2) || 0,
      color: "bg-slate-50",
    },
    {
      title: "Business Tickets",
      amount: flightClassCount?.businessCount || 0,
      percentage: flightClassCount?.businessPercentage.toFixed(2) || 0,
      color: "bg-slate-50",
    },
    {
      title: "Total Sold Tickets",
      amount: result?.count || 0,
    },
  ];

  return (
    <div>
      <div className="row pt-4">
        {isLoading ? (
          <>
            <div className="col-xl-3 col-sm-6">
              <Skeleton width="100%" height="180px" />
            </div>
            <div className="col-xl-3 col-sm-6">
              <Skeleton width="100%" height="180px" />
            </div>
            <div className="col-xl-3 col-sm-6">
              <Skeleton width="100%" height="180px" />
            </div>
            <div className="col-xl-3 col-sm-6">
              <Skeleton width="100%" height="180px" />
            </div>
          </>
        ) : (
          cardData.map(({ percentage, amount, title, color }, index) => (
            <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6 " key={index}>
              <div
                className={`card ${index === 4 ? "gradient-5" : color} card-bx`}
              >
                <div className="card-body flex justify-between items-center ">
                  <div className="me-auto text-white font-normal">
                    <h2 className="text-black text-2xl">{amount}</h2>
                    <span className="text-lg  text-black">{title}</span>
                  </div>
                  {/* {icon} */}
                  {percentage && (
                    <div className="text-main font-normal -mt-10">
                      <span className="text-main text-lg font-semibold">
                        {percentage}%
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
