import React, { useState } from "react";

import convertNumbThousand from "../../../utils/convertNumbThousand";
import { getCarrierInfo } from "../../../utils/flightInfo";

const TopAirlinesSells = ({ airlines, isLoading }) => {
  const getCarrier = (code) => {
    return getCarrierInfo(code)?.Name || code;
  };

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderRows = () => {
    const rows = airlines?.topAirlines?.map((agency, index) => (
      <tr key={index} role="row" className={index % 2 === 0 ? "even" : "odd"}>
        <td className="sorting_1">
          <div className="flex flex-row gap-2">
            <p>{++index}</p>
            {index === 1 && (
              <span className="mt-1">
                <svg
                  class="w-5 h-5 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </span>
            )}
          </div>
        </td>
        <td>
          <div className="text-black font-semibold capitalize text-sm">
            <h6>
              {getCarrier(agency?.airlines?.airlineCarrier)} (
              {agency?.airlines?.airlineCarrier})
            </h6>
          </div>
        </td>
        <td>{convertNumbThousand(agency?.airlines?.totalPrice)}</td>
        <td>{convertNumbThousand(agency?.airlines?.systemFare)}</td>
        <td>{convertNumbThousand(agency?.airlines?.serviceCharge)}</td>
        <td>{convertNumbThousand(agency?.airlines?.systemFee)}</td>
        <td>{convertNumbThousand(agency?.airlines?.netRevenue)}</td>
        <td className="text-center text-green-500">
          {/* {convertNumbThousand(agency?.airlines?.ticketCount)} */}
          {airlines?.ticketCountByAirlines?.some(
            (ticket) => ticket._id === agency.airlines.airlineCarrier
          )
            ? airlines?.ticketCountByAirlines.find(
                (ticket) => ticket._id === agency.airlines.airlineCarrier
              )?.ticketCount
            : 0}
        </td>
        <td className="text-center text-green-500">
          {convertNumbThousand(agency?.airlines?.percentageOfTotal)} %
        </td>
      </tr>
    ));

    if (!showMore) {
      return rows?.slice(0, 10); // Show only the first 10 rows
    } else {
      return rows; // Show all rows if showMore is true
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold text-black p-2 mt-4">
        Top Selling Airlines
      </h2>
      <div className="table-responsive">
        <table
          className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer text-gray-700"
          id="example5"
          role="grid"
          aria-describedby="example5_info"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <p>#</p>
              </th>
              <th className="sorting">Airline Name</th>
              <th className="sorting">Total Price</th>
              <th className="sorting">System Fare</th>
              <th className="sorting">Service Charge</th>
              <th className="sorting">System Fee</th>
              <th className="sorting">Net Revenue</th>
              <th className="sorting">Sold Ticket</th>
              <th className="sorting">Percentage</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <tr role="row" class="odd">
                <td>
                  <div class="flex flex-row gap-2">
                    <div class="h-4 bg-gray-200 rounded w-4"></div>
                  </div>
                </td>
                <td>
                  <div class="text-black font-semibold capitalize text-sm">
                    <div class="h-4 bg-gray-200 rounded w-20"></div>
                  </div>
                </td>
                <td>
                  <div class="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div class="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div class="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div class="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div class="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td class="text-center text-green-500">
                  <div class="h-4 bg-gray-200 rounded w-14"></div>
                </td>
              </tr>
            ) : (
              renderRows()
            )}
          </tbody>
        </table>
        {airlines?.topAirlines?.length > 10 && (
          <button
            onClick={handleShowMore}
            className="border-1 border-blue-500 text-black  py-2 px-4 rounded-lg"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default TopAirlinesSells;
