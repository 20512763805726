import React from "react";
import img from "../../images/spin2.svg";

const Loading = ({ search = false }) => {
  const [showTryAgain, setShowTryAgain] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowTryAgain(true);
    }, 30000); // 30 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleTryAgain = () => {
    window.location.reload();
  };

  return (
    <div
      className={`flex flex-col items-center justify-center ${
        search ? "mt-12" : "mt-52"
      }`}
    >
      {showTryAgain ? (
        <button
          onClick={handleTryAgain}
          className="mt-5 bg-main hover:bg-main-900 text-white font-bold py-2 px-4 rounded-md
        focus:outline-none focus:shadow-outline"
        >
          Try Again
        </button>
      ) : (
        <img src={img} alt="Loading" className="w-28 h-28 mx-auto" />
      )}
    </div>
  );
};

export default Loading;
