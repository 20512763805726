import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getAgentsCredit } from "../../redux2/billing/billingSlice";
import Loading from "../../components/Loading";
import { formatNumber } from "../../utils/convertToNumber";

const Credit = () => {
  const { credit, isError, isLoading } = useSelector((state) => state.billing);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgentsCredit());
  }, [dispatch]);

  if (isLoading) return <Loading />;

  return (
    <div className="row lg:mx-10 md:mx-16">
      <div className="col-xl-12 mx-4 lg:mx-0">
        <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap ">
          <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
            <h1 className="text-4xl font-bold px-4 py-2">Agents with Credit</h1>
          </div>
        </div>
        <div className="row mt-6">
          <div className="table-responsive h-screen">
            <table
              className="table card-table default-table dataTablesCard dataTable no-footer shadow-none"
              id="example5"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th className="sorting_asc bg-none"></th>
                  <th className="sorting">Company Name</th>
                  <th className="sorting">Agent Name</th>
                  <th className="sorting">Phone Number</th>
                  <th className="sorting">Available Wallet </th>
                  <th className="sorting">Payable Credit</th>
                  <th className="sorting">Payable USD Credit</th>
                  <th className="sorting">Total Bookings</th>
                  <th className="sorting bg-none"></th>
                </tr>
              </thead>
              {
                <tbody>
                  {isError ? (
                    <tr className="text-danger text-2xl">
                      <td>
                        <p>Error occurred</p>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {credit?.map((company, index) => {
                        return (
                          <tr role="row" className="odd" key={index}>
                            <td className="sorting_1 text-black">{++index}</td>
                            <td>
                              <div className="concierge-bx">
                                <div>
                                  <h4 className="mt-1">{company?.name}</h4>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="concierge-bx">
                                <div>
                                  <h4 className="mt-1">
                                    {company?.user?.firstName}{" "}
                                    {company?.user?.lastName}
                                  </h4>
                                </div>
                              </div>
                            </td>
                            <td>
                              <h4 className="mt-1">{company?.user?.phone}</h4>
                            </td>

                            <td>
                              <div className="text-nowrap">
                                <span className="text-black font-w500">
                                  {formatNumber(company?.wallet.toFixed(2))}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="text-black font-w500">
                                {formatNumber(company?.difference.toFixed(2))}{" "}
                                birr
                              </span>
                            </td>
                            <td>
                              <span className="text-black font-w500">
                                {formatNumber(
                                  company?.USDDifference.toFixed(2)
                                )}{" "}
                                USD
                              </span>
                            </td>
                            <td>
                              <span className="text-black font-w500">
                                {formatNumber(company?.totalBookings)}
                              </span>
                            </td>

                            <td>
                              <Link
                                className="px-2 py-1 text-main text-nowrap hover:text-main-800 hover:bg-main-200 rounded-lg"
                                to={`/agent-billing/${company?._id}`}
                              >
                                <i className="fas fa-link me-2"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
