export const SSRStatus = (status) => {
  switch (status) {
    case "HK":
      return "Confirmed";
    case "NN":
      return "Requested";
    case "PN":
      return "Pending";
    case "UN":
      return "Declined";
    case "KK":
      return "Carrier confirmed";
    case "UC":
      return "Waitlist";
    default:
      return status;
  }
};

export const SSRTypes = (code) => {
  switch (code) {
    case "AVML":
      return "Asian Vegetarian Meal";
    case "BBML":
      return "Baby Meal";
    case "BLML":
      return "Bland Meal";
    case "CHML":
      return "Child Meal";
    case "DBML":
      return "Diabetic Meal";
    case "FPML":
      return "Fruit Platter Meal";
    case "GFML":
      return "Gluten Free Meal";
    case "HNML":
      return "Hindu Meal";
    case "INFT":
      return "Infant Passenger";
    case "KSML":
      return "Kosher Meal";
    case "LCML":
      return "Low Calorie Meal";
    case "LFML":
      return "Low Fat Meal";
    case "LSML":
      return "Low Salt Meal";
    case "MOML":
      return "Muslim Meal";
    case "NLML":
      return "Non-Lactose Meal";
    case "RVML":
      return "Raw Vegetarian Meal";
    case "SFML":
      return "Seafood Meal";
    case "VGML":
      return "Vegetarian Meal";
    case "VJML":
      return "Vegetarian Jain Meal";
    case "VLML":
      return "Vegetarian Lacto-Ovo Meal";
    case "VOML":
      return "Vegetarian Oriental Meal";
    case "BLND":
      return "Blind Passenger";
    case "DEAF":
      return "Deaf Passenger";
    case "WCHR":
      return "Wheelchair";
    case "WCHC":
      return "Wheelchair Cabin Seat";
    case "DOCS":
      return "Document Holder";
    case "TKNE":
      return "Ticket Number";
    default:
      return code;
  }
};
