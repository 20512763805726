import axios from "axios";
import API_URL from "../redux2";

const getBookingReport = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.get(API_URL + "booking/report", {
      ...config,
      params: query,
      query: query,
    });

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export { getBookingReport };
