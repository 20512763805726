import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Search } from "@mui/icons-material";
import Agent from "./Agent";
import Employees from "../../components/Employees/Employees";
import moment from "moment";
import excelExport from "../../utils/excelExport";
import Enterprise from "../../components/Enterprise/Enterprise";

const Agents = () => {
  const [current, setCurrent] = useState("Agents");
  const [agents, setAgents] = useState([]);
  const [enterprise, setEnterprise] = useState([]);

  const NavData = [
    { name: "Agents" },
    { name: "Enterprise" },
    { name: "Employees" },
  ];

  const renderButton = () => {
    switch (current) {
      case "Agents":
        return <Agent setAgents={setAgents} />;
      case "Employees":
        return <Employees />;
      case "Enterprise":
        return <Enterprise setEnterprise={setEnterprise} />;
      default:
        return <Agent setAgents={setAgents} />;
    }
  };

  function handleExport() {
    let name = "Company" + moment().format("DD-MM-YYYY");
    const data = agents?.map((agent) => {
      return {
        Agent: agent.user.firstName + " " + agent.user.lastName,
        Email: agent.user.email,
        OfficeNumber: agent.officeNumber,
        Company: agent.name,
        Address: agent.address,
      };
    });
    excelExport(data, name);
  }

  return (
    <div className="row lg:mx-32 md:mx-16">
      <div className="col-xl-12 mx-4 lg:mx-0">
        <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap ">
          <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4 shadow-sm rounded">
            {/* <h1 className="text-4xl font-bold px-4 py-2">Agents</h1> */}
            <Nav as="ul" className="nav nav-tabs">
              {NavData.map(({ name }, index) => (
                <Nav.Item
                  as="li"
                  key={index}
                  className="nav-item"
                  onClick={() => setCurrent(name)}
                >
                  <Nav.Link
                    className="nav-link text-4xl font-bold px-4 py-2"
                    eventKey={name}
                    active={current === name}
                  >
                    {name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          {/* <div className="table-search">
            <div className="input-group search-area mb-xxl-0 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                on
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <Search size={35} className="text-main" />
                </Link>
              </span>
            </div>
          </div> */}
          <button
            className="btn bg-main hover:bg-main-800 text-white mb-xxl-0 "
            onClick={handleExport}
          >
            <i className="far fa-file-word me-2"></i>
            Generate Report
          </button>
        </div>
        {renderButton()}
        {/* <Agent /> */}
      </div>
    </div>
  );
};
export default Agents;
