import { RangeDatePicker } from "@y0c/react-datepicker";
import React from "react";
import Select from "react-select/";

const CustomDateSelector = ({ rangeDate, setRangeDate, setDurationSelect }) => {
  return (
    <div className="d-flex mb-2 justify-content-between align-items-center flex-wrap justify-between">
      <div className="col-xl-6 col-4 col-xxl-3 mt-3 mt-sm-0 mb-xxl-0  px-2">
        <Select
          options={[
            { value: "All", label: "All" },
            { value: "Today", label: "Today" },
            { value: "This_Week", label: "This Week" },
            { value: "This_Month", label: "This Month" },
            { value: "Last_Month", label: "Last Month" },
            { value: "This_Year", label: "This Year" },
          ]}
          style={{
            lineHeight: "40px",
            color: "#7e7e7e",
            paddingLeft: " 15px",
          }}
          onChange={(e) => setDurationSelect(e.value)}
        />
      </div>
      {/* <div className="col-xl-3 col-xxl-3"></div> */}
      <div className="col-xl-5 col-xxl-3 mt-3 mt-sm-0 mb-xxl-0  px-0 ">
        <RangeDatePicker
          startText="Start"
          endText="End"
          startPlaceholder="Start Date"
          endPlaceholder="End Date"
          value={rangeDate}
          onChange={(start, end) => {
            setRangeDate([start, end]);
            setDurationSelect("Custom");
          }}
          size="small"
          showMonthCnt={1}
          disableDay={(date) =>
            date > new Date() || date < new Date("2022-01-01")
          }
        />
      </div>
    </div>
  );
};

export default CustomDateSelector;
