import React from "react";
import ReactApexChart from "react-apexcharts";

const AverageNightlyRateChart = ({ data }) => {
  let series1 = data?.map((item) => item.count);

  return (
    <div id="chart">
      <ReactApexChart
        options={{
          chart: {
            height: 400,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          colors: ["#FCD15C"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 6,
            curve: "smooth",
          },
          legend: {
            show: false,
          },
          grid: {
            borderColor: "#EBEBEB",
            strokeDashArray: 6,
          },
          markers: {
            strokeWidth: 6,
            hover: {
              size: 15,
            },
          },
          yaxis: {
            labels: {
              offsetX: -12,
              style: {
                colors: "#787878",
                fontSize: "13px",
                fontFamily: "Poppins",
                fontWeight: 400,
              },
            },
          },
          xaxis: {
            categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            labels: {
              style: {
                colors: "#787878",
                fontSize: "13px",
                fontFamily: "Poppins",
                fontWeight: 400,
              },
            },
          },
          fill: {
            type: "solid",
            opacity: 0.1,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        }}
        series={[
          {
            name: "Weekly Ticket Rate",
            data: series1,
          },
        ]}
        type="area"
        height={400}
      />
    </div>
  );
};

export default AverageNightlyRateChart;
