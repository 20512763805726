import { Dialog, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useState } from "react";
import FlightFormMobile from "./FlightFormMobile";
import { useTimeoutFn } from "react-use";

import useWindowSize from "../../../hooks/useWindowResize";
import { useSelector } from "react-redux";

const SearchFlightMobile = () => {
  const { user } = useSelector((state) => state.auth);
  const windowSize = useWindowSize();
  // if window screen > 640px then show modal
  const [showModal, setShowModal] = useState(
    windowSize.width < 1024 ? true : false
  );

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  // get data from flight form
  const [destination, setDestination] = useState("");

  useEffect(() => {
    if (windowSize.width > 1024) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [windowSize]);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <div className="py-3 px-2">
        <div className="px-4 mb-10 font-bold text-4xl">
          <h1 className=" ">Welcome Back, </h1>
          <h1 className="capitalize text-main">
            {user?.firstName} {user?.lastName}!
          </h1>
          <div className="w-40 h-2 bg-neutral-200 dark:bg-neutral-700 mx-auto rounded-full mt-3"></div>
        </div>

        <button
          onClick={openModal}
          className="relative flex items-center w-full border-1 border-neutral-400  px-4 py-2 pr-11 rounded-xl shadow-lg h-20"
        >
          <SearchIcon className="flex-shrink-0 w-7 h-7" />

          <div className="ml-3 flex-1 text-left overflow-hidden">
            <span className="block font-medium text-base">Where to?</span>
            <span className="block mt-0.5 text-sm font-light text-neutral-500 dark:text-neutral-400 line-clamp-1">
              Anywhere • Any week • Add guests
            </span>
          </div>

          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-12 h-12 flex items-center justify-center rounded-full border border-blue-500 dark:border-blue-600 dark:text-neutral-300">
            <svg
              viewBox="0 0 16 16"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              className="block w-4 h-4"
              fill="currentColor"
            >
              <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
            </svg>
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className="HeroSearchForm2Mobile mt-10">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative mt-28 z-0"
          onClose={closeModal}
        >
          <div className="fixed mt-16 inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog === true && (
                    <>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-b border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <Dialog.Title className="text-lg font-medium text-neutral-900 dark:text-neutral-100">
                          Search flights
                        </Dialog.Title>
                        <button
                          type="button"
                          className="text-neutral-500 dark:text-neutral-400 hover:text-neutral-600 dark:hover:text-neutral-300"
                          onClick={() => {
                            resetIsShowingDialog();
                            closeModal();
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="w-5 h-5" />
                        </button>
                      </div>
                      {/* <Tab.Group manual>
                        <div className="absolute left-4 top-4">
                          <button className="" onClick={closeModal}>
                            <XIcon className="w-5 h-5 text-black dark:text-white" />
                          </button>
                        </div> */}

                      {/* <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                          {["Flights"].map((item, index) => (
                            <Tab key={index} as={Fragment}>
                              {({ selected }) => (
                                <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                                  <div
                                    className={`${
                                      selected
                                        ? "text-black dark:text-white"
                                        : ""
                                    }  `}
                                  >
                                    {item}
                                  </div>
                                  {selected && (
                                    <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                  )}
                                </div>
                              )}
                            </Tab>
                          ))}
                        </Tab.List> */}
                      <div className="flex-1 pt-2 px-1 flex overflow-hidden">
                        <div className="flex-1 overflow-y-auto py-3">
                          <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                            <FlightFormMobile
                              setDestination={setDestination}
                              destination={destination}
                              closeModal={closeModal}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button>
                        <Link to={`/search-result?origin=${origin}`}>
                          <ButtonPrimary
                            onClick={() => {
                              closeModal();
                            }}
                            navigateTo="/search"
                          >
                            Search
                          </ButtonPrimary>
                        </Link>
                      </div> */}
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SearchFlightMobile;
