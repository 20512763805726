import React, { useState, useEffect, useMemo } from "react";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { useSelector } from "react-redux";
import Select from "react-select";
import "react-calendar/dist/Calendar.css";

import { USER_ROLE_SUPERAGENT } from "../../common/constants";
import DashboardItems from "./Components/dashboardItems";

const Dashboard = () => {
  const [rangeDate, setRangeDate] = useState([]);
  const [durationSelect, setDurationSelect] = useState("");

  const { user } = useSelector((state) => state.auth);

  const currentDate = new Date();
  let startDate, endDate;

  if (user?.role !== USER_ROLE_SUPERAGENT) {
    window.location.href = "/";
  }

  useEffect(() => {
    if (rangeDate?.[1] !== undefined) {
      setDurationSelect("Custom");
    }
  }, [rangeDate]);

  switch (durationSelect) {
    case "This_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "Last_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "This_Week":
      const firstDayOfWeek = new Date(currentDate);
      firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

      startDate = new Date(firstDayOfWeek);
      endDate = new Date(firstDayOfWeek);
      endDate.setDate(firstDayOfWeek.getDate() + 6);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "This_Year":
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      endDate = new Date(currentDate.getFullYear(), 11, 31);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "Today":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59
      );
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "Custom":
      startDate = new Date(rangeDate?.[0] || null);
      endDate = new Date(rangeDate?.[1] || null);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;

    default:
      startDate = null;
      endDate = null;
      break;
  }

  const query = useMemo(() => {
    return {
      startDate: startDate ? startDate?.toISOString() : "",
      endDate: endDate ? endDate?.toISOString() : "",
    };
  }, [startDate, endDate]);

  return (
    <div className="lg:mx-32 md:mx-16 p-2">
      <div className="row">
        <div className="col-xl-12">
          <div className="d-flex mb-2 justify-content-between align-items-center flex-wrap justify-between">
            <div className="col-xl-3 col-xxl-3 mt-3 mt-sm-0 mb-xxl-0  px-2">
              <Select
                options={[
                  { value: "All", label: "All" },
                  { value: "Today", label: "Today" },
                  { value: "This_Week", label: "This Week" },
                  { value: "This_Month", label: "This Month" },
                  { value: "Last_Month", label: "Last Month" },
                  { value: "This_Year", label: "This Year" },
                ]}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
                onChange={(e) => setDurationSelect(e.value)}
              />
            </div>
            {/* <div className="col-xl-3 col-xxl-3"></div> */}
            <div className="col-xl-3 col-xxl-3 mt-3 mt-sm-0 mb-xxl-0  px-2">
              <RangeDatePicker
                startText="Start"
                endText="End"
                startPlaceholder="Start Date"
                endPlaceholder="End Date"
                value={rangeDate}
                size="small"
                showMonthCnt={1}
                disableDay={(date) =>
                  date > new Date() || date < new Date("2022-01-01")
                }
                onChange={(start, end) => setRangeDate([start, end])}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <DashboardItems query={query} />
          {/* <div className=" ">
            <TopSellsAgencies title="Total" state="total" query={query} />
          </div>
          <div className=" ">
            <TopAirlinesSells title="Total" state="total" query={query} />
          </div> */}

          {/* <div className="col-xl-6 col-xxl-8 ">
            <SpendVSSaving title="Total" state="total" />
          </div> */}
          {/* <div className="col-xl-6 col-xxl-4">
            <AverageNightlyRate />
          </div> */}
          {/* <div className="col-xl-6 col-xxl-8">
            <SpendVSSaving title="Sales" />
          </div> */}
          {/* <div className="col-xl-6 col-xxl-4">
            <div className="card">
              <div className="card-body py-0">
                <div className="d-flex justify-content-between mb-3">
                  <h4 className="card-title">Newest Booking</h4>
                  <Link to={"/booking"} className="btn-link">
                    More
                  </Link>
                </div>
                <div className="row">
                  {booking?.length === 0 ? (
                    <div className="col-xl-12 col-sm-12">
                      <Typography variant="h4">No booking found</Typography>
                    </div>
                  ) : (
                    booking?.map((booking, index) => (
                      <div className="col-xl-12 col-sm-12" key={index}>
                        <div className="dz-image-bx rounded">
                          <div className="dz-info">
                            <h5>{booking?.name}</h5>
                            <span className="text-primary">
                              {moment(booking?.bookingDate).format(
                                "YYYY-MM-DD"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
