import * as Yup from "yup";

export const PhoneCheckerSchema = () =>
  Yup.object({
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
  });

export const EmployeeSchema = () =>
  Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
    gender: Yup.string().required("Gender is required"),
    // groupId: Yup.string().required("Group is required"),
    role: Yup.string().required("Role is required"),
    // nightLimit: Yup.number()
    //   .min(0, "No of night can't be below 0")
    //   .required("No of night is required"),
    // bookingLimit: Yup.number()
    //   .min(0, "Monthly booking limit can't be below 0")
    //   .required("Monthly booking limit is required"),
    // budgetLimit: Yup.number()
    //   .min(0, "Budget for night can't be below 0")
    //   .required("Budget for night is required"),
  });

export const EmployeeSchema1 = () =>
  Yup.object({
    group: Yup.string().required("Group is required"),
    Role: Yup.string().required("Role is required"),
    noOfNight: Yup.number()
      .min(0, "No of night can't be below 0")
      .required("No of night is required"),
    monthlyBookingLimit: Yup.number()
      .min(0, "Monthly booking limit can't be below 0")
      .required("Monthly booking limit is required"),
    budgetForNight: Yup.number()
      .min(0, "Budget for night can't be below 0")
      .required("Budget for night is required"),
  });

export const formatPhone = (phone) => {
  if (phone.startsWith("0")) {
    return phone;
  } else {
    return `0${phone}`;
  }
};
