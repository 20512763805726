import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";

import { TicketPrint } from "../../Pages/Home/Components/TicketPrint";
import { SSRStatus, SSRTypes } from "../../utils/SSR";

const CustomPrintTicketPage = () => {
  const componentRef = useRef();
  const componentRef2 = useRef();
  const printWrapperRef = useRef();

  const [selectedPassenger, setSelectedPassenger] = useState("all");

  const location = useLocation();
  let { ticketData, flightInfo, passengerInfo, companyInfo } = location.state;

  if (!ticketData) {
    toast.warning("No ticket data found");
    window.history.back();
  }

  if (ticketData?.AirReservation?.DocumentInfo?.TicketInfo instanceof Array) {
    ticketData.AirReservation.DocumentInfo.TicketInfo =
      ticketData?.AirReservation?.DocumentInfo?.TicketInfo?.filter((ticket) => {
        return (
          ticket?._attributes?.Status === "N" ||
          ticket?._attributes?.Status === "R"
        );
      });
  }
  let activeTicket = ticketData?.AirReservation?.DocumentInfo?.TicketInfo;

  passengerInfo = passengerInfo.filter((passenger) => {
    return passenger?.status === "pending" || passenger?.status === "approved";
  });

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // return printWrapperRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });

  const getSegmentByKey = (key) => {
    return ticketData?.AirReservation?.AirSegment?.find(
      (segment) => segment?._attributes?.Key === key
    )?._attributes;
  };

  let passengerList = [];
  let passengerNames = [];
  if (ticketData?.BookingTraveler instanceof Array) {
    passengerList = ticketData?.BookingTraveler?.map((passenger) => {
      if (activeTicket) {
        const isPassengerIncluded = activeTicket?.some(
          (ticket) =>
            ticket?._attributes?.BookingTravelerRef ===
            passenger?._attributes?.Key
        );
        if (isPassengerIncluded) {
          passengerNames.push
            ? passengerNames.push(passenger?.BookingTravelerName?._attributes)
            : passengerNames.push(passenger?.BookingTravelerName?._attributes);

          return [
            passenger?.BookingTravelerName?._attributes,
            passenger?.AirSeatAssignment,
          ];
        }
      } else {
        return [
          passenger?.BookingTravelerName?._attributes,
          passenger?.AirSeatAssignment,
        ];
      }
    });
  } else {
    passengerList.push([
      ticketData?.BookingTraveler?.BookingTravelerName?._attributes,
      ticketData?.BookingTraveler?.AirSeatAssignment,
    ]);
    passengerNames.push(
      ticketData?.BookingTraveler?.BookingTravelerName?._attributes
    );
  }

  let ticketNumbers = [];
  let ticket =
    ticketData?.AirReservation?.DocumentInfo?.TicketInfo?._attributes?.Number ||
    ticketData?.AirReservation?.DocumentInfo?.TicketInfo;
  if (ticket instanceof Array) {
    ticketNumbers = ticket.map((item) => {
      return item?._attributes?.Number;
    });
  } else {
    ticketNumbers.push(ticket);
  }

  let fareInfo = ticketData?.AirReservation?.AirPricingInfo;
  let seatAssignment = [];

  // if (ticketData?.BookingTraveler?.AirSeatAssignment) {
  //   if (ticketData?.BookingTraveler?.AirSeatAssignment instanceof Array) {
  //     seatAssignment = ticketData?.BookingTraveler?.AirSeatAssignment?.map(
  //       (passenger) => {
  //         return passenger?._attributes?.Seat;
  //       }
  //     );
  //   } else {
  //     seatAssignment.push(
  //       ticketData?.BookingTraveler?.AirSeatAssignment?._attributes?.Seat
  //     );
  //   }
  // }

  if (ticketData?.BookingTraveler instanceof Array) {
    ticketData?.BookingTraveler?.forEach((traveler, index) => {
      if (!seatAssignment[index]) {
        seatAssignment[index] = [];
      }
      if (traveler?.AirSeatAssignment instanceof Array) {
        traveler?.AirSeatAssignment.forEach((seat) => {
          if (seat?._attributes?.Status === "HK") {
            seatAssignment?.[index].push(seat?._attributes?.Seat);
          }
        });
      } else {
        if (traveler?.AirSeatAssignment?._attributes?.Status === "HK") {
          seatAssignment?.[index].push(
            traveler?.AirSeatAssignment?._attributes?.Seat
          );
        }
      }
    });
  } else {
    if (ticketData?.BookingTraveler?.AirSeatAssignment instanceof Array) {
      ticketData?.BookingTraveler?.AirSeatAssignment.forEach((seat) => {
        if (seat?._attributes?.Status === "HK") {
          seatAssignment.push(seat?._attributes?.Seat);
        }
      });
    } else {
      if (
        ticketData?.BookingTraveler?.AirSeatAssignment?._attributes?.Status ===
        "HK"
      ) {
        seatAssignment.push(
          ticketData?.BookingTraveler?.AirSeatAssignment?._attributes?.Seat
        );
      }
    }
  }

  let passportInfo = passengerInfo;
  if (selectedPassenger !== "all") {
    passengerList = passengerList.filter((passenger, index) => {
      return (
        passenger?.[0]?.First?.trim() + " " + passenger?.[0]?.Last.trim() ===
        selectedPassenger
      );
    });
    ticketNumbers =
      ticketData?.AirReservation?.DocumentInfo?.TicketInfo?.filter((ticket) => {
        return (
          ticket?.Name?._attributes?.First.trim() +
            " " +
            ticket?.Name?._attributes?.Last.trim() ===
          selectedPassenger
        );
      })?.[0]?._attributes?.Number;
    ticketNumbers = [ticketNumbers];

    passportInfo = passengerInfo.filter((passenger) => {
      return (
        passenger?.firstName.trim() + " " + passenger?.lastName.trim() ===
        selectedPassenger
      );
    });
  }

  // remove undefined values from passengerList
  passengerList = passengerList.filter((passenger) => passenger !== undefined);

  // ticketData?.BookingTraveler?.AirSeatAssignment?._attributes?.Status ===
  //   "HK" && ticketData?.BookingTraveler?.AirSeatAssignment?._attributes?.Seat;

  return (
    <>
      <div className="xl:mx-80 my-4">
        {passengerNames?.length > 1 && (
          <select
            className="mx-10 block border-1 text-black text-[15px] border-black w-1/4 rounded-lg  bg-white p-1"
            onChange={(e) => setSelectedPassenger(e.target.value)}
          >
            <option value="all">All</option>
            {passengerNames?.map((passenger, index) => (
              <option key={index}>
                <h3>
                  {/* {passenger?.[0]?.Prefix && passenger?.[0]?.Prefix + ". "} */}
                  {passenger?.First + " " + passenger?.Last}
                </h3>
              </option>
            ))}
          </select>
        )}
        <div ref={printWrapperRef}>
          <div style={{ pageBreakAfter: "always" }}>
            <TicketPrint
              passengerList={passengerList}
              airReservation={ticketData?.AirReservation}
              reservation={ticketData?.ProviderReservationInfo}
              ticketNumbers={ticketNumbers}
              flightDestination={flightInfo.destination}
              passengerInfo={passportInfo}
              ref={componentRef}
              companyInfo={companyInfo?._id}
              seatAssignment={seatAssignment}
            />
          </div>
          <div className="mx-10">
            <h1 className="text-xl font-bold underline">SSR</h1>
            {ticketData?.BookingTraveler?.length > 0 ? (
              ticketData?.BookingTraveler?.map((data, index) => (
                <div className="flex flex-col space-y-1" key={index}>
                  <h1 className="text-lg font-bold">Passenger {++index}</h1>
                  {data?.SSR?.length > 0
                    ? data?.SSR?.map((ssr, i) => (
                        <div
                          key={i}
                          className="flex flex-row px-2 py-1 gap-5 rounded-xl mx-3"
                        >
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(ssr?._attributes?.Type)} (
                            {ssr?._attributes?.Type})
                          </h1>
                          <h1 className="px-2 text-gray-500 w-1/4">
                            {SSRStatus(ssr?._attributes?.Status)} (
                            {ssr?._attributes?.Status})
                          </h1>
                          {ticketData?.AirReservation?.AirSegment.length && (
                            <h1 className="px-2 text-gray-500">
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Origin
                              }
                              {" - "}
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Destination
                              }
                            </h1>
                          )}
                        </div>
                      ))
                    : data?.SSR?._attributes && (
                        <div className="flex flex-row px-2 py-1 gap-5  rounded-xl mx-3 ">
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(data?.SSR?._attributes?.Type)} (
                            {data?.SSR?._attributes?.Type})
                          </h1>
                          <h1 className="px-2 text-gray-500">
                            {SSRStatus(data?.SSR?._attributes?.Status)} (
                            {data?.SSR?._attributes?.Status})
                          </h1>
                        </div>
                      )}
                </div>
              ))
            ) : (
              <div className="">
                <div className="flex flex-col space-y-1">
                  {ticketData?.BookingTraveler?.SSR?.length > 0
                    ? ticketData?.BookingTraveler?.SSR?.map((ssr, i) => (
                        <div
                          key={i}
                          className="flex flex-row px-2 py-1 gap-5 rounded-xl mx-3"
                        >
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(ssr?._attributes?.Type)} (
                            {ssr?._attributes?.Type})
                          </h1>
                          <h1 className="px-2 text-gray-500 w-1/4">
                            {SSRStatus(ssr?._attributes?.Status)} (
                            {ssr?._attributes?.Status})
                          </h1>
                          {ticketData?.AirReservation?.AirSegment.length && (
                            <h1 className="px-2 text-gray-500">
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Origin
                              }
                              {" - "}
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Destination
                              }
                            </h1>
                          )}
                        </div>
                      ))
                    : ticketData?.BookingTraveler?.SSR?._attributes && (
                        <div className="flex flex-row px-2 py-1 gap-5  rounded-xl mx-3 ">
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Type
                            )}{" "}
                            (
                            {
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Type
                            }
                            )
                          </h1>
                          <h1 className="px-2 text-gray-500">
                            {SSRStatus(
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Status
                            )}{" "}
                            (
                            {
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Status
                            }
                            )
                          </h1>
                        </div>
                      )}
                </div>
              </div>
            )}
          </div>
          {/* <div ref={componentRef2} className="mx-10 text-xs">
            <>
              <div className="mt-4 mx-auto p-2 border-1 border-black">
                <h1 className="text-md font-semibold py-2">Traveler</h1>
                <div className="flex flex-row justify-between">
                  <div>
                    <h2 className="font-semibold">Passenger Name:</h2>
                    {passengerList.map((passenger, index) => (
                      <div className="">
                        <div className="space-y-1">
                          {passenger?.Middle ? (
                            <h3 key={index}>
                              {passenger?.Prefix && passenger?.Prefix + ". "}
                              {passenger?.First +
                                " " +
                                passenger?.Middle +
                                " " +
                                passenger?.Last}
                            </h3>
                          ) : (
                            <h3>
                              {passenger?.Prefix && passenger?.Prefix + ". "}
                              {passenger?.First + " " + passenger?.Last}
                            </h3>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <h2 className="font-semibold">e-Ticket Number:</h2>
                    <div className="">
                      {ticketNumbers?.map((ticket) => (
                        <h3>{ticket}</h3>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h2 className="font-semibold">Reservation Number:</h2>
                    <h3>
                      {ticketData?.AirReservation?._attributes?.LocatorCode}
                    </h3>
                  </div>
                  <div>
                    <h2 className="font-semibold">Ticket Issue Date:</h2>
                    <h3>
                      {moment(
                        ticketData?.AirReservation?._attributes?.CreateDate
                      ).format("DD-MM-YYYY")}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="my-2 mx-auto p-2 border-1 border-black">
                <h1 className="text-md font-semibold py-2">Agency Detail</h1>
                <div className="flex gap-4">
                  <div className="w-1/4">
                    <div className="space-y-1">
                      <h2 className="font-semibold">Agency Information:</h2>
                      <h3>Sterling Travel and Tour Agency</h3>
                      <h3>Head Office: Near the National Theater </h3>
                      <h3>Addis Ababa</h3>
                      <h3>Ethiopia</h3>
                    </div>
                  </div>
                  <div className="w-1/4 tracking-wide">
                    <div>
                      <h2 className="font-semibold">Agency Office Phone:</h2>
                      <h3>+251 115 511333 </h3>
                      <h3>+251 115 514666</h3>
                    </div>
                  </div>
                  <div className="w-1/4 tracking-wide">
                    <div>
                      <h2 className="font-semibold">Agency Mobile Phone:</h2>
                      <h3>+251 922 238193</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 mx-auto p-2 border-1 border-black">
                <h1 className="text-md font-semibold py-2">
                  Flight Information
                </h1>
                <div className="flex flex-col">
                  {passengerList.length > 1
                    ? passengerList?.map((_, i) =>
                        ticketData?.AirReservation?.AirSegment instanceof Array
                          ? ticketData?.AirReservation?.AirSegment.map(
                              (item, index) => {
                                return FlightDetail(
                                  fareInfo?.[i]?.FareInfo[index],
                                  item?._attributes,
                                  i
                                );
                              }
                            )
                          : FlightDetail(
                              fareInfo[i]?.FareInfo,
                              ticketData?.AirReservation?.AirSegment._attributes
                            )
                      )
                    : ticketData?.AirReservation?.AirSegment instanceof Array
                    ? ticketData?.AirReservation?.AirSegment.map(
                        (item, index) => {
                          return FlightDetail(
                            fareInfo?.FareInfo[index],
                            item?._attributes
                          );
                        }
                      )
                    : FlightDetail(
                        fareInfo?.FareInfo,
                        ticketData?.AirReservation?.AirSegment?._attributes
                      )}
                </div>
              </div>

              <div className="my-2 mx-auto p-2 border-1 border-black">
                <h1 className="text-md font-semibold py-2">Notes</h1>
                <h3 className="text-sm py-2">
                  Baggage discounts may apply based on frequent flyer
                  status/online checkin/form of payment/military/etc.
                </h3>
                <div className="flex flex-row justify-between">
                  <div>
                    <h1 className="text-lg text-slate-800">
                      IMPORTANT INFORMATION FOR TRAVELERS WITH ELECTRONIC
                      TICKETS • PLEASE READ:{" "}
                    </h1>
                    <h4 className="text-slate-600 tracking-wide">
                      Carriage and other services provided by the carrier are
                      subject to conditions of carriage, which are hereby
                      incorporated by reference. These conditions may be
                      obtained from the issuing carrier. Passengers on a journey
                      involving an ultimate destination or a stop in a country
                      other than the country of departure are advised that
                      international or its predecessor, the Warsaw Convention,
                      including its amendments (the Warsaw Convention System),
                      may apply to the treaties known as the Montreal
                      Convention, entire journey, including any portion thereof
                      within a country. For such passengers, the applicable
                      treaty, including special contracts of carriage embodied
                      in any applicable in tariffs, governs and may limit the
                      liability of the carrier. The carriage of certain
                      hazardous materials, like aerosols, fireworks, and
                      flammable liquids, aboard the aircraft is forbidden. If
                      you do not understand these restrictions, further
                      information may be obtained from your airline. be
                      processed in accordance with the applicable carrier's
                      privacy policy and, where your booking is made via a
                      reservation Data Protection Notice: Your personal data
                      will These are available at
                      http://www.latatravelcenter.com/privacy or from the
                      carrier or GDS directly. You should read this system
                      provider ("GDS"), with its privacy policy. documentation,
                      which applies to your booking and specifies, for example,
                      how your personal data is collected, stored, used,
                      disclosed and transferred.
                    </h4>
                  </div>
                </div>
              </div>
            </>
          </div> */}
        </div>
      </div>
      <div className="flex justify-center p-6 mt-10 gap-6 space-x-6">
        <button
          className="p-3 outline rounded-md text-black bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
          onClick={handlePrint}
        >
          Download Ticket
        </button>
        <button
          className="p-3 outline rounded-md text-black bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl
          focus:outline-none"
          onClick={handlePrint}
        >
          Print Ticket
        </button>
      </div>
    </>
  );
};

function FlightDetail(fareInfo, segment, index) {
  return (
    <>
      <h1 className="py-1 font-bold text-md ">
        Flight: {segment?.Origin} - {segment?.Destination} - Passenger (
        {++index || 1})
      </h1>
      <div className="flex flex-row justify-between px-4">
        <div>
          <h2 className="font-semibold">Baggage Allowance</h2>
          <div className="">
            <div className="space-y-1">
              {fareInfo?.BaggageAllowance?.NumberOfPieces ? (
                <h3>
                  <span className="font-semibold">
                    {fareInfo?.BaggageAllowance?.NumberOfPieces?._text}{" "}
                  </span>
                  Piece(s) Plan
                </h3>
              ) : (
                <h3>
                  <span className="font-semibold">
                    {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Value}{" "}
                  </span>
                  {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Unit}{" "}
                  Plan
                </h3>
              )}
            </div>
          </div>
        </div>
        <div>
          <h2 className="font-semibold">Fare Basis:</h2>
          <div className="">{fareInfo?._attributes?.FareBasis}</div>
        </div>
        <div>
          <h2 className="font-semibold">Not Valid Before:</h2>
          <h3>
            {moment(fareInfo?._attributes?.NotValidBefore).format(
              "MMM DD, YYYY"
            )}
          </h3>
        </div>
        <div>
          <h2 className="font-semibold">Not Valid After:</h2>
          <h3>
            {" "}
            {moment(fareInfo?._attributes?.NotValidAfter).format(
              "MMM DD, YYYY"
            )}
          </h3>
        </div>
      </div>
    </>
  );
}

export default CustomPrintTicketPage;
