import React from "react";

const StatCard = ({ icon, color, title, data }) => {
  return (
    <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6" key={title}>
      <div className="widget-stat card">
        <div className="card-body p-3">
          <div className="media ai-icon">
            <span className={`me-3 bgl-${color} text-${color}`}>{icon}</span>
            <div className="media-body">
              <p className="mb-1">{title}</p>
              <h4 className="mb-0">{data}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
