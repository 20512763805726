import { LocationMarkerIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, useRef } from "react";
import { flightSearchList } from "../../../utils/flightSearchList";
import AirportListMobile from "./AirportListMobile";

const LocationInput = ({
  onChange,
  className = "",
  autoFocus = false,
  defaultValue,
  placeholder,
  headingText = "Where to?",
}) => {
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [input, setInput] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleSelectLocation = (item) => {
    setValue(item.name);
    setInput(item.name);
    setShowPopover(false);
    onChange && onChange(item.code);
    // onInputDone && onInputDone(item);
  };

  const renderSearchValues = ({ heading, items }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={item}
              >
                <LocationMarkerIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="py-3 px-4" onClick={() => setShowPopover(true)}>
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-3">
          <input
            className={`block w-full bg-transparent border-1 px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={placeholder}
            value={input}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              setInput(e.currentTarget.value);
            }}
            ref={inputRef}
            autoFocus={showPopover}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <SearchIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        {/* <div className="">
          {showPopover && (
            <>
              <div className="mt-3">
                {value &&
                  flightSearchList(value).map((item) => (
                    <div
                      className="py-2 mb-1 flex items-center space-x-3 text-sm"
                      onClick={() => handleSelectLocation(item)}
                      key={item.code}
                    >
                      <LocationMarkerIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                      <span className="">
                        {item.city} - {item.name} ({item.code})
                      </span>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div> */}
        <div className="">
          {showPopover && (
            <AirportListMobile
              value={value}
              type="from"
              handleSelectLocation={handleSelectLocation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
