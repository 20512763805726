import axios from "axios";

import API_URL from "..";

const API_URL_BILLING = API_URL + "billing/";

const createBilling = async (token, billing) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL_BILLING, billing, config);

  return res.data;
};

const getBilling = async (token, { id, query }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_BILLING +
      `/${id}?startDate=${query?.startDate}&endDate=${query?.endDate}`,
    config
  );

  return res.data;
};

const getBillings = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_BILLING +
      `?startDate=${query.startDate}&endDate=${query.endDate}&page=${query.page}&limit=${query.limit}`,
    config
  );

  return res.data;
};

const editBillingTransaction = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL_BILLING + `/${data?.id}`, data, config);

  console.log({ res: res?.data });

  return res.data;
};

const createBookingDeposit = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    API_URL_BILLING + `deposit/${data?.id}`,
    data,
    config
  );

  return res.data;
};

const USDSettlement = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    API_URL_BILLING + `usd-settlement/${data?.id}`,
    data,
    config
  );

  return res.data;
};

const getTotalPayableCredit = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_BILLING + `payable-credit/${id}`, config);

  return res.data;
};

const getAgentsCredit = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_BILLING + `credit`, config);

  return res.data;
};

const billingApi = {
  createBilling,
  getBilling,
  getBillings,
  USDSettlement,
  createBookingDeposit,
  editBillingTransaction,
  getTotalPayableCredit,
  getAgentsCredit,
};

export default billingApi;
