import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import GuestsInput from "./GuestsInput";
import { Link, useLocation } from "react-router-dom";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import moment from "moment";
import {
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
} from "../../../common/constants";
import PassengerTypeModal from "../../../components/Modals/PassengerTypeModal";
import Locals from "../../../utils/localFlights";

const FlightFormMobile = ({ closeModal }) => {
  const [fieldNameShow, setFieldNameShow] = useState(
    "origin" || "destination" || "dates" || "guests" || "general"
  );

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const from = query.get("origin");
  const to = query.get("destination");
  const tripType = query.get("tripType");
  const cabinClass = query.get("cabinClass");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const labor = query.get("labors");
  const _id = query.get("_id");

  // const [fieldNameShow, setFieldNameShow] = useState("locationPickup");
  const [origin, setOrigin] = useState(from || "");
  const [destination, setDestination] = useState(to || "");
  const [showPassengerTypeModal, setShowPassengerTypeModal] = useState(false);

  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dropOffLocationType, setDropOffLocationType] = useState(
    tripType || TRIP_TYPE_ROUND_TRIP
  );
  const [flightClassState, setFlightClassState] = useState(cabinClass || "Any");
  const [passengerTypeState, setPassengerTypeState] = useState("Resident");

  const [guestInput, setGuestInput] = useState({
    adults: +labor > 0 ? 0 : +adults || 1,
    children: +children || 0,
    infants: +infants || 0,
    labor: +labor || 0,
  });

  useEffect(() => {
    if (startDate) {
      setDateRangeValue({
        startDate: moment(startDate),
        endDate: null,
      });
    }
    if (endDate) {
      setDateRangeValue({
        startDate: moment(startDate),
        endDate: moment(endDate),
      });
    }
  }, [endDate, startDate]);

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "origin";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("origin")}
          >
            <span className="text-neutral-400">Origin</span>
            <span>{origin || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="From"
            placeholder="Search Departing City"
            defaultValue={origin}
            onChange={(e) => {
              setOrigin(e);
              setFieldNameShow("destination");
            }}
            // autoFocus={false}
            // onChange={(value) => {
            //   setOrigin(value);
            //   setFieldNameShow("dates");
            // }}
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "destination";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("destination")}
          >
            <span className="text-neutral-400">Destination</span>
            <span>{destination || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="To"
            placeholder="Search Arrival City"
            onChange={(e) => {
              setDestination(e);
              setFieldNameShow("general");
            }}
            defaultValue={destination}
            // onChange={(value) => {
            //   // setDestination(value);
            //   setFieldNameShow("dates");
            // }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";
    const startDateString = dateRangeValue.startDate?.format("MMM DD");
    const endDateString =
      dateRangeValue?.endDate !== "null" &&
      dateRangeValue?.endDate?._i !== "Invalid date" &&
      dateRangeValue?.endDate?._i !== "null"
        ? dateRangeValue.endDate?.format("MMM DD")
        : null;
    const dateSelected =
      startDateString && endDateString
        ? `${startDateString} - ${endDateString ? endDateString : ""}`
        : `${startDateString || ""}`;
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>{dateSelected || "Add date"}</span>
          </button>
        ) : (
          <StayDatesRangeInput
            defaultValue={dateRangeValue}
            onChange={setDateRangeValue}
            required={true}
            isOneWay={dropOffLocationType === TRIP_TYPE_ONE_WAY}
          />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-3`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Flight type?</span>
            <span>{`${dropOffLocationType}, ${flightClassState}`}</span>
          </button>
        ) : (
          <div className="p-7">
            <span className="block font-semibold text-xl sm:text-2xl">
              Flight type?
            </span>
            <div className="relative mt-4">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === TRIP_TYPE_ROUND_TRIP
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => setDropOffLocationType(TRIP_TYPE_ROUND_TRIP)}
                >
                  Round-trip
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === TRIP_TYPE_ONE_WAY
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => setDropOffLocationType(TRIP_TYPE_ONE_WAY)}
                >
                  One-way
                </div>
              </div>

              <div className="flex flex-row justify-between mt-2 px-2">
                <div className="mt-6">
                  <label className="text-base font-semibold" htmlFor="">
                    Ticket Class:
                  </label>
                  <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-3">
                    {renderRadio("class", "Economy", "Economy")}
                    {renderRadio("class", "Business", "Business")}
                    {renderRadio("class", "Any", "Any")}
                    {/* {renderRadio("class", "Multiple", "Multiple")} */}
                  </div>
                </div>

                <div className="mt-6">
                  <label className="text-base font-semibold" htmlFor="">
                    Passenger Type:
                  </label>
                  <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-3">
                    {renderPassengerTypeRadio("type", "Resident", "Resident")}
                    {renderPassengerTypeRadio("type", "Foreigner", "Foreigner")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (name, id, label, defaultChecked) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => setFlightClassState(label)}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderPassengerTypeRadio = (name, id, label) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={passengerTypeState === label}
          id={id + name}
          name={name}
          onChange={() => setPassengerTypeState(label)}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.adults || guestInput.children) {
      const guest =
        (guestInput.adults || 0) +
        (guestInput.children || 0) +
        (guestInput.labor || 0);
      guestSelected += `${guest} guests`;
    } else {
      guestSelected += `${guestInput.labor} labors`;
    }

    if (guestInput.infants) {
      guestSelected += `, ${guestInput.infants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Who</span>
            <span>{guestSelected || `Add guests`}</span>
          </button>
        ) : (
          <GuestsInput
            defaultValue={guestInput}
            setGuestInput={setGuestInput}
          />
        )}
      </div>
    );
  };

  const renderSubmitButton = () => {
    const url = `/search-result?origin=${origin}&destination=${destination}&startDate=${moment(
      dateRangeValue.startDate
    ).format("YYYY-MM-DD")}&endDate=${
      dateRangeValue.endDate
        ? moment(dateRangeValue?.endDate).format("YYYY-MM-DD")
        : null
    }&tripType=${dropOffLocationType}&classType=${flightClassState}&adults=${
      guestInput.adults
    }&labors=${guestInput.labor}&children=${guestInput.children}&infants=${
      guestInput.infants
    }&id=${_id}`;

    let isLocal = Locals.includes(origin) && Locals.includes(destination);

    return (
      <div>
        <ButtonPrimary
          onClick={() => {
            setShowPassengerTypeModal(true);
          }}
        >
          Search
        </ButtonPrimary>
        <PassengerTypeModal
          show={showPassengerTypeModal}
          onHide={() => setShowPassengerTypeModal(false)}
          href={url}
          isLocal={isLocal}
          mobile={true}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {renderGenerals()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
        <div className="w-full flex justify-between text-sm font-medium p-4"></div>
      </div>
      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between fixed-bottom">
        <button
          type="button"
          className="underline font-semibold flex-shrink-0"
          // onClick={() => {
          //   setShowDialog(false);
          //   resetIsShowingDialog();
          // }}
        >
          Clear all
        </button>

        {/* <ButtonPrimary
          // onClick={() => closeModal()}
        >
          Search
        </ButtonPrimary> */}
        {renderSubmitButton()}
      </div>
    </div>
  );
};

export default FlightFormMobile;
