import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiBooking from "./apiBooking";

const initialState = {
  bookings: [],
  booking: null,
  isError: false,
  isLoading: false,
  errorMessage: "",
};

// create booking
export const createBooking = createAsyncThunk(
  "booking/createBooking",
  async (booking, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.createBooking(token, booking);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateBooking = createAsyncThunk(
  "booking/updateBooking",
  async (booking, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.updateBooking(token, booking);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateWallet = createAsyncThunk(
  "booking/updateWallet",
  async (booking, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.updateWallet(token, booking);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get booking
export const getBooking = createAsyncThunk(
  "booking/getBooking",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.getBooking(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getBookingById = createAsyncThunk(
  "booking/getBookingById",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.getBookingById(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get all bookings
export const getBookings = createAsyncThunk(
  "booking/getBookings",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.getBookings(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// // get bookings by user
// export const getBookingsByUser = createAsyncThunk(
//   "booking/getBookingsByUser",
//   async (_, thunkApi) => {
//     try {
//       const token = thunkApi.getState().auth.user.token;
//       return await apiBooking.getBookingsByUser(token);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkApi.rejectWithValue(message);
//     }
//   }
// );

// get bookings by company
export const getBookingsByCompany = createAsyncThunk(
  "booking/getBookingsByCompany",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.getBookingsByCompany(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get bookings by company
export const getCompanyBookings = createAsyncThunk(
  "booking/getCompanyBookings",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.getCompanyBookings(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get bookings by user
export const getBookingByUser = createAsyncThunk(
  "booking/getBookingByUser",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.getBookingByUser(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update booking request status
export const updateBookingRequestStatus = createAsyncThunk(
  "booking/updateBookingRequestStatus",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiBooking.updateBookingRequestStatus(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    resetBooking: (state) => {
      state.booking = null;
    },
  },
  extraReducers: {
    // create booking
    [createBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [createBooking.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.booking = action.payload;
    },
    [createBooking.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get booking
    [getBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [getBooking.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.booking = action.payload.data;
    },
    [getBooking.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get all bookings
    [getBookings.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookings.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bookings = action.payload;
    },
    [getBookings.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get bookings by company
    [getBookingsByCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingsByCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bookings = action.payload;
    },
    [getBookingsByCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getCompanyBookings.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompanyBookings.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bookings = action.payload;
    },
    [getCompanyBookings.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update booking PNR
    [updateBooking.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBooking.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.booking = action.payload;
    },
    [updateBooking.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update wallet
    [updateWallet.pending]: (state) => {
      state.isLoading = true;
    },
    [updateWallet.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.booking = action.payload;
      // // update bookings
      // state.bookings = state.bookings.data.map((booking) => {
      //   if (booking._id === action.payload.data._id) {
      //     return { ...booking, ...action.payload };
      //   }
      //   return booking;
      // });
    },
    [updateWallet.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getBookingById.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.booking = action.payload.data;
    },
    [getBookingById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getBookingByUser.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingByUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bookings = action.payload;
    },
    [getBookingByUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [updateBookingRequestStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBookingRequestStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.booking = action.payload;
    },
    [updateBookingRequestStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { resetBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
