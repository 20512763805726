import moment from "moment";

const calculateTime = (departure, arrival) => {
  let time = moment(departure).diff(moment(arrival), "minutes");

  let hours = Math.floor(time / 60);
  let minutes = time % 60;

  return `${hours}h ${minutes}m`;
};

export default calculateTime;
