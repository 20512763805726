import React, { useState } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearButton";
import { useRef } from "react";
import { flightSearchList } from "../../../utils/flightSearchList";

const LocationInput = ({
  defaultValue,
  onChange,
  autoFocus = false,
  onInputDone,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [value, setValue] = useState(defaultValue);
  const [input, setInput] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);

  useEffect(() => {
    if (defaultValue) {
      setInput(getFlightName(defaultValue));
    }
  }, []);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  function UseFlightName(code) {
    return flightSearchList(code)?.filter((flight) => {
      return flight?.code === code;
    })?.[0]?.name;
  }

  function getFlightName(code) {
    if (!code) return;
    return UseFlightName(code);
  }

  const eventClickOutsideDiv = (event) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item) => {
    setValue(item.name);
    setInput(item.name);
    onChange && onChange(item.code);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? "nc-hero-field-focused--" : ""
        }`}
      >
        <div className="flex-1">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-bold text-black placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={input}
            required
            autoFocus={showPopover}
            onFocus={() => {
              setInput("");
              setValue("");
            }}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              setInput(e.currentTarget.value);
              // onChange && onChange(value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                onChange && onChange("");
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[400px] bg-white dark:bg-neutral-800 top-full mt-2 py-3 sm:py-5 rounded-3xl shadow-2xl ml-2 max-h-96 overflow-y-auto">
          {value ? (
            flightSearchList(value).map((item) => (
              <span
                onClick={() => handleSelectLocation(item)}
                key={item.code}
                className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 sm:h-6 sm:w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </span>
                <span className="block text-neutral-700 dark:text-neutral-200">
                  {item.city} - {item.name} ({item.code})
                </span>
              </span>
            ))
          ) : (
            <span className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
              <span className="block text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 sm:h-6 sm:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </span>
              <span className="block text-neutral-700 dark:text-neutral-200">
                {placeHolder}
              </span>
            </span>
          )}
          {/* {value ? renderSearchValue() : renderRecentSearches()} */}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
