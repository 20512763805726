import React from "react";

const Input = ({
  className = "",
  sizeClass = "h-11 px-4 py-3",
  fontClass = "text-sm font-normal",
  rounded = "rounded-2xl",
  children,
  type = "text",
  defaultValue = "",
  required = false,
  ...args
}) => {
  return (
    <input
      className={`block w-full border text-black text-[15px] border-black focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white  ${rounded} ${fontClass} ${sizeClass} ${className}
        `}
      type={type}
      defaultValue={defaultValue}
      required={required}
      {...args}
    />
  );
};

// ref={ref}
// type={type}
// className={`block w-full border text-black text-[15px] border-black focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white  ${rounded} ${fontClass} ${sizeClass} ${className}`}
// {...args}
// placeholder={defaultValue}
// required={required}

export default Input;
