import axios from "axios";

import API_URL from "..";

const API_URL_CORP = API_URL + "corporate/";

const getCorporate = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_CORP + `${id}`, config);

  return res.data;
};

const updateExchangeRate = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL_CORP + `exchange-rate`, data, config);

  return res.data;
};

const apiCorporate = {
  getCorporate,
  updateExchangeRate,
};

export default apiCorporate;
