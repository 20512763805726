import React, { useEffect, useMemo, useState } from "react";

import AgentBillingStat from "./components/AgentBillingStat";
import CustomDateSelector from "../../components/CustomDateSelector";

const BillingAgent = () => {
  const [rangeDate, setRangeDate] = useState([]);
  const [durationSelect, setDurationSelect] = useState("");

  const currentDate = new Date();
  let startDate, endDate;

  useEffect(() => {
    if (rangeDate?.[1] !== undefined) {
      setDurationSelect("Custom");
    }
  }, [rangeDate]);

  switch (durationSelect) {
    case "This_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      break;
    case "Last_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      break;
    case "This_Week":
      const firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
      startDate = new Date(currentDate.setDate(firstDayOfWeek));
      endDate = new Date(currentDate.setDate(firstDayOfWeek + 6));
      break;
    case "This_Year":
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      endDate = new Date(currentDate.getFullYear(), 11, 31);
      break;
    case "Today":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59
      );
      break;
    case "Custom":
      startDate = new Date(rangeDate?.[0] || null);
      endDate = new Date(rangeDate?.[1] || null);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);

      break;

    default:
      startDate = null;
      endDate = null;
      break;
  }

  const query = useMemo(() => {
    return {
      startDate: startDate ? startDate?.toISOString() : "",
      endDate: endDate ? endDate?.toISOString() : "",
    };
  }, [startDate, endDate]);

  // const bankStatData = [
  //   {
  //     title: "CBE Total Sales",
  //     data: "0 birr",
  //     // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
  //     icon: (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth={1.5}
  //         stroke="currentColor"
  //         width="30"
  //         height="30"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
  //         />
  //       </svg>
  //     ),
  //     color: "warning",
  //   },
  //   {
  //     title: "Abyssinia Total Sales",
  //     data: "0 birr",
  //     // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
  //     icon: (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth={1.5}
  //         stroke="currentColor"
  //         width="30"
  //         height="30"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
  //         />
  //       </svg>
  //     ),
  //     color: "warning",
  //   },
  //   {
  //     title: "Amhara Total Sales",
  //     data: "0 birr",
  //     // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
  //     icon: (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth={1.5}
  //         stroke="currentColor"
  //         width="30"
  //         height="30"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
  //         />
  //       </svg>
  //     ),
  //     color: "warning",
  //   },
  //   {
  //     title: "Awash Total Sales",
  //     data: "0 birr",
  //     // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
  //     icon: (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth={1.5}
  //         stroke="currentColor"
  //         width="30"
  //         height="30"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
  //         />
  //       </svg>
  //     ),
  //     color: "warning",
  //   },
  // ];

  // const calculatePercentage = (price, commission) => {
  //   if (price === 0 || price === undefined) {
  //     return 0;
  //   }
  //   return ((commission / price) * 100).toFixed(2) + " %";
  // };

  return (
    <div className="lg:mx-32 md:mx-16">
      <CustomDateSelector
        rangeDate={rangeDate}
        setRangeDate={setRangeDate}
        setDurationSelect={setDurationSelect}
      />

      <AgentBillingStat query={query} />
    </div>
  );
};

export default BillingAgent;
