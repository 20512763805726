import { Search } from "@mui/icons-material";
import { CircularProgress, Pagination, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { getBooking, getBookings } from "../../redux2/booking/bookingSlice";
import excelExport from "../../utils/excelExport";
import Loading from "../../components/Loading";
import customTime from "../../utils/customTime";

const AgentBooking = () => {
  const dispatch = useDispatch();
  const { booking, isLoading } = useSelector((state) => state.booking);

  const { id } = useParams();

  // console.log(booking?.data);

  useEffect(() => {
    dispatch(getBooking(id));
  }, [dispatch, id]);

  if (isLoading) {
    return <Loading />;
  }

  // if (booking?.loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <CircularProgress size={50} />
  //     </div>
  //   );
  // }

  function handleExport() {
    let name =
      booking?.[0]?.company?.name + "Flights" + moment().format("DD-MM-YYYY");
    const data = booking?.map((booking) => {
      return {
        "Passenger Name":
          booking.passengers[0].firstName +
          " " +
          booking.passengers[0].lastName,
        "Ticket Number": booking.flight?.ticketNumber,
        Origin: booking.flight?.origin,
        Destination: booking.flight?.destination,
        "Flight Number": booking.flight?.flightNumber,
        "Flight Date": booking.flight?.departureTime,
        "Issuing Airline": booking.flight?.carrier,
        "Issuing Agent":
          booking.agent?.firstName + " " + booking.agent?.lastName,
        Price: booking.flight?.price,
        "Payment Status": booking.paymentStatus,
        "Payment Method": booking.payment,
        "Issuing Date": booking.createdAt,
      };
    });
    excelExport(data, name);
  }

  return (
    <>
      <Tab.Container defaultActiveKey="All">
        <div className="row">
          <div className="col-xl-12">
            <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
              <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
                <h1 className="text-3xl font-bold pl-5 py-2 capitalize">
                  {booking?.[0]?.company?.name}
                </h1>
                {/* <Nav as="ul" className="nav nav-tabs">
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link
                      className="nav-link"
                      eventKey="All"
                      // onClick={() => dispatch(bookingActions.getBooking())}
                    >
                      All Booking (12)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link
                      className="nav-link"
                      eventKey="Pending"
                      // onClick={() =>
                      //   dispatch(bookingActions.getBooking("pending"))
                      // }
                    >
                      Pending
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link
                      className="nav-link"
                      eventKey="Ticketed"
                      onClick={() => dispatch(bookingActions.getBooking())}
                    >
                      Ticketed
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link
                      className="nav-link"
                      eventKey="Canceled"
                      // onClick={() =>
                      //   dispatch(bookingActions.getBooking("cancelled"))
                      // }
                    >
                      Canceled
                    </Nav.Link>
                  </Nav.Item>
                </Nav> */}
              </div>
              <div className="table-search">
                <div className="input-group search-area mb-xxl-0 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                  />
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <Search size={35} className="text-main" />
                      {/* <i className="flaticon-381-search-2 text-main"></i> */}
                    </Link>
                  </span>
                </div>
              </div>
              <button
                onClick={handleExport}
                className="btn bg-[#EA9C2A] hover:bg-main-800 text-white border-main-100 mb-xxl-0 mb-4 "
              >
                <i className="far fa-file-word me-2"></i>Generate Report
              </button>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="All">{AllTable(booking, isLoading)}</Tab.Pane>
              <Tab.Pane eventKey="Pending">{AllTable(booking)}</Tab.Pane>
              <Tab.Pane eventKey="Ticketed">{AllTable(booking)}</Tab.Pane>
              <Tab.Pane eventKey="Canceled">{AllTable(booking)}</Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

const AllTable = (bookings, loading) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">
              <th className="sorting"></th>
              <th className="sorting">#</th>
              <th>Passenger Name</th>
              <th>Ticket Number</th>
              <th className="sorting_asc">Issue Date</th>
              <th>Issuing Airline</th>
              <th>Issuing Agent</th>
              <th className="sorting_asc">Payment Type</th>
              <th className="sorting">Departure Date</th>
              <th className="sorting">Arrival Date</th>
              <th className="sorting">Price</th>
              <th className="sorting">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* {booking?.loading && (
              <div className="flex items-center justify-center h-screen">
                <CircularProgress size={50} />
              </div>
            )} */}
            {/* {booking?.error && <Alert severity="error">Error occured</Alert>} */}
            {bookings?.length === 0 ? (
              <tr>
                <td colSpan={11}>
                  <Typography variant="h5">No Booking Found</Typography>
                </td>
              </tr>
            ) : (
              bookings?.map((booking, index) => (
                <tr role="row" className="odd " key={index}>
                  <td className="py-2 ">
                    {booking?.status === "pending" ? (
                      <span className="badge badge-warning">
                        Pending
                        <span className="ms-1 fa fa-clock" />
                      </span>
                    ) : booking?.status === "cancelled" ? (
                      <span className="badge badge-danger">
                        Cancelled
                        <span className="ms-1 fa fa-times" />
                      </span>
                    ) : (
                      <span className="badge badge-success">
                        Issued
                        <span className="ms-1 fa fa-check" />
                      </span>
                    )}
                  </td>
                  <td>
                    <p>{index + 1}</p>
                  </td>

                  <td>
                    <div className="media-bx">
                      <div>
                        <h4>
                          <div className="text-black font-semibold capitalize">
                            {booking?.passengers[0]?.firstName}{" "}
                            {booking?.passengers[0]?.lastName}
                          </div>
                        </h4>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="text-main ">
                      {/* {booking?.flight?.ticketNumber} */}
                      {booking?.flight?.ticketNumber?.map((ticket, index) => (
                        <div className="flex flex-col" key={index}>
                          {ticket}
                        </div>
                      ))}
                    </p>
                    {/* <p className="text-main">#ET-631c3e1d29</p> */}
                  </td>
                  <td>
                    <div>
                      <h5>
                        {moment(booking?.createdAt).format("MMM DD, YYYY")}
                      </h5>
                      {/* <span className="fs-14">
                        {moment(booking?.issueDate).format("HH:MM")}
                      </span> */}
                    </div>
                  </td>
                  <td>
                    <p>Ethiopian Airlines</p>
                  </td>
                  <td>
                    <p className="capitalize">
                      {booking?.agent?.firstName +
                        " " +
                        booking?.agent?.lastName}
                    </p>
                  </td>
                  <td>
                    <p className="text-green-600">{booking?.payment}</p>
                  </td>
                  <td>
                    <div>
                      <h5>
                        {moment(booking?.flight?.departureTime).format(
                          "MMM DD, YYYY"
                        )}
                      </h5>
                      <span className="fs-14">
                        {customTime(booking?.flight?.departureTime)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h5>
                        {moment(booking?.flight?.arrivalTime).format(
                          "MMM DD, YYYY"
                        )}
                      </h5>
                      <span className="fs-14">
                        {customTime(booking?.flight?.arrivalTime)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <p>
                      {booking?.flight?.price}
                      <span className="mx-1 text-blue-700">birr</span>
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="flex items-center justify-center mt-3">
          {/* <Pagination count={10} /> */}
        </div>
      </div>
    </div>
  );
};

export default AgentBooking;
