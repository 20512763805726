import React, { Fragment, useEffect, useRef, useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";
import NcImage from "../NcImage/NcImage";
import { Tab } from "@headlessui/react";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { currencyType, getCarrierLogo } from "../../utils/flightInfo";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { TicketPrint } from "../../Pages/Home/Components/TicketPrint";
import { useDispatch, useSelector } from "react-redux";
import { convertToNumber } from "../../utils/convertToNumber";
import convertNumbThousand from "../../utils/convertNumbThousand";
import { getBookingById } from "../../redux2/booking/bookingSlice";
import { DirectPrint } from "../Ticket/DirectPrint";
import customTime from "../../utils/customTime";

const PayPage = ({ className = "" }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    airReservation,
    ticket,
    travelers,
    charges,
    serviceCharge,
    airPrice,
    currency,
    reservation,
  } = location.state;
  const { booking } = useSelector((state) => state.booking);

  let passengerList = [];
  if (travelers instanceof Array) {
    passengerList = travelers?.map((passenger) => {
      return [
        passenger?.BookingTravelerName?._attributes,
        passenger?.AirSeatAssignment,
      ];
    });
  } else {
    passengerList.push([
      travelers?.BookingTravelerName?._attributes,
      travelers?.AirSeatAssignment,
    ]);
  }

  const { id } = useParams();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const destination = query.get("destination");

  useEffect(() => {
    dispatch(getBookingById(id));
  }, [dispatch, id]);

  const componentRef = useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  // let ticketData = ticket?.ETR?.Ticket?._attributes.TicketNumber;
  let ticketData = ticket?.ETR?.Ticket;
  let ticketNumbers = [];

  if (ticket?.ETR instanceof Array) {
    ticketNumbers = ticket?.ETR.map((item) => {
      return item?.Ticket?._attributes?.TicketNumber;
    });
  } else {
    ticketNumbers.push(ticket?.ETR?.Ticket?._attributes?.TicketNumber);
  }

  function totalPrice() {
    // let airPrice =
    //   airReservation.AirPricingInfo._attributes.TotalPrice.toString().slice(3);
    let totalPrice = airPrice._attributes.TotalPrice?.toString().slice(3);
    totalPrice = parseFloat(totalPrice);

    let result =
      totalPrice +
      parseFloat(booking?.commission || 0) +
      charges +
      parseFloat(serviceCharge || 0);
    return convertNumbThousand(result);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onBeforeGetContent: () => ({
    //   styles: "@media print { body { visibility: hidden; } }",
    //   documentTitle: `Ticket-`,
    // }),
    // onBeforePrint: () => ({
    //   documentTitle: `Ticket-`,
    // }),
  });

  const renderContent = () => {
    let AirSegment =
      airReservation?.AirSegment instanceof Array
        ? airReservation?.AirSegment
        : airReservation.AirSegment._attributes;

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-4 sm:p-6 p-6 xl:p-8 shadow">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulation 🎉
        </h2>
        <p className="text-neutral-500">
          Your flight has been booked successfully. Please check your email for
          the ticket.
        </p>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        <div className="space-y-6 mb-4">
          <h3 className="text-2xl font-semibold">Your flight</h3>
          <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 rounded p-6 sm:p-6 xl:p-8 shadow mb-5">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                {/* <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden"> */}
                <NcImage
                  src={getCarrierLogo(
                    AirSegment?.Carrier || AirSegment?.[0]?._attributes?.Carrier
                  )}
                />
                {/* <NcImage src="https://www.logolynx.com/images/logolynx/d5/d55d1aa6350e1e5374c7e0b7ecb1a007.jpeg" /> */}
                {/* </div> */}
              </div>
              <div className="py-5 sm:px-5 space-y-3">
                <span className="text-lg text-neutral-500 font-semibold">
                  <h1>
                    {AirSegment?.Carrier ||
                      AirSegment?.[0]?._attributes?.Carrier}
                  </h1>
                </span>
                {AirSegment instanceof Array ? (
                  AirSegment.map((segment, index) => (
                    <div key={index}>
                      <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                        <h1>
                          {segment._attributes.Origin} -{" "}
                          {segment._attributes.Destination}
                        </h1>
                      </span>
                      <span className="text-base font-medium mt-1 block">
                        {moment(segment._attributes.DepartureTime).format(
                          "DD MMM YYYY"
                        )}{" "}
                        -
                        {moment(segment._attributes.ArrivalTime).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      <span className="text-base font-medium mt-1 block">
                        {customTime(segment._attributes.DepartureTime)} -
                        {customTime(segment._attributes.ArrivalTime)}
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="">
                    <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                      <h1>
                        {AirSegment.Origin} - {AirSegment.Destination}
                      </h1>
                    </span>
                    <span className="text-base font-medium mt-1 block">
                      {moment(AirSegment.DepartureTime).format("DD MMM YYYY")} -
                      {moment(AirSegment.ArrivalTime).format("DD MMM YYYY")}
                    </span>
                    <span className="text-base font-medium mt-1 block">
                      {customTime(AirSegment.DepartureTime)} -
                      {customTime(AirSegment.ArrivalTime)}
                    </span>
                  </div>
                )}
                <div className="flex flex-row justify-between">
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    {parseInt(adults)} Adult
                  </span>
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    {children > 0 && children + " Children"}
                  </span>
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    {infants > 0 && infants + " Infants"}
                  </span>
                </div>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <h3 className="text-2xl font-semibold">Price detail</h3>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Price</span>
                <span>
                  {/* 1 *{" "}
                  {convertToNumber(
                    airReservation.AirPricingInfo._attributes.TotalPrice
                  )}
                  {airReservation.AirPricingInfo._attributes.TotalPrice.substring(
                    0,
                    3
                  )} */}
                  {airPrice._attributes.TotalPrice.toString().slice(3)}{" "}
                  {airPrice._attributes.TotalPrice.substring(0, 3)}
                </span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Service charge</span>
                <span>
                  {charges !== 0 ? charges : serviceCharge}{" "}
                  {currencyType(currency)}
                </span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Agent Fee</span>
                <span>
                  {booking?.commission || 0} {currencyType(currency)}
                </span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-bold text-xl text-black">
                <span>Total</span>
                <span>
                  {totalPrice()}{" "}
                  {airPrice._attributes.TotalPrice.substring(0, 3)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Flight detail</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Ticket code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {ticket?.ETR instanceof Array
                  ? ticket.ETR.map((item, index) => (
                      <p className="font-thin">
                        Ticket {++index}{" "}
                        <span className="font-semibold">
                          {item.Ticket?._attributes?.TicketNumber}
                        </span>
                      </p>
                    ))
                  : ticketData?._attributes?.TicketNumber}
              </span>
            </div>
            {/* <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Departure Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {moment(ticketData?.Coupon?._attributes?.DepartureTime).format(
                  "DD MMM YYYY"
                )}
              </span>
            </div> */}
            {/* <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                4321 birr
              </span>
            </div> */}
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.payment}
              </span>
            </div>
            {booking?.payment === "credit" && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Corporate Name</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {booking?.corporateName}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="pt-5">
          <h3 className="text-2xl font-semibold">Ticket Purchasing Complete</h3>
          {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"> */}
          <Tab.Group>
            <Tab.List className="flex my-5">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <Link to="#" className="hover:text-gray-400">
                    <button className="p-3 ml-5 mb-3 text-black hover:border rounded">
                      <div className="ml-5 pb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                          />
                        </svg>
                      </div>
                      <p className="hover:text-gray-500"> Share Ticket</p>
                    </button>
                  </Link>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  // <Link to="#" className="hover:text-gray-400">
                  <>
                    <button
                      className="p-3 ml-5 mb-3 text-black hover:border rounded "
                      onClick={handlePrint}
                      mediaPrint
                      media="print"
                    >
                      <div className="ml-14 pb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      </div>
                      <p className="hover:text-gray-500">Download Ticket pdf</p>
                    </button>

                    <div className="hidden">
                      <DirectPrint
                        ref={componentRef}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                        passengerInfo={booking?.passengers}
                        companyInfo={booking?.company}
                        travelers={travelers}
                        reservation={reservation}
                      />
                      {/* <TicketPrint
                        ref={componentRef}
                        // passengerList={travelers}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                      /> */}
                    </div>
                  </>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <>
                    <ReactToPrint
                      trigger={() => (
                        <button className="p-3 ml-5 mb-3 text-black hover:border rounded ">
                          <div className="ml-5 pb-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-8 h-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                              />
                            </svg>
                          </div>
                          <p className="hover:text-gray-500">Print Ticket</p>
                        </button>
                      )}
                      // content={() => componentRef.current}
                      content={reactToPrintContent}
                    />
                    <div className="hidden">
                      <DirectPrint
                        ref={componentRef}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                        passengerInfo={booking?.passengers}
                        companyInfo={booking?.company}
                        travelers={travelers}
                        reservation={reservation}
                      />
                      {/* <TicketPrint
                        ref={componentRef}
                        // passengerList={travelers}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                      /> */}
                    </div>
                  </>
                  // <Link to="#" className="hover:text-gray-400">
                  //   <button className="p-3 ml-5 mb-3 text-black hover:border rounded ">
                  //     <div className="ml-5 pb-3">
                  //       <svg
                  //         xmlns="http://www.w3.org/2000/svg"
                  //         fill="none"
                  //         viewBox="0 0 24 24"
                  //         strokeWidth={1.5}
                  //         stroke="currentColor"
                  //         className="w-8 h-8"
                  //       >
                  //         <path
                  //           strokeLinecap="round"
                  //           strokeLinejoin="round"
                  //           d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                  //         />
                  //       </svg>
                  //     </div>
                  //     <p className="hover:text-gray-500 "> Print Ticket</p>
                  //   </button>
                  // </Link>
                )}
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div>
          <ButtonPrimary href="/">Explore more flights</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
