import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { clearMember, createMember } from "../../../../redux2/agent/agentSlice";
import { reset, userExists } from "../../../../redux2/user/userSlice";
import {
  EmployeeSchema,
  formatPhone,
  PhoneCheckerSchema,
} from "../employee.util";

const AddEmployeeForm = () => {
  const dispatch = useDispatch();
  const {
    member,
    isError: memberError,
    errorMessage: memberErrorMessage,
  } = useSelector((state) => state.agent);
  const { checker, isLoading, isError, errorMessage } = useSelector(
    (state) => state.user
  );

  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [checkedPhone, setCheckedPhone] = useState("");
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    if (isError) {
      setOpenForm(false);
      toast.error(errorMessage);
    }

    if (memberError) {
      toast.error(memberErrorMessage);
    }

    // if (checker?.role && checker?.role !== "member") {
    //   setOpenForm(false);
    //   toast.error("User already exists");
    // }

    if (checker?.role) {
      setOpenForm(false);
      toast.error("User already exists");
    }

    if (member?.data) {
      setOpenAddEmployee(false);
      toast.success("Member added successfully");
    }

    dispatch(reset());
    dispatch(clearMember());
  }, [
    isError,
    errorMessage,
    dispatch,
    checker,
    member,
    memberError,
    memberErrorMessage,
  ]);

  const handleCheckUser = (values) => {
    setCheckedPhone(values.phone);
    dispatch(userExists(formatPhone(values.phone)));
    setOpenForm(true);
  };

  const handleAddMember = (values) => {
    const data = {
      ...values,
      phone: formatPhone(values.phone),
    };

    dispatch(createMember(data));
  };

  return (
    <>
      <div
        className="btn bg-main  text-white btn-sm mb-xxl-0 mb-4 hover:bg-main-800"
        onClick={() => setOpenAddEmployee(true)}
      >
        + New Sales Rep
      </div>
      <Modal
        className="modal fade"
        show={openAddEmployee}
        onHide={setOpenAddEmployee}
        size="lg"
        onExit={() => {
          setOpenForm(false);
          dispatch(reset());
        }}
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add Employee</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenAddEmployee(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close text-gray"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    phone: "",
                  }}
                  validationSchema={PhoneCheckerSchema}
                  onSubmit={handleCheckUser}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            size="small"
                            fullWidth
                            id="phone"
                            variant="outlined"
                            name="phone"
                            placeholder="Phone number"
                            label="Phone number"
                            value={values.phone}
                            onChange={handleChange}
                            error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +251
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="col-md-2">
                          <LoadingButton
                            type="submit"
                            variant="text"
                            loading={checker?.loading}
                            className="text-success"
                          >
                            Check
                          </LoadingButton>
                        </div>
                        <div className="col-md-12 py-4">
                          <Divider />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

                {isLoading ? (
                  <CircularProgress />
                ) : (
                  openForm && (
                    <Formik
                      initialValues={{
                        firstName: checker?.firstName ?? "",
                        lastName: checker?.lastName ?? "",
                        email: checker?.email ?? "",
                        phone:
                          checker?.phone?.replace("+251", "") ?? checkedPhone,
                        gender: checker?.gender ?? "",
                        role: checker?.role ?? "",
                      }}
                      validationSchema={EmployeeSchema}
                      onSubmit={handleAddMember}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          className="flex items-end"
                        >
                          <div className="flex flex-col">
                            <div className="row">
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="firstName"
                                  size="small"
                                  variant="outlined"
                                  name="firstName"
                                  placeholder="First Name"
                                  label="First Name"
                                  value={values.firstName}
                                  disabled={checker?.firstName && true}
                                  onChange={handleChange}
                                  error={
                                    touched.firstName &&
                                    Boolean(errors.firstName)
                                  }
                                  helperText={
                                    touched.firstName && errors.firstName
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="lastName"
                                  disabled={checker?.lastName && true}
                                  size="small"
                                  variant="outlined"
                                  name="lastName"
                                  placeholder="Last Name"
                                  label="Last Name"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  error={
                                    touched.lastName && Boolean(errors.lastName)
                                  }
                                  helperText={
                                    touched.lastName && errors.lastName
                                  }
                                />
                              </div>
                              <div className="col-lg-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="phone"
                                  size="small"
                                  variant="outlined"
                                  name="phone"
                                  disabled={
                                    (checker?.phone || checkedPhone) && true
                                  }
                                  placeholder="Phone number"
                                  label="Phone number"
                                  value={values.phone}
                                  onChange={handleChange}
                                  error={touched.phone && Boolean(errors.phone)}
                                  helperText={touched.phone && errors.phone}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        +251
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="email"
                                  size="small"
                                  variant="outlined"
                                  name="email"
                                  placeholder="Email"
                                  label="Email"
                                  disabled={checker?.email && true}
                                  value={values.email}
                                  onChange={handleChange}
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                />
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  size="small"
                                  // labelId="demo-simple-select-label"
                                  id="gender"
                                  name="gender"
                                  variant="outlined"
                                  disabled={checker?.gender && true}
                                  value={values.gender}
                                  label="Gender"
                                  onChange={handleChange}
                                  select
                                  error={
                                    touched.gender && Boolean(errors.gender)
                                  }
                                  helperText={touched.gender && errors.gender}
                                >
                                  <MenuItem value="Male">Male</MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                </TextField>
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  // labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="role"
                                  size="small"
                                  value={values.role}
                                  label="Role"
                                  onChange={handleChange}
                                  select
                                  error={touched.role && Boolean(errors.role)}
                                  helperText={touched.role && errors.role}
                                  defaultValue="member"
                                >
                                  {/* <MenuItem value={"Owner"} disabled>
                                    Owner
                                  </MenuItem>
                                  <MenuItem value="Admin" disabled>
                                    Admin
                                  </MenuItem> */}

                                  <MenuItem value="member" selected>
                                    Member
                                  </MenuItem>
                                </TextField>
                              </div>
                            </div>
                            <LoadingButton
                              className="mt-8"
                              variant="contained"
                              type="submit"
                              loading={isLoading}
                            >
                              {checker?.email ? "Update" : "Add"}
                            </LoadingButton>
                          </div>
                        </form>
                      )}
                    </Formik>
                  )
                )}
              </div>
            </div>
            <div className="modal-footer">
              {/* <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); swal("Employee Added!", "Employee has been successfully added!", "success") }}>Add</button> */}
              <Button
                onClick={() => {
                  setOpenAddEmployee(false);
                  dispatch(reset());
                }}
                variant="danger"
              >
                {" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEmployeeForm;
