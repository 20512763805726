import { LocationMarkerIcon } from "@heroicons/react/outline";
import React, { useMemo } from "react";
import { flightSearchList } from "../../../utils/flightSearchList";
import { FixedSizeList } from "react-window";

const AirportListMobile = ({ value, handleSelectLocation }) => {
  const filteredFlights = useMemo(() => flightSearchList(value), [value]);

  const Row = React.memo(({ index, style }) => {
    const flight = filteredFlights[index];
    return (
      <div style={style}>
        <div className="mt-3">
          <div
            className="py-2 mb-1 flex items-center space-x-3 text-sm"
            onClick={() => handleSelectLocation(flight)}
          >
            <LocationMarkerIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span>
              {flight.city} - {flight.name} ({flight.code})
            </span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      {value && (
        <FixedSizeList
          height={150}
          itemCount={filteredFlights.length}
          itemSize={50}
          width={"100%"}
        >
          {Row}
        </FixedSizeList>
      )}
    </div>
  );
};

export default AirportListMobile;
