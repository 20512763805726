import React, { useEffect } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "../Loading";
import { Edit } from "@mui/icons-material";
import {
  deleteEnterprise,
  getCompanyEnterprises,
  getEnterprises,
  updateEnterpriseStatus,
} from "../../redux2/enterprise/enterpriseSlice";
import AddAgentForm from "../../Pages/Agents/AddAgent";
import { USER_ROLE_AGENT, USER_ROLE_ENTERPRISE } from "../../common/constants";

const Enterprise = ({ setEnterprise }) => {
  const { enterprises, isError, loading } = useSelector(
    (state) => state.enterprise
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    user?.role === USER_ROLE_AGENT
      ? dispatch(getCompanyEnterprises())
      : dispatch(getEnterprises());
  }, [dispatch, user]);

  const handleChange = (event, id) => {
    dispatch(
      updateEnterpriseStatus({
        id,
        status: event.target.checked ? "active" : "inactive",
      })
    );
  };

  if (loading) {
    return <Loading />;
  }

  // if (!loading && enterprises.length > 0) {
  //   setEnterprise(enterprises);
  // }

  return (
    <div className="row mt-6">
      <div className="col-md-2">
        <AddAgentForm type={USER_ROLE_ENTERPRISE} />
      </div>
      <div className="table-responsive h-screen">
        <table
          className="display w-full dataTable table-responsive-lg"
          id="example5"
          role="grid"
          aria-describedby="example5_info"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => {}}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting">Enterprise Name</th>
              <th className="sorting">Name</th>
              <th className="sorting">Email</th>
              <th className="sorting">Role</th>
              <th className="sorting">Contact</th>
              <th className="sorting">Status</th>
              <th className="sorting bg-none"></th>
              <th className="sorting bg-none"></th>
            </tr>
          </thead>
          {
            <tbody>
              {isError ? (
                <tr className="text-danger text-2xl">
                  <td>
                    <p>Error occurred</p>
                  </td>
                </tr>
              ) : (
                <>
                  {enterprises?.map((enterprise, index) => {
                    return (
                      <tr role="row" className="odd" key={index}>
                        <td className="sorting_1">
                          <div className="form-check  style-1">
                            <input
                              type="checkbox"
                              onClick={() => {}}
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                            />
                          </div>
                        </td>
                        <td>
                          <div className="concierge-bx">
                            <div>
                              {/* <span className="text-primary">#AGT-00025</span> */}
                              <h4 className="mt-1">
                                <Link
                                  className="text-black"
                                  to={"./guest-detail"}
                                >
                                  {enterprise.name}
                                </Link>
                              </h4>
                              <span className="fs-14">
                                Join on{" "}
                                {moment(enterprise.user?.createdAt).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="concierge-bx">
                            <div>
                              <h4 className="mt-1">
                                {enterprise.user?.firstName}{" "}
                                {enterprise.user?.lastName}
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>{enterprise.user?.email}</td>
                        {/* <td>Group 01</td> */}
                        <td>
                          <Badge variant="warning badge light ml-4">
                            {enterprise.user?.role}
                          </Badge>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            <span className="text-black font-w500">
                              <i className="fas fa-phone-volume me-2 text-primary"></i>
                              {enterprise.user?.phone}
                            </span>
                          </div>
                        </td>
                        <td>
                          {enterprise.status === "active" ? (
                            <span className="text-success font-w600">
                              ACTIVE
                            </span>
                          ) : (
                            <span className="text-danger font-w600">
                              INACTIVE
                            </span>
                          )}{" "}
                        </td>
                        <td>
                          <Switch
                            style={{ color: "#FBA82C" }}
                            checked={
                              enterprise.status === "active" ? true : false
                            }
                            onChange={(e) => handleChange(e, enterprise._id)}
                          />
                        </td>
                        <td>
                          <DropdownBlog enterprise={enterprise} />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          }
        </table>
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">Showing 1 of 1 entries</div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example2_paginate"
          >
            <Link
              className="paginate_button previous disabled"
              to="/guest-list"
            >
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            </Link>

            <Link className="paginate_button next" to="/guest-list">
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const DropdownBlog = ({ enterprise }) => {
  const dispatch = useDispatch();
  const handleDelete = (enterprise) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete this ${enterprise?.name}.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteEnterprise(enterprise._id));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <>
      <Dropdown className="dropdown text-sans-serif">
        <Dropdown.Toggle
          variant=""
          className="btn btn-primary i-false tp-btn-light sharp"
          type="button"
          id="order-dropdown-0"
          data-toggle="dropdown"
          data-boundary="viewport"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-right border py-0"
          aria-labelledby="order-dropdown-0"
        >
          <div className="py-2">
            {/* <Link className="dropdown-item" to="/agent-trends">
              <i className="fa fa-chart-pie me-2"></i>
              Trends
            </Link> */}
            <Link
              className="dropdown-item"
              to={`/flight-list/${enterprise?._id}`}
            >
              <i className="fas fa-plane me-2"></i>
              Flight
            </Link>
            <Link
              className="dropdown-item"
              to={`/agent-billing/${enterprise?._id}`}
            >
              <i className="fas fa-money-bill me-2"></i>
              Billings
            </Link>
            {/* <div className="dropdown-item" to="#">
              <Edit enterprise={enterprise} />
            </div> */}
            <btn
              className="dropdown-item hover:cursor-pointer"
              onClick={() => handleDelete(enterprise)}
            >
              <i className="fas fa-trash-alt me-2"></i>
              Delete
            </btn>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Enterprise;
