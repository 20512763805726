import React from "react";

import ReactApexChart from "react-apexcharts";

const ApexBar2 = ({ data }) => {
  let series1 = data
    ?.filter((item) => item._id !== "undefined")
    .map((item) => item.value);

  let xCol = data
    ?.filter((item) => item._id !== "undefined")
    .map((item) => item._id);

  const state = {
    series: [
      {
        name: "Destination",
        data: series1,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 230,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["#FBA82C"],
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          // colors: ["#fff"],
        },
      },
      stroke: {
        show: false,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },

      xaxis: {
        show: false,
        categories: xCol,
      },
    },
  };

  return (
    <div id="chart" className="bar-chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
    </div>
  );
};
export default ApexBar2;
