import axios from "axios";
import moment from "moment";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { Tab } from "@headlessui/react";
import countryList from "country-list";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import API_URL from "../../redux2";
import Loading from "../Loading";
import Label from "../Label/Label";
import Input from "../Input/Input";
import NcImage from "../NcImage/NcImage";
import { currencyType, getCarrierLogo } from "../../utils/flightInfo";
import { getMyCompany } from "../../redux2/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import SeatModal from "../SelectSeat/SeatModal";
import { convertToNumber } from "../../utils/convertToNumber";
import ButtonPrimary from "../Button/ButtonPrimary";
import convertNumbThousand from "../../utils/convertNumbThousand";
import {
  getBookingById,
  resetBooking,
} from "../../redux2/booking/bookingSlice";
import { getMe } from "../../redux2/user/userSlice";
import customTime from "../../utils/customTime";
import { getMyEnterprise } from "../../redux2/enterprise/enterpriseSlice";
import {
  FOREIGN_PASSENGER,
  INTERNATIONAL_SYSTEM_FEE,
  LOCAL_SYSTEM_FEE,
  TRIP_TYPE_MULTI_CITY,
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_MEMBER,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../common/constants";
import { getCorporate } from "../../redux2/corporate/corporateSlice";

const CustomerDetail = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const labors = query.get("labors");
  const traceId = query.get("traceId");
  const tripType = query.get("tripType");
  const isLocal = query.get("isLocal");
  const destination = query.get("destination");
  const passengerTypeState = query.get("passengerTypeState");
  const _id = query.get("_id");

  let totalPassengers = 0;
  if (adults) {
    totalPassengers += parseInt(parseInt(adults));
  }
  if (labors) {
    totalPassengers += parseInt(labors);
  }
  if (children) {
    totalPassengers += parseInt(children);
  }
  if (infants) {
    totalPassengers += parseInt(infants);
  }

  const {
    AirPricing,
    AirSegment,
    FlightKey,
    charges,
    bookingRef,
    queryURL,
    isRoundTrip,
    rule,
    currency,
  } = location.state;

  const ruleString = JSON.stringify(rule);

  // TODO: if same passenger selected more than once i,e 2 adults

  const { booking, isLoading: bookingLoading } = useSelector(
    (state) => state.booking
  );
  const { company, isLoading, user } = useSelector((state) => state.user);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { corporate, isLoading: corpLoading } = useSelector(
    (state) => state.corporate
  );

  const [seatModal, setSeatModal] = useState(false);
  const [seatIndex, setSeatIndex] = useState(0);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [warning, setWarning] = useState("");
  const [data, setData] = useState([]);
  const [commission, setCommission] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [bookingId, setBookingId] = useState("");
  const [creditDue, setCreditDue] = useState(null);
  const [queueNumber, setQueueNumber] = useState();
  const [ssrCollapsed, setSsrCollapsed] = useState(true);
  const [loyaltyCollapsed, setLoyaltyCollapsed] = useState(true);
  const [formData, setFormData] = useState([
    // {
    //   FirstName: "merry",
    //   MiddleName: "",
    //   LastName: "Doe",
    //   Email: "merry@gmail.com",
    //   phone: "900000007",
    //   Location: "ETH",
    //   Nationality: "",
    //   AreaCode: "251",
    //   CountryCode: "251",
    //   DOB: "2000-02-02",
    //   Gender: "",
    //   Prefix: "",
    // },
    // {
    //   FirstName: "Jane",
    //   MiddleName: "",
    //   LastName: "Joe",
    //   Email: "jane@gmail.com",
    //   phone: "900000008",
    //   Location: "ETH",
    //   Nationality: "",
    //   AreaCode: "251",
    //   CountryCode: "251",
    //   DOB: "2000-01-01",
    //   Gender: "",
    // },
    {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Email: "",
      phone: "",
      Location: "ETH",
      Nationality: "Ethiopia",
      AreaCode: "251",
      CountryCode: "251",
      DOB: "",
      Gender: "",
      Prefix: "",
      OriginId: "",
      ResidentId: "",
      // address
      AddressName: "",
      Street: "",
      City: "",
      State: "",
      PostalCode: "",
      Country: "",
    },
  ]);

  const [passportData, setPassportData] = useState([
    {
      issueDate: "",
      expiryDate: "",
      issueCountry: "",
      passportNumber: "",
      SupplierCode: "",
      CardNumber: "",
      DocumentType: "P",
      DocumentNumber: "",
      ResidentCountry: "ET",
      ProviderCountry: "ET",
      DateOfBirth: "10OCT19",
      passport: "",
      idCard: "",
      certificate: "",
      other: "",
      ssr: [],
      // visa: "",
    },
  ]);

  useEffect(() => {
    if (_id) {
      dispatch(getBookingById(_id));
    }
  }, [dispatch, _id]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    user?.role === USER_ROLE_ENTERPRISE
      ? dispatch(getMyEnterprise())
      : dispatch(getMyCompany());
  }, [dispatch, user?.role]);

  useEffect(() => {
    if (company?.corporate || enterprise?.corporate) {
      dispatch(getCorporate(company?.corporate || enterprise?.corporate));
    } else {
      let id =
        user?.role === USER_ROLE_EMPLOYEE
          ? user?.corporate
          : company?.corporate;
      dispatch(getCorporate(id));
    }
  }, [dispatch, company, user, enterprise]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    // if password expired date is less than 6 months show error
    passportData.forEach((passport) => {
      if (passport.expiryDate) {
        let expiryDate = moment(passport.expiryDate);
        let today = moment();
        let diff = expiryDate.diff(today, "months");
        if (diff < 6) {
          setWarning("Your passport is about to expire");
        } else {
          setWarning("");
        }
      }
    });

    // if (airReservation && !error) {
    //   toast.warning("Reservation Successful");

    //   let passengers = [];
    //   for (let i = 0; i < formData.length; i++) {
    //     passengers.push({
    //       firstName: formData[i].FirstName,
    //       lastName: formData[i].LastName,
    //       email: formData[i].Email,
    //       phoneNumber: formData[i].AreaCode + formData[i].phone,
    //       dob: formData[i].DOB,
    //       nationality: formData[i].Nationality,
    //       gender: formData[i].Gender === "M" ? "Male" : "Female",
    //       location: formData[i].Location,
    //       passportNumber: passportData[i]?.DocumentNumber || "",
    //       originId: formData[i]?.OriginId || "",
    //       residentId: formData[i]?.ResidentId || "",
    //       passport: passportData[i]?.passport || "",
    //       idCard: passportData[i]?.idCard || "",
    //       visa: passportData[i]?.visa || "",
    //     });
    //   }

    //   let price = parseFloat(totalPrice());

    //   let arrivalDate;
    //   if (AirSegment instanceof Array) {
    //     arrivalDate = AirSegment?.filter((item) => {
    //       return item?._attributes?.Group === "0";
    //     });
    //   }

    //   let routes = [
    //     {
    //       flights: [],
    //       departureTime: [],
    //       arrivalTime: [],
    //       flightNumber: [],
    //     },
    //   ];

    //   function addRoute(routeIndex, item) {
    //     if (!routes[routeIndex]) {
    //       routes[routeIndex] = {
    //         flights: [],
    //         departureTime: [],
    //         arrivalTime: [],
    //         flightNumber: [],
    //       };
    //     }
    //     routes[routeIndex].flights.push(item?.Origin, item?.Destination);
    //     routes[routeIndex].departureTime.push(item?.DepartureTime);
    //     routes[routeIndex].arrivalTime.push(item?.ArrivalTime);
    //     routes[routeIndex].flightNumber.push(item?.FlightNumber);
    //   }

    //   if (tripType === TRIP_TYPE_MULTI_CITY) {
    //     for (let i = 0; i < AirSegment?.length; i++) {
    //       const item = AirSegment[i];
    //       addRoute(i, item?._attributes);
    //     }
    //   } else {
    //     if (AirSegment instanceof Array) {
    //       for (let i = 0; i < AirSegment.length; i++) {
    //         const item = AirSegment[i];
    //         if (item._attributes.Group === "0") {
    //           addRoute(0, item?._attributes);
    //         } else if (item._attributes.Group === "1") {
    //           addRoute(1, item?._attributes);
    //         }
    //       }
    //     } else {
    //       addRoute(0, AirSegment);
    //     }
    //   }

    //   // remove duplicates from routes on flights
    //   routes = routes.map((route) => {
    //     return {
    //       ...route,
    //       flights: route.flights.filter(
    //         (item, index) => route.flights.indexOf(item) === index
    //       ),
    //     };
    //   });

    //   const flightType = {
    //     oneWay: "One Way",
    //     roundTrip: "Round Trip",
    //     multiCity: "Multi City",
    //   };

    //   let flight = {};
    //   AirSegment instanceof Array
    //     ? (flight = {
    //         origin: AirSegment[0]._attributes.Origin,
    //         destination: destination,
    //         departureTime: AirSegment[0]._attributes.DepartureTime,
    //         arrivalTime:
    //           arrivalDate?.[arrivalDate.length - 1]?._attributes?.ArrivalTime ||
    //           AirSegment[0]._attributes.ArrivalTime,
    //         flightNumber: AirSegment[0]._attributes.FlightNumber,
    //         carrier: AirSegment[0]._attributes.Carrier,
    //         equipment: AirSegment[0]._attributes.Equipment,
    //         cabinClass: AirSegment[0]._attributes.CabinClass,
    //         seat: reservedSeats[0] ? reservedSeats[0]._attributes.SeatCode : "",
    //         commission,
    //         price: parseFloat(
    //           AirPricing?._attributes?.TotalPrice?.toString().slice(3)
    //         ),
    //         totalPrice: price,
    //         isRoundTrip,
    //         flightType: flightType[tripType] || "Round Trip",
    //         routes,
    //       })
    //     : (flight = {
    //         origin: AirSegment.Origin,
    //         destination: destination,
    //         departureTime: AirSegment.DepartureTime,
    //         arrivalTime: AirSegment.ArrivalTime,
    //         flightNumber: AirSegment.FlightNumber,
    //         carrier: AirSegment.Carrier,
    //         equipment: AirSegment.Equipment,
    //         cabinClass: AirSegment.CabinClass,
    //         seat: reservedSeats[0]
    //           ? reservedSeats?.[0]?.[0]?.[0]?._attributes?.SeatCode
    //           : "",
    //         commission,
    //         price: parseFloat(
    //           AirPricing?._attributes?.TotalPrice?.toString().slice(3)
    //         ),
    //         totalPrice: price,
    //         isRoundTrip,
    //         routes,
    //         flightType: flightType[tripType] || "One Way",
    //       });

    //   let bookingData = {
    //     passengers,
    //     flight,
    //   };

    //   let files = [];
    //   for (let i = 0; i < passportData.length; i++) {
    //     if (passportData[i]?.passport) {
    //       files.push(passportData[i]?.passport);
    //     }
    //     if (passportData[i]?.idCard) {
    //       files.push(passportData[i]?.idCard);
    //     }
    //     if (passportData[i]?.visa) {
    //       files.push(passportData[i]?.visa);
    //     }
    //   }

    //   const data = new FormData();
    //   data.append("bookingData", JSON.stringify(bookingData));
    //   for (let i = 0; i < files.length; i++) {
    //     data.append("files", files[i]);
    //   }

    //   // dispatch(createBooking(data));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch, passportData]);

  useEffect(() => {
    // if (booking?.data?._id) {
    if (data?.AirReservation && !error) {
      let airReservation = data?.AirReservation;
      let travelers = data?.BookingTraveler;
      let retrievalPnr = data?._attributes?.LocatorCode;
      let reservation = data?.ProviderReservationInfo;
      toast.warning("Reservation Successful");
      // navigate(`/checkout/${booking?.data?._id}?${queryURL}`, {
      navigate(`/checkout/${bookingId}?${queryURL}`, {
        state: {
          charges,
          queryURL,
          travelers,
          systemFee: getSystemFee(),
          retrievalPnr,
          reservation,
          serviceCharge,
          airReservation,
          airPrice: AirPricing,
          currency,
          creditDue,
        },
      });
    }
    dispatch(resetBooking());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.AirReservation, error]);

  useEffect(() => {
    if (booking?.passengers) {
      setFormData([]);
      setPassportData([]);
      Array(totalPassengers)
        .fill()
        .map((_, index) => {
          setFormData((prev) => {
            return [
              ...prev,
              {
                Prefix: booking?.passengers[index]?.title,
                FirstName: booking?.passengers[index]?.firstName,
                MiddleName: booking?.passengers[index]?.middleName,
                LastName: booking?.passengers[index]?.lastName,
                Email: booking?.passengers[index]?.email,
                phone: +booking?.passengers[index]?.phoneNumber
                  ?.toString()
                  ?.slice(3),
                Location: booking?.passengers[index]?.location,
                Nationality: booking?.passengers[index]?.nationality,
                DOB: booking?.passengers[index]?.dob?.split("T")[0],
                Gender:
                  booking?.passengers[index]?.gender === "Male" ? "M" : "F",
              },
            ];
          });
          setPassportData((prev) => {
            return [
              ...prev,
              {
                DocumentNumber: booking?.passengers[index]?.passportNumber,
                expiryDate:
                  booking?.passengers[index]?.passportExpiry?.split("T")[0],
              },
            ];
          });
        });
    }
  }, [booking?.passengers, totalPassengers]);

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      [name]: value,
    };
    setFormData(newFormData);

    // setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSSRChange = (e, index, i) => {
    const { value } = e.target;
    const newPassportData = [...passportData];

    if (!newPassportData[index]) {
      newPassportData[index] = {
        issueDate: "",
        expiryDate: "",
        issueCountry: "",
        ssr: [],
      };
    }
    if (!newPassportData[index].ssr) {
      newPassportData[index].ssr = [];
    }
    newPassportData[index].ssr[i] = value;
    setPassportData(newPassportData);
  };

  const getMinDate = (type) => {
    const today = new Date();
    let minDate;

    if (type === "INF") {
      minDate = new Date(
        today.getFullYear() - 2,
        today.getMonth(),
        today.getDate()
      );
    } else if (type === "CNN") {
      minDate = new Date(
        today.getFullYear() - 12,
        today.getMonth(),
        today.getDate()
      );
    } else {
      return "";
    }

    return minDate.toISOString().split("T")[0];
  };

  const getMaxDate = (type) => {
    const today = new Date();
    let maxDate;

    if (type === "ADT") {
      maxDate = new Date(
        today.getFullYear() - 12,
        today.getMonth(),
        today.getDate()
      );
    } else {
      maxDate = new Date();
    }

    return maxDate.toISOString().split("T")[0];
  };

  async function handleAcceptedFiles(e, index) {
    const { name, files } = e.target;
    if (files.length > 3) {
      alert("You can only upload 3 images");
      return;
    }
    if (files[0].size > 1024 * 1024 * 5) {
      alert("Image size should be less than 5MB");
      return;
    }
    const newPassportData = [...passportData];
    newPassportData[index] = {
      ...newPassportData[index],
      [name]: files[0],
      // [name]: await fileToBase64(files[0]),
    };
    setPassportData(newPassportData);
  }

  const onPassportChange = (e, index) => {
    const { name, value } = e.target;
    const newPassportData = [...passportData];
    newPassportData[index] = {
      ...newPassportData[index],
      [name]: value,
    };
    setPassportData(newPassportData);
    // setPassportData({ ...passportData, [e.target.name]: e.target.value });
  };

  function getSystemFee() {
    if (isLocal === "true")
      return LOCAL_SYSTEM_FEE * (+adults + +children + +labors);
    else {
      return user?.role === USER_ROLE_SUPERAGENT ||
        user?.role === USER_ROLE_EMPLOYEE
        ? INTERNATIONAL_SYSTEM_FEE * (+adults + +children + +labors)
        : // : passengerTypeState === NON_DOCUMENT_HOLDER
        currencyType(currency) === "USD"
        ? corporate?.exchangeRate * charges * 0.2
        : charges * 0.2;
    }
  }

  function getServiceCharge() {
    // if (passengerTypeState === NON_DOCUMENT_HOLDER) {
    if (currencyType(currency) === "USD") {
      return charges > 0
        ? charges * corporate?.exchangeRate
        : serviceCharge * corporate?.exchangeRate;
    } else {
      return charges > 0 ? charges : serviceCharge;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      isLocal === "true" &&
      (user?.role === USER_ROLE_MEMBER || user?.role === USER_ROLE_AGENT)
    ) {
      let hasPassportOrIdCard = false;
      passportData.forEach((data) => {
        if (data?.passport !== "" || data?.idCard !== "") {
          hasPassportOrIdCard = true;
        }
      });
      if (!hasPassportOrIdCard) {
        toast.error("Please upload passport or id card");
        return;
      }
    }

    // if (
    //   company &&
    //   (company?.wallet < WALLET_MINIMUM_AMOUNT ||
    //     company.wallet < parseFloat(totalPrice()))
    // ) {
    //   toast.error(
    //     "Insufficient balance in your wallet. Please deposit money to continue."
    //     // "Insufficient Balance. Balance is less than 20,000 birr. Please deposit to your wallet"
    //   );
    //   return;
    // }

    if (
      enterprise &&
      enterprise?.method === "prePaid" &&
      enterprise?.wallet < WALLET_MINIMUM_AMOUNT + parseFloat(totalPrice())
    ) {
      toast.error(
        "Insufficient balance in your wallet. Please deposit money to continue."
        // "Insufficient Balance. Balance is less than 20,000 birr. Please deposit to your wallet"
      );
      return;
    }

    if (
      user?.role === USER_ROLE_EMPLOYEE &&
      user?.dailyBookingLimit < parseFloat(totalPrice() + user?.dailyBooking)
    ) {
      toast.error("You have reached your daily booking limit.");
      return;
    }

    if (
      (user?.role === USER_ROLE_SUPERAGENT ||
        user?.role === USER_ROLE_EMPLOYEE) &&
      serviceCharge < 1
    ) {
      toast.error("Service Charge must be greater than 0");
      return;
    } else if (
      (user?.role === USER_ROLE_AGENT || user?.role === USER_ROLE_MEMBER) &&
      commission < 1
    ) {
      toast.error("Agent Fee must be greater than 0");
      return;
    }

    let bookingData2 = {
      // Company: "FetanTicket",
      TraceId: traceId,
      TotalAmount: AirPricing._attributes.TotalPrice,
      TicketDate:
        AirSegment instanceof Array
          ? AirSegment[0]._attributes.DepartureTime
          : AirSegment.DepartureTime,
      AirPricingSolution: {
        ...AirPricing,
        AirSegment: FlightKey,
      },
      BookingTraveler: [],
      SpecificSeatAssignment: [],
      // ActionStatus: {
      //   Type: "TTL",
      //   AirportCode: AirSegment?.Origin || AirSegment?.[0]?._attributes?.Origin,
      //   TicketDate:
      //     AirSegment instanceof Array
      //       ? AirSegment[0]._attributes.DepartureTime
      //       : AirSegment.DepartureTime,
      //   SupplierCode:
      //     AirSegment?.Carrier || AirSegment?.[0]?._attributes?.Carrier,
      // },
      OtherInfo: {
        ServiceCharge: getServiceCharge(),
        USDServiceCharge: charges > 0 ? +charges : +serviceCharge,
        Commission: commission,
        SystemFee: getSystemFee(),
        TripType: tripType,
        isRoundTrip: isRoundTrip,
        Destination: destination,
        company: company?._id,
        queueNumber,
        rule: ruleString,
        // currency:
        //   passengerTypeState === NON_DOCUMENT_HOLDER &&
        //   (AirSegment.Carrier || AirSegment[0]._attributes.Carrier !== "ET")
        //     ? "USD"
        //     : "ETB",
        currency,
        user: {
          id: user?._id,
          role: user?.role,
        },
      },
    };

    for (let passenger in formData) {
      let SSR = passportData[passenger]?.ssr?.filter((item) => {
        return Object.values(item).some((val) => val !== "");
      });
      bookingData2.BookingTraveler.push({
        Prefix: formData[passenger].Prefix,
        FirstName: formData[passenger].FirstName,
        MiddleName: formData[passenger].MiddleName,
        LastName: formData[passenger].LastName,
        Email: formData[passenger].Email,
        OriginId: formData[passenger].OriginId,
        ResidentId: formData[passenger].ResidentId,
        PhoneNumber: {
          AreaCode: "251",
          CountryCode: "251",
          Location: "ETH",
          Number: formData[passenger].phone,
        },
        DOB: formData[passenger].DOB,
        Gender: formData[passenger].Gender || "M",
        Nationality: formData[passenger].Nationality || "Ethiopia",
        TravelerType: bookingRef[passenger]?.Code,
        Key: bookingRef[passenger]?.BookingTravelerRef,
        passportData: passportData[passenger]?.DocumentNumber,
        Price:
          +AirPricing?.AirPricingInfo?.[
            passenger
          ]?._attributes?.TotalPrice?.toString().slice(3) ||
          +AirPricing?.AirPricingInfo?._attributes?.TotalPrice?.toString().slice(
            3
          ),

        LoyaltyCard: {
          CardNumber: passportData[passenger]?.CardNumber,
          SupplierCode: passportData[passenger]?.SupplierCode,
        },
        SSR:
          SSR?.map((item) => {
            if (item !== "") {
              return {
                Type: item,
                FreeText: "",
              };
            }
          }, []) || [],
        Document: {
          // DocumentType: passportData[passenger].DocumentType,
          // ResidentCountry: passportData[passenger].ResidentCountry,
          // ProviderCountry: passportData[passenger].ProviderCountry,
          ExpiryDate: passportData[passenger]?.expiryDate
            ? moment(passportData[passenger]?.expiryDate).format("DDMMMYY")
            : "10OCT30",
          BirthDay: moment(formData[passenger]?.DOB).format("DDMMMYY"),
          DocumentType: "P",
          DocumentNumber:
            passportData[passenger]?.DocumentNumber || "ET1234567",
          ResidentCountry: "ET",
          ProviderCountry: "ET",
        },
        Address: {
          AddressName: formData[passenger]?.AddressName,
          Street: formData[passenger]?.Street,
          City: formData[passenger]?.City,
          State: formData[passenger]?.State,
          PostalCode: formData[passenger]?.PostalCode,
          Country: formData[passenger]?.Country,
        },
      });
    }

    // if (
    //   isLocal === "true" &&
    //   formData.length === 1 &&
    //   FlightKey instanceof Object
    // ) {
    //   bookingData2.SpecificSeatAssignment.push({
    //     PassengerKey: bookingRef?.[0]?.BookingTravelerRef,
    //     SegmentRef: FlightKey?._attributes?.Key,
    //     SeatId: reservedSeats?.[0]?.[0]?.[0]?._attributes?.SeatCode,
    //   });
    // }

    if (reservedSeats?.length > 0) {
      if (FlightKey?.length > 1) {
        for (let i = 0; i < FlightKey.length; i++) {
          for (let k = 0; k < formData.length; k++) {
            if (reservedSeats?.[k]?.[i]?.[0]?._attributes?.SeatCode) {
              bookingData2.SpecificSeatAssignment.push({
                PassengerKey: bookingRef?.[k]?.BookingTravelerRef,
                SegmentRef: FlightKey[i]?._attributes?.Key,
                SeatId: reservedSeats?.[k]?.[i]?.[0]?._attributes?.SeatCode,
              });
            }
          }
        }
      } else {
        for (let i = 0; i < formData.length; i++) {
          if (reservedSeats?.[i]?.[0]?.[0]?._attributes?.SeatCode) {
            bookingData2.SpecificSeatAssignment.push({
              PassengerKey: bookingRef?.[i]?.BookingTravelerRef,
              SegmentRef: FlightKey?._attributes?.Key,
              SeatId: reservedSeats?.[i]?.[0]?.[0]?._attributes?.SeatCode,
            });
          }
        }
      }
    }

    let files = [];
    for (let i = 0; i < passportData.length; i++) {
      if (passportData[i]?.passport) {
        files.push(passportData[i]?.passport);
      }
      if (passportData[i]?.idCard) {
        files.push(passportData[i]?.idCard);
      }

      if (passportData[i]?.certificate) {
        files.push(passportData[i]?.certificate);
      }

      if (passportData[i]?.other) {
        files.push(passportData[i]?.other);
      }
      // if (passportData[i]?.visa) {
      //   files.push(passportData[i]?.visa);
      // }
    }

    const data = new FormData();
    data.append("bookingData", JSON.stringify(bookingData2));
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    setLoading(true);
    axios
      .post(API_URL + "flight/booking", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setBookingId(res.data?.bookingId);
        setCreditDue(res.data?.creditDue);
        setData(res.data?.AirCreateReservationRsp?.UniversalRecord);
        setError(
          res.data?.AirCreateReservationRsp?.ResponseMessage?.[1]?._text
        );
        setError(res.data?.Fault?.faultstring?._text);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
      });
  };

  if (loading || bookingLoading || isLoading || corpLoading) {
    return <Loading />;
  }

  function totalPrice() {
    let airPrice = AirPricing?._attributes?.TotalPrice?.toString().slice(3);
    airPrice = parseFloat(airPrice);

    let result = airPrice + charges + parseFloat(serviceCharge || 0);

    return result;
  }

  const isRequired = (isLocal, user, i) => {
    if (
      user?.role === USER_ROLE_SUPERAGENT ||
      user?.role === USER_ROLE_EMPLOYEE
    ) {
      return false;
    }
    if (
      isLocal === "true" &&
      (passportData[i]?.passport || passportData[i]?.idCard)
    ) {
      return false;
    }
    // if (
    //   isLocal === "false"
    //    &&
    //   (AirSegment.Carrier || AirSegment[0]._attributes.Carrier) === "ET"
    // ) {
    //   return false;
    // }
    return true;
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-8 p-5 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            {/* <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden"> */}
            <NcImage
              src={getCarrierLogo(
                AirSegment.Carrier || AirSegment[0]._attributes.Carrier
              )}
            />
            {/* </div> */}
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>{AirSegment.Carrier || AirSegment[0]._attributes.Carrier}</h1>
            </span>
            {AirSegment instanceof Array ? (
              AirSegment.map((segment, index) => (
                <div key={index}>
                  <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                    <h1>
                      {segment._attributes.Origin} -{" "}
                      {segment._attributes.Destination}
                    </h1>
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {moment(segment._attributes.DepartureTime).format(
                      "DD MMM YYYY"
                    )}{" "}
                    -
                    {moment(segment._attributes.ArrivalTime).format(
                      "DD MMM YYYY"
                    )}
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {customTime(segment._attributes.DepartureTime)} -
                    {customTime(segment._attributes.ArrivalTime)}
                  </span>
                </div>
              ))
            ) : (
              <div>
                {/* <span className="text-lg text-neutral-500 font-semibold">
                  <h1>{AirSegment.Carrier}</h1>
                </span> */}
                <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                  <h1>
                    {AirSegment.Origin} - {AirSegment.Destination}
                  </h1>
                </span>
                <span className="text-base font-medium mt-1 block">
                  {moment(AirSegment.DepartureTime).format("DD MMM YYYY")} -
                  {moment(AirSegment.ArrivalTime).format("DD MMM YYYY")}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {customTime(AirSegment.DepartureTime)} -
                  {customTime(AirSegment.ArrivalTime)}
                </span>
              </div>
            )}
            <div className="flex flex-row justify-between">
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {parseInt(adults)} Adult
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {children > 0 && children + " Children"}
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {infants > 0 && infants + " Infants"}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <div>
              {" "}
              {+adults > 0 ? adults + " * Adults" : labors + " * Labors "}
            </div>
            <div>
              {convertToNumber(
                AirPricing?.AirPricingInfo?._attributes?.TotalPrice ??
                  AirPricing?.AirPricingInfo[0]?._attributes?.TotalPrice
              )}{" "}
              {AirPricing?.AirPricingInfo?._attributes?.TotalPrice?.substring(
                0,
                3
              ) ??
                AirPricing?.AirPricingInfo[0]?._attributes?.TotalPrice?.substring(
                  0,
                  3
                )}
            </div>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {children > 0 && (
              <>
                <div>
                  {children} *{" Children "}
                </div>
                <div>
                  {convertToNumber(
                    AirPricing?.AirPricingInfo[1]?._attributes?.TotalPrice
                  )}{" "}
                  {AirPricing?.AirPricingInfo[1]?._attributes?.TotalPrice?.substring(
                    0,
                    3
                  )}
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {infants > 0 && (
              <>
                <div>
                  {infants} *{" Infants "}
                </div>
                <div>
                  {convertToNumber(
                    AirPricing?.AirPricingInfo[children > 0 ? 2 : 1]
                      ?._attributes?.TotalPrice
                  )}{" "}
                  {AirPricing?.AirPricingInfo[
                    children > 0 ? 2 : 1
                  ]?._attributes?.TotalPrice?.substring(0, 3)}
                </div>
              </>
            )}
          </div>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            {user?.role === USER_ROLE_SUPERAGENT ||
            user?.role === USER_ROLE_EMPLOYEE ? (
              <span>
                <input
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  className="w-20 border border-black rounded-md p-1"
                  value={serviceCharge}
                  onChange={(e) => setServiceCharge(e.target.value)}
                  required
                />{" "}
                {currencyType(currency)}
              </span>
            ) : (
              <span className="text-red-500">
                {charges || 0} {currencyType(currency)}
              </span>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Agent Fee</span>
            {user?.role === USER_ROLE_SUPERAGENT ||
            user?.role === USER_ROLE_EMPLOYEE ||
            user?.role === USER_ROLE_ENTERPRISE ? (
              <span>0 birr</span>
            ) : (
              <span className="text-red-500">
                <input
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  className="w-20 border border-black rounded-md p-1 text-black"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                  required
                />{" "}
                {currencyType(currency)}
              </span>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Queue Number</span>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="w-20 border border-black rounded-md p-1"
              value={queueNumber}
              onChange={(e) => setQueueNumber(e.target.value)}
            />
          </div>
          <div className="border-b border-neutral-200 "></div>
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span>
              {convertNumbThousand(totalPrice() + parseFloat(commission || 0))}{" "}
              {AirPricing?._attributes?.TotalPrice?.substring(0, 3)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = (index, type) => {
    return (
      <div className="mt-6">
        <Tab.Group>
          <Tab.Panels>
            <Tab.Panel className=" text-lg">
              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <div className="flex flex-row gap-3">
                      <div className="flex flex-col ">
                        <Label>Title</Label>
                        <div className="flex flex-row gap-1">
                          <select
                            className="block border text-black text-[15px] border-black w-20 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white h-10 mt-1"
                            style={{
                              display:
                                type !== "ADT" && type !== "LBR" && "none",
                            }}
                            name="Prefix"
                            value={formData[index]?.Prefix || ""}
                            onChange={(e) => onChange(e, index)}
                          >
                            <option value="" selected></option>
                            <option
                              value="Mr"
                              onChange={(e) => onChange(e, index)}
                            >
                              Mr.
                            </option>
                            <option
                              value="Mrs"
                              onChange={(e) => onChange(e, index)}
                            >
                              Mrs.
                            </option>
                            <option
                              value="Ms"
                              onChange={(e) => onChange(e, index)}
                            >
                              Ms.
                            </option>
                            <option
                              value="Dr"
                              onChange={(e) => onChange(e, index)}
                            >
                              Dr.
                            </option>
                            <option
                              value="Prof"
                              onChange={(e) => onChange(e, index)}
                            >
                              Prof.
                            </option>
                            <option
                              value="Amb"
                              onChange={(e) => onChange(e, index)}
                            >
                              Amb.
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col gap-1 w-full">
                        <Label>
                          First Name{" "}
                          <span className="py-1 text-red-500">*</span>
                        </Label>
                        <Input
                          placeholder="Enter first name"
                          className="w-full"
                          name="FirstName"
                          value={formData[index]?.FirstName || ""}
                          onChange={(e) => onChange(e, index)}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>Middle Name </Label>
                    <Input
                      // className="w-1/2"
                      placeholder="Enter middle name"
                      name="MiddleName"
                      value={formData[index]?.MiddleName || ""}
                      onChange={(e) => onChange(e, index)}
                    />
                    {/* </div> */}
                  </div>
                </div>

                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Last Name <span className="py-1 text-red-500">*</span>
                    </Label>
                    <Input
                      // className="w-1/2"
                      placeholder="Enter last name"
                      name="LastName"
                      value={formData[index]?.LastName || ""}
                      onChange={(e) => onChange(e, index)}
                      required={true}
                      // required={isLocal === "true" ? false : true}
                    />
                    {/* </div> */}
                  </div>
                </div>
                <div
                  className="col-md-6 my-2"
                  style={{
                    display: type !== "ADT" && type !== "LBR" && "none",
                  }}
                >
                  <Label>
                    Email <span className="py-1 text-red-500">*</span>
                  </Label>
                  <Input
                    // className="w-1/2"
                    placeholder="Enter email"
                    value={formData[index]?.Email || ""}
                    onChange={(e) => onChange(e, index)}
                    name="Email"
                    required={type === "ADT"}
                    type="email"
                  />
                </div>

                <div
                  className="col-md-6 my-2"
                  style={{
                    display: type !== "ADT" && type !== "LBR" && "none",
                  }}
                >
                  <div className="space-y-0">
                    <Label>
                      Phone Number <span className="py-1 text-red-500">*</span>
                    </Label>
                    <div className="flex flex-row">
                      <select
                        className="block border text-black text-[15px] border-black w-20 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                        name="AreaCode"
                        value={formData[index]?.AreaCode || ""}
                        onChange={(e) => onChange(e, index)}
                      >
                        <option>+251</option>
                        <option>+1</option>
                        <option>+40</option>
                        <option>+97</option>
                      </select>
                      {/* <Input defaultValue="+251" className="w-24" /> */}
                      <Input
                        defaultValue="900000000"
                        placeholder="Enter phone number"
                        // className="w-1/3"
                        type="Number"
                        name="phone"
                        value={formData[index]?.phone || ""}
                        onChange={(e) => onChange(e, index)}
                        required={type === "ADT"}
                        minLength={9}
                        maxLength={9}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Nationality <span className="py-1 text-red-500">*</span>
                    </Label>
                    <select
                      defaultValue="Ethiopia"
                      className="block border text-black text-[15px] border-black w-1/2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white p-2"
                      name="Nationality"
                      onChange={(e) => onChange(e, index)}
                    >
                      {countryList.getNames().map((country) => (
                        <option
                          selected={country === "Ethiopia"}
                          defaultValue="Ethiopia"
                          key={country}
                          value={country}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="flex-1 space-y-0">
                    <Label>
                      Date of birth <span className="py-1 text-red-500">*</span>
                    </Label>
                    <Input
                      type="date"
                      // className="w-1/2"
                      name="DOB"
                      value={formData[index]?.DOB || ""}
                      onChange={(e) => onChange(e, index)}
                      required={true}
                      min={getMinDate(type)}
                      max={getMaxDate(type)}
                      // add validation for age if type is CNN age between 2-11 and if type is INF age between 0-2
                    />
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Gender <span className="py-1 text-red-500">*</span>
                    </Label>
                    <select
                      className="block border text-black text-[15px] border-black w-1/2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white p-2"
                      name="Gender"
                      onChange={(e) => onChange(e, index)}
                    >
                      <option
                        value="M"
                        selected
                        onChange={(e) => onChange(e, index)}
                      >
                        Male
                      </option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
                {passengerTypeState === FOREIGN_PASSENGER && (
                  <div className="col-md-6 my-2">
                    <div className="space-y-0">
                      <Label>
                        Origin ID NO.
                        <p className="text-red-500/80 text-xs">
                          Note: Please note that if your customer is not an
                          Ethiopian national and has an Ethiopian origin ID,
                          please insert the ID number in the box provided.
                        </p>{" "}
                      </Label>
                    </div>
                    <Input
                      placeholder="Ethiopian Origin ID No."
                      name="OriginId"
                      value={formData[index]?.OriginId}
                      onChange={(e) => onChange(e, index)}
                    />
                  </div>
                )}
                {type !== "INF" && (
                  <>
                    <div className="space-y-0">
                      <Label>Choose Seat </Label>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <Button
                          className="bg-black text-white mx-3 border px-5  py-2 rounded-lg align-middle items-center"
                          onClick={() => {
                            setSeatIndex(index);
                            setSeatModal(true);
                          }}
                        >
                          Choose Seat
                        </Button>
                      </div>

                      <div className="col-md-8">
                        {/* <Label className="text-xs">Selected Seat </Label> */}
                        {reservedSeats.length > 0 && (
                          <div className="flex flex-wrap justify-between">
                            {reservedSeats?.[index]?.map((seat) => (
                              <div className="text-sky-600 font-light text-sm">
                                {/* add index of 0 at seat on 3 of them */}
                                <p>{seat?.[0]?._attributes?.SeatCode}</p>
                                {seat?.[0]?.Characteristic instanceof Array ? (
                                  seat?.[0]?.Characteristic?.map(
                                    (characteristic, index) => {
                                      return (
                                        <p key={index} className="">
                                          {"*"}{" "}
                                          {characteristic._attributes.Value}
                                        </p>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="">
                                    {"*"}{" "}
                                    {
                                      seat?.[0]?.Characteristic?._attributes
                                        ?.Value
                                    }
                                  </p>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {(AirSegment.Carrier || AirSegment[0]._attributes.Carrier) ===
                  "TK" && (
                  <div className="space-y-2 my-4">
                    <h1 className="font-bold text-black text-2xl">
                      Passenger Destination Address
                    </h1>

                    <div className="row border-1 border-slate-400 p-2 rounded-md">
                      <h6 className="text-red-500 text-base">
                        * All Fields are Mandatory for <b>Turkish Airlines</b>{" "}
                      </h6>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Address Name </Label>
                        </div>
                        <Input
                          placeholder="Address Name"
                          // className="w-1/2"
                          name="AddressName"
                          value={passportData.addressName}
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="space-y-0">
                          <Label>Street</Label>
                          <Input
                            type="text"
                            placeholder="Street"
                            name="Street"
                            value={passportData.street}
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="space-y-0">
                          <Label>City</Label>
                          <Input
                            type="text"
                            placeholder="City"
                            name="City"
                            value={passportData.city}
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>State</Label>
                        </div>
                        <Input
                          type="text"
                          name="State"
                          placeholder="State"
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Postal Code </Label>
                        </div>
                        <Input
                          type="text"
                          name="PostalCode"
                          placeholder="Postal Code"
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Country Code </Label>
                        </div>
                        <Input
                          type="text"
                          placeholder="Country"
                          name="Country"
                          onChange={(e) => onChange(e, index)}
                          // className="w-1/2"
                        />
                        <p className="text-sm">
                          (Eg. insert "FR" - if your destination city is in
                          France)
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="space-y-2">
                  <h1 className="font-bold text-black text-2xl">
                    Passport Details
                  </h1>
                  {/* show warring */}
                  {warning && (
                    <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                      <i className="fas fa-exclamation-triangle mr-2"></i>
                      Passport is about to expire. or check the passport expiry
                      date input again.
                    </p>
                  )}
                  <div className="row border-1 border-slate-400 p-2 rounded-md">
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Passport Number </Label>
                      </div>
                      <Input
                        placeholder="Passport Number"
                        // className="w-1/2"
                        name="DocumentNumber"
                        value={passportData?.[index]?.DocumentNumber || ""}
                        onChange={(e) => onPassportChange(e, index)}
                        required={isLocal === "true" ? false : true}
                      />
                    </div>
                    {/* <div className="col-md-6"></div> */}
                    {/* <div className="col-md-6">
                      <div className="space-y-0">
                        <Label>Passport Issued Date</Label>
                        <Input
                          type="date"
                          // className="w-1/2"
                          required={isLocal === "true" ? false : true}
                          name="issueDate"
                          value={passportData?.[index]?.issueDate || ""}
                          onChange={(e) => onPassportChange(e, index)}
                          // min date before today
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div> */}
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Passport Expire Date</Label>
                        <Input
                          type="date"
                          // className="w-1/2"
                          required={isLocal === "true" ? false : true}
                          name="expiryDate"
                          value={passportData?.[index]?.expiryDate || ""}
                          onChange={(e) => onPassportChange(e, index)}
                        />
                      </div>
                    </div>
                    {isLocal === "false" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label> Upload Passport </Label>
                        </div>
                        <input
                          // accept="image/*"
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="passport"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={isRequired(isLocal, user, index)}

                          // onChange={(e) => onPassportChange(e, index)}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-600">
                          Upload a file with Max size of 5MB
                        </Label>
                        {user?.role !== USER_ROLE_SUPERAGENT && (
                          <p className="text-sm text-red-500">
                            * Please upload passport.
                          </p>
                        )}
                      </div>
                    )}

                    {isLocal === "true" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>
                            {passengerTypeState === "Foreigner"
                              ? "Upload passport"
                              : "Upload passport/Kebele Card(ID)"}
                          </Label>
                        </div>
                        <input
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="idCard"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={isRequired(isLocal, user, index)}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-700">
                          Upload a file with Max size of 5MB
                        </Label>
                        {passengerTypeState !== "Foreigner" && (
                          <p className="text-sm text-red-500">
                            * Please upload passport, Kebele ID, Driving License
                            or Work ID
                          </p>
                        )}
                      </div>
                    )}
                    {/* {type === "LBR" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label> Labor Id </Label>
                        </div>
                        <input
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="certificate"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={type === "LBR"}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-700">
                          Upload a file with Max size of 5MB
                        </Label>
                      </div>
                    )} */}

                    {passengerTypeState === "Foreigner" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label> Other Uploads </Label>
                        </div>
                        <input
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="other"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={passengerTypeState === "Foreigner"}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-700">
                          Upload a file with Max size of 5MB
                        </Label>
                        <p className="text-sm text-red-500">
                          * Please upload Yellow Card, Residential Permit, Work
                          Permit or Origin Id
                        </p>
                      </div>
                    )}

                    <div className="space-y-2">
                      <div className="flex flex-row justify-between py-3">
                        <h1 className="font-semibold text-black text-xl pt-2">
                          Special Requests
                        </h1>

                        <button
                          onClick={() => {
                            setSsrCollapsed(!ssrCollapsed);
                          }}
                          type="button"
                        >
                          <i
                            className={
                              "text-xl text-main font-bold pt-2 " +
                              (ssrCollapsed ? "fa fa-plus" : "fa fa-minus")
                            }
                          ></i>
                        </button>
                      </div>
                      <div
                        className={
                          ssrCollapsed
                            ? "hidden row border-1 border-slate-200 p-2 rounded-md"
                            : "visible row border-1 border-slate-200 p-2 rounded-md"
                        }
                        id="collapseSSR"
                      >
                        {type !== "INF" && (
                          <div className="col-md-6 ">
                            <div className="space-y-0">
                              <Label>Meal Preference </Label>
                              <select
                                className="block border p-2 text-black text-[15px] border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white w-full"
                                name="ssr"
                                value={passportData[index]?.ssr?.[0] || ""}
                                onChange={(e) => onSSRChange(e, index, 0)}
                              >
                                <option value="" selected>
                                  (optional)
                                </option>
                                <option value="AVML">
                                  Vegetarian Hindu meal
                                </option>
                                <option value="BBML">Baby meal</option>
                                <option value="BLML">Bland meal</option>
                                <option value="CHML">Child meal</option>
                                <option value="DBML">Diabetic meal</option>
                                <option value="FPML">Fruit platter meal</option>
                                <option value="GFML">
                                  Gluten intolerant meal
                                </option>
                                <option value="HNML">Hindu meal</option>
                                <option value="KSML">Kosher meal</option>
                                <option value="LCML">Low calorie meal</option>
                                <option value="LFML">Low fat meal</option>
                                <option value="LSML">Low salt meal</option>
                                <option value="MOML">Moslem meal</option>
                                <option value="NLML">Low lactose meal</option>
                                <option value="NOML">No meal</option>
                                <option value="RVML">
                                  Vegetarian raw meal
                                </option>
                                <option value="SFML">Sea food meal</option>
                                <option value="VGML">
                                  Vegetarian vegan meal
                                </option>
                                <option value="VJML">
                                  Vegetarian Jain meal
                                </option>
                                <option value="VLML">
                                  Vegetarian meal lacto-ovo (allows milk & eggs)
                                </option>
                                <option value="VOML">
                                  Vegetarian oriental meal
                                </option>
                              </select>
                            </div>
                          </div>
                        )}

                        <div className="col-md-6">
                          <div className="space-y-0">
                            <Label>Special Requests </Label>
                            <select
                              className="block border p-2 text-black text-[15px] border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white w-full"
                              name="ssr"
                              value={passportData[index]?.ssr?.[1] || ""}
                              onChange={(e) => onSSRChange(e, index, 1)}
                            >
                              <option value="" selected>
                                (optional)
                              </option>
                              <option value="BLND">Blind Passenger</option>
                              <option value="DEAF">Deaf Passenger</option>
                              {/* <option value="NZ">Meet and Assist</option> */}
                              {/* DPNA: Intellectual or development disability needing assistance */}
                              <option value="WCHR">
                                Wheelchair/Passenger can walk up/to stairs
                              </option>
                              <option value="WCHC">
                                Wheelchair/Passenger must be carried
                              </option>
                              {/* <option value="KP">Medical Case</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <div className="flex flex-row justify-between py-3">
                        <h1 className="font-semibold text-black text-xl pt-2">
                          Loyalty Card
                        </h1>
                        <button
                          onClick={() => {
                            setLoyaltyCollapsed(!loyaltyCollapsed);
                          }}
                          type="button"
                        >
                          <i
                            className={
                              "text-xl text-main font-bold pt-2 " +
                              (loyaltyCollapsed ? "fa fa-plus" : "fa fa-minus")
                            }
                          ></i>
                        </button>
                      </div>
                      <div
                        className={
                          loyaltyCollapsed
                            ? "hidden row border-1 border-slate-200 p-2 rounded-md"
                            : "visible row border-1 border-slate-200 p-2 rounded-md"
                        }
                      >
                        <div className="col-md-6 ">
                          <div className="space-y-0">
                            <Label>Supplier Code </Label>
                            <select
                              className="block border p-2 text-black text-[15px] border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                              name="SupplierCode"
                              value={passportData[index]?.SupplierCode || ""}
                              onChange={(e) => onPassportChange(e, index)}
                            >
                              <option value="" selected>
                                Select Supplier Code
                              </option>
                              <option value="ET">ShebaMiles (ET)</option>
                              <option value="AC">Aeroplan (AC)</option>
                              <option value="NZ">Airpoints (NZ)</option>
                              <option value="NH">ANA Mileage Club (NH)</option>
                              <option value="OZ">Asiana Club (OZ)</option>
                              <option value="KP">ASKY Club (KP)</option>
                              <option value="CM">ConnectMiles (CM)</option>
                              <option value="MS">EgyptAir Plus (MS)</option>
                              <option value="SK">EuroBonus (SK)</option>
                              <option value="AI">Flying Returns (AI)</option>
                              <option value="BR">
                                Infinity MileageLands (BR)
                              </option>
                              <option value="SQ">KrisFlyer (SQ)</option>
                              <option value="AV">LifeMiles (AV)</option>
                              <option value="UA">MileagePlus (UA)</option>
                              <option value="A3">Miles & Bonus (A3)</option>
                              <option value="LH">Miles & More (LH)</option>
                              <option value="TK">Miles & Smiles (TK)</option>
                              <option value="CA">PhoenixMiles (CA)</option>
                              <option value="TG">Royal Orchid Plus (TG)</option>
                              <option value="">TAP Miles&Go</option>
                              <option value="SA">Voyager (SA)</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="space-y-0">
                            <Label>Card Number </Label>
                          </div>
                          <Input
                            placeholder="(optional) "
                            // className="w-1/2"
                            name="CardNumber"
                            value={passportData[index]?.CardNumber || ""}
                            onChange={(e) => onPassportChange(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        {/*  */}
        {/* <div className="flex justify-end pb-3">
          <ButtonPrimary
            // href={"/checkout"}
            className="hover:text-white hover:bg-blue-600 block w-72 md:w-48"
            // onClick={onSubmit}
            type="submit"
          >
            Continue
          </ButtonPrimary>
        </div> */}
      </div>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage ${className} px-1 py-4`}
      data-nc-id="CheckOutPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          {/* <div className="">{renderMain()}</div> */}
          <form onSubmit={onSubmit}>
            {[...Array(totalPassengers)].map((_, index) => {
              return (
                <div className="mb-10">
                  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 shadow space-y-8 px-5 sm:p-6 xl:p-8 mb-2">
                    <div>
                      <h3 className="text-2xl font-semibold">
                        Passenger {index + 1} Information (
                        {bookingRef?.[index]?.Code})
                      </h3>
                      {/* {company &&
                        (company?.wallet < WALLET_MINIMUM_AMOUNT ||
                          company.wallet < parseFloat(totalPrice())) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Insufficient balance in your wallet. Please deposit
                            money to continue.
                          </p>
                        )} */}
                      {enterprise &&
                        enterprise?.method === "prePaid" &&
                        enterprise?.wallet <
                          WALLET_MINIMUM_AMOUNT + parseFloat(totalPrice()) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Insufficient balance in your wallet. Please deposit
                            money to continue.
                          </p>
                        )}
                      {user?.role === USER_ROLE_EMPLOYEE &&
                        user?.dailyBookingLimit <
                          parseFloat(totalPrice() + user?.dailyBooking) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            You have reached your daily booking limit.
                          </p>
                        )}
                      {renderMain(index, bookingRef?.[index]?.Code)}
                    </div>
                  </div>

                  <hr className="my-5" />
                </div>
              );
            })}
            <div className="flex justify-end pb-3">
              <ButtonPrimary
                className="hover:text-white hover:bg-blue-600 block w-72 md:w-48"
                type="submit"
              >
                Continue
              </ButtonPrimary>
            </div>
          </form>
        </div>
        <div className=" lg:block flex-grow mb-10 ">{renderSidebar()}</div>
      </main>
      {seatModal && (
        <SeatModal
          seatModal={seatModal}
          setSeatModal={setSeatModal}
          reservedSeats={reservedSeats}
          setReservedSeats={setReservedSeats}
          airSegment={AirSegment}
          traceId={traceId}
          index={seatIndex}
        />
      )}
    </div>
  );
};

export default CustomerDetail;
