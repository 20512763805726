import React from "react";

const statusMap = {
  pending: {
    label: "pending",
    badgeClass: "badge-warning",
    icon: "fa fa-clock",
  },
  bookingRequested: {
    label: "bookingRequested",
    badgeClass: "badge-warning",
    icon: "fa fa-clock",
  },
  cancelled: {
    label: "Cancelled",
    badgeClass: "badge-danger",
    icon: "fa fa-times",
  },
  voided: { label: "Void", badgeClass: "badge-secondary", icon: "fa fa-check" },
  "void-late": {
    label: "Void-Late",
    badgeClass: "badge-secondary",
    icon: "fa fa-check",
  },
  exchanged: {
    label: "Exchange",
    badgeClass: "bg-green-700",
    icon: "fa fa-check",
  },
  refunded: {
    label: "Refund",
    badgeClass: "badge-danger",
    icon: "fa fa-check",
  },
  default: {
    label: "Issued",
    badgeClass: "badge-success",
    icon: "fa fa-check",
  },
};

const paymentStatusMap = {
  unpaidKachaCredit: {
    label: "Issued\nunpaid",
    badgeClass: "bg-green-400/90",
    icon: "fa fa-check",
  },
};

export const getStatusBadge = (status, isPaymentStatus = false) => {
  const map = isPaymentStatus ? paymentStatusMap : statusMap;
  const statusInfo = map[status] || statusMap.default;
  return (
    <span className={`badge ${statusInfo.badgeClass} capitalize`}>
      {statusInfo.label.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < statusInfo.label.split("\n").length - 1 && <br />}
        </React.Fragment>
      ))}
      <span className={`ms-1 ${statusInfo.icon}`} />
    </span>
  );
};
