const Locals = [
  "ADD",
  "AMH",
  "ASO",
  "AWA",
  "AXU",
  "ABK",
  "BCO",
  "BJR",
  "DIR",
  "DSE",
  "DEM",
  "GDQ",
  "GOB",
  "GMB",
  "GDE",
  "JIM",
  "JIJ",
  "LLI",
  "QHR",
  "MQX",
  "SZE",
  "SHC",
  "NEK",
  "HUE",
  "NEK",
];

export default Locals;
