import axios from "axios";

import API_URL from "..";

const API_URL_DASHBOARD = API_URL + "dashboard/";

const getTopAgencies = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_DASHBOARD +
      `/?startDate=${query.startDate}&endDate=${query.endDate}&destination=${query.destination}`,
    config
  );

  return res.data;
};

const getTopAirlines = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_DASHBOARD +
      `airlines/?startDate=${query.startDate}&endDate=${query.endDate}`,
    config
  );

  return res.data;
};

const apiDashboard = {
  getTopAgencies,
  getTopAirlines,
};

export default apiDashboard;
