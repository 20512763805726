import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const CorporateList = ({ show, handleClose, setShowCorporateList }) => {
  const [selectedCorporate, setSelectedCorporate] = useState(null);

  const handleCorporateSelect = (corporate) => {
    setSelectedCorporate(corporate);
  };

  const handleCorporateSubmit = () => {
    handleClose(selectedCorporate);
  };

  const corporates = [
    { name: "ZTE (HK) Limited  Ethiopian Branch" },
    { name: "Balezaf Alcohol and Liquor Factory" },
    { name: "Lions International Trading PLC" },
    { name: "Getachew Kassaye" },
    { name: "Adama Science and Technology University" },
  ];

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Select Corporate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="pb-3">Corporate Clients of Sterling Travel & Tour</h1>
        <div>
          <select
            className="block w-full border-2 p-2 text-black text-[14px] border-black rounded-lg  bg-white"
            name="corporate"
            value={selectedCorporate || ""}
            onChange={(e) => handleCorporateSelect(e.target.value)}
          >
            <option value="">Select Corporate Name</option>
            {corporates.map((corporate, index) => (
              <option key={index} value={corporate.name}>
                {corporate.name}
              </option>
            ))}
          </select>
        </div>
        {/* <ListGroup>
          {corporates.map((corporate) => (
            <ListGroup.Item
              key={corporate.id}
              active={selectedCorporate === corporate}
              onClick={() => handleCorporateSelect(corporate)}
              action
              className="list-group-item-action border border-black"
            >
              {corporate.name}
            </ListGroup.Item>
          ))}
        </ListGroup> */}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setShowCorporateList(false)} className="btn ">
          Cancel
        </button>
        <button
          onClick={handleCorporateSubmit}
          disabled={!selectedCorporate}
          className="btn btn-primary"
        >
          Select
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CorporateList;
