import React from "react";

const Checkbox = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  defaultChecked,
  type = "checkbox",
  onChange,
}) => {
  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={name}
        name={name}
        type={type}
        style={{ accentColor: "#FBBF24" }}
        className="focus:ring-action-main h-6 w-6 text-main border-main rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-main focus:ring-main"
        defaultChecked={defaultChecked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {label && (
        <label
          htmlFor={name}
          className="ml-3.5 flex flex-col flex-1 justify-center"
        >
          <span className=" text-neutral-900 dark:text-neutral-100">
            {label}
          </span>
          {subLabel && (
            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
