import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Dropdown } from "react-bootstrap";

const RequestList = ({ request, index, currentPage, handleDelete }) => {
  const requestPerPage = 10;

  return (
    // <li
    //   key={request.id}
    //   className="bg-white rounded-lg shadow-md p-4 mb-4 border text-black flex justify-between items-center"
    // >
    //   <div>
    //     <div className="font-bold text-lg mb-2">
    //       {request.user.firstName} {request.user.lastName}
    //     </div>
    //     <div className="text-gray-700">{request.type}</div>
    //     <div className="text-gray-700">
    //       {moment(request.createdAt).format("DD-MM-YYYY")}
    //     </div>
    //   </div>
    //   <Link to="#" className="text-main py-2 px-4 rounded">
    //     Show Details
    //   </Link>
    // </li>

    <>
      <tr role="row" className="odd ">
        <td>
          <p>{index + 1 + (currentPage - 1) * requestPerPage}</p>
        </td>
        <td>
          <h5>
            {request?.user.firstName} {request.user.lastName}
          </h5>
        </td>
        <td>
          <div>
            <h5>
              {request?.booking?.passengers?.map((passenger) => (
                <p>{passenger.firstName + " " + passenger.lastName}</p>
              ))}
            </h5>
          </div>
        </td>
        <td>
          <div>
            <h5>{request?.company?.name || request?.corporate?.name}</h5>
          </div>
        </td>
        <td>
          <p>{request?.type}</p>
        </td>
        <td>
          <p>
            {request?.ticketNumbers?.map((ticket) => (
              <p> {ticket}</p>
            ))}
          </p>
        </td>
        <td>
          <p>
            {request?.status === "approved" ? (
              <span className="badge badge-success">Approved</span>
            ) : request?.status === "processing" ? (
              <span className="badge badge-secondary">Processing</span>
            ) : request?.status === "rejected" ? (
              <span className="badge badge-danger">Rejected</span>
            ) : (
              <span className="badge badge-warning">Pending</span>
            )}
          </p>
        </td>
        <td>
          <h5>{moment(request?.createdAt).format("MMM DD, YYYY")}</h5>
        </td>
        <td>
          <Link
            to={`/requests/detail/${request?._id}`}
            className="text-main py-2"
          >
            Show Details
          </Link>
        </td>
        {request?.status === "pending" && (
          <td>
            <Dropdown className="dropdown text-sans-serif">
              <Dropdown.Toggle
                variant=""
                className="btn btn-primary i-false tp-btn-light sharp"
                type="button"
                id="order-dropdown-0"
                data-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <circle fill="#000000" cx={5} cy={12} r={2} />
                      <circle fill="#000000" cx={12} cy={12} r={2} />
                      <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                  </svg>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div className="py-2">
                  <btn
                    className="dropdown-item hover:cursor-pointer"
                    onClick={() => handleDelete(request._id)}
                  >
                    <i className="fas fa-trash-alt me-2"></i>
                    Delete
                  </btn>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        )}
      </tr>
    </>
  );
};

export default RequestList;
