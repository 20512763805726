import { Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import { ProfileUpdateValidationSchema } from "./Profile.util";
import { useSelector } from "react-redux";
import { useState } from "react";
import UpdatePasswordModal from "../../components/User/ChangePassword";

const ProfilePage = () => {
  const [showModal, setShowModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { firstName, lastName } = user;

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <div className="flex justify-center items-center py-16 ">
      <div className="border border-slate-200 w-6/12 shadow-2xl">
        <Formik
          initialValues={{
            firstName,
            lastName,
          }}
          validationSchema={ProfileUpdateValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} style={{ padding: 20 }}>
                <Grid item xs={3}>
                  First name:{" "}
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="firstName"
                    size="small"
                    disabled
                    placeholder="First name"
                    label="First name"
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={3}>
                  Last name:{" "}
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lastName"
                    size="small"
                    disabled
                    placeholder="Last Name"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>

                <Grid item xs={12}>
                  <button
                    className="flex flex-row-reverse  text-main hover:text-main-800"
                    onClick={() => setShowModal(true)}
                  >
                    Change Password
                  </button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <UpdatePasswordModal status={showModal} onClose={onClose} />
    </div>
  );
};

export default ProfilePage;
