import axios from "axios";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import API_URL from "../../redux2";
import PickDate from "./Components/PickDate";
import Loading from "../../components/Loading";
import OneWay from "../../components/Flights/OneWay";
import Heading2 from "../../components/Heading/Headings2";
import RoundTrip from "../../components/Flights/RoundTrip";
import MultiCity from "../../components/Flights/MultiCity";
import ReturnTrip from "../../components/Flights/ReturnFlight";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import { getMyCompany, reset } from "../../redux2/user/userSlice";
import { getCorporate } from "../../redux2/corporate/corporateSlice";
import { getMyEnterprise } from "../../redux2/enterprise/enterpriseSlice";
import {
  FOREIGN_PASSENGER,
  TRIP_TYPE_MULTI_CITY,
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
} from "../../common/constants";
import ModifySearchModal from "../../components/Modals/ModifySearchModal";
import Locals from "../../utils/localFlights";
import { getServiceChargeInternational } from "../../utils/serviceChargePrice";
import TabFilters from "./TabFilter";

const RecentFlights = () => {
  const [data, setData] = useState();
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [airPrice, setAirPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);
  const [isReturn, setIsReturn] = useState(false);
  const [departData, setDepartData] = useState([]);
  const [countMulti, setCountMulti] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [multiCityParams, setMultiCityData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [CustomSearchModal, setCustomSearchModal] = useState(false);

  const [airlinesStates, setAirlinesStates] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [currencyStates, setCurrencyStates] = useState("USD");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const {
    origin,
    destination,
    startDate,
    endDate,
    tripType,
    classType: cabinClass,
    adults,
    labors,
    children,
    infants,
    childrenAge,
    infantsAge: infantAge,
    passengerTypeState,
    id: _id,
  } = Object.fromEntries(query.entries());

  const dispatch = useDispatch();

  const { company, isLoading } = useSelector((state) => state.user);
  const { corporate, isLoading: corpLoading } = useSelector(
    (state) => state.corporate
  );
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);

  let queryParam = {};

  if (tripType === TRIP_TYPE_MULTI_CITY) {
    const state = location?.state;

    if (state) {
      multiCityParams.length < 1 && setMultiCityData(state?.flightSearch);
    }
  } else {
    queryParam = {
      Origin: origin || "",
      Destination: destination || "",
      startDate: startDate || "",
      endDate: endDate || "",
      tripType: tripType,
      cabinClass: cabinClass,
      adults: adults,
      children: children,
      infants: infants,
      labors: labors,
    };
  }

  useEffect(() => {
    if (isLoading) return;

    user?.role === USER_ROLE_ENTERPRISE
      ? dispatch(getMyEnterprise())
      : dispatch(getMyCompany());

    dispatch(reset());
  }, [dispatch, isLoading, user]);

  useEffect(() => {
    if (company?.corporate || enterprise?.corporate) {
      dispatch(getCorporate(company?.corporate || enterprise?.corporate));
    } else {
      let id =
        user.role === USER_ROLE_EMPLOYEE ? user?.corporate : company?.corporate;
      dispatch(getCorporate(id));
    }
  }, [dispatch, company, user, enterprise]);

  let isLocal = Locals.includes(origin) && Locals.includes(destination);

  let searchData = {
    NotHoldCountryId: passengerTypeState === FOREIGN_PASSENGER && isLocal,
    ...(cabinClass !== "Any" && { CabinClass: cabinClass }),
    segments: [],
    passengers: [],
    ...(passengerTypeState === "Non-Document-Holder" && {
      CurrencyType: "USD",
    }),
  };

  if (tripType === TRIP_TYPE_MULTI_CITY) {
    multiCityParams.length > 0 &&
      multiCityParams?.forEach((item) => {
        searchData.segments.push({
          Origin: item.origin,
          Destination: item.destination,
          PreferredTime: item.departureDate,
        });
      });
  } else {
    searchData.segments.push({
      Origin: origin,
      Destination: destination,
      PreferredTime: startDate,
    });

    if (tripType !== TRIP_TYPE_ONE_WAY) {
      searchData.segments.push({
        Origin: destination,
        Destination: origin,
        PreferredTime: endDate,
      });
    }
  }

  if (+labors > 0) {
    searchData.passengers.push({
      Code: "LBR",
      Quantity: labors || "0",
    });
  } else
    searchData.passengers.push({
      Code: "ADT",
      Quantity: adults || "1",
    });

  let childrenAgeArray = children?.split(",");
  let infantAgeArray = infants?.split(",");

  childrenAgeArray
    ? childrenAgeArray?.forEach((age) => {
        if (children > 0) {
          searchData.passengers.push({
            Code: "CNN",
            Quantity: children || "1",
            Age: "10",
          });
        }
      })
    : searchData.passengers.push({
        Code: "CNN",
        Quantity: children || "0",
        Age: "10",
      });

  infantAgeArray
    ? infantAgeArray?.forEach((age) => {
        if (infants > 0) {
          searchData.passengers.push({
            Code: "INF",
            Quantity: infants || "1",
            Age: "1",
          });
        }
      })
    : searchData.passengers.push({
        Code: "INF",
        Quantity: infants || "0",
      });

  useEffect(() => {
    searchFlight(searchData);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    destination,
    origin,
    startDate,
    endDate,
    tripType,
    cabinClass,
    adults,
    children,
    infants,
    childrenAge,
    infantAge,
    passengerTypeState,
  ]);

  const searchFlight = async (flightData) => {
    setLoading(true);
    try {
      const response = await axios.post(API_URL + "flight/search", flightData);
      const { data } = response;
      setData(data);
      setAirPrice(data?.LowFareSearchRsp?.AirPricingSolution);
      setFlights(data?.LowFareSearchRsp?.AirSegmentList?.AirSegment);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && airPrice) {
      if (!(airPrice instanceof Array)) {
        let airPriceArray = [airPrice];
        setAirPrice(airPriceArray);
      }
      if (!(flights instanceof Array)) {
        let airFlight = [flights];
        setFlights(airFlight);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (
      isLocal ||
      passengerTypeState !== "Non-Document-Holder" ||
      tripType === TRIP_TYPE_MULTI_CITY
    ) {
      setCurrencyStates("ETB");
    }
  }, [isLocal, passengerTypeState, tripType]);

  let isLocalMulti = multiCityParams?.every(
    (item) => Locals.includes(item.origin) && Locals.includes(item.destination)
  );

  if (!airPrice && !loading) {
    return (
      <>
        <div className="container mx-auto mb-8">
          <PickDate
            queryParam={queryParam}
            searchFlight={searchFlight}
            searchData={searchData}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCarrier={setCarrier}
          />
        </div>
        <div className="">
          <div className="flex justify-start items-start mx-5 mt-5">
            {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && !isLocal && (
              <TabFilters
                searchData={searchData}
                searchFlight={searchFlight}
                carrier={carrier}
                airlinesStates={airlinesStates}
                setCarrier={setCarrier}
                setAirlinesStates={setAirlinesStates}
                currencyStates={currencyStates}
                setCurrencyStates={setCurrencyStates}
              />
            )}
          </div>
          <div className="flex flex-col justify-center items-center ">
            <div className="text-2xl font-bold text-main">No Flights Found</div>
            {/* go back button */}
            <div className="mt-10">
              <ButtonPrimary
                href={`/?origin=${queryParam.Origin}&destination=${queryParam.Destination}&tripType=${queryParam.tripType}&cabinClass=${queryParam.cabinClass}&adults=${queryParam.adults}&labors=${queryParam.labors}&children=${queryParam.children}&infants=${queryParam.infants}&startDate=${queryParam.startDate}&endDate=${queryParam.endDate}`}
              >
                Explore more flights
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </>
    );
  }

  // filter airPrice if price, bookingClass  are same
  let newAirPrice = [];
  if (airPrice?.length > 0 && tripType === TRIP_TYPE_ROUND_TRIP) {
    const uniqueAirPrice = [];
    airPrice.forEach((item) => {
      const key =
        item?.Journey[0]?.AirSegmentRef?._attributes?.Key ||
        item?.Journey[0]?.AirSegmentRef?.[0]?._attributes?.Key;
      const key2 = item?.Journey[0]?.AirSegmentRef?.[1]?._attributes?.Key;
      if (
        key &&
        !uniqueAirPrice.some(
          (uniqueItem) =>
            (uniqueItem?.Journey[0]?.AirSegmentRef?._attributes?.Key === key ||
              (uniqueItem?.Journey[0]?.AirSegmentRef?.[0]?._attributes?.Key ===
                key &&
                uniqueItem?.Journey[0]?.AirSegmentRef?.[1]?._attributes?.Key ===
                  key2)) &&
            uniqueItem._attributes.TotalPrice === item._attributes.TotalPrice
        )
      ) {
        uniqueAirPrice.push(item);
      }
    });
    newAirPrice = uniqueAirPrice;
  }

  let temp = [];
  let multiTemp = [];

  if (airPrice && tripType === TRIP_TYPE_ROUND_TRIP && isReturn) {
    airPrice.forEach((item) => {
      if (totalPrice === item._attributes.TotalPrice) {
        temp.push(item);
      }
    });
  }
  if (airPrice && tripType === TRIP_TYPE_MULTI_CITY && countMulti > 0) {
    airPrice.forEach((item) => {
      if (selectedId === item._attributes.Key) {
        multiTemp.push(item);
      }
    });
  }

  if (isLoading || corpLoading || corporate?.exchangeRate === undefined) {
    return <Loading />;
  }

  //TODO: add service charge for enterprise / corporate
  function getServiceCharge(airPricing) {
    let basePrice;
    // Initialize passengersPrice object
    let passengersPrice = {
      ADTPrice: 0,
      CNNPrice: 0,
    };

    // Check if airPricing is an array
    if (airPricing instanceof Array) {
      // Map through airPricing array
      passengersPrice = airPricing.map((item) => {
        // Extract passenger types
        const passengerTypes =
          item.PassengerType instanceof Array
            ? item.PassengerType
            : [item.PassengerType];

        // Loop through passenger types
        passengerTypes.forEach((passenger) => {
          if (passenger._attributes.Code === "ADT") {
            passengersPrice.ADTPrice = item._attributes.TotalPrice;
          }
          if (passenger._attributes.Code === "CNN") {
            passengersPrice.CNNPrice = item._attributes.TotalPrice;
          }
        });

        return passengersPrice;
      });
    } else {
      // If airPricing is not an array
      passengersPrice = {
        ADTPrice: airPricing._attributes.TotalPrice,
        CNNPrice: 0,
      };
    }

    // Extract the first element from passengersPrice array, or use the original passengersPrice
    let merged = passengersPrice[0] || passengersPrice;

    // Calculate ADT and CNN totals
    let ADTTotal = +merged.ADTPrice.toString().slice(3);
    let CNNTotal = +merged.CNNPrice?.toString().slice(3) || 0;

    if (passengerTypeState === "Non-Document-Holder") {
      ADTTotal = (ADTTotal * corporate?.exchangeRate).toFixed(2);
      CNNTotal =
        CNNTotal > 0 ? (CNNTotal * corporate?.exchangeRate).toFixed(2) : 0;
    }

    // Calculate basePrice
    if (+labors > 0 && company?.isLabor) {
      basePrice = company?.serviceChargeLabor * +labors;
    } else if (tripType === TRIP_TYPE_ONE_WAY && company?.isOneWay) {
      basePrice = company?.serviceChargeOneWay * (+adults + +children);
    } else {
      basePrice =
        getServiceChargeInternational(
          +ADTTotal,
          company?.serviceChargeInternational
        ) *
          (+adults || +labors) +
        (+CNNTotal > 0
          ? getServiceChargeInternational(
              +CNNTotal,
              company?.serviceChargeInternational
            ) * +children
          : 0);
    }

    if (!company && !enterprise) return 0;
    if (tripType === TRIP_TYPE_MULTI_CITY ? isLocalMulti : isLocal)
      return (
        company?.serviceChargeLocal * (+adults + +children + +labors) ||
        enterprise?.serviceChargeLocal +
          enterprise?.company?.serviceChargeLocal *
            (+adults + +children + +labors)
      );
    else {
      // basePrice = basePrice?.toString().slice(3);

      if (cabinClass === "Economy" || cabinClass === "Any") {
        return currencyStates === "USD"
          ? basePrice ||
              enterprise?.serviceChargeInternational +
                enterprise?.company?.serviceChargeInternational *
                  (+adults + +children + +labors)
          : basePrice * corporate?.exchangeRate ||
              enterprise?.serviceChargeInternational +
                enterprise?.company?.serviceChargeInternational *
                  (+adults + +children + +labors);
      } else {
        return currencyStates === "USD"
          ? company?.serviceChargeInternationalBusiness *
              (+adults + +children + +labors) ||
              enterprise?.serviceChargeInternationalBusiness +
                enterprise?.company?.serviceChargeInternationalBusiness *
                  (+adults + +children + +labors)
          : company?.serviceChargeInternationalBusiness *
              corporate?.exchangeRate *
              (+adults + +children + +labors) ||
              enterprise?.serviceChargeInternationalBusiness +
                enterprise?.company?.serviceChargeInternationalBusiness *
                  corporate?.exchangeRate *
                  (+adults + +children + +labors);
      }
    }
  }

  let traceId = data?.LowFareSearchRsp?._attributes?.TraceId;

  let queryURl = `/?origin=${queryParam.Origin}&destination=${
    queryParam.Destination
  }&tripType=${multiCityParams.tripType || tripType}&cabinClass=${
    queryParam.cabinClass
  }&adults=${multiCityParams.adults || adults}&labors=${
    multiCityParams.labors || labors
  }&children=${queryParam.children || children}&infants=${
    multiCityParams.infants || infants
  }&startDate=${queryParam.startDate || multiCityParams.startDate}&endDate=${
    queryParam.endDate
  }&traceId=${traceId}&isLocal=${isLocal}&passengerTypeState=${passengerTypeState}&_id=${_id}`;

  let flightDetail = data?.LowFareSearchRsp?.FlightDetailsList?.FlightDetails;
  let bookingRef = data?.SearchPassenger?._attributes || data?.SearchPassenger;

  const renderOneWayFlights = () => {
    return (
      airPrice?.length > 0 &&
      airPrice?.map((item, index) => (
        <OneWay
          key={item?._attributes?.Key}
          flight={item}
          flights={flights}
          flightDetail={flightDetail}
          query={queryURl}
          bookingRef={bookingRef}
          // charge={getServiceCharge() || 0}
          charge={getServiceCharge(item?.AirPricingInfo)}
          passengerTypeState={passengerTypeState}
          currencyStates={currencyStates}
        />
      ))
    );
  };

  const renderMultiCityFlights = () => {
    return multiTemp.length === 0
      ? airPrice.length > 1 &&
          airPrice?.map((item, index) => (
            <MultiCity
              key={item?._attributes?.Key}
              flight={item}
              flights={flights}
              flightDetail={flightDetail}
              setTotalPrice={setTotalPrice}
              setSelectedId={setSelectedId}
              countMulti={countMulti}
              setCountMulti={setCountMulti}
              setIsReturn={setIsReturn}
              setDepartData={setDepartData}
              charge={getServiceCharge(item?.AirPricingInfo) || 0}
              currencyStates={currencyStates}
            />
          ))
      : multiTemp?.map((item, index) => (
          <MultiCity
            key={item?._attributes?.Key}
            flight={item}
            flights={flights}
            countMulti={countMulti}
            setCountMulti={setCountMulti}
            flightDetail={flightDetail}
            setTotalPrice={setTotalPrice}
            setIsReturn={setIsReturn}
            setDepartData={setDepartData}
            charge={getServiceCharge(item?.AirPricingInfo) || 0}
            query={queryURl}
            bookingRef={bookingRef}
            classType={departData.classType}
            currencyStates={currencyStates}
          />
        ));
  };

  const renderRoundTripFlights = () => {
    return (
      airPrice?.length > 0 &&
      airPrice?.map((item, index) => (
        <RoundTrip
          key={item?._attributes?.Key}
          flight={item}
          flights={flights}
          flightDetail={flightDetail}
          setTotalPrice={setTotalPrice}
          isReturn={isReturn}
          setIsReturn={setIsReturn}
          setDepartData={setDepartData}
          charge={getServiceCharge(item?.AirPricingInfo)}
          passengerTypeState={passengerTypeState}
          currencyStates={currencyStates}
        />
      ))
    );
  };

  const renderReturnTripFlights = () => {
    return temp?.map((item, index) => (
      <ReturnTrip
        key={item?._attributes?.Key}
        flight={item}
        flights={flights}
        flightDetail={flightDetail}
        setTotalPrice={setTotalPrice}
        query={queryURl}
        departData={departData.segment}
        charge={getServiceCharge(item?.AirPricingInfo)}
        classType={departData.classType}
        bookingRef={bookingRef}
        currencyStates={currencyStates}
      />
    ));
  };

  return (
    <div
      className={`nc-SectionGridFilterCard mt-8 px-4 lg:px-0 lg:mt-16 pb-24 lg:pb-20 lg:mx-16`}
      data-nc-id="SectionGridFilterCard"
    >
      {tripType === TRIP_TYPE_MULTI_CITY ? (
        <Heading2
          heading={`Flights from ${multiCityParams[countMulti]?.origin} to ${multiCityParams[countMulti]?.destination}`}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {countMulti > 0 ? multiTemp?.length : airPrice?.length} Flights
              <span className="mx-2">·</span>
              Multi City
              {/* <span className="mx-2">·</span>2 Guests */}
              <span className="mx-2">·</span>{" "}
              {moment(multiCityParams[countMulti]?.departureDate).format("ll")}
              <span className="mx-2">·</span> {adults} Adults {children}{" "}
              Children {infants} Infants
              <span className="mx-2">·</span> {cabinClass}
              <span className="mx-2">·</span>{" "}
              {isLocal ? "Local" : "International"}
            </span>
          }
        />
      ) : (
        <Heading2
          heading={
            <div>
              Flights from{" "}
              <span className="text-main">
                {isReturn
                  ? `${destination} to ${origin}`
                  : `${origin} to ${destination}`}
              </span>
            </div>
          }
          subHeading={
            <div className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {tripType === TRIP_TYPE_ROUND_TRIP && isReturn
                ? temp?.length
                : airPrice?.length}{" "}
              Flights
              <span className="mx-2">·</span>
              {tripType === TRIP_TYPE_ONE_WAY ? "One Way" : "Round Trip"}
              {/* <span className="mx-2">·</span>2 Guests */}
              <span className="mx-2">·</span>{" "}
              {moment(new Date(selectedDate), "ddd, MMM D").format("ll") ??
                moment(new Date(startDate)).format("ll")}
              {tripType === TRIP_TYPE_ROUND_TRIP &&
                " - " + moment(new Date(endDate)).format("ll")}
              <span className="mx-2">·</span>
              {+labors < 1
                ? `${adults} Adults ${children}
              Children ${infants} Infants`
                : `${labors} Labor`}
              <span className="mx-2">·</span> {cabinClass}
              <span className="mx-2">·</span>{" "}
              {isLocal ? "Local" : "International"}
              {/* {+labors > 0 && 1 + "labor"} */}
            </div>
          }
        />
      )}

      {tripType !== TRIP_TYPE_MULTI_CITY && !isReturn && (
        <PickDate
          queryParam={queryParam}
          searchData={searchData}
          searchFlight={searchFlight}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setCarrier={setCarrier}
        />
      )}

      <div className="flex justify-between mx-2 ">
        <h1 className="text-xl">
          {isReturn ? "Select Return Flight" : "Select Departure Flight"}
        </h1>
        {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && (
          <div className="hidden xl:block">
            <button
              onClick={() => setCustomSearchModal(!CustomSearchModal)}
              className="px-4 py-2 bg-neutral-300 text-neutral-700 rounded-md hover:bg-neutral-200 focus:outline-none focus:bg-neutral-200 "
            >
              Modify Search
            </button>
            <ModifySearchModal
              show={CustomSearchModal}
              onHide={() => setCustomSearchModal(false)}
              query={queryParam}
              setAirPrice={setAirPrice}
              setSelectedDate={setSelectedDate}
              setCustomSearchModal={setCustomSearchModal}
              isLocal={isLocal}
            />
          </div>
        )}
        {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && (
          <div className="sm:hidden">
            <Link
              to={`/?origin=${queryParam.Origin}&destination=${queryParam.Destination}&tripType=${queryParam.tripType}&cabinClass=${queryParam.cabinClass}&adults=${queryParam.adults}&labors=${queryParam.labors}&children=${queryParam.children}&infants=${queryParam.infants}&startDate=${queryParam.startDate}&endDate=${queryParam.endDate}`}
            >
              <button className="px-4 py-2 bg-neutral-300 text-neutral-700 rounded-md hover:bg-neutral-200 focus:outline-none focus:bg-neutral-200 ">
                Modify Search
              </button>
            </Link>
          </div>
        )}
        {(isReturn || countMulti > 0) && (
          <button
            onClick={() => {
              setIsReturn(false);
              setCountMulti((prev) => prev - 1);
            }}
            className="btn btn-primary"
          >
            Go Back
          </button>
        )}
      </div>
      <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
        <div className="">
          {!isReturn && tripType !== TRIP_TYPE_MULTI_CITY && !isLocal && (
            <TabFilters
              searchData={searchData}
              searchFlight={searchFlight}
              carrier={carrier}
              airlinesStates={airlinesStates}
              setCarrier={setCarrier}
              setAirlinesStates={setAirlinesStates}
              currencyStates={currencyStates}
              setCurrencyStates={setCurrencyStates}
            />
          )}
        </div>
        {loading ? (
          <Loading search={true} />
        ) : (
          <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
            <>
              {tripType === TRIP_TYPE_ONE_WAY && renderOneWayFlights()}
              {tripType === TRIP_TYPE_MULTI_CITY && renderMultiCityFlights()}
              {tripType === TRIP_TYPE_ROUND_TRIP &&
                isReturn &&
                renderReturnTripFlights()}
              {tripType === TRIP_TYPE_ROUND_TRIP &&
                !isReturn &&
                renderRoundTripFlights()}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentFlights;
