import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import Employee from "./Employees/employees";
// import Group from "./Groups/groups";
// import AddEmployeeForm from "./Employees/components/add-employee";
// import AddGroupForm from "./Groups/components/add-group";
import { Search } from "@mui/icons-material";
import Enterprise from "../../components/Enterprise/Enterprise";

const Members = () => {
  const [current, setCurrent] = useState("Employees");
  const NavData = [
    { name: "Employees" },
    { name: "Enterprise" },
    // { name: "Roles" },
  ];

  const renderButton = () => {
    switch (current) {
      case "Employees":
        return <Employee />;
      case "Enterprise":
        return <Enterprise />;
      default:
        return <Employee />;
    }
  };

  return (
    // <Tab.Container defaultActiveKey={current}>
    //   <div className="row ">
    //     <div className="col-xl-12">
    //       <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
    //         <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4 shadow-sm rounded">
    //           <Nav as="ul" className="nav nav-tabs">
    //             {NavData.map(({ name }, index) => (
    //               <Nav.Item
    //                 key={index}
    //                 as="li"
    //                 className="nav-item"
    //                 onClick={() => setCurrent(name)}
    //               >
    //                 <Nav.Link
    //                   className="nav-link text-4xl font-bold px-4 py-2"
    //                   eventKey={name}
    //                 >
    //                   {name}
    //                 </Nav.Link>
    //               </Nav.Item>
    //             ))}
    //           </Nav>
    //         </div>
    //         {current === "Roles" ? (
    //           <></>
    //         ) : (
    //           <>
    //             <div className="table-search">
    //               <div className="input-group search-area mb-xxl-0 mb-4">
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="Search here"
    //                 />
    //                 <span className="input-group-text">
    //                   <Link to={"#"}>
    //                     <Search size={35} className="text-main" />
    //                     {/* <i className="flaticon-381-search-2"></i> */}
    //                   </Link>
    //                 </span>
    //               </div>
    //             </div>
    //             {/* {renderButton()} */}
    //             <div className="btn bg-main hover:bg-main-800 text-white mb-xxl-0 mb-4 ">
    //               <i className="far fa-file-word me-2"></i>Generate Report
    //             </div>
    //           </>
    //         )}
    //       </div>
    //       <Tab.Content>
    //         <Tab.Pane eventKey="Employees">
    //           <Employee />
    //         </Tab.Pane>
    //         {/* <Tab.Pane eventKey="Groups">
    //           <Group />
    //         </Tab.Pane>
    //         <Tab.Pane eventKey="Roles">
    //           <h1>Roles</h1>
    //         </Tab.Pane> */}
    //       </Tab.Content>
    //     </div>
    //   </div>
    // </Tab.Container>
    <div className="row lg:mx-32 md:mx-16">
      <div className="col-xl-12 mx-4 lg:mx-0">
        <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap ">
          <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4 shadow-sm rounded">
            {/* <h1 className="text-4xl font-bold px-4 py-2">Agents</h1> */}
            <Nav as="ul" className="nav nav-tabs">
              {NavData.map(({ name }) => (
                <Nav.Item
                  as="li"
                  className="nav-item"
                  onClick={() => setCurrent(name)}
                >
                  <Nav.Link
                    className="nav-link text-4xl font-bold px-4 py-2"
                    eventKey={name}
                    active={current === name}
                  >
                    {name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <div className="table-search">
            <div className="input-group search-area mb-xxl-0 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <Search size={35} className="text-main" />
                  {/* <i className="flaticon-381-search-2"></i> */}
                </Link>
              </span>
            </div>
          </div>
          <div className="btn bg-main hover:bg-main-800 text-white mb-xxl-0  ">
            <i className="far fa-file-word me-2"></i>Generate Report
          </div>
        </div>
        {/* <Employee /> */}
        {renderButton()}
        {/* <Agent /> */}
      </div>
    </div>
  );
};
export default Members;
