import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { UpdatePasswordSchema } from "./user.util";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../redux2/user/authSlice";

const UpdatePasswordModal = ({ status, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = (values) => {
    dispatch(updatePassword(values));
    onClose();
  };

  return (
    <>
      <Modal
        show={status}
        onHide={() => onClose()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className=""
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={UpdatePasswordSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="text-black">
              <Modal.Body>
                <div className="form-group ">
                  <label htmlFor="oldPassword">Old Password</label>
                  <Field
                    type="password"
                    className="form-control"
                    id="oldPassword"
                    name="oldPassword"
                    placeholder="Enter Old Password"
                  />
                  <ErrorMessage
                    name="oldPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <Field
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="newPassword"
                    placeholder="Enter New Password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <Field
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn text-white bg-main hover:bg-main-900"
                  disabled={!formik.isValid}
                >
                  Update Password
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdatePasswordModal;
