import * as Yup from "yup";

export const forgetPasswordSchema = Yup.object().shape({
  phoneOrEmailValue: Yup.string()
    .required("Required")
    .when("phoneOrEmail", {
      is: "phone",
      then: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid phone number")
        .length(10, "Invalid phone number"),
      otherwise: Yup.string()
        .email("Invalid email address")
        .min(5, "Invalid email address"),
    }),
});

export const UpdatePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export const ResetPasswordSchema = Yup.object({
  resetCode: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
