import axios from "axios";

import API_URL from "../redux2";

const retrieveTicket = async (pnr, setLoading) => {
  if (!pnr) return;

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "flight/retrieve-pnr", {
      PNR: pnr,
    });

    return res.data;
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export { retrieveTicket };
