import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import billingApi from "./apiBilling";

const initialState = {
  billings: [],
  billing: null,
  credit: null,
  isError: false,
  isLoading: false,
  errorMessage: "",
};

// create billing
export const createBilling = createAsyncThunk(
  "billing/createBilling",
  async (billing, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.createBilling(token, billing);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get billing
export const getBilling = createAsyncThunk(
  "billing/getBilling",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.getBilling(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const createBookingDeposit = createAsyncThunk(
  "billing/createBookingDeposit",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.createBookingDeposit(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const USDSettlement = createAsyncThunk(
  "billing/USD-credit-settlement",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.USDSettlement(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get all billings
export const getBillings = createAsyncThunk(
  "billing/getBillings",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.getBillings(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getTotalPayableCredit = createAsyncThunk(
  "billing/getTotalPayableCredit",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.getTotalPayableCredit(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// edit Billing Transaction
export const editBillingTransaction = createAsyncThunk(
  "billing/editBillingTransaction",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.editBillingTransaction(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getAgentsCredit = createAsyncThunk(
  "billing/getAgentsCredit",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await billingApi.getAgentsCredit(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetBilling: (state, action) => {
      state.billing = null;
      state.isError = false;
      state.isLoading = false;
      state.errorMessage = "";
      state.billings = [];
      state.credit = null;
    },
  },
  extraReducers: {
    // create billing
    [createBilling.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createBilling.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      // state.billing = action.payload;
    },
    [createBilling.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get billing
    [getBilling.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getBilling.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.billing = action.payload.data;
    },
    [getBilling.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get all billings
    [getBillings.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getBillings.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.billings = action.payload.data;
    },
    [getBillings.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [createBookingDeposit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createBookingDeposit.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.billing = action.payload.data;
      state.billings = state.billings?.map((billing) => {
        if (billing._id === action.payload.data._id) {
          return action.payload.data;
        }
        return billing;
      });
    },
    [createBookingDeposit.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [USDSettlement.pending]: (state, action) => {
      state.isLoading = true;
    },
    [USDSettlement.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.billing = action.payload.data;
      state.billings = state.billings?.map((billing) => {
        if (billing._id === action.payload.data._id) {
          return action.payload.data;
        }
        return billing;
      });
    },
    [USDSettlement.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [editBillingTransaction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editBillingTransaction.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.billing = action.payload.data;
      state.billings = state.billings?.map((billing) => {
        if (billing._id === action.payload.data._id) {
          return action.payload.data;
        }
        return billing;
      });
    },
    [editBillingTransaction.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getTotalPayableCredit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTotalPayableCredit.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.credit = action.payload.data;
    },
    [getTotalPayableCredit.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getAgentsCredit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAgentsCredit.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.credit = action.payload.data;
    },
    [getAgentsCredit.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { resetBilling } = billingSlice.actions;
export default billingSlice.reducer;
