import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { PhoneCheckerSchema, UserSchema } from "../user.util";
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";

const AddUser = ({
  openAddEmployee,
  setOpenAddEmployee,
  setOpenForm,
  handleCheckUser,
  handleUser,
  isLoading,
  openForm,
  checker,
  checkedPhone,
  reset,
  userType,
}) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        className="modal fade"
        show={openAddEmployee}
        onHide={setOpenAddEmployee}
        size="lg"
        onExit={() => {
          setOpenForm(false);
          dispatch(reset());
        }}
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add User</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenAddEmployee(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close text-gray"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    phone: "",
                  }}
                  validationSchema={PhoneCheckerSchema}
                  onSubmit={handleCheckUser}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            size="small"
                            fullWidth
                            id="phone"
                            variant="outlined"
                            name="phone"
                            placeholder="Phone number"
                            label="Phone number"
                            value={values.phone}
                            onChange={handleChange}
                            error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +251
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="col-md-2">
                          <LoadingButton
                            type="submit"
                            variant="text"
                            loading={isLoading}
                            className="text-success"
                          >
                            Check
                          </LoadingButton>
                        </div>
                        <div className="col-md-12 py-4">
                          <Divider />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

                {isLoading ? (
                  <div className="text-center">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                ) : openForm ? (
                  <>
                    <h3 className="text-center text-blue-500 mb-4">
                      {checker?.phone
                        ? "User found. Confirm user data"
                        : "User not found. Add user data"}
                    </h3>
                    <Formik
                      initialValues={{
                        firstName: checker?.firstName ?? "",
                        lastName: checker?.lastName ?? "",
                        email: checker?.email ?? "",
                        phone:
                          checker?.phone?.replace("+251", "") ?? checkedPhone,
                        gender: checker?.gender ?? "",
                        role: checker?.role ?? userType ?? "",
                      }}
                      validationSchema={UserSchema}
                      onSubmit={handleUser}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          className="flex items-end"
                        >
                          <div className="flex flex-col">
                            <div className="row">
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="firstName"
                                  size="small"
                                  variant="outlined"
                                  name="firstName"
                                  placeholder="First Name"
                                  label="First Name"
                                  value={values.firstName}
                                  disabled={checker?.firstName && true}
                                  onChange={handleChange}
                                  error={
                                    touched.firstName &&
                                    Boolean(errors.firstName)
                                  }
                                  helperText={
                                    touched.firstName && errors.firstName
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="lastName"
                                  disabled={checker?.lastName && true}
                                  size="small"
                                  variant="outlined"
                                  name="lastName"
                                  placeholder="Middle Name"
                                  label="Middle Name"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  error={
                                    touched.lastName && Boolean(errors.lastName)
                                  }
                                  helperText={
                                    touched.lastName && errors.lastName
                                  }
                                />
                              </div>
                              <div className="col-lg-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="phone"
                                  size="small"
                                  variant="outlined"
                                  name="phone"
                                  disabled={
                                    (checker?.phone || checkedPhone) && true
                                  }
                                  placeholder="Phone number"
                                  label="Phone number"
                                  value={values.phone}
                                  onChange={handleChange}
                                  error={touched.phone && Boolean(errors.phone)}
                                  helperText={touched.phone && errors.phone}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        +251
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  id="email"
                                  size="small"
                                  variant="outlined"
                                  name="email"
                                  placeholder="Email"
                                  label="Email"
                                  disabled={checker?.email && true}
                                  value={values.email}
                                  onChange={handleChange}
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                />
                              </div>
                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  size="small"
                                  labelid="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="gender"
                                  disabled={checker?.gender && true}
                                  value={values.gender}
                                  label="Gender"
                                  onChange={handleChange}
                                  select
                                  error={
                                    touched.gender && Boolean(errors.gender)
                                  }
                                  helperText={touched.gender && errors.gender}
                                >
                                  <MenuItem value="Male">Male</MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                </TextField>
                              </div>

                              <div className="col-md-6 mb-8">
                                <TextField
                                  fullWidth
                                  labelid="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="role"
                                  size="small"
                                  value={values.role}
                                  disabled={(checker?.role || userType) && true}
                                  label="Role"
                                  onChange={handleChange}
                                  select
                                  error={touched.role && Boolean(errors.role)}
                                  helperText={touched.role && errors.role}
                                >
                                  <MenuItem value={"agent"}>Agent</MenuItem>
                                  <MenuItem value="employee">Employee</MenuItem>
                                  <MenuItem value="finance">Finance</MenuItem>
                                  <MenuItem value="enterprise">
                                    Enterprise
                                  </MenuItem>
                                </TextField>
                              </div>
                            </div>
                            <LoadingButton
                              className="mt-8"
                              variant="contained"
                              type="submit"
                              // onClick={() => {
                              //   handleUser(values);
                              //   setOpenAddEmployee(false);
                              //   setOpenCompany(true);
                              // }}
                              style={{
                                backgroundColor: "#FBA82C",
                                color: "#fff",
                              }}
                              loading={isLoading}
                            >
                              {values.role === "employee" ||
                              values.role === "finance"
                                ? "Add Employee"
                                : "Next "}
                            </LoadingButton>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                onClick={() => {
                  setOpenAddEmployee(false);
                  dispatch(reset());
                  // setOpenForm(false);
                }}
                variant="danger"
              >
                {" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddUser;
