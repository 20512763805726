import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Modal } from "react-bootstrap";
import { updateCompany } from "../../../redux2/user/userSlice";

const AddPCC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { company, isLoading } = useSelector((state) => state.user);
  const [pcc, setPcc] = useState(company?.pcc);

  const dispatch = useDispatch();

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: company._id,
      pcc,
    };

    dispatch(updateCompany(data));
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={handleClick}>
        {company?.pcc ? company?.pcc : " + Add PCC"}
      </div>
      <Modal
        show={openModal}
        className="modal fade"
        onHide={() => {
          setOpenModal(false);
        }}
        size="md"
        centered
      >
        <div className="">
          <div className="modal-header  text-white rounded-t-2xl">
            <h1 className="text-black font-bold  text-xl">Add PCC</h1>
            <button
              type="button"
              className=" font-bold text-3xl text-white rounded-full shadow-
              xl hover:outline-none border-none hover:ring-2 px-2"
              onClick={() => setOpenModal(false)}
            >
              &times;
            </button>
          </div>
          <div className="modal-body text-black">
            <form className="w-full" onSubmit={handleSubmit}>
              <h6 className=" pb-2 font-semibold">PCC</h6>
              <div className="flex justify-between ">
                <input
                  id="outlined-basic"
                  label="pcc"
                  variant="outlined"
                  name="pcc"
                  className="w-full border-1 border-black rounded-md px-4 py-3 mb-4"
                  placeholder="Enter PCC"
                  size="medium"
                  type="text"
                  value={pcc}
                  onChange={(e) => setPcc(e.target.value)}
                />
              </div>

              <LoadingButton
                loading={isLoading}
                className="bg-primary text-white rounded-md px-4 py-3 w-full mt-2"
                type="submit"
              >
                {company?.pcc ? "Update PCC" : "Add PCC"}
              </LoadingButton>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddPCC;
