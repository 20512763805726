import { confirmAlert } from "react-confirm-alert";

const confirmModal = (title, message, onConfirm, cancel, confirm) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="text-center w-[500px] p-10 bg-white shadow-lg text-black">
          <h1 className="text-2xl font-bold text-red-500 p-2">{title}</h1>
          <p className="text-lg">{message}</p>
          <button
            onClick={onClose}
            className="w-40 border-1 cursor-pointer text-black text-lg m-2.5 p-2.5 border-black"
          >
            {cancel}
          </button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="w-48 border-1 cursor-pointer text-red-500 text-lg m-2.5 p-2.5 border-red-500 font-bold"
          >
            {confirm}
          </button>
        </div>
      );
    },
  });
};

export default confirmModal;
