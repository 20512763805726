import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ResetPasswordSchema } from "./user.util";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux2/user/authSlice";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    resetCode: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = (values) => {
    dispatch(resetPassword(values));
    navigate("/login");
  };

  return (
    <>
      <div className="max-w-2xl mx-auto py-4 my-10 mb-20">
        <div className="text-center p-3 text-xl font-bold">
          <h1>Reset Password</h1>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={ResetPasswordSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="text-black border border-slate-100 shadow-xl rounded p-4">
              <div className="p-3 ">
                <div className="form-group py-2">
                  <label htmlFor="resetCode">Enter Recovery Code</label>
                  <Field
                    type="password"
                    className="form-control border-2 border-[#FFE7C0]"
                    id="resetCode"
                    name="resetCode"
                    placeholder="Enter The Recovery Code"
                  />
                  <ErrorMessage
                    name="resetCode"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group py-2">
                  <label htmlFor="newPassword">New Password</label>
                  <Field
                    type="password"
                    className="form-control border-2 border-[#FFE7C0]"
                    id="newPassword"
                    name="newPassword"
                    placeholder="Enter New Password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group py-2">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <Field
                    type="password"
                    className="form-control border-2 border-[#FFE7C0]"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <button
                  type="submit"
                  className="btn text-white bg-main hover:bg-main-900 w-full py-3 my-8"
                  disabled={!formik.isValid}
                >
                  Reset Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ResetPassword;
