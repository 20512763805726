import axios from "axios";

import API_URL from "..";

const API_URL_REQUEST = API_URL + "request/";

const getRequest = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_REQUEST + `/${id}`, config);

  return res.data;
};

const getRequests = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_REQUEST + `?page=${query.page}&search=${query.search}`,
    config
  );

  return res.data;
};

const createRequest = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL_REQUEST + "/", data, config);

  return res.data;
};

const updateRequestStatus = async (token, { id, data }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL_REQUEST + `status/${id}`, data, config);

  return res.data;
};

const getUnseenRequests = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_REQUEST + "/count-unseen", config);

  return res.data;
};

const updateRequestSeen = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL_REQUEST + "seen", {}, config);

  return res.data;
};

const deleteRequest = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL_REQUEST + `/${id}`, config);

  console.log(res.data);

  return res.data;
};

const apiRequest = {
  getRequest,
  getRequests,
  createRequest,
  updateRequestStatus,
  getUnseenRequests,
  updateRequestSeen,
  deleteRequest,
};

export default apiRequest;
