import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import LocationInput from "../../Pages/Home/Components/LocationInput";
import FlightDatesRangeInput from "../../Pages/Home/Components/FlightDatesRangeInput";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Popover, Transition } from "@headlessui/react";
import { Switch } from "@mui/material";
import NcInputNumber from "../../Pages/Home/Components/NcInputNumber";
import { flightSearchList } from "../../utils/flightSearchList";
import ButtonSubmit from "../../Pages/Home/Components/SubmitButton";
import PassengerTypeModal from "./PassengerTypeModal";
import Locals from "../../utils/localFlights";

const ModifySearchModal = ({
  show,
  onHide,
  query,
  setAirPrice,
  setSelectedDate,
  setCustomSearchModal,
}) => {
  const [searchForm, setSearchForm] = useState({
    origin: query?.Origin,
    destination: query?.Destination,
    startDate: query?.startDate,
    endDate: query?.endDate,
    tripType: query?.tripType,
    classType: query?.classType,
    adults: query?.adults,
    labors: query?.labors,
    children: query?.children,
    infants: query?.infants,
  });

  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: moment(query?.startDate),
    endDate:
      query?.endDate !== "Invalid date"
        ? moment(query?.endDate)
        : moment(query?.startDate).add(4, "days"),
    // endDate: moment(query?.endDate) || moment(query?.startDate).add(4, "days"),
  });
  const [pickUpInputValue, setPickUpInputValue] = useState(
    query?.Origin || "ADD"
  );
  const [dropOffInputValue, setDropOffInputValue] = useState(
    query?.Destination
  );
  const [fieldFocused, setFieldFocused] = useState(null);
  const [guests, setGuests] = useState({
    adults: +query?.adults || 1,
    labor: +query?.labors || 0,
    children: +query?.children || 0,
    infants: +query?.infants || 0,
  });
  const options = {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  useEffect(() => {
    if (guests.labor > 0) {
      setEnabledLabor(true);
    }
  }, [guests.labor]);

  let isLocal =
    Locals.includes(pickUpInputValue) && Locals.includes(dropOffInputValue);

  let isLabor = guests?.labor > 0;

  function UseFlightName(code) {
    return flightSearchList(code)?.filter((flight) => {
      return flight?.code === code;
    })?.[0]?.city;
  }

  function getFlightName(code) {
    if (!code) return;
    return UseFlightName(code);
  }

  const [flightClassState, setFlightClassState] = useState(
    query?.cabinClass || "Any"
  );

  const passengerType = ["Resident", "Foreigner"];

  const flightClass = [
    {
      name: "Economy",
    },
    {
      name: "Business",
    },
    {
      name: "Any",
    },
  ];

  const [passengerTypeState, setPassengerTypeState] = useState("Resident");
  const [enabledLabor, setEnabledLabor] = useState(false);

  const [showPassengerTypeModal, setShowPassengerTypeModal] = useState(false);

  const TripTypes = [
    {
      name: "One Way",
      value: "oneWay",
    },
    {
      name: "Round Trip",
      value: "roundTrip",
    },
    // {
    //   name: "Multi City",
    //   value: "multiCity",
    // },
  ];

  let buttonSubmitHref = `/search-result?origin=${pickUpInputValue}&destination=${dropOffInputValue}&startDate=${moment(
    dateRangeValue.startDate
  ).format("YYYY-MM-DD")}&endDate=${moment(dateRangeValue.endDate).format(
    "YYYY-MM-DD"
  )}&tripType=${searchForm?.tripType}&classType=${flightClassState}&adults=${
    guests.adults
  }&labors=${guests.labor}&children=${guests.children}&infants=${
    guests.infants
  }`;

  const renderPassengerTypeModal = () => {
    return (
      <div className="pr-2 xl:pr-4 relative z-20 mt-3 lg:mr-14">
        <button
          type="button"
          className={`h-14 w-14 rounded-full bg-main hover:bg-main-700 flex items-center justify-center text-neutral-50 focus:outline-none`}
          onClick={() => {
            // setCustomSearchModal(false);
            setShowPassengerTypeModal(true);
            // setAirPrice(null);
          }}
        >
          <span className="mr-3 md:hidden">Search</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
        <PassengerTypeModal
          show={showPassengerTypeModal}
          onHide={() => setShowPassengerTypeModal(false)}
          href={buttonSubmitHref}
          isLocal={isLocal}
        />
      </div>
    );
  };

  const renderGuest = () => {
    return (
      <div className="opacity-1">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span className="text-black">{`${
                  (+guests.labor > 0 ? 0 : guests.adults) +
                  guests.children +
                  guests.infants +
                  guests.labor
                } ${+guests.labor > 0 ? "Labor" : "Passenger(s)"}`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black/5 dark:ring-white/10">
                    <div className="relative px-4 pt-2 pb-4 bg-white text-black font-semibold">
                      <div className="flex justify-center">
                        <Switch
                          size="small"
                          className="bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                          onChange={(e) => {
                            setEnabledLabor(e.target.checked);
                            if (e.target.checked) {
                              setGuests({
                                ...guests,
                                labor: 1,
                                adults: 0,
                                children: 0,
                                infants: 0,
                              });
                            } else {
                              setGuests({
                                ...guests,
                                adults: 1,
                                children: 0,
                                infants: 0,
                                labor: 0,
                              });
                            }
                          }}
                          checked={enabledLabor}
                        />
                        <h2 className="text-md bg-white p-2">Labor</h2>
                      </div>
                      {enabledLabor ? (
                        <NcInputNumber
                          onChange={(e) =>
                            setGuests({ ...guests, labor: e, adults: 0 })
                          }
                          min={1}
                          defaultValue={guests.labor}
                          max={9}
                          label="Labors"
                          desc="12+ yrs"
                        />
                      ) : (
                        <>
                          <NcInputNumber
                            onChange={(e) =>
                              setGuests({ ...guests, adults: e, labor: 0 })
                            }
                            min={1}
                            defaultValue={guests.adults}
                            max={9 - (guests.infants + guests.children)}
                            label="Adults"
                            desc="12+ yrs"
                          />
                          <NcInputNumber
                            onChange={(e) =>
                              setGuests({ ...guests, children: e })
                            }
                            min={0}
                            defaultValue={guests.children}
                            max={9 - (guests.adults + guests.infants)}
                            label="Children"
                            desc="2-11 yrs"
                          />

                          <NcInputNumber
                            onChange={(e) =>
                              setGuests({ ...guests, infants: e })
                            }
                            min={0}
                            defaultValue={guests.infants}
                            max={9 - (guests.adults + guests.children)}
                            label="Infants"
                            desc="0-2 yrs"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="[ nc-hero-field-padding ] py-3 flex flex-row flex-wrap ">
        <div className="my-1 mr-2 border rounded-full sm:mr-4 border-neutral-300 dark:border-neutral-700">
          <Popover className="relative ">
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                  onClick={() => document.querySelector("html")?.click()}
                >
                  <span className="text-black">{`${flightClassState}`}</span>
                  <ChevronDownIcon
                    className={`${
                      open ? "" : "text-opacity-70 "
                    } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                    aria-hidden="true"
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                    <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black/5 dark:ring-white/10 ">
                      <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                        {flightClass.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={(e) => {
                              e.preventDefault();
                              setFlightClassState(item.name);
                              close();
                            }}
                            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <p className="text-sm font-medium ">{item.name}</p>
                          </a>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        <div className="my-1 border rounded-full border-neutral-300 dark:border-neutral-700 z-30">
          {renderGuest()}
        </div>
        <div className="my-1 border rounded-full border-neutral-300 dark:border-neutral-700 z-30 mx-3">
          {/* {renderSelectPassengerType()} */}
        </div>
      </div>
    );
  };

  const renderSelectPassengerType = () => {
    return (
      <div className="">
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span className="text-black">{`${passengerTypeState}`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "" : "text-opacity-70 "
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black/5 dark:ring-white/10 ">
                    <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                      {passengerType.map((item) => (
                        <div
                          key={item}
                          onClick={(e) => {
                            e.preventDefault();
                            setPassengerTypeState(item);
                            close();
                          }}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <p className="text-sm font-medium ">{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton className="bg-main-900">
        {/* <Modal.Title className="text-center text-xl text-black">
          Custom Search
        </Modal.Title> */}
        <div className="text-lg text-black font-medium mx-2 w-full">
          <div className="flex justify-start gap-10 items-center w-full">
            <div className="flex  gap-1 text-lg text-black font-medium ">
              <p>
                {query?.Origin}({getFlightName(query?.Origin)})
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>

              <p>
                {query?.Destination}({getFlightName(query?.Destination)})
              </p>
            </div>

            <div className="flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                />
              </svg>

              <p>{moment(query?.startDate).format("D MMMM, YYYY")}</p>
            </div>

            <div className="">
              <p>
                {+query?.adults +
                  +query?.children +
                  +query?.infants +
                  +query?.labors}{" "}
                {+guests.labor > 0 ? "Labor" : "Passenger(s)"}
              </p>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex justify-center mx-2 items-center">
          <div className="flex flex-col w-32 space-y-3">
            {TripTypes.map((item, index) => (
              <button
                className={`${
                  searchForm?.tripType === item.value
                    ? "bg-main text-white"
                    : "text-black "
                } "px-3 py-2 font-medium border-1 border-black/40 gap-2 rounded-md shadow"`}
                onClick={() =>
                  setSearchForm({ ...searchForm, tripType: item.value })
                }
              >
                {item.name}
              </button>
            ))}
          </div>
          <form className="w-full xl:rounded-t-3xl  rounded-t-2xl ">
            <div className="">{renderSelectClass()}</div>
            <div className="bg-white  z-40 flex flex-wrap justify-center mx-8">
              <div className="flex flex-1 rounded-full">
                <div className="relative flex flex-1 lg:gap-5">
                  <div className="border-1 rounded-xl border-gray-900/30 h-20">
                    <LocationInput
                      defaultValue={pickUpInputValue}
                      onChange={(e) => setPickUpInputValue(e)}
                      onInputDone={() => setFieldFocused("dropOffInput")}
                      placeHolder="Flying from"
                      desc="Where do you want to fly from?"
                    />
                  </div>
                  <div className="border-1 rounded-xl border-gray-900/30 h-20">
                    <LocationInput
                      defaultValue={dropOffInputValue}
                      onChange={(e) => setDropOffInputValue(e)}
                      onInputDone={() => setFieldFocused("startDate")}
                      placeHolder="Flying to"
                      desc="Where you want to fly to?"
                      autoFocus={fieldFocused === "dropOffInput"}
                    />
                  </div>
                  <div className="border-1 rounded-xl border-gray-900/30 h-20">
                    <FlightDatesRangeInput
                      showButton={false}
                      defaultDateValue={dateRangeValue}
                      tripType={searchForm?.tripType}
                      defaultFocus={
                        fieldFocused === "dropOffInput" ? null : fieldFocused
                      }
                      onFocusChange={(focus) => setFieldFocused(focus)}
                      onChange={(data) => {
                        setDateRangeValue(data.stateDate);
                        setSelectedDate(
                          new Date(
                            data?.stateDate.startDate
                          ).toLocaleDateString("en-US", options)
                        );
                      }}
                      className="flex-1"
                      modal={true}
                    />
                  </div>
                </div>
                {isLabor ? (
                  <div
                    className="pr-2 xl:pr-4 relative z-20 mt-3 lg:mr-14"
                    onClick={() => {
                      onHide();
                      setFieldFocused(null);
                      setAirPrice(null);
                    }}
                  >
                    <ButtonSubmit href={buttonSubmitHref} />
                  </div>
                ) : (
                  renderPassengerTypeModal()
                )}
                {/* {renderPassengerTypeModal()} */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => onHide()}
          className="outline-red-500 outline text-black px-4 py-2 rounded-md shadow"
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModifySearchModal;
