import { LoadingButton } from "@mui/lab";
import { Button, InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { CompanySchema1 } from "../user.util";

const EditCompany = ({
  company,
  openCompanyModal,
  setOpenCompanyModal,
  setOpenAgent,
  handleCompany,
}) => {
  return (
    <div>
      <Modal
        className="modal fade"
        show={openCompanyModal}
        onHide={setOpenCompanyModal}
        size="lg"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Company Information</h4>
              <button
                type="button"
                className="btn-close"
                // onClick={() => setOpenAddEmployee(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close text-gray"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    name: company?.name ?? "",
                    address: company?.address ?? "",
                    officeNumber:
                      company?.officeNumber?.replace("+251", "") ?? "",
                    officeNumber2:
                      company?.officeNumber2?.replace("+251", "") ?? "",
                  }}
                  onSubmit={handleCompany}
                  validationSchema={CompanySchema1}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form className="flex items-end" onSubmit={handleSubmit}>
                      <div className="flex flex-col">
                        <div className="row">
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="name"
                              size="small"
                              variant="outlined"
                              name="name"
                              placeholder="Company Name"
                              label="Company Name"
                              value={values.name}
                              onChange={handleChange}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="address"
                              size="small"
                              variant="outlined"
                              name="address"
                              placeholder="Address"
                              label="Address"
                              value={values.address}
                              onChange={handleChange}
                              error={touched.address && Boolean(errors.address)}
                              helperText={touched.address && errors.address}
                            />
                          </div>
                          <div className="col-lg-6 mb-8">
                            <TextField
                              fullWidth
                              id="officeNumber"
                              size="small"
                              variant="outlined"
                              name="officeNumber"
                              placeholder="Office Phone number"
                              label="Office Phone number"
                              value={values.officeNumber}
                              onChange={handleChange}
                              error={
                                touched.officeNumber &&
                                Boolean(errors.officeNumber)
                              }
                              helperText={
                                touched.officeNumber && errors.officeNumber
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +251
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-8">
                            <TextField
                              fullWidth
                              id="officeNumber2"
                              size="small"
                              variant="outlined"
                              name="officeNumber2"
                              placeholder="Office Phone number 2"
                              label="Office Phone number 2 (optional)"
                              value={values.officeNumber2}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +251
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-md-6 mb-8">
                            <input
                              accept="image/*"
                              // style={{ display: "none" }}
                              id="raised-button-file"
                              multiple
                              type="file"
                            />
                            <label htmlFor="raised-button-file">
                              <Button variant="raised" component="span">
                                Upload Trade License File
                              </Button>
                            </label>
                          </div>
                          <div className="col-md-6 mb-8">
                            <input
                              accept="image/*"
                              // style={{ display: "none" }}
                              id="raised-button-file"
                              multiple
                              type="file"
                            />
                            <label htmlFor="raised-button-file">
                              <Button variant="raised" component="span">
                                Upload Vat File
                              </Button>
                            </label>
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"
                          // loading={isLoading}
                          style={{
                            backgroundColor: "#FBA82C",
                            color: "#fff",
                          }}
                        >
                          Save
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer justify-between">
              <Button
                variant="danger"
                onClick={() => {
                  setOpenCompanyModal(false);
                  setOpenAgent(true);
                  // setOpenForm(true);
                }}
              >
                {" "}
                Go Back
              </Button>
              <Button
                variant="danger"
                onClick={() => setOpenCompanyModal(false)}
                className="text-red"
              >
                {" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditCompany;
