import React, { useEffect, useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import moment from "moment";

import LocationInput from "./Components/LocationInput";
import FlightDatesRangeInput from "./Components/FlightDatesRangeInput";
import NcInputNumber from "./Components/NcInputNumber";
import { useLocation, useNavigate } from "react-router-dom";
// import logoSterling from "../../images/hero4.png";
import logoSterling from "../../images/logo_sterling.jpeg";
import {
  TRIP_TYPE_MULTI_CITY,
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
} from "../../common/constants";
import { Switch } from "@mui/material";
import Locals from "../../utils/localFlights";

const flightClass = [
  {
    name: "Economy",
    href: "##",
  },
  {
    name: "Business",
    href: "##",
  },
  {
    name: "Any",
    href: "##",
  },
];

const passengerType = ["Resident", "Foreigner"];

const FlightSearchForm = ({ haveDefaultValue = true, height }) => {
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });

  // USE STATE
  const [timeRangeValue, setTimeRangeValue] = useState({
    startTime: "10:00 AM",
    endTime: "10:00 AM",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const origin = query.get("origin");
  const destination = query.get("destination");
  const tripType = query.get("tripType");
  const cabinClass = query.get("cabinClass");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const labor = query.get("labors");
  const _id = query.get("_id");

  const [pickUpInputValue, setPickUpInputValue] = useState(origin || "ADD");
  const [dropOffInputValue, setDropOffInputValue] = useState(destination || "");
  const [fieldFocused, setFieldFocused] = useState(null);
  const [fieldFocusedId, setFieldFocusedId] = useState(null);
  const [dropOffLocationType, setDropOffLocationType] = useState(
    tripType || TRIP_TYPE_ROUND_TRIP
  );
  const [guests, setGuests] = useState({
    adults: +labor > 0 ? 0 : +adults || 1,
    children: +children || 0,
    infants: +infants || 0,
    labor: +labor || 0,
  });

  const defaultGuestValue = {
    adults: +adults || 1,
    children: +children || 0,
    infants: +infants || 0,
    labor: 0,
  };

  const [guestsAge, setGuestsAge] = useState({
    children: "",
    infants: "",
  });
  const [flightClassState, setFlightClassState] = useState(cabinClass || "Any");
  const [passengerTypeState, setPassengerTypeState] = useState("Resident");
  const [enabledLabor, setEnabledLabor] = useState(+labor > 0 ? true : false);

  useEffect(() => {
    if (startDate) {
      setDateRangeValue({
        startDate: moment(startDate),
        endDate: moment(endDate),
      });
      setGuests(defaultGuestValue);
    } else if (haveDefaultValue) {
      setDateRangeValue({
        startDate: moment().add(1, "days"),
        endDate: moment().add(4, "days"),
      });

      setGuests(defaultGuestValue);
    }
  }, [endDate, haveDefaultValue, startDate]);

  useEffect(() => {
    if (enabledLabor) {
      setGuests({ ...guests, labor: +labor > 0 ? +labor : 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledLabor]);

  const [forms, setForms] = useState([
    {
      id: 1,
      origin,
      destination,
      departureDate: moment().add(1, "days").format("YYYY-MM-DD"),
      cabinClass: "Multi City",
    },
    {
      id: 2,
      origin,
      destination,
      departureDate: moment().add(4, "days").format("YYYY-MM-DD"),
    },
  ]);

  const addForm = () => {
    const newForm = {
      id: forms.length + 1,
      departureDate: forms[forms.length - 1].departureDate,
    };
    setForms([...forms, newForm]);
  };

  const removeForm = (id) => {
    const updatedForms = forms.filter((form) => form.id !== id);
    setForms(updatedForms);
  };

  const onChange = (value, index, name) => {
    const newForms = [...forms];
    newForms[index] = {
      ...newForms[index],
      [name]: value,
    };
    setForms(newForms);
  };

  let isLocal =
    Locals.includes(pickUpInputValue) && Locals.includes(dropOffInputValue);

  const renderGuest = () => {
    return (
      <div className="opacity-1">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span className="text-black">{`${
                  guests.adults +
                  guests.children +
                  guests.infants +
                  guests.labor
                } ${
                  guests.adults +
                    guests.children +
                    guests.infants +
                    guests.labor >
                  1
                    ? guests.labor > 0
                      ? "Labors"
                      : "Passengers"
                    : guests.labor > 0
                    ? "Labor"
                    : "Passenger"
                }`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black/5 dark:ring-white/10">
                    <div className="relative px-4 pt-2 pb-4 bg-white text-black font-semibold">
                      <div className="flex justify-center">
                        <Switch
                          size="small"
                          className="bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
                          onChange={(e) => {
                            setEnabledLabor(e.target.checked);
                            if (e.target.checked) {
                              setGuests({
                                ...guests,
                                labor: 1,
                                adults: 0,
                              });
                            } else {
                              setGuests({
                                ...guests,
                                adults: 1,
                                children: 0,
                                infants: 0,
                                labor: 0,
                              });
                            }
                            // setGuests({ ...guests, labor: 1 });
                          }}
                          checked={enabledLabor}
                        />
                        <h2 className="text-md bg-white p-2">Labor</h2>
                      </div>
                      {enabledLabor ? (
                        <NcInputNumber
                          onChange={(e) => setGuests({ ...guests, labor: e })}
                          min={1}
                          defaultValue={guests.labor}
                          max={9}
                          label="Labors"
                          desc="12+ yrs"
                        />
                      ) : (
                        <>
                          <NcInputNumber
                            onChange={(e) =>
                              setGuests({ ...guests, adults: e, labor: 0 })
                            }
                            min={1}
                            defaultValue={guests.adults}
                            max={9 - (guests.infants + guests.children)}
                            label="Adults"
                            desc="12+ yrs"
                          />
                          <NcInputNumber
                            onChange={(e) =>
                              setGuests({ ...guests, children: e })
                            }
                            min={0}
                            onChangeAge={(e) => {
                              console.log(e);
                              setGuestsAge({ ...guestsAge, children: e });
                            }}
                            // onChangeAge={(e) =>
                            //   setGuestsAge({ ...guestsAge, children: e })
                            // }
                            defaultValue={guests.children}
                            max={9 - (guests.adults + guests.infants)}
                            label="Children"
                            desc="2-11 yrs"
                            age
                          />

                          <NcInputNumber
                            onChange={(e) =>
                              setGuests({ ...guests, infants: e })
                            }
                            min={0}
                            defaultValue={guests.infants}
                            max={9 - (guests.adults + guests.children)}
                            onChangeAge={(e) =>
                              setGuestsAge({ ...guestsAge, infants: e })
                            }
                            label="Infants"
                            desc="0-2 yrs"
                            age
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="">
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
                onClick={() => document.querySelector("html")?.click()}
              >
                <span className="text-black">{`${flightClassState}`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? "" : "text-opacity-70 "
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black/5 dark:ring-white/10 ">
                    <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                      {flightClass.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={(e) => {
                            e.preventDefault();
                            setFlightClassState(item.name);
                            close();
                          }}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <p className="text-sm font-medium ">{item.name}</p>
                        </a>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  // const renderSelectPassengerType = () => {
  //   return (
  //     <div className="">
  //       <Popover className="relative">
  //         {({ open, close }) => (
  //           <>
  //             <Popover.Button
  //               className={`
  //          ${open ? "" : ""}
  //           px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
  //               onClick={() => document.querySelector("html")?.click()}
  //             >
  //               <span className="text-black">{`${passengerTypeState}`}</span>
  //               <ChevronDownIcon
  //                 className={`${
  //                   open ? "" : "text-opacity-70 "
  //                 } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
  //                 aria-hidden="true"
  //               />
  //             </Popover.Button>
  //             <Transition
  //               as={Fragment}
  //               enter="transition ease-out duration-200"
  //               enterFrom="opacity-0 translate-y-1"
  //               enterTo="opacity-100 translate-y-0"
  //               leave="transition ease-in duration-150"
  //               leaveFrom="opacity-100 translate-y-0"
  //               leaveTo="opacity-0 translate-y-1"
  //             >
  //               <Popover.Panel className="absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
  //                 <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black/5 dark:ring-white/10 ">
  //                   <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
  //                     {passengerType.map((item) => (
  //                       <div
  //                         key={item}
  //                         onClick={(e) => {
  //                           e.preventDefault();
  //                           setPassengerTypeState(item);
  //                           close();
  //                         }}
  //                         className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
  //                       >
  //                         <p className="text-sm font-medium ">{item}</p>
  //                       </div>
  //                     ))}
  //                   </div>
  //                 </div>
  //               </Popover.Panel>
  //             </Transition>
  //           </>
  //         )}
  //       </Popover>
  //     </div>
  //   );
  // };

  const renderRadioBtn = () => {
    return (
      <div className="[ nc-hero-field-padding ] py-3 flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === TRIP_TYPE_ONE_WAY
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "text-black border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType(TRIP_TYPE_ONE_WAY)}
        >
          One-way
        </div>

        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === TRIP_TYPE_ROUND_TRIP
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "text-black border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType(TRIP_TYPE_ROUND_TRIP)}
        >
          Round-trip
        </div>

        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === TRIP_TYPE_MULTI_CITY
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "text-black border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType(TRIP_TYPE_MULTI_CITY)}
        >
          Multi-city
        </div>

        <div className="my-1 mr-2 border rounded-full sm:mr-4 border-neutral-300 dark:border-neutral-700">
          {renderSelectClass()}
        </div>
        <div className="my-1 border rounded-full border-neutral-300 dark:border-neutral-700 z-30">
          {renderGuest()}
        </div>
        {/* <div className="my-1 border rounded-full border-neutral-300 dark:border-neutral-700 z-30 mx-3">
          {renderSelectPassengerType()}
        </div> */}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div
        className={`bg-fixed  bg-no-repeat bg-cover w-full object-cover py-36 px-48 rounded-lg
      ${height} hidden lg:block z-0 mb-80`}
        style={{ backgroundImage: `url(${logoSterling})` }}
      >
        {dropOffLocationType !== TRIP_TYPE_MULTI_CITY ? (
          <form className="opacity-[0.96] w-full  mt-28 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white  hidden lg:block z-40">
            {renderRadioBtn()}
            <div className="flex flex-1 rounded-full">
              <div className="relative flex flex-1">
                <LocationInput
                  defaultValue={pickUpInputValue}
                  onChange={(e) => setPickUpInputValue(e)}
                  onInputDone={() => setFieldFocused("dropOffInput")}
                  placeHolder="Flying from"
                  desc="Where do you want to fly from?"
                />
                <LocationInput
                  defaultValue={dropOffInputValue}
                  onChange={(e) => setDropOffInputValue(e)}
                  onInputDone={() => setFieldFocused("startDate")}
                  placeHolder="Flying to"
                  desc="Where you want to fly to?"
                  autoFocus={fieldFocused === "dropOffInput"}
                />
              </div>
              <FlightDatesRangeInput
                showButton={true}
                defaultDateValue={dateRangeValue}
                defaultTimeValue={timeRangeValue}
                tripType={dropOffLocationType}
                defaultFocus={
                  fieldFocused === "dropOffInput" ? null : fieldFocused
                }
                onFocusChange={(focus) => setFieldFocused(focus)}
                onChange={(data) => {
                  setDateRangeValue(data.stateDate);
                  setTimeRangeValue(data.stateTimeRage);
                }}
                className="flex-1"
                prevDate={
                  new Date(moment().subtract(1, "days").format("YYYY-MM-DD"))
                }
                isLocal={isLocal}
                isLabor={guests.labor > 0}
                buttonSubmitHref={`/search-result?origin=${pickUpInputValue}&destination=${dropOffInputValue}&startDate=${moment(
                  dateRangeValue.startDate
                ).format("YYYY-MM-DD")}&endDate=${moment(
                  dateRangeValue.endDate
                ).format(
                  "YYYY-MM-DD"
                )}&tripType=${dropOffLocationType}&classType=${flightClassState}&adults=${
                  guests.adults
                }&labors=${guests.labor}&children=${guests.children}&infants=${
                  guests.infants
                }&childrenAge=${guestsAge.children}&infantsAge=${
                  guestsAge.infants
                }&id=${_id}`}
              />
            </div>
          </form>
        ) : (
          <>
            {forms.map((form, index) => (
              <form
                key={form.id}
                className={`w-full shadow-xl bg-white hidden lg:block border-b border-neutral-100 
                ${index === 0 ? "rounded-t-3xl" : ""}  ${
                  index === forms.length - 1
                    ? "rounded-b-[40px] xl:rounded-b-[49px] "
                    : ""
                } z-40
                `}
              >
                {index === 0 && renderRadioBtn()}
                <div className="flex items-center flex-2 rounded">
                  <div className="relative flex justify-between">
                    <LocationInput
                      // defaultValue={index > 0 && forms?.at(-2)?.destination}
                      onChange={(e) => onChange(e, index, "origin")}
                      onInputDone={() => {
                        setFieldFocused("dropOffInput");
                        setFieldFocusedId(index);
                      }}
                      placeHolder="Flying from"
                      className="w-80"
                      desc="Where do you want to fly from?"
                    />
                    <LocationInput
                      defaultValue={form.destination}
                      onChange={(e) => onChange(e, index, "destination")}
                      onInputDone={() => {
                        setFieldFocused("startDate");
                        setFieldFocusedId(index);
                      }}
                      placeHolder="Flying to"
                      desc="Where you want to fly to?"
                      className="w-80"
                      autoFocus={
                        fieldFocused === "dropOffInput" &&
                        index === fieldFocusedId
                      }
                    />
                  </div>
                  <FlightDatesRangeInput
                    showButton={false}
                    // defaultDateValue={moment(form.startDate).format(
                    //   "YYYY-MM-DD"
                    // )}
                    defaultDateValue={moment(form.startDate).format(
                      "YYYY-MM-DD"
                    )}
                    tripType={dropOffLocationType}
                    defaultFocus={
                      fieldFocused === "dropOffInput"
                        ? null
                        : fieldFocused && index === fieldFocusedId
                    }
                    onFocusChange={(focus) => setFieldFocused(focus)}
                    onChange={(data) => {
                      onChange(
                        moment(data?.stateDate?.startDate?._d).format(
                          "YYYY-MM-DD"
                        ),
                        index,
                        "departureDate"
                      );
                    }}
                    // className="w-80"
                    className="flex-1"
                    prevDate={
                      index === 0
                        ? new Date(
                            moment().subtract(1, "days").format("YYYY-MM-DD")
                          )
                        : forms[index - 1].departureDate
                    }
                  />
                  <div className="flex mx-auto">
                    {forms.length > 1 && index !== 0 && index !== 1 && (
                      <button
                        type="button"
                        className="h-8 w-8 m-1 rounded-full bg-red-500 hover:bg-red-700 flex items-center justify-center text-neutral-50 focus:outline-none"
                        onClick={() => removeForm(form.id)}
                      >
                        <span className="sr-only">Remove</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            ))}

            <div className="flex justify-between">
              {forms.length <= 5 ? (
                <div
                  type="button"
                  className="h-10 w-64 mt-2 rounded-full bg-main hover:bg-main-700 flex items-center justify-center text-neutral-50 focus:outline-none "
                  onClick={addForm}
                >
                  {/* <span className="mr-3 md:hidden">Add Another Form</span> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  <p>Add another flight (up to 6)</p>
                </div>
              ) : (
                <div></div>
              )}
              <button
                type="button"
                className="h-14 w-1/3 mt-2 rounded-full bg-main hover:bg-main-700 flex items-center justify-center text-neutral-50 focus:outline-none"
                onClick={() =>
                  navigate(
                    `/search-result?tripType=${dropOffLocationType}&classType=${flightClassState}&adults=${guests.adults}&labors=${guests.labor}&children=${guests.children}&infants=${guests.infants}&childrenAge=${guestsAge.children}&infantsAge=${guestsAge.infants}&passengerTypeState=${passengerTypeState}`,
                    {
                      state: {
                        flightSearch: forms,
                      },
                    }
                  )
                }
              >
                <span className="mr-3 text-lg font-bold ">Search</span>
              </button>

              {/* <ButtonSubmit
                className="mt-2"
                href={`/flight-result?origin=${pickUpInputValue}&destination=${dropOffInputValue}&startDate=${moment(
                  dateRangeValue.startDate
                ).format("YYYY-MM-DD")}&endDate=${moment(
                  dateRangeValue.endDate
                ).format(
                  "YYYY-MM-DD"
                )}&tripType=${dropOffLocationType}&classType=${flightClassState}&adults=${
                  guests.adults
                }&children=${guests.children}&infants=${
                  guests.infants
                }&childrenAge=${guestsAge.children}&infantsAge=${
                  guestsAge.infants
                }&passengerTypeState=${passengerTypeState}`}
              >
                Search
              </ButtonSubmit> */}
            </div>
          </>
        )}
      </div>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
