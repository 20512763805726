import React, { useEffect, useState } from "react";
import NcInputNumber from "../../../Pages/Home/Components/NcInputNumber";
import { Switch } from "@mui/material";

const GuestsInput = ({ defaultValue, setGuestInput, className = "" }) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue.adults || 0
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue.children || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue.infants || 0
  );
  const [guestLaborInputValue, setGuestLaborInputValue] = useState(
    defaultValue.labor || 0
  );
  const [enabledLabor, setEnabledLabor] = useState(false);

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue.adults || 0);
  }, [defaultValue.adults]);
  useEffect(() => {
    setGuestChildrenInputValue(defaultValue.children || 0);
  }, [defaultValue.children]);
  useEffect(() => {
    setGuestInfantsInputValue(defaultValue.infants || 0);
  }, [defaultValue.infants]);
  useEffect(() => {
    setGuestLaborInputValue(defaultValue.labor || 1);
  }, [defaultValue.labor]);

  // useEffect(() => {
  //   let newValue = {
  //     guestAdults: guestAdultsInputValue,
  //     guestChildren: guestChildrenInputValue,
  //     guestInfants: guestInfantsInputValue,
  //     guestLabor: guestLaborInputValue,
  //   };
  //   if (enabledLabor) {
  //     newValue.guestLabor = defaultValue.labor || 1;
  //     newValue.guestAdults = 0;
  //     newValue.guestChildren = 0;
  //     newValue.guestInfants = 0;
  //     onChange && onChange(newValue);
  //   } else {
  //     newValue.guestLabor = 0;
  //     newValue.guestAdults = defaultValue.adults || 1;
  //     newValue.guestChildren = defaultValue.children || 0;
  //     newValue.guestInfants = defaultValue.infants || 0;
  //     onChange && onChange(newValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   enabledLabor,
  //   guestAdultsInputValue,
  //   guestLaborInputValue,
  //   guestChildrenInputValue,
  //   guestInfantsInputValue,
  //   onChange,
  //   defaultValue,
  // ]);

  const handleChangeData = (value, type) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
      guestLabor: guestLaborInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.adults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.children = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.infants = value;
    }
    if (type === "guestLabor" && enabledLabor) {
      newValue.labor = value;
    }
    // onChange && onChange(newValue);
  };

  // useEffect(() => {
  //   if (enabledLabor) {
  //     setGuestInput({
  //       ...defaultValue,
  //       labor: +defaultValue?.labor > 0 ? +defaultValue?.labor : 1,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [enabledLabor]);
  console.log({ labors: defaultValue });
  useEffect(() => {
    if (+defaultValue.labor > 0) {
      setEnabledLabor(true);
    }
  }, [defaultValue.labor]);

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        Who's coming?
      </span>
      <div className="flex justify-center">
        <Switch
          size="small"
          className="bg-gray-200 relative inline-flex h-6 w-11 items-center rounded-full"
          onChange={(e) => {
            setEnabledLabor(e.target.checked);
            if (e.target.checked) {
              setGuestInput({
                ...defaultValue,
                labor: 1,
                adults: 0,
                children: 0,
                infants: 0,
              });
            } else {
              setGuestInput({
                ...defaultValue,
                adults: 1,
                children: 0,
                infants: 0,
                labor: 0,
              });
            }
          }}
          checked={enabledLabor}
        />
        <h2 className="text-md bg-white p-2">Labor</h2>
      </div>
      {enabledLabor ? (
        <NcInputNumber
          onChange={(e) => setGuestInput({ ...defaultValue, labor: e })}
          min={1}
          defaultValue={guestLaborInputValue}
          max={9}
          label="Labors"
          desc="12+ yrs"
        />
      ) : (
        <>
          <NcInputNumber
            className="w-full"
            defaultValue={guestAdultsInputValue}
            onChange={(e) =>
              setGuestInput({ ...defaultValue, adults: e, labor: 0 })
            }
            max={9}
            min={1}
            label="Adults"
            desc="Ages 13 or above"
          />
          <NcInputNumber
            className="w-full mt-6"
            defaultValue={guestChildrenInputValue}
            onChange={(e) => setGuestInput({ ...defaultValue, children: e })}
            max={8}
            label="Children"
            desc="Ages 2–12"
            age
          />

          <NcInputNumber
            className="w-full mt-6"
            defaultValue={guestInfantsInputValue}
            onChange={(e) => setGuestInput({ ...defaultValue, infants: e })}
            max={8}
            label="Infants"
            desc="Ages 0–2"
            age
          />
        </>
      )}
    </div>
  );
};

export default GuestsInput;
