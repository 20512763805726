import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCompany, updateUser } from "../../../redux2/user/userSlice";

import EditAgent from "./EditAgent";
import EditCompany from "./EditCompany";
import { formatPhone } from "../user.util";

const Edit = ({ company }) => {
  const [openAgent, setOpenAgent] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);

  const dispatch = useDispatch();

  const handleOpenAgent = () => {
    setOpenAgent(true);
  };

  const handleCloseAgent = () => {
    setOpenAgent(false);
  };

  const handleAgent = (values) => {
    handleCloseAgent();
    setOpenCompanyModal(true);

    const user = {
      ...values,
      id: company?.user?._id,
    };

    dispatch(updateUser(user));
  };

  const handleCompany = (values) => {
    setOpenCompanyModal(false);

    const companyData = {
      ...values,
      id: company?._id,
      officeNumber: values.officeNumber
        ? formatPhone(values.officeNumber)
        : null,
      officeNumber2: values.officeNumber2
        ? formatPhone(values.officeNumber2)
        : null,
    };
    dispatch(updateCompany(companyData));
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={handleOpenAgent}>
        <i className="fas fa-edit me-2"></i>
        Edit
      </div>
      <EditAgent
        openAgent={openAgent}
        setOpenAgent={setOpenAgent}
        handleAgent={handleAgent}
        company={company}
      />
      <EditCompany
        openCompanyModal={openCompanyModal}
        setOpenCompanyModal={setOpenCompanyModal}
        setOpenAgent={setOpenAgent}
        company={company}
        handleCompany={handleCompany}
      />
    </div>
  );
};

export default Edit;
