import AverageNightlyRateChart from "./averageNightlyRateChart";

const AverageWeeklyRate = ({ title, data }) => {
  return (
    <div className="card">
      <div className="card-header border-0 d-sm-flex d-block">
        <div className="me-auto mb-sm-0 mb-3">
          <h4 className="card-title mb-2">{title}</h4>
        </div>
      </div>
      <div className="card-body">
        <div id="reservationChart" className="reservationChart">
          <AverageNightlyRateChart data={data} />
        </div>
      </div>
    </div>
  );
};

export default AverageWeeklyRate;
