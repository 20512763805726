import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiDashboard from "./apiDashboard";

const initialState = {
  topAgencies: [],
  airlines: [],
  isError: false,
  isLoading: false,
  errorMessage: "",
};

export const getTopAgencies = createAsyncThunk(
  "dashboard/getTopAgencies",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiDashboard.getTopAgencies(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getTopAirlines = createAsyncThunk(
  "dashboard/getTopAirlines",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiDashboard.getTopAirlines(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboard: (state) => {
      state.isError = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [getTopAgencies.pending]: (state) => {
      state.isLoading = true;
    },
    [getTopAgencies.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topAgencies = action.payload.data;
    },
    [getTopAgencies.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getTopAirlines.pending]: (state) => {
      state.isLoading = true;
    },
    [getTopAirlines.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.airlines = action.payload.data;
    },
    [getTopAirlines.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
