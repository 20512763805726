import { LoadingButton } from "@mui/lab";
import { MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateBookingRequestStatus } from "../../../redux2/booking/bookingSlice";

const BookingRequestedModal = ({
  bookingRequestModal,
  setBookingRequestModal,
  bookingId,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const data = {
      status: values.status === "approve" ? "pending" : values.status,
      id: bookingId,
    };

    dispatch(updateBookingRequestStatus(data));
    setBookingRequestModal(false);
  };

  return (
    <Modal
      className="modal fade"
      show={bookingRequestModal}
      onHide={setBookingRequestModal}
      size="md"
      centered
    >
      <div className="" role="document">
        <div className="">
          <div className="modal-header">
            <h4 className="modal-title fs-20">Change Booking Status</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setBookingRequestModal(false)}
              data-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close text-gray"></i>
            <div className="add-contact-box">
              <Formik
                initialValues={{
                  status: "",
                }}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="flex items-end">
                    <div className="flex flex-col w-full">
                      <div className="col-md col-12 mb-8">
                        <TextField
                          fullWidth
                          labelid="demo-simple-select-label"
                          id="status"
                          name="status"
                          size="small"
                          value={values.status}
                          label="Status"
                          onChange={handleChange}
                          select
                          error={touched.status && Boolean(errors.status)}
                          helperText={touched.status && errors.status}
                        >
                          <MenuItem value="approve">Approve</MenuItem>
                          <MenuItem value="cancelled">Cancel</MenuItem>
                        </TextField>
                      </div>

                      <LoadingButton
                        className="mt-8"
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: values.status ? "#FBA82C" : "#ccc",
                          color: "#fff",
                        }}
                        disabled={!values.status}
                        // loading={isLoading}
                      >
                        Submit
                      </LoadingButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BookingRequestedModal;
