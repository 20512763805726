import React, { Fragment, useState } from "react";
import NavHader from "./NavHader";
import Header from "./Header";
import SearchFlightMobile from "./mobile/SearchFlightMobile";

const JobieNav = ({ title, onClick: ClickToAddEvent }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <div className="border-b shadow-sm mb-4">
      <NavHader />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      {/* <div className="lg:hidden w-full my-2 mx-auto">
        <SearchFlightMobile />
      </div> */}
    </div>
  );
};

export default JobieNav;
