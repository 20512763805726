import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { forgetPasswordSchema } from "./user.util";
import { forgetPassword } from "../../redux2/user/authSlice";

const ForgetPasswordModal = ({ show, onClose }) => {
  const dispatch = useDispatch();

  const initialValues = {
    phoneOrEmail: "phone",
    phoneOrEmailValue: "",
  };

  const onSubmit = (values) => {
    let data = {};
    if (values.phoneOrEmail === "phone") {
      data.phone = values.phoneOrEmailValue;
    } else {
      data.email = values.phoneOrEmailValue;
    }

    dispatch(forgetPassword(data));
    onClose();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => onClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={initialValues}
          validationSchema={forgetPasswordSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Forget Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group text-black">
                  <label htmlFor="phoneOrEmail">Phone or Email</label>
                  <div className="flex flex-row justify-evenly ">
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="phoneOrEmail"
                        id="phone"
                        value="phone"
                      />
                      <label className="form-check-label" htmlFor="phone">
                        Phone
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="phoneOrEmail"
                        id="email"
                        value="email"
                      />
                      <label className="form-check-label" htmlFor="email">
                        Email
                      </label>
                    </div>
                  </div>
                  <label htmlFor="phoneOrEmailValue">
                    {formik.values.phoneOrEmail === "phone"
                      ? "Phone Number"
                      : "Email Address"}
                  </label>
                  <Field
                    type={
                      formik.values.phoneOrEmail === "phone" ? "text" : "email"
                    }
                    className="form-control"
                    id="phoneOrEmailValue"
                    name="phoneOrEmailValue"
                    required
                    placeholder={`Enter ${
                      formik.values.phoneOrEmail === "phone"
                        ? "Phone Number (09......)"
                        : "Email Address"
                    }`}
                  />
                  <ErrorMessage
                    name="phoneOrEmailValue"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn text-white bg-main hover:bg-main-900"
                  disabled={!formik.isValid}
                >
                  Send OTP
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ForgetPasswordModal;
