import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiEnterprise from "./apiEnterprise";

const initialState = {
  enterprises: [],
  enterprise: null,
  isError: false,
  isLoading: false,
  loading: false,
  errorMessage: "",
};

// create enterprise
export const createEnterprise = createAsyncThunk(
  "enterprise/createEnterprise",
  async (enterprise, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.createEnterprise(token, enterprise);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get enterprise
export const getEnterprise = createAsyncThunk(
  "enterprise/getEnterprise",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.getEnterprise(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get enterprise by id
export const getEnterpriseById = createAsyncThunk(
  "enterprise/getEnterpriseById",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.getEnterpriseById(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get all enterprises
export const getEnterprises = createAsyncThunk(
  "enterprise/getEnterprises",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.getEnterprises(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get my enterprise
export const getMyEnterprise = createAsyncThunk(
  "enterprise/getMyEnterprise",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.getMyEnterprise(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get company enterprise
export const getCompanyEnterprises = createAsyncThunk(
  "enterprise/getCompanyEnterprises",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.getCompanyEnterprises(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update enterprise
export const updateEnterprise = createAsyncThunk(
  "enterprise/updateEnterprise",
  async (enterprise, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.updateEnterprise(token, enterprise);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update user status
export const updateEnterpriseStatus = createAsyncThunk(
  "enterprise/updateEnterpriseStatus",
  async ({ id, status }, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.updateEnterpriseStatus(token, { id, status });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// delete enterprise
export const deleteEnterprise = createAsyncThunk(
  "enterprise/deleteEnterprise",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.deleteEnterprise(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update credit limit
export const updateCreditLimit = createAsyncThunk(
  "enterprise/updateCreditLimit",
  async ({ id, creditLimit }, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.updateCreditLimit(token, { id, creditLimit });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update service charge
export const updateServiceCharge = createAsyncThunk(
  "enterprise/updateServiceCharge",
  async ({ id, local, international, internationalBusiness }, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiEnterprise.updateServiceCharge(token, {
        id,
        local,
        international,
        internationalBusiness,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const enterpriseSlice = createSlice({
  name: "enterprise",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = "";
      state.enterprise = null;
    },
  },
  extraReducers: {
    // create enterprise
    [createEnterprise.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createEnterprise.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprise = action.payload;
      state.enterprises.push(action.payload.data);
    },
    [createEnterprise.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get enterprise
    [getEnterprise.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getEnterprise.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprise = action.payload.data;
    },
    [getEnterprise.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get enterprise by id
    [getEnterpriseById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getEnterpriseById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprise = action.payload.data;
    },
    [getEnterpriseById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get all enterprises
    [getEnterprises.pending]: (state, action) => {
      state.loading = true;
    },
    [getEnterprises.fulfilled]: (state, action) => {
      state.loading = false;
      state.enterprises = action.payload.data;
    },
    [getEnterprises.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get my enterprise
    [getMyEnterprise.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMyEnterprise.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprise = action.payload.data;
    },
    [getMyEnterprise.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get compnay enterprise
    [getCompanyEnterprises.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCompanyEnterprises.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprises = action.payload.data;
    },
    [getCompanyEnterprises.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update enterprise
    [updateEnterprise.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateEnterprise.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprise = action.payload.data;
      state.enterprises = state.enterprises.map((enterprise) => {
        if (enterprise._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return enterprise;
        }
      });
    },
    [updateEnterprise.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update enterprise status
    [updateEnterpriseStatus.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateEnterpriseStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprises = state.enterprises.map((enterprise) => {
        if (enterprise._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return enterprise;
        }
      });
    },
    [updateEnterpriseStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update credit limit
    [updateCreditLimit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCreditLimit.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.enterprises = state.enterprises.map((enterprise) => {
        if (enterprise._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return enterprise;
        }
      });
    },
    [updateCreditLimit.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update service charge
    [updateServiceCharge.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateServiceCharge.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.enterprises = state.enterprises.map((enterprise) => {
        if (enterprise._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return enterprise;
        }
      });
    },
    [updateServiceCharge.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // delete enterprise
    [deleteEnterprise.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteEnterprise.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.enterprises = state.enterprises.filter(
        (enterprise) => enterprise._id !== action.payload.data._id
      );
    },
    [deleteEnterprise.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { reset } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;
