import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PassengerTypeModal = ({ show, onHide, href, isLocal, mobile }) => {
  const [type, setType] = useState("resident");

  let typeOfPassenger =
    type === "resident"
      ? "Resident"
      : type === "foreigner"
      ? "Foreigner"
      : "Non-Document-Holder";

  href = `${href}&passengerTypeState=${typeOfPassenger}`;

  const navigate = useNavigate();

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-right"
      >
        <Modal.Body>
          <div className="w-full border-rgb(0, 0, 0) rgb(0, rgb(46, 125, 50) border-[1px 1px 10px] text-neutral-800 bg-white border-solid p-3">
            {isLocal ? (
              <>
                <h5 className="text-xl font-medium mb-2">
                  Discounted fare is available if:
                </h5>
                <div className="flex flex-wrap -ml-3 -mr-3">
                  <form action="">
                    <div className="px-3">
                      <div className="flex ">
                        <div className="flex items-center  mb-3">
                          <input
                            id="default-radio-1"
                            type="radio"
                            value={type}
                            name="default-radio"
                            defaultChecked
                            className="w-7 h-7 text-green-600 bg-gray-100 border-gray-300"
                            onChange={() => setType("resident")}
                          />

                          <label className="cursor-pointer text-lg pl-6 relative">
                            My client is Ethiopian national, or My client has a
                            proof of residence in Ethiopian.{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="px-3">
                      <div className="flex items-center  mb-3">
                        <input
                          id="default-radio-1"
                          type="radio"
                          value={type}
                          name="default-radio"
                          className="w-7 h-7 text-green-600  bg-gray-100 border-gray-300"
                          onChange={() => setType("foreigner")}
                        />

                        <label className="cursor-pointer text-lg  pl-6 relative">
                          No, My client hold none of the documents{" "}
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                <h5 className="text-xl font-medium mb-2">
                  Select the category to which your client belongs:
                </h5>
                <div className="flex flex-wrap -ml-3 -mr-3">
                  <form action="">
                    <div className="px-3">
                      <div className="flex ">
                        <div className="flex items-center  mb-3">
                          <input
                            id="default-radio-1"
                            type="radio"
                            value={type}
                            name="default-radio"
                            defaultChecked
                            className="w-7 h-7 text-green-600 bg-gray-100 border-gray-300"
                            onChange={() => setType("resident")}
                          />

                          <label
                            htmlFor="default-radio-1"
                            className="cursor-pointer text-lg pl-6 relative"
                          >
                            My client is an Ethiopian National. (Has Ethiopian
                            Passport).
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="px-3">
                      <div className="flex ">
                        <div className="flex items-center  mb-3">
                          <input
                            id="default-radio-2"
                            type="radio"
                            value={type}
                            name="default-radio"
                            className="w-12 h-10 text-green-600 bg-gray-100 border-gray-300"
                            onChange={() => setType("foreigner")}
                          />

                          <label
                            htmlFor="default-radio-2"
                            className="cursor-pointer text-lg pl-6 relative"
                          >
                            My client has proof of residence in Ethiopia. like
                            (Origin ID, Yellow Card, Work Permit, Residence
                            Permit).
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="px-3">
                      <div className="flex items-center mb-3">
                        <input
                          id="default-radio-3"
                          type="radio"
                          value={type}
                          name="default-radio"
                          className="w-7 h-7 text-green-600 bg-gray-100 border-gray-300"
                          onChange={() => setType("non-document-holder")}
                        />
                        <label
                          htmlFor="default-radio-3"
                          className="cursor-pointer text-lg pl-6 relative"
                        >
                          My client does not have any proof of residence in
                          Ethiopia.
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            type="button"
            className={`h-14 w-14 rounded-full bg-main hover:bg-main-700 flex items-center justify-center text-neutral-50 focus:outline-none`}
            onClick={() => {
              navigate(href, { replace: true });
              window.location.reload();
            }}
          >
            {!mobile && <span className="mr-3 md:hidden">Search</span>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PassengerTypeModal;
