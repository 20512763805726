import React from "react";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { Button, MenuItem, TextField } from "@mui/material";

import { EnterpriseSchema } from "../user.util";

const AddEnterpriseWallet = ({
  openAddEnterpriseWallet,
  setOpenAddEnterpriseWallet,
  handleSubmitEnterprise,
  isLoading,
}) => {
  return (
    <div>
      <Modal
        className="modal fade"
        show={openAddEnterpriseWallet}
        onHide={setOpenAddEnterpriseWallet}
        size="lg"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add To Wallet</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenAddEnterpriseWallet(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close text-gray"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    method: "",
                    bank: "",
                    deposit: "",
                    transactionId: "",
                    amount: "",
                    creditLimit: "",
                    serviceChargeLocal: 100,
                    serviceChargeInternational: 30,
                    serviceChargeInternationalBusiness: 45,
                  }}
                  validationSchema={EnterpriseSchema}
                  onSubmit={handleSubmitEnterprise}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="flex items-end">
                      <div className="flex flex-col">
                        <div className="row">
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              labelid="demo-simple-select-label"
                              id="method"
                              name="method"
                              size="small"
                              value={values.method}
                              label="Method"
                              onChange={handleChange}
                              select
                              error={touched.method && Boolean(errors.method)}
                              helperText={touched.method && errors.method}
                            >
                              <MenuItem value="prePaid" selected>
                                Pre Paid (Wallet)
                              </MenuItem>
                              <MenuItem value="postPaid">Post Paid</MenuItem>
                              <MenuItem value="credit" disabled>
                                Credit
                              </MenuItem>
                            </TextField>
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              labelid="demo-simple-select-label"
                              id="bank"
                              name="bank"
                              size="small"
                              value={
                                values.method === "postPaid"
                                  ? "none"
                                  : values.bank
                              }
                              label="Bank"
                              onChange={handleChange}
                              disabled={values.method === "postPaid"}
                              select
                              error={touched.bank && Boolean(errors.bank)}
                              helperText={touched.bank && errors.bank}
                            >
                              <MenuItem value="none">none</MenuItem>
                              <MenuItem value="cbe">CBE</MenuItem>
                              <MenuItem value="awash">Awash</MenuItem>
                              <MenuItem value="abyssinia">Abyssinia</MenuItem>
                            </TextField>
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              type="number"
                              id="deposit"
                              size="small"
                              variant="outlined"
                              name="deposit"
                              placeholder="Deposit Amount"
                              label="Deposit Amount"
                              value={
                                values.method === "postPaid"
                                  ? 0
                                  : values.deposit
                              }
                              disabled={values.method === "postPaid"}
                              onChange={handleChange}
                              error={touched.deposit && Boolean(errors.deposit)}
                              helperText={touched.deposit && errors.deposit}
                            />
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="transactionId"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="transactionId"
                              placeholder="Transaction ID"
                              label="Transaction ID"
                              disabled={values.method === "postPaid"}
                              value={values.transactionId}
                              onChange={handleChange}
                              error={
                                touched.transactionId &&
                                Boolean(errors.transactionId)
                              }
                              helperText={
                                touched.transactionId && errors.transactionId
                              }
                            />
                          </div>

                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="creditLimit"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="creditLimit"
                              placeholder="Credit Limit"
                              label="Credit Limit"
                              disabled
                              value={0}
                              error={
                                touched.creditLimit &&
                                Boolean(errors.creditLimit)
                              }
                              helperText={
                                touched.creditLimit && errors.creditLimit
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="serviceChargeLocal"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeLocal"
                              placeholder="Local Service Charge"
                              label="Local Service Charge (in Birr)"
                              value={values.serviceChargeLocal}
                              onChange={handleChange}
                              error={
                                touched.serviceChargeLocal &&
                                Boolean(errors.serviceChargeLocal)
                              }
                              helperText={
                                touched.serviceChargeLocal &&
                                errors.serviceChargeLocal
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="serviceChargeInternational"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeInternational"
                              placeholder="International Economy Service Charge"
                              label="International Economy Service Charge (in Dollars)"
                              value={values.serviceChargeInternational}
                              onChange={handleChange}
                              error={
                                touched.serviceChargeInternational &&
                                Boolean(errors.serviceChargeInternational)
                              }
                              helperText={
                                touched.serviceChargeInternational &&
                                errors.serviceChargeInternational
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="serviceChargeInternationalBusiness"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeInternationalBusiness"
                              placeholder="International Business Service Charge"
                              label="International Business Service Charge (in Dollars)"
                              value={values.serviceChargeInternationalBusiness}
                              onChange={handleChange}
                              error={
                                touched.serviceChargeInternationalBusiness &&
                                Boolean(
                                  errors.serviceChargeInternationalBusiness
                                )
                              }
                              helperText={
                                touched.serviceChargeInternationalBusiness &&
                                errors.serviceChargeInternationalBusiness
                              }
                            />
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"
                          loading={isLoading}
                          style={{
                            backgroundColor: "#FBA82C",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                onClick={() => setOpenAddEnterpriseWallet(false)}
                variant="danger"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddEnterpriseWallet;
