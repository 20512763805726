import React, { useEffect } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditEmployeeModal from "./components/edit-employee";
// import swal from "sweetalert";
import AddEmployeeForm from "./components/add-employee";
import { useDispatch, useSelector } from "react-redux";
// import { employeeActions } from "../../../redux/employee/employee.slice";
import { CircularProgress, Switch } from "@mui/material";
// import { groupActions } from "../../../redux/group/group.slice";
import {
  getMembers,
  updateMemberStatus,
} from "../../../redux2/agent/agentSlice";
import moment from "moment";
import Edit from "../../../components/Employees/Edit";
import Loading from "../../../components/Loading";

const Employees = () => {
  const { members, isError, isLoading } = useSelector((state) => state.agent);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  let pagination = Array(Math.ceil(1))
    .fill()
    .map((_, i) => i + 1);

  const handleChange = (event, id) => {
    dispatch(
      updateMemberStatus({
        id,
        status: event.target.checked ? "active" : "inactive",
      })
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="row">
      <div className="col-md-2">
        <AddEmployeeForm />
      </div>
      <div className="table-responsive h-screen">
        <table
          className="display w-full dataTable "
          // className="display w-full dataTable table-responsive-lg"
          id="example5"
          role="grid"
          aria-describedby="example5_info"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => {}}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting">Employee Name</th>
              <th className="sorting">Email</th>
              <th className="sorting">Role</th>
              <th className="sorting">Contact</th>
              <th className="sorting">Status</th>
              <th className="sorting bg-none"></th>
              <th className="sorting bg-none"></th>
            </tr>
          </thead>
          {
            <tbody>
              {isError ? (
                <p className="text-danger text-2xl">Error occurred</p>
              ) : (
                <>
                  {members?.map((member) => {
                    return (
                      <tr role="row" className="odd" key={member?._id}>
                        <td className="sorting_1">
                          <div className="form-check  style-1">
                            <input
                              type="checkbox"
                              onClick={() => {}}
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                            />
                          </div>
                        </td>
                        <td>
                          <div className="concierge-bx">
                            <div>
                              {/* <span className="text-primary">#EMP-00025</span> */}
                              <h4 className="mt-1">
                                <Link
                                  className="text-black"
                                  to={"./guest-detail"}
                                >{`${member?.firstName} ${member?.lastName}`}</Link>
                              </h4>
                              <span className="fs-14">
                                Join on{" "}
                                {moment(member?.createdAt).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{member?.email}</td>
                        {/* <td>Group 01</td> */}
                        <td>
                          <Badge variant="warning badge light ml-4">
                            {member?.role}
                          </Badge>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            <span className="text-black font-w500">
                              <i className="fas fa-phone-volume me-2 text-primary"></i>
                              {member?.phone}
                            </span>
                          </div>
                        </td>
                        <td>
                          {member?.status === "active" ? (
                            <span className="text-success font-w600">
                              ACTIVE
                            </span>
                          ) : (
                            <span className="text-danger font-w600">
                              INACTIVE
                            </span>
                          )}{" "}
                        </td>
                        <td>
                          <Switch
                            style={{ color: "#FBA82C" }}
                            checked={member?.status === "active" ? true : false}
                            onChange={(e) => handleChange(e, member._id)}
                          />
                        </td>
                        <td>
                          <DropdownBlog member={member} />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          }
        </table>
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">Showing 1 of 12 entries</div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example2_paginate"
          >
            {/* <Link
              className="paginate_button previous disabled"
              to="/guest-list"
            >
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            </Link>
            <span>
              {pagination.map((number, i) => (
                <Link key={i} to="/guest-list">
                  {1}
                </Link>
              ))}
            </span>

            <Link className="paginate_button next" to="/guest-list">
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const DropdownBlog = ({ member }) => {
  return (
    <>
      <Dropdown className="dropdown text-sans-serif">
        <Dropdown.Toggle
          variant=""
          className="btn btn-primary i-false tp-btn-light sharp"
          type="button"
          id="order-dropdown-0"
          data-toggle="dropdown"
          data-boundary="viewport"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-right border py-0"
          aria-labelledby="order-dropdown-0"
        >
          <div className="py-2">
            {/* <Link className="dropdown-item" to="/agent-trends">
              <i className="fa fa-chart-pie me-2"></i>
              Trends
            </Link> */}
            <Link className="dropdown-item" to={`/flight-list/${member?._id}`}>
              <i className="fas fa-plane me-2"></i>
              Flight Bookings
            </Link>
            <div className="dropdown-item">
              <Edit employee={member} />
            </div>
            <Link className="dropdown-item" to="#">
              <i className="fas fa-trash-alt me-2"></i>
              Delete
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Employees;
