import React from "react";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import { Typography } from "@mui/material";
import moment from "moment";
import { formatNumber } from "../../../utils/convertToNumber";

const AgentBillingList = ({ billing }) => {
  return (
    <div>
      <h3 className="text-2xl font-black text-primary">Transaction</h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body " style={{ padding: "1.25rem" }}>
              <div className="table-responsive">
                <table className="table table-sm mb-0 table-responsive-lg  ">
                  <thead>
                    <tr className="text-black ">
                      <th></th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Created At</th>
                      <th className="align-middle">Transaction ID</th>
                      <th className="align-middle">Ticket Number</th>
                      <th className="align-middle">Payment Method</th>
                      <th className="align-middle">Deposit Amount</th>
                      <th className="align-middle pr-7">Total Price</th>
                      <th className="align-middle pr-7">System Fare</th>
                      <th className="align-middle ">Sterling S.C</th>
                      <th className="align-middle ">Agent Fee</th>
                      <th className="align-middle ">Passenger Name</th>
                      <th className="align-middle ">From Ticket Number</th>
                      <th className="align-middle ">Ticket Issue Date</th>
                      <th className="align-middle ">Tax</th>
                      <th className="align-middle ">Penalty</th>
                      <th className="align-middle ">Fare Difference</th>
                      <th className="align-middle ">Reason</th>
                      <th className="align-middle ">Withdrawn By</th>
                      <th className="align-middle ">Cheque Number</th>
                      <th className="align-middle ">Paid By</th>
                      <th className="align-middle ">Approved By</th>
                      <th className="align-middle">Balance</th>
                      <th className="no-sort" />
                    </tr>
                  </thead>
                  <tbody id="orders">
                    {billing?.billing?.length === 0 ? (
                      <tr>
                        <td colSpan={11}>
                          <Typography variant="h5">No Billing Found</Typography>
                        </td>
                      </tr>
                    ) : (
                      billing?.billing?.map((item, index) => (
                        <tr
                          className="btn-reveal-trigger hover:bg-slate-100"
                          key={index}
                        >
                          <td>{++index}</td>
                          <td className="py-2">
                            {item?.type === "refundDeposit" ? (
                              <span className="badge bg-red-600">
                                Refund Deposit
                              </span>
                            ) : item?.type === "voidDeposit" ? (
                              <span className="badge bg-blue-600">
                                Void Deposit
                              </span>
                            ) : item?.reason ? (
                              <span className="badge badge-info">
                                Credit Payment
                              </span>
                            ) : item?.deposit ? (
                              <span className="badge badge-dark">
                                Wallet Deposit
                              </span>
                            ) : item?.status === "refunded" ? (
                              <span className="badge badge-danger">
                                Refunded
                              </span>
                            ) : item?.status === "voided" ? (
                              <span className="badge badge-secondary">
                                Void
                              </span>
                            ) : item?.status === "void-late" ? (
                              <span className="badge badge-secondary">
                                Void-Late
                              </span>
                            ) : item?.status === "exchanged" ? (
                              <span className="badge bg-green-700">
                                Exchanged
                              </span>
                            ) : item?.type === "exchangeFee" ? (
                              <span className="badge bg-green-700">
                                Exchanged Fee
                              </span>
                            ) : (
                              <span className="badge badge-success">
                                Success
                                <span className="ms-1 fa fa-check" />
                              </span>
                            )}
                          </td>
                          <td className="py-2">
                            {moment(item.createdAt).format("MMMM Do YYYY")}
                          </td>
                          <td className="py-2">{item.transaction}</td>
                          <td className="py-2">
                            {item?.ticketNumber?.map((ticket, index) => (
                              <div className="flex flex-col" key={index}>
                                {ticket}
                              </div>
                            ))}
                          </td>
                          <td className="py-2">{item.bank}</td>
                          <td className="py-2">
                            {item.deposit && item.deposit + " birr"}
                          </td>
                          <td className="py-2">
                            {convertNumbThousand(
                              item.amount +
                                // (item.commission || 0) +
                                (item.serviceCharge || 0) || 0
                            )}{" "}
                            birr
                          </td>
                          <td className="py-2">
                            {convertNumbThousand(item.amount || 0)} birr
                          </td>
                          <td className="py-2">{item.serviceCharge || 0}</td>
                          <td className="py-2">{item?.commission || 0}</td>
                          <td className="py-2">
                            {item.booking?.passengers?.[0]
                              ? item.booking?.passengers[0]?.firstName +
                                " " +
                                item.booking?.passengers[0]?.lastName
                              : ""}
                          </td>
                          <td>
                            {item?.booking?.flight?.ticketNumber?.map(
                              (ticket, index) => (
                                <div className="flex flex-col" key={index}>
                                  {ticket}
                                </div>
                              )
                            )}
                          </td>
                          <td className="py-2">
                            {moment(item?.booking?.createdAt).format(
                              "MMMM Do YYYY"
                            )}
                          </td>
                          <td className="py-2">{item.tax || 0}</td>
                          <td className="py-2">{item.penalty || 0}</td>
                          <td className="py-2">
                            {item.exchangeDifference || 0}
                          </td>
                          <td className="py-2">{item.reason}</td>
                          <td className="py-2">{item.withdrawMethod}</td>
                          <td className="py-2">{item.chequeNumber}</td>
                          <td className="py-2">{item.paidBy}</td>
                          <td className="py-2">{item.approvedBy}</td>
                          <td className="py-2">
                            {item.reason
                              ? "-"
                              : formatNumber(item.balance) + " birr"}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentBillingList;
