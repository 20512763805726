import React from "react";
import { useLocation } from "react-router-dom";

const VoidCheck = () => {
  const location = useLocation();
  let { ticketData } = location.state;

  let passengerNames = [];
  let status = [];
  if (ticketData?.BookingTraveler instanceof Array) {
    ticketData?.BookingTraveler?.map((passenger) => {
      passengerNames.push
        ? passengerNames.push(passenger?.BookingTravelerName?._attributes)
        : passengerNames.push(passenger?.BookingTravelerName?._attributes);
    });
  } else {
    passengerNames.push(
      ticketData?.BookingTraveler?.BookingTravelerName?._attributes
    );
  }

  if (ticketData?.AirReservation?.DocumentInfo?.TicketInfo.length) {
    status = ticketData?.AirReservation?.DocumentInfo?.TicketInfo.map(
      (ticket) => {
        return ticket?._attributes?.Status;
      }
    );
  } else {
    status.push(
      ticketData?.AirReservation?.DocumentInfo?.TicketInfo?._attributes?.Status
    );
  }

  return (
    <div className="">
      <div className="flex flex-col sm:flex-row items-center">
        <div className="mx-4 sm:mx-20 py-4 mt-4">
          <div className="flex flex-col p-2 space-y-3 text-black w-60 ml-4 sm:ml-20 border-1 border-black rounded-lg">
            <h1 className="font-semibold text-lg mb-1">Legends</h1>
            <div class="flex items-center">
              <p class="ml-2">T - Ticketed</p>
            </div>

            <div class="flex items-center mt-2 space-y-3">
              <p class="ml-2">V - Void</p>
            </div>

            <div class="flex items-center mt-2 space-y-3">
              <p class="ml-2">R- Refunded</p>
            </div>

            <div class="flex items-center mt-2 space-y-3">
              <p class="ml-2">N - Unused</p>
            </div>

            <div class="flex items-center mt-2 space-y-3">
              <p class="ml-2">X - Exchanged</p>
            </div>

            <div class="flex items-center mt-2 space-y-3">
              <p class="ml-2">P - Partially Refunded</p>
            </div>

            <div class="flex items-center mt-2 space-y-3">
              <p class="ml-2">S/F - Used</p>
            </div>
          </div>
        </div>

        <div className="md:mx-10 w-full">
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl font-semibold text-black p-2">
              Ticket Status Checker
            </h2>
            <div className="">
              <button
                className="bg-red-500 hover:bg-red-600 rounded-lg px-4 py-2 text-white font-bold"
                onClick={() => window.history.back()}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className="table-responsive ">
            <table
              className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer text-gray-700"
              id="example5"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th className="sorting_asc bg-none">
                    <p>#</p>
                  </th>
                  <th className="sorting">Passenger Name</th>
                  <th className="sorting">Status</th>
                </tr>
              </thead>
              <tbody>
                {passengerNames?.map((passenger, index) => (
                  <tr role="row" key={index}>
                    <td>
                      <div className="flex flex-row gap-2">
                        <div>{++index}</div>
                      </div>
                    </td>
                    <td>
                      <div className="text-black font-semibold capitalize text-sm">
                        {passenger?.First} {passenger?.Last}
                      </div>
                    </td>
                    <td>
                      <div className="badge badge-success text-white px-2 py-1 rounded-full text-xs font-semibold bg-green-500 text-center">
                        {status[index - 1]}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoidCheck;
