// Desc: Constants used in the application

// User role constants
export const USER_ROLE_SUPERAGENT = "superAgent";
export const USER_ROLE_AGENT = "agent";
export const USER_ROLE_ENTERPRISE = "enterprise";
export const USER_ROLE_EMPLOYEE = "employee";
export const USER_ROLE_MEMBER = "member";
export const USER_ROLE_FINANCE = "finance";

// Trip type constants
export const TRIP_TYPE_ONE_WAY = "oneWay";
export const TRIP_TYPE_ROUND_TRIP = "roundTrip";
export const TRIP_TYPE_MULTI_CITY = "multiCity";

// System fee constants
export const LOCAL_SYSTEM_FEE = 50;
export const INTERNATIONAL_SYSTEM_FEE = 330;

// Booking status constants
export const BOOKING_STATUS_PENDING = "PENDING";
export const BOOKING_STATUS_CONFIRMED = "CONFIRMED";
export const BOOKING_STATUS_CANCELLED = "CANCELLED";
export const BOOKING_STATUS_COMPLETED = "COMPLETED";

// Booking type constants
export const BOOKING_TYPE_LOCAL = "LOCAL";
export const BOOKING_TYPE_INTERNATIONAL = "INTERNATIONAL";

// Booking payment status constants
export const BOOKING_PAYMENT_STATUS_PENDING = "PENDING";
export const BOOKING_PAYMENT_STATUS_PAID = "PAID";
export const BOOKING_PAYMENT_STATUS_REFUNDED = "REFUNDED";

// Booking payment method constants
export const BOOKING_PAYMENT_METHOD_CASH = "CASH";
export const BOOKING_PAYMENT_METHOD_CARD = "CARD";
export const BOOKING_PAYMENT_METHOD_WALLET = "WALLET";

export const WALLET_MINIMUM_AMOUNT = 4999;

export const DEFAULT_SERVICE_INTL_CHARGE = 30;
export const DEFAULT_SERVICE_BUSINESS_INTL_CHARGE = 36;

// Passenger search type constants
export const RESIDENT_PASSENGER = "resident";
export const FOREIGN_PASSENGER = "Foreigner";
export const NON_DOCUMENT_HOLDER = "Non-Document-Holder";

// Credit Types
export const ONE_DAY_CREDIT = "oneDay";
export const ONE_WEEK_CREDIT = "oneWeek";
export const TWO_WEEK_CREDIT = "twoWeek";
