// import * as XLSX from "xlsx";

// export default function exportExcel(data, name, order) {
//   // Create a new worksheet
//   const worksheet = XLSX.utils.json_to_sheet(data);

//   console.log(worksheet);

//   // Set the column widths
//   const maxColLength = data.reduce(
//     (max, row) =>
//       Math.max(
//         max,
//         ...Object.keys(row).map((key) => (row[key] || "").toString().length)
//       ),
//     0
//   );
//   worksheet["!cols"] = Object.keys(data[0]).map((key) => ({
//     wch: maxColLength + 0,
//   }));

//   const headerRow = worksheet?.["!rows"];

//   console.log({ headerRow });
//   headerRow?.forEach((cell, index) => {
//     // Set font style
//     worksheet[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
//       font: { bold: true },
//       fill: { fgColor: { rgb: "FF0000" } }, // Set header cell background color to red
//     };
//   });

//   // Create a new workbook and add the worksheet
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//   // Generate the Excel file
//   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

//   // Create a blob from the buffer
//   const blob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });

//   // Create a temporary URL for the blob
//   const url = window.URL.createObjectURL(blob);

//   // Create a temporary link element to trigger the download
//   const link = document.createElement("a");
//   link.href = url;
//   link.setAttribute("download", `${name}.xlsx`);
//   document.body.appendChild(link);
//   link.click();

//   // Clean up the temporary link and URL
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(url);
// }

// import * as XLSX from "xlsx-js-style";

// export default function exportExcel(data, name) {
//   // Create a new worksheet
//   const worksheet = XLSX.utils.json_to_sheet(data);

//   // Set the column widths
//   const maxColLength = data.reduce(
//     (max, row) =>
//       Math.max(
//         max,
//         ...Object.keys(row).map((key) => (row[key] || "").toString().length)
//       ),
//     0
//   );
//   worksheet["!cols"] = Object.keys(data[0]).map((key) => ({
//     wch: maxColLength,
//   }));

//   // Apply styles to the header row
//   const headerKeys = Object.keys(data[0]);
//   headerKeys.forEach((key, index) => {
//     const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
//     if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s", v: key };
//     worksheet[cellAddress].s = {
//       font: { bold: true },
//       fill: { fgColor: { rgb: "a7bdd8" } }, //
//       alignment: { horizontal: "center", vertical: "center" }, // Optional: center align headers
//     };
//   });

//   // Create a new workbook and add the worksheet
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//   // Generate the Excel file
//   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

//   // Create a blob from the buffer
//   const blob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });

//   // Create a temporary URL for the blob
//   const url = window.URL.createObjectURL(blob);

//   // Create a temporary link element to trigger the download
//   const link = document.createElement("a");
//   link.href = url;
//   link.setAttribute("download", `${name}.xlsx`);
//   document.body.appendChild(link);
//   link.click();

//   // Clean up the temporary link and URL
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(url);
// }

import * as XLSX from "xlsx-js-style";

export default function exportExcel(data, name) {
  // Create a new worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Define column widths
  const columnWidths = {
    "S/N": 5,
    Status: 15,
    "Issuing Date": 15,
    "Passenger Name": 35,
    "Ticket NO.": 35,
    "Passenger Passport No.": 20,
    "Issuing Airline": 20,
    Nationality: 20,
    Sector: 25,
    "System Fare": 15,
    "Sterling SC": 15,
    "Agent Fee": 15,
    Total: 15,
    "Fare Difference": 15,
    "Penalty + Tax": 15,
    Refund: 15,
    Payment: 15,
    "Issuing Company": 20,
    "Issued By": 15,
    Remark: 20,
  };

  // Apply column widths
  worksheet["!cols"] = Object.keys(columnWidths).map((key) => ({
    wch: columnWidths[key],
  }));

  // Define headers and which columns should be centered
  const headers = Object.keys(columnWidths);
  const centeredColumns = [
    "System Fare",
    "Sterling SC",
    "Agent Fee",
    "Total",
    "Fare Difference",
    "Penalty + Tax",
    "Refund",
    "Payment",
  ];

  // Apply styles to the header row
  headers.forEach((key, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s", v: key };
    worksheet[cellAddress].s = {
      font: { bold: true },
      fill: { fgColor: { rgb: "a7bdd8" } },
      alignment: { horizontal: "center", vertical: "center" }, // Center align headers
    };
  });

  // Apply styles to the data rows
  data.forEach((row, rowIndex) => {
    headers.forEach((key, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({
        r: rowIndex + 1,
        c: colIndex,
      });
      if (!worksheet[cellAddress])
        worksheet[cellAddress] = { t: "s", v: row[key] };
      worksheet[cellAddress].s = {
        alignment: {
          horizontal: centeredColumns.includes(key) ? "center" : "left",
          vertical: "center",
        },
      };
    });
  });

  // Apply bold font style to the last row
  const lastRowIndex = data.length; // Since data rows start at index 1, this gives the correct last row index
  headers.forEach((key, colIndex) => {
    const cellAddress = XLSX.utils.encode_cell({
      r: lastRowIndex,
      c: colIndex,
    });
    if (!worksheet[cellAddress])
      worksheet[cellAddress] = { t: "s", v: data[lastRowIndex - 1][key] };
    if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {};
    worksheet[cellAddress].s.font = { bold: true };
  });

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate the Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a blob from the buffer
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();

  // Clean up the temporary link and URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

// export function exportExcel(data, name, order) {
//   const ws = XLSX.utils.json_to_sheet(data, {
//     header: order,
//   });
//   const wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, name);
//   XLSX.writeFile(wb, name + ".xlsx");
// }
