import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  userExists,
  reset,
  createUser,
  createCompany,
  getMyCompany,
} from "../../redux2/user/userSlice";
import { formatPhone } from "./user.util";
import AddCompany from "./components/AddCompany";
import AddWallet from "./components/AddWallet";
import AddUser from "./components/AddUser";
import AddEnterprise from "./components/AddEnterprise";
import {
  createEnterprise,
  reset as enterpriseReset,
} from "../../redux2/enterprise/enterpriseSlice";
import AddEnterpriseWallet from "./components/AddEnterpriseWallet";
import { USER_ROLE_AGENT, USER_ROLE_ENTERPRISE } from "../../common/constants";

const AddAgentForm = ({ type }) => {
  const dispatch = useDispatch();
  const { checker, user, isError, errorMessage, isLoading, company } =
    useSelector((state) => state.user);
  const { enterprise, isLoading: enterpriseLoading } = useSelector(
    (state) => state.enterprise
  );

  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [openCompany, setOpenCompany] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openAddWallet, setOpenAddWallet] = useState(false);
  const [openEnterprise, setOpenEnterprise] = useState(false);
  const [openAddEnterpriseWallet, setOpenAddEnterpriseWallet] = useState(false);

  const [companyData, setCompanyData] = useState({});
  const [enterpriseData, setEnterpriseData] = useState({});
  const [userId, setUserId] = useState("");
  const [checkedPhone, setCheckedPhone] = useState("");

  useEffect(() => {
    dispatch(getMyCompany());
  }, [dispatch, openEnterprise]);

  useEffect(() => {
    if (isError) {
      setOpenForm(false);
      setOpenAddWallet(false);
      toast.error(errorMessage);
    }

    if (user) {
      toast.success(`${user?.data?.role} created successfully!`);
      setOpenAddEmployee(false);
      user?.data?.role === USER_ROLE_AGENT && setOpenCompany(true);
      user?.data?.role === USER_ROLE_ENTERPRISE && setOpenEnterprise(true);
      setUserId(user?.data?._id);
    }

    if (company?.success === true) {
      toast.success("Company created successfully!");
      setOpenAddWallet(false);
    }

    if (enterprise?.success === true) {
      toast.success("Enterprise created successfully!");
      setOpenAddEnterpriseWallet(false);
    }

    type !== USER_ROLE_ENTERPRISE && dispatch(reset());
    dispatch(enterpriseReset());
  }, [user, isError, errorMessage, dispatch, company, enterprise, type]);

  const handleUser = (values) => {
    const data = {
      ...values,
      phone: formatPhone(values.phone),
    };

    dispatch(createUser(data));
  };

  const handleCheckUser = (values) => {
    setOpenForm(true);
    setCheckedPhone(values.phone);
    dispatch(userExists(formatPhone(values.phone)));
  };

  const handleCompany = (values) => {
    setCompanyData({
      ...values,
      user: userId,
      officeNumber: formatPhone(values.officeNumber),
      officeNumber2: values.officeNumber2
        ? formatPhone(values.officeNumber2)
        : null,
    });

    setOpenCompany(false);
    setOpenAddWallet(true);
  };

  const handleEnterprise = (values) => {
    setEnterpriseData({
      ...values,
      user: userId,
      officeNumber: formatPhone(values.officeNumber),
    });

    setOpenEnterprise(false);
    setOpenAddEnterpriseWallet(true);
  };

  const handleSubmitCompany = (values) => {
    const data = {
      ...values,
      ...companyData,
    };

    dispatch(createCompany(data));
  };

  const handleSubmitEnterprise = (values) => {
    const data = {
      ...values,
      bank: values.method === "postPaid" ? "none" : values.bank,
      deposit: values.method === "postPaid" ? 0 : values.deposit,
      company: company?._id,
      ...enterpriseData,
    };

    dispatch(createEnterprise(data));
  };

  return (
    <>
      <div
        className="btn bg-main hover:bg-main-800 text-white btn-sm mb-xxl-0 mb-4"
        onClick={() => setOpenAddEmployee(true)}
      >
        + {type === USER_ROLE_ENTERPRISE ? "New Enterprise" : "New Agent"}
      </div>
      <AddUser
        openAddEmployee={openAddEmployee}
        setOpenAddEmployee={setOpenAddEmployee}
        setOpenForm={setOpenForm}
        handleCheckUser={handleCheckUser}
        isLoading={isLoading}
        reset={reset}
        checkedPhone={checkedPhone}
        checker={checker}
        handleUser={handleUser}
        openForm={openForm}
        userType={type}
      />
      <AddWallet
        openAddWallet={openAddWallet}
        setOpenAddWallet={setOpenAddWallet}
        handleSubmitCompany={handleSubmitCompany}
      />
      <AddCompany
        openCompany={openCompany}
        setOpenCompany={setOpenCompany}
        setOpenAddWallet={setOpenAddWallet}
        setOpenForm={setOpenForm}
        handleCompany={handleCompany}
        isLoading={isLoading}
      />
      <AddEnterprise
        openEnterprise={openEnterprise}
        setOpenEnterprise={setOpenEnterprise}
        setOpenAddEmployee={setOpenAddEmployee}
        setOpenForm={setOpenForm}
        isLoading={enterpriseLoading}
        handleEnterprise={handleEnterprise}
      />
      <AddEnterpriseWallet
        openAddEnterpriseWallet={openAddEnterpriseWallet}
        setOpenAddEnterpriseWallet={setOpenAddEnterpriseWallet}
        handleSubmitEnterprise={handleSubmitEnterprise}
        isLoading={enterpriseLoading}
      />
    </>
  );
};

export default AddAgentForm;
