import { Pagination, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import convertNumbThousand from "../../utils/convertNumbThousand";

const SuperAgentBilling = ({ billings }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const billingPerPage = 10;

  const indexOfLastProduct = currentPage * billingPerPage;
  const indexOfFirstProduct = indexOfLastProduct - billingPerPage;
  const currentBillings = billings?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body " style={{ padding: "1.25rem" }}>
            <div className="table-responsive">
              <table className="table table-sm mb-0 table-responsive-lg default-table dataTable text-xs">
                <thead>
                  <tr className="text-black">
                    <th className="align-middle">Status</th>
                    <th className="align-middle">Created At</th>
                    <th className="align-middle">Transaction ID</th>
                    <th className="align-middle">Ticket Number</th>
                    <th className="align-middle">Corporate Name</th>
                    <th className="align-middle">Payment Method</th>
                    <th className="align-middle">Total Price</th>
                    <th className="align-middle pr-7">Base Price</th>
                    <th className="align-middle ">Service Charge</th>
                    <th className="align-middle ">System Fee</th>
                    <th className="align-middle ">Net Revenue</th>
                    <th className="align-middle ">Tax</th>
                    <th className="align-middle ">Penalty</th>
                    <th className="align-middle ">Fare Difference</th>
                    <th className="align-middle ">Reason</th>
                    <th className="align-middle ">Withdrawn By</th>
                    <th className="align-middle ">Cheque Number</th>
                    <th className="align-middle ">Paid By</th>
                    <th className="align-middle ">Approved By</th>
                    <th className="no-sort" />
                  </tr>
                </thead>
                <tbody id="orders">
                  {currentBillings?.length === 0 ? (
                    <tr>
                      <td colSpan={11}>
                        <Typography variant="h5">No Billing Found</Typography>
                      </td>
                    </tr>
                  ) : (
                    currentBillings?.map((item, index) => (
                      <tr className="btn-reveal-trigger" key={index}>
                        <td className="py-2">
                          {item?.status === "refunded" ? (
                            <span className="badge badge-secondary">
                              Refunded
                            </span>
                          ) : item?.status === "voided" ? (
                            <span className="badge badge-secondary">Void</span>
                          ) : item?.status === "exchanged" ? (
                            <span className="badge bg-green-700">
                              Exchanged
                            </span>
                          ) : (
                            <span className="badge badge-success">
                              Success
                              <span className="ms-1 fa fa-check" />
                            </span>
                          )}
                        </td>
                        <td className="py-2">
                          {moment(item.createdAt).format("MMMM Do YYYY")}
                        </td>
                        <td className="py-2">{item?.transaction}</td>
                        <td>
                          {item?.ticketNumber?.map((item, index) => (
                            <div className="flex flex-col" key={index}>
                              {item}
                            </div>
                          ))}
                        </td>
                        <td className="py-2 text-center">
                          {item.corporateName || "-"}
                        </td>
                        <td className="py-2 text-center">{item.bank}</td>
                        <td className="py-2">
                          {convertNumbThousand(
                            item.amount + item.serviceCharge || 0
                          )}{" "}
                          birr
                        </td>
                        <td className="py-2">
                          {/* {item.deposit ||
                            item.amount + item.serviceCharge ||
                            0}{" "} */}
                          {convertNumbThousand(item.deposit || item.amount)}{" "}
                          birr
                        </td>
                        <td className="py-2">
                          {convertNumbThousand(item.serviceCharge || 0)} birr
                        </td>
                        <td className="py-2">
                          {convertNumbThousand(item.systemFee || 0)} birr
                        </td>
                        <td className="py-2">
                          {convertNumbThousand(
                            item.serviceCharge - item.systemFee || 0
                          )}{" "}
                          birr
                        </td>
                        <td className="py-2">{item.tax || 0}</td>
                        <td className="py-2">{item.penalty || 0}</td>
                        <td className="py-2">{item.exchangeDifference || 0}</td>
                        <td className="py-2">{item.reason}</td>
                        <td className="py-2">{item.withdrawMethod}</td>
                        <td className="py-2">{item.chequeNumber}</td>
                        <td className="py-2">{item.paidBy}</td>
                        <td className="py-2">{item.approvedBy}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="flex items-center justify-center my-5">
                <Pagination
                  count={Math.ceil(billings?.length / billingPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  siblingCount={4}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAgentBilling;
