import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiAgent from "./apiAgent";
import apiUser from "../user/apiUser";

const initialState = {
  agents: [],
  agent: null,
  members: [],
  member: null,
  isError: false,
  isLoading: false,
  errorMessage: "",
};

// get all members
export const getMembers = createAsyncThunk(
  "agent/getMembers",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiAgent.getMembers(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get member
export const getMember = createAsyncThunk(
  "agent/getMember",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiAgent.getMember(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// create member
export const createMember = createAsyncThunk(
  "agent/createMember",
  async (member, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiAgent.createMember(token, member);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update employee status
export const updateMemberStatus = createAsyncThunk(
  "user/updateMemberStatus",
  async ({ id, status }, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateEmployeeStatus(token, { id, status });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get booking
// export const getBookings = createAsyncThunk(
//   "agent/getBookings",
//   async (_, thunkApi) => {
//     try {
//       const token = thunkApi.getState().auth.user.token;
//       return await apiAgent.getBookings(token);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkApi.rejectWithValue(message);
//     }
//   }
// );

const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    clearAgent: (state) => {
      state.agent = null;
    },
    clearMember: (state) => {
      state.member = null;
      // state.members = [];
      state.isError = false;
      state.isLoading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    // get all members
    [getMembers.pending]: (state) => {
      state.isLoading = true;
    },
    [getMembers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.members = action.payload.data;
    },
    [getMembers.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get member
    [getMember.pending]: (state) => {
      state.isLoading = true;
    },
    [getMember.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.member = action.payload;
    },
    [getMember.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // create member
    [createMember.pending]: (state) => {
      state.isLoading = true;
    },
    [createMember.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.member = action.payload;
      state.members.push(action.payload.data);
    },
    [createMember.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update member status
    [updateMemberStatus.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateMemberStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.members = state.members.map((member) => {
        if (member._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return member;
        }
      });
    },
    [updateMemberStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get bookings
    // [getBookings.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getBookings.fulfilled]: (state, action) => {
    //   state.isLoading = false;
    //   state.bookings = action.payload.data;
    // },
    // [getBookings.rejected]: (state, action) => {
    //   state.isLoading = false;
    //   state.isError = true;
    //   state.errorMessage = action.payload;
    // },
  },
});

export const { clearAgent, clearMember } = agentSlice.actions;

export default agentSlice.reducer;
