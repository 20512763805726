import axios from "axios";

import API_URL from "..";
const API_URL_ENTERPRISE = API_URL + "enterprise";

const getEnterprise = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_ENTERPRISE + `/${id}`, config);

  return res.data;
};

const getEnterpriseById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_ENTERPRISE + `/${id}`, config);

  return res.data;
};

const getCompanyEnterprises = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_ENTERPRISE + "/company", config);

  return res.data;
};

const getEnterprises = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_ENTERPRISE + "/", config);

  return res.data;
};

const getMyEnterprise = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_ENTERPRISE + "/me", config);

  return res.data;
};

const createEnterprise = async (token, enterprise) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL_ENTERPRISE + "/", enterprise, config);

  return res.data;
};

const updateEnterprise = async (token, enterprise) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL_ENTERPRISE + `/${enterprise.id}`,
    enterprise,
    config
  );

  return res.data;
};

const updateEnterpriseStatus = async (token, { id, status }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL_ENTERPRISE + `/status/${id}`,
    { status },
    config
  );

  return res.data;
};

const updateCreditLimit = async (token, { id, creditLimit }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL_ENTERPRISE + `/credit/${id}`,
    { creditLimit },
    config
  );

  return res.data;
};

const updateServiceCharge = async (
  token,
  { id, local, international, internationalBusiness }
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL_ENTERPRISE + `/service/${id}`,
    {
      local,
      international,
      internationalBusiness,
    },
    config
  );

  return res.data;
};

const deleteEnterprise = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL_ENTERPRISE + `/${id}`, config);

  return res.data;
};

const getEmployees = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_ENTERPRISE + "/employees", config);

  return res.data;
};

const apiEnterprise = {
  getEnterprise,
  getEnterpriseById,
  getEnterprises,
  getMyEnterprise,
  createEnterprise,
  updateEnterprise,
  updateCreditLimit,
  updateServiceCharge,
  deleteEnterprise,
  getEmployees,
  updateEnterpriseStatus,
  getCompanyEnterprises,
};

export default apiEnterprise;
