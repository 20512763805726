import * as Yup from "yup";

export const LoginValidationSchema = Yup.object({
  username: Yup.string()
    .required("Phone is required")
    .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
  password: Yup.string().required("Password is required"),
});

export const handleLogin = (data) => {
  localStorage.setItem("token", data?.token);
  localStorage.setItem("user_data", JSON.stringify(data?.data));
};

export const handleLogout = () => {
  localStorage.clear();
};

export const getUserData = () => {
  const userData = localStorage.getItem("user_data");

  return { user: JSON.parse(userData) };
};
