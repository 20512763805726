import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiUser from "./apiUser";

const initialState = {
  user: null,
  agent: null,
  checker: null,
  agents: [],
  company: null,
  companies: [],
  employee: null,
  employees: [],
  isError: false,
  isLoading: false,
  loading: false,
  errorMessage: "",
};

// create user
export const createUser = createAsyncThunk(
  "user/createUser",
  async (user, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.createUser(token, user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getMe = createAsyncThunk("auth/getMe", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await apiUser.getMe(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get user
export const getUser = createAsyncThunk(
  "user/getUser",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getUser(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get all users
export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getUsers(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update user
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (user, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateUser(token, user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// delete user
export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.deleteUser(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// user exists
export const userExists = createAsyncThunk(
  "user/userExists",
  async (phone, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.userExists(token, phone);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// create company
export const createCompany = createAsyncThunk(
  "user/createCompany",
  async (company, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.createCompany(token, company);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get company
export const getCompany = createAsyncThunk(
  "user/getCompany",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getCompany(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get company by id
export const getCompanyById = createAsyncThunk(
  "user/getCompanyById",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getCompanyById(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get all companies
export const getCompanies = createAsyncThunk(
  "user/getCompanies",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getCompanies(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get my company
export const getMyCompany = createAsyncThunk(
  "user/getMyCompany",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getMyCompany(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update company
export const updateCompany = createAsyncThunk(
  "user/updateCompany",
  async (company, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateCompany(token, company);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update user status
export const updateCompanyStatus = createAsyncThunk(
  "user/updateCompanyStatus",
  async ({ id, status }, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateCompanyStatus(token, { id, status });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// delete company
export const deleteCompany = createAsyncThunk(
  "user/deleteCompany",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.deleteCompany(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update credit limit
export const updateCreditLimit = createAsyncThunk(
  "user/updateCreditLimit",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateCreditLimit(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update service charge
export const updateServiceCharge = createAsyncThunk(
  "user/updateServiceCharge",
  async (data, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateServiceCharge(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// get all employees
export const getEmployees = createAsyncThunk(
  "user/getEmployees",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.getEmployees(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// update employee status
export const updateEmployeeStatus = createAsyncThunk(
  "user/updateEmployeeStatus",
  async ({ id, status }, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiUser.updateEmployeeStatus(token, { id, status });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = "";
      // state.checker = null;
      state.user = null;
      state.company = null;
    },
  },
  extraReducers: {
    // create user
    [createUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    [createUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get me
    [getMe.pending]: (state) => {
      state.isLoading = true;
    },
    [getMe.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
    },
    [getMe.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.user = null;
    },

    // get user
    [getUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get all users
    [getUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update user
    [updateUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employees = state.employees.map((employee) => {
        if (employee.id === action.payload.id) {
          return action.payload.data;
        }
        return employee;
      });

      // state.user = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // delete user
    [deleteUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    [deleteUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // user exists
    [userExists.pending]: (state, action) => {
      state.isLoading = true;
    },
    [userExists.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.checker = action.payload.data;
    },
    [userExists.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.checker = null;
    },
    // create company
    [createCompany.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.company = action.payload;
      state.companies.push(action.payload.data);
    },
    [createCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get company
    [getCompany.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.company = action.payload.data;
    },
    [getCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get company by id
    [getCompanyById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCompanyById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.company = action.payload.data;
    },
    [getCompanyById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get all companies
    [getCompanies.pending]: (state, action) => {
      state.loading = true;
    },
    [getCompanies.fulfilled]: (state, action) => {
      state.loading = false;
      state.companies = action.payload.data;
    },
    [getCompanies.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get my company
    [getMyCompany.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMyCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.company = action.payload.data;
    },
    [getMyCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update company
    [updateCompany.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.company = action.payload.data;
      state.companies = state.companies.map((company) => {
        if (company._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return company;
        }
      });
    },
    [updateCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update company status
    [updateCompanyStatus.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCompanyStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.companies = state.companies.map((company) => {
        if (company._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return company;
        }
      });
    },
    [updateCompanyStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update credit limit
    [updateCreditLimit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCreditLimit.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.companies = state.companies.map((company) => {
        if (company._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return company;
        }
      });
    },
    [updateCreditLimit.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update service charge
    [updateServiceCharge.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateServiceCharge.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.companies = state.companies.map((company) => {
        if (company._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return company;
        }
      });
    },
    [updateServiceCharge.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // delete company
    [deleteCompany.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteCompany.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.companies = state.companies.filter(
        (company) => company._id !== action.payload.data._id
      );
    },
    [deleteCompany.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // get all employees
    [getEmployees.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getEmployees.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employees = action.payload.data;
    },
    [getEmployees.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    // update employee status
    [updateEmployeeStatus.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateEmployeeStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employees = state.employees.map((employee) => {
        if (employee._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return employee;
        }
      });
    },
    [updateEmployeeStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
