export const flightSearchList = (value) => {
  return [
    {
      city: "Anaa",
      code: "AAA",
      name: "Anaa Airport",
    },
    {
      city: "El Tarf",
      code: "AAE",
      name: "El Mellah Airport",
    },
    {
      city: "Norresundby",
      code: "AAL",
      name: "Aalborg Airport",
    },
    {
      city: "Mala Mala",
      code: "AAM",
      name: "Mala Mala",
    },
    {
      city: "Ayn al Faydah",
      code: "AAN",
      name: "Al Ain Airport",
    },
    {
      city: "Novorossiysk",
      code: "AAQ",
      name: "Olkhovka Airport",
    },
    {
      city: "Kolind",
      code: "AAR",
      name: "Tirstrup Airport",
    },
    {
      city: "Altay",
      code: "AAT",
      name: "Altay Airport",
    },
    {
      city: "Araxá",
      code: "AAX",
      name: "Romeu Zuma Airport",
    },
    {
      city: "Al Ghaydah",
      code: "AAY",
      name: "Al Gaidah Airport",
    },
    {
      city: "Abakan",
      code: "ABA",
      name: "Abakan",
    },
    {
      city: "Albacete",
      code: "ABC",
      name: "Los Llanos",
    },
    {
      city: "Abadan",
      code: "ABD",
      name: "",
    },
    {
      city: "Allentown",
      code: "ABE",
      name: "Lehigh Valley International Airport",
    },
    {
      city: "Abilene",
      code: "ABI",
      name: "Abilene Regional Airport",
    },
    {
      city: "Abidjan",
      code: "ABJ",
      name: "Abidjan Port Bouet Airport",
    },
    {
      city: "Kabri Dar",
      code: "ABK",
      name: "Kabri Dar",
    },
    {
      city: "Kiana",
      code: "ABL",
      name: "Ambler Airport",
    },
    {
      city: "Seisia",
      code: "ABM",
      name: "Bamaga Airport",
    },
    {
      city: "Albuquerque",
      code: "ABQ",
      name: "Albuquerque International Airport",
    },
    {
      city: "Aberdeen",
      code: "ABR",
      name: "Aberdeen Regional Airport",
    },
    {
      city: "Abu Simbel",
      code: "ABS",
      name: "Abu Simbel Airport",
    },
    {
      city: "Al Aqiq",
      code: "ABT",
      name: "Al Baha Airport",
    },
    {
      city: "Gwagwa",
      code: "ABV",
      name: "Abuja International Airport",
    },
    {
      city: "Albury",
      code: "ABX",
      name: "Albury Airport",
    },
    {
      city: "Albany",
      code: "ABY",
      name: "Southwest Georgia Regional Airport",
    },
    {
      city: "Aberdeen",
      code: "ABZ",
      name: "Aberdeen Dyce International Airport",
    },
    {
      city: "Acapulco",
      code: "ACA",
      name: "General Juan N Alvarez International Airport",
    },
    {
      city: "Accra",
      code: "ACC",
      name: "Kotoka International Airport",
    },
    {
      city: "San Bartolomé",
      code: "ACE",
      name: "Arrecife Airport",
    },
    {
      city: "Altenrhein",
      code: "ACH",
      name: "Altenrhein Airport",
    },
    {
      city: "St. Peter Port",
      code: "ACI",
      name: "The Blaye Airport",
    },
    {
      city: "Nantucket",
      code: "ACK",
      name: "Nantucket Memorial Airport",
    },
    {
      city: "Sahand",
      code: "ACP",
      name: "Sahand Airport",
    },
    {
      city: "Achinsk",
      code: "ACS",
      name: "",
    },
    {
      city: "Waco",
      code: "ACT",
      name: "Waco Regional Airport",
    },
    {
      city: "Mckinleyville",
      code: "ACV",
      name: "Arcata Airport",
    },
    {
      city: "Xingyi",
      code: "ACX",
      name: "Xingyi",
    },
    {
      city: "Egg Harbor City",
      code: "ACY",
      name: "Atlantic City International Airport",
    },
    {
      city: "Zabol",
      code: "ACZ",
      name: "Zabol A/P",
    },
    {
      city: "Adana",
      code: "ADA",
      name: "Sakirpasa Airport",
    },
    {
      city: "Azmir",
      code: "ADB",
      name: "Gaziemir Airport",
    },
    {
      city: "Addis Ababa",
      code: "ADD",
      name: "Bole International Airport",
    },
    {
      city: "Ash Shaykh Uthman",
      code: "ADE",
      name: "Aden International Airport",
    },
    {
      city: "Adiyaman",
      code: "ADF",
      name: "Adiyaman Airport",
    },
    {
      city: "Amman",
      code: "ADJ",
      name: "Al Matar Airport",
    },
    {
      city: "Adak",
      code: "ADK",
      name: "Adak Airport",
    },
    {
      city: "Adelaide",
      code: "ADL",
      name: "Adelaide International Airport",
    },
    {
      city: "Kodiak",
      code: "ADQ",
      name: "Kodiak Airport",
    },
    {
      city: "Ardabil",
      code: "ADU",
      name: "Ardabil Airport",
    },
    {
      city: "St. Andrews",
      code: "ADX",
      name: "Leuchars Airport",
    },
    {
      city: "San Andrés",
      code: "ADZ",
      name: "Sesquicentenario Airport",
    },
    {
      city: "Abéché",
      code: "AEH",
      name: "Abeche Airport",
    },
    {
      city: "Buenos Aires",
      code: "AEP",
      name: "Aeroparque Jorge Newbery",
    },
    {
      city: "Sochi",
      code: "AER",
      name: "Adler Airport",
    },
    {
      city: "Vigra",
      code: "AES",
      name: "Vigra Airport",
    },
    {
      city: "Allakaket",
      code: "AET",
      name: "Allakaket Airport",
    },
    {
      city: "Alexandria",
      code: "AEX",
      name: "Alexandria International Airport",
    },
    {
      city: "Akureyri",
      code: "AEY",
      name: "Akureyri Airport",
    },
    {
      city: "San Rafael",
      code: "AFA",
      name: "San Rafael Airport",
    },
    {
      city: "Alta Floresta",
      code: "AFL",
      name: "Alta Floresta Airport",
    },
    {
      city: "Zarafshan",
      code: "AFS",
      name: "Zarafshan Airport",
    },
    {
      city: "Afutara",
      code: "AFT",
      name: "Afutara Aerodrome",
    },
    {
      city: "Sabzevar",
      code: "AFZ",
      name: "Sabzevar Airport",
    },
    {
      city: "Agadir",
      code: "AGA",
      name: "Almassira Airport",
    },
    {
      city: "Laplume",
      code: "AGF",
      name: "La Garenne Airport",
    },
    {
      city: "Angelholm",
      code: "AGH",
      name: "Angelholm Airport",
    },
    {
      city: "Wanigela",
      code: "AGL",
      name: "Wanigela",
    },
    {
      city: "Angmassalik",
      code: "AGM",
      name: "Angmagssalik Airport",
    },
    {
      city: "Angoon",
      code: "AGN",
      name: "Angoon Airport",
    },
    {
      city: "Malaga",
      code: "AGP",
      name: "Malaga Airport",
    },
    {
      city: "Agra",
      code: "AGR",
      name: "Agra Airport",
    },
    {
      city: "Augusta",
      code: "AGS",
      name: "Bush Field Airport",
    },
    {
      city: "Presidente Franco",
      code: "AGT",
      name: "Alejo Garcia Airport",
    },
    {
      city: "Aguascalientes",
      code: "AGU",
      name: "Aguascalientes Airport",
    },
    {
      city: "Acarigua",
      code: "AGV",
      name: "Acarigua",
    },
    {
      city: "Agatti Island",
      code: "AGX",
      name: "Agatti Island Airport",
    },
    {
      city: "Hajlah",
      code: "AHB",
      name: "Abha Airport",
    },
    {
      city: "Herlong",
      code: "AHC",
      name: "Amedee Army Air Field",
    },
    {
      city: "Ahe",
      code: "AHE",
      name: "Ahe Airport",
    },
    {
      city: "Alghero",
      code: "AHO",
      name: "Alghero Airport",
    },
    {
      city: "Auas",
      code: "AHS",
      name: "Ahuas Airport",
    },
    {
      city: "Al Hoceima",
      code: "AHU",
      name: "Cote du Rif Airport",
    },
    {
      city: "Alliance",
      code: "AIA",
      name: "Alliance Municipal Airport",
    },
    {
      city: "Wainwright",
      code: "AIN",
      name: "Wainwright Airport",
    },
    {
      city: "Aitutaki",
      code: "AIT",
      name: "Aitutaki Airport",
    },
    {
      city: "Atiu Island",
      code: "AIU",
      name: "Atiu Island",
    },
    {
      city: "Ajaccio",
      code: "AJA",
      name: "Campo Dell Oro Airport",
    },
    {
      city: "Sakakah",
      code: "AJF",
      name: "Al Jouf Airport",
    },
    {
      city: "Agrı",
      code: "AJI",
      name: "Agri Airport",
    },
    {
      city: "Aizawl",
      code: "AJL",
      name: "Aizwal Airport",
    },
    {
      city: "Anjouan",
      code: "AJN",
      name: "Comoros",
    },
    {
      city: "Arvidsjaur",
      code: "AJR",
      name: "Arvidsjaur Airport",
    },
    {
      city: "Aracaju",
      code: "AJU",
      name: "Santa Maria Airport",
    },
    {
      city: "Ankang",
      code: "AKA",
      name: "Ankang Airport",
    },
    {
      city: "Atka",
      code: "AKB",
      name: "Atka Airport",
    },
    {
      city: "Kufrah",
      code: "AKF",
      name: "Kufra Airport",
    },
    {
      city: "Bethel",
      code: "AKI",
      name: "Akiak Airport",
    },
    {
      city: "Asahikawa-shi",
      code: "AKJ",
      name: "Asahikawa Airport",
    },
    {
      city: "Kodiak",
      code: "AKK",
      name: "Akhiok Airport",
    },
    {
      city: "Manukau City",
      code: "AKL",
      name: "Auckland International Airport",
    },
    {
      city: "King Salmon",
      code: "AKN",
      name: "King Salmon Airport",
    },
    {
      city: "Anaktuvuk Pass",
      code: "AKP",
      name: "Anaktuvuk Pass Airport",
    },
    {
      city: "Kroonstad",
      code: "AKS",
      name: "Kroonstad Airport",
    },
    {
      city: "Aksu",
      code: "AKU",
      name: "Aksu Airport",
    },
    {
      city: "Chisasibi",
      code: "AKV",
      name: "Akulivik Airport",
    },
    {
      city: "Aqtobe",
      code: "AKX",
      name: "Aktyubinsk Airport",
    },
    {
      city: "Sittwe",
      code: "AKY",
      name: "Sittwe Airport",
    },
    {
      city: "Almaty",
      code: "ALA",
      name: "Alma Ata Airport",
    },
    {
      city: "Latham",
      code: "ALB",
      name: "Albany International Airport",
    },
    {
      city: "Elx",
      code: "ALC",
      name: "Alicante Airport",
    },
    {
      city: "Alta",
      code: "ALF",
      name: "Alta Airport",
    },
    {
      city: "Algiers",
      code: "ALG",
      name: "Houari Boumediene Airport",
    },
    {
      city: "Albany",
      code: "ALH",
      name: "Albany Airport",
    },
    {
      city: "Alamogordo",
      code: "ALM",
      name: "Alamogordo White Sands Regional Airport",
    },
    {
      city: "Waterloo",
      code: "ALO",
      name: "Waterloo Municipal Airport",
    },
    {
      city: "Djibrine",
      code: "ALP",
      name: "Aleppo International Airport",
    },
    {
      city: "Alamosa",
      code: "ALS",
      name: "San Luis Valley Regional Airport",
    },
    {
      city: "Walla Walla",
      code: "ALW",
      name: "Walla Walla Regional Airport",
    },
    {
      city: "Alexandria",
      code: "ALY",
      name: "An-Nuzhah Airport",
    },
    {
      city: "Alitak",
      code: "ALZ",
      name: "Alitak Seaplane Base",
    },
    {
      city: "Amarillo",
      code: "AMA",
      name: "Amarillo International Airport",
    },
    {
      city: "Ahmedabad",
      code: "AMD",
      name: "Sardar Vallabhbhai Patel International Airport",
    },
    {
      city: "Arba Minch",
      code: "AMH",
      name: "Arba Minch Airport",
    },
    {
      city: "Mataram",
      code: "AMI",
      name: "Selaparang",
    },
    {
      city: "Amman",
      code: "AMM",
      name: "Queen Alia International Airport",
    },
    {
      city: "Ambon",
      code: "AMQ",
      name: "Pattimura Airport",
    },
    {
      city: "Schiphol Zuid",
      code: "AMS",
      name: "Schiphol Airport",
    },
    {
      city: "Nar'yan-Mar",
      code: "AMV",
      name: "Amderma Airport",
    },
    {
      city: "Ambatomainty",
      code: "AMY",
      name: "Ambatomainty",
    },
    {
      city: "Anchorage",
      code: "ANC",
      name: "Anchorage International Airport",
    },
    {
      city: "Seiches-sur-le-Loir",
      code: "ANE",
      name: "Aéroport d'Angers-Marcé",
    },
    {
      city: "Antofagasta",
      code: "ANF",
      name: "Cerro Moreno International Airport",
    },
    {
      city: "Champniers",
      code: "ANG",
      name: "Brie Champniers Airport",
    },
    {
      city: "Bethel",
      code: "ANI",
      name: "Aniak Airport",
    },
    {
      city: "Antalaha",
      code: "ANM",
      name: "Madagascar",
    },
    {
      city: "Antwerp",
      code: "ANR",
      name: "Deurne Airport",
    },
    {
      city: "Carlisle",
      code: "ANU",
      name: "V C Bird International Airport",
    },
    {
      city: "Anvik",
      code: "ANV",
      name: "Anvik Airport",
    },
    {
      city: "Andenes",
      code: "ANX",
      name: "Andoya Airport",
    },
    {
      city: "Altenburg",
      code: "AOC",
      name: "Altenburg Nobitz",
    },
    {
      city: "Eskişehir",
      code: "AOE",
      name: "Anadolu University Airport",
    },
    {
      city: "Falconara Marittima",
      code: "AOI",
      name: "Falconara Airport",
    },
    {
      city: "Aomori-shi",
      code: "AOJ",
      name: "Aomori Airport",
    },
    {
      city: "Karpathos",
      code: "AOK",
      name: "Karpathos Airport",
    },
    {
      city: "Martinsburg",
      code: "AOO",
      name: "Altoona-Blair County Airport",
    },
    {
      city: "Kepala Batas",
      code: "AOR",
      name: "Sultan Abdul Halim Airport",
    },
    {
      city: "Amook",
      code: "AOS",
      name: "Amook Bay Seaplane Base",
    },
    {
      city: "Englewood",
      code: "APA",
      name: "Centennial Airport",
    },
    {
      city: "Naples",
      code: "APF",
      name: "Naples Municipal Airport",
    },
    {
      city: "Apataki",
      code: "APK",
      name: "French Polynesia",
    },
    {
      city: "Nampula",
      code: "APL",
      name: "Nampula Airport",
    },
    {
      city: "Alpena",
      code: "APN",
      name: "Alpena County Regional Airport",
    },
    {
      city: "Apartadó",
      code: "APO",
      name: "Apartado Airport",
    },
    {
      city: "Apia",
      code: "APW",
      name: "Faleolo Airport",
    },
    {
      city: "Anqing",
      code: "AQG",
      name: "Anqing Airport",
    },
    {
      city: "Qaisumah",
      code: "AQI",
      name: "Hafr Al Batin Airport",
    },
    {
      city: "Aqaba",
      code: "AQJ",
      name: "Aqaba International Airport",
    },
    {
      city: "Arequipa",
      code: "AQP",
      name: "Rodriguez Ballon Airport",
    },
    {
      city: "Arctic Village",
      code: "ARC",
      name: "Arctic Village Airport",
    },
    {
      city: "Alor Island",
      code: "ARD",
      name: "Alor Island",
    },
    {
      city: "Arkhangel'sk",
      code: "ARH",
      name: "Arkhangelsk Airport",
    },
    {
      city: "Arica",
      code: "ARI",
      name: "Chacalluta Airport",
    },
    {
      city: "Arusha",
      code: "ARK",
      name: "Arusha Airport",
    },
    {
      city: "Armidale",
      code: "ARM",
      name: "Armidale Airport",
    },
    {
      city: "Märst",
      code: "ARN",
      name: "Arlanda Airport",
    },
    {
      city: "Dexter",
      code: "ART",
      name: "Watertown International Airport",
    },
    {
      city: "Araçatuba",
      code: "ARU",
      name: "Aracatuba Airport",
    },
    {
      city: "Woodruff",
      code: "ARV",
      name: "Lakelan-Noble F. Lee Memerial Field Airport",
    },
    {
      city: "Arad",
      code: "ARW",
      name: "Ceala Airport",
    },
    {
      city: "Assab",
      code: "ASA",
      name: "Assab",
    },
    {
      city: "Ashgabat",
      code: "ASB",
      name: "Ashkhabad Northwest Airport",
    },
    {
      city: "Fresh Creek",
      code: "ASD",
      name: "Andros Town Airport",
    },
    {
      city: "Aspen",
      code: "ASE",
      name: "Aspen Pitkin County Airport-Sardy Field",
    },
    {
      city: "Astrakhan'",
      code: "ASF",
      name: "Astrakhan Southeast Airport",
    },
    {
      city: "Georgetown, Ascension Island",
      code: "ASI",
      name: "Wideawake Fld",
    },
    {
      city: "Amami O Shima",
      code: "ASJ",
      name: "Amami Airport",
    },
    {
      city: "Asmara",
      code: "ASM",
      name: "Yohannes Iv International Airport",
    },
    {
      city: "Asosa",
      code: "ASO",
      name: "Ethiopia",
    },
    {
      city: "Alice Springs",
      code: "ASP",
      name: "Alice Springs Airport",
    },
    {
      city: "Kayseri",
      code: "ASR",
      name: "Erkilet Airport",
    },
    {
      city: "Colonia Mariano Roque Alonso",
      code: "ASU",
      name: "Silvio Pettirossi International Airport",
    },
    {
      city: "Amboseli",
      code: "ASV",
      name: "Amboseli",
    },
    {
      city: "Aswan",
      code: "ASW",
      name: "Aswan Airport",
    },
    {
      city: "Atbara",
      code: "ATB",
      name: "Atbara",
    },
    {
      city: "Arthur Town",
      code: "ATC",
      name: "Arthur's Town Airport",
    },
    {
      city: "Atoifi",
      code: "ATD",
      name: "Atoifi",
    },
    {
      city: "Athens",
      code: "ATH",
      name: "Eleftherios Venizelos International Airport",
    },
    {
      city: "Atqasuk",
      code: "ATK",
      name: "Atqasuk Airport",
    },
    {
      city: "Atlanta",
      code: "ATL",
      name: "Hartsfield-Jackson Atlanta International Airport",
    },
    {
      city: "Altamira",
      code: "ATM",
      name: "Altamira Airport",
    },
    {
      city: "Raja Sansi",
      code: "ATQ",
      name: "Raja Sansi Airport",
    },
    {
      city: "Atar",
      code: "ATR",
      name: "Atar Airport",
    },
    {
      city: "Atmautluak",
      code: "ATT",
      name: "Atmautluak Airport",
    },
    {
      city: "Appleton",
      code: "ATW",
      name: "Outagamie County Airport",
    },
    {
      city: "Watertown",
      code: "ATY",
      name: "Watertown Municipal Airport",
    },
    {
      city: "Asyut",
      code: "ATZ",
      name: "Asyut Airport",
    },
    {
      city: "Aruba",
      code: "AUA",
      name: "Reina Beatrix International Airport",
    },
    {
      city: "Arauca",
      code: "AUC",
      name: "Santiago Perez Airport",
    },
    {
      city: "Augusta",
      code: "AUG",
      name: "Augusta State Airport",
    },
    {
      city: "Abu Dhabi",
      code: "AUH",
      name: "Abu Dhabi International Airport",
    },
    {
      city: "Alakanuk",
      code: "AUK",
      name: "Alakanuk Airport",
    },
    {
      city: "Atuona",
      code: "AUQ",
      name: "Atuona Airport",
    },
    {
      city: "Aurillac",
      code: "AUR",
      name: "Aurillac Airport",
    },
    {
      city: "Austin",
      code: "AUS",
      name: "Austin-Bergstrom International Airport",
    },
    {
      city: "Araguaina",
      code: "AUX",
      name: "Araguaina Airport",
    },
    {
      city: "Aneityum",
      code: "AUY",
      name: "Vanuatu",
    },
    {
      city: "Fletcher",
      code: "AVL",
      name: "Asheville Regional Airport",
    },
    {
      city: "Avignon",
      code: "AVN",
      name: "Avignon-Caumont Airport",
    },
    {
      city: "Pittston",
      code: "AVP",
      name: "Wilkes Barre Scranton International Airport",
    },
    {
      city: "Avu Avu",
      code: "AVU",
      name: "Avu Avu",
    },
    {
      city: "Lara",
      code: "AVV",
      name: "Avalon Airport",
    },
    {
      city: "Awaba",
      code: "AWB",
      name: "Awaba",
    },
    {
      city: "Aniwa",
      code: "AWD",
      name: "Aniwa Airport",
    },
    {
      city: "Ahvaz",
      code: "AWZ",
      name: "Ahvaz Airport",
    },
    {
      city: "Anguilla",
      code: "AXA",
      name: "Wallblake Airport",
    },
    {
      city: "Alexandroupolis",
      code: "AXD",
      name: "Alexandroupolis Airport",
    },
    {
      city: "La Tebaida",
      code: "AXM",
      name: "El Eden Airport",
    },
    {
      city: "Spring Point",
      code: "AXP",
      name: "Spring Point Airport",
    },
    {
      city: "Arutua",
      code: "AXR",
      name: "French Polynesia",
    },
    {
      city: "Akita-shi",
      code: "AXT",
      name: "Akita Airport",
    },
    {
      city: "Axum",
      code: "AXU",
      name: "Axum",
    },
    {
      city: "Ayers Rock",
      code: "AYQ",
      name: "Ayers Rock Airport",
    },
    {
      city: "Antalya",
      code: "AYT",
      name: "Antalya Airport",
    },
    {
      city: "Phoenix",
      code: "AZA",
      name: "Phoenix-Mesa Gateway Airport",
    },
    {
      city: "Yezd",
      code: "AZD",
      name: "Yazd Airport",
    },
    {
      city: "Andijon",
      code: "AZN",
      name: "Andizhan Airport",
    },
    {
      city: "Kalamazoo",
      code: "AZO",
      name: "Kalamazoo-Battle Creek International Airport",
    },
    {
      city: "Adrar",
      code: "AZR",
      name: "Touat Airport",
    },
    {
      city: "Samana",
      code: "AZS",
      name: "Samana El Catey International Airport",
    },
    {
      city: "Baguio City",
      code: "BAG",
      name: "Baguio Airport",
    },
    {
      city: "Al Muharraq",
      code: "BAH",
      name: "Bahrain International Airport",
    },
    {
      city: "Batman",
      code: "BAL",
      name: "Batman Airport",
    },
    {
      city: "Soledad",
      code: "BAQ",
      name: "Ernesto Cortissoz Airport",
    },
    {
      city: "Balalae",
      code: "BAS",
      name: "Balalae",
    },
    {
      city: "Bauru",
      code: "BAU",
      name: "Bauru Airport",
    },
    {
      city: "Baotou",
      code: "BAV",
      name: "Baotou Airport",
    },
    {
      city: "Barnaul",
      code: "BAX",
      name: "Barnaui West Airport",
    },
    {
      city: "Baia Mare",
      code: "BAY",
      name: "Baia Mare",
    },
    {
      city: "Balmaceda",
      code: "BBA",
      name: "Balmaceda Airport",
    },
    {
      city: "Bhubaneswar",
      code: "BBI",
      name: "Bhubaneswar Airport",
    },
    {
      city: "Kasane",
      code: "BBK",
      name: "Kasane Airport",
    },
    {
      city: "Nanga Medamit",
      code: "BBN",
      name: "Bario Airport",
    },
    {
      city: "Berbera",
      code: "BBO",
      name: "Berbera Airport",
    },
    {
      city: "Camberley",
      code: "BBS",
      name: "Blackbushe Airport",
    },
    {
      city: "Bucharest",
      code: "BBU",
      name: "Aeroportul National Bucuresti-Baneasa",
    },
    {
      city: "Baracoa",
      code: "BCA",
      name: "Baracoa Airport",
    },
    {
      city: "Bacolod City",
      code: "BCD",
      name: "Bacolod Airport",
    },
    {
      city: "Barcaldine",
      code: "BCI",
      name: "Barcaldine Aerodrome",
    },
    {
      city: "Colorado",
      code: "BCL",
      name: "Barra Colorado Airport",
    },
    {
      city: "Bacæu",
      code: "BCM",
      name: "Luizi Calugara Airport",
    },
    {
      city: "El Prat de Llobregat",
      code: "BCN",
      name: "Barcelona International Airport",
    },
    {
      city: "St. George",
      code: "BDA",
      name: "Bermuda International Airport",
    },
    {
      city: "Bundaberg",
      code: "BDB",
      name: "Bundaberg Airport",
    },
    {
      city: "Badu Island",
      code: "BDD",
      name: "Badu Island Airport",
    },
    {
      city: "Bandar Abbas",
      code: "BDH",
      name: "Bandar Lengeh Airport",
    },
    {
      city: "Banjarmasin",
      code: "BDJ",
      name: "Syamsuddin Noor Airport",
    },
    {
      city: "Windsor Locks",
      code: "BDL",
      name: "Bradley International Airport",
    },
    {
      city: "Bandung",
      code: "BDO",
      name: "Husein Sastranegara Airport",
    },
    {
      city: "Bhadrapur",
      code: "BDP",
      name: "Bhadrapur",
    },
    {
      city: "Vadodara",
      code: "BDQ",
      name: "Vadodara Airport",
    },
    {
      city: "Stratford",
      code: "BDR",
      name: "Igor I Sikorsky Memorial Airport",
    },
    {
      city: "Brindisi",
      code: "BDS",
      name: "Casale Airport",
    },
    {
      city: "Bardufoss",
      code: "BDU",
      name: "Bardufoss Airport",
    },
    {
      city: "Balivanich",
      code: "BEB",
      name: "Benbecula Airport",
    },
    {
      city: "Surčin",
      code: "BEG",
      name: "Surcin Airport",
    },
    {
      city: "Belem",
      code: "BEL",
      name: "Val de Caes International Airport",
    },
    {
      city: "Benghazi",
      code: "BEN",
      name: "Benina Intl",
    },
    {
      city: "Guipavas",
      code: "BES",
      name: "Guipavas Airport",
    },
    {
      city: "Berlin",
      code: "BER",
      name: "Berlin International Airport",
    },
    {
      city: "Bethel",
      code: "BET",
      name: "Bethel Airport",
    },
    {
      city: "Bedourie",
      code: "BEU",
      name: "Bedourie Aerodrome",
    },
    {
      city: "Beira",
      code: "BEW",
      name: "Beira Airport",
    },
    {
      city: "Beirut",
      code: "BEY",
      name: "Beirut International Airport",
    },
    {
      city: "Lewis Run",
      code: "BFD",
      name: "Bradford Regional Airport",
    },
    {
      city: "Bielefeld",
      code: "BFE",
      name: "Bielefeld",
    },
    {
      city: "Scottsbluff",
      code: "BFF",
      name: "William B Heilig Field Airport",
    },
    {
      city: "Seattle",
      code: "BFI",
      name: "King County International Airport-Boeing Field",
    },
    {
      city: "Bakersfield",
      code: "BFL",
      name: "Kern County-Meadows Field Airport",
    },
    {
      city: "Bloemfontein",
      code: "BFN",
      name: "J B M Hertzog Airport",
    },
    {
      city: "Crumlin",
      code: "BFS",
      name: "Aldergrove Airport",
    },
    {
      city: "Buri Ram",
      code: "BFV",
      name: "Buri Ram",
    },
    {
      city: "Bucaramanga",
      code: "BGA",
      name: "Palonegro Airport",
    },
    {
      city: "Bangui",
      code: "BGF",
      name: "Bangui M Poko Airport",
    },
    {
      city: "Bridgetown",
      code: "BGI",
      name: "Grantley Adams International Airport",
    },
    {
      city: "Johnson City",
      code: "BGM",
      name: "Greater Binghamton Edwin A Link Field Airport",
    },
    {
      city: "Blomsterdalen",
      code: "BGO",
      name: "Bergen Flesland Airport",
    },
    {
      city: "Bangor",
      code: "BGR",
      name: "Bangor International Airport",
    },
    {
      city: "Baghdad",
      code: "BGW",
      name: "Al Muthana Airport",
    },
    {
      city: "Grassobbio",
      code: "BGY",
      name: "Orio Al Serio Airport",
    },
    {
      city: "Ellsworth",
      code: "BHB",
      name: "Hancock County-Bar Harbor Airport",
    },
    {
      city: "Belfast",
      code: "BHD",
      name: "George Best Belfast City Airport",
    },
    {
      city: "Blenheim",
      code: "BHE",
      name: "Woodbourne Airport",
    },
    {
      city: "Brus Laguna",
      code: "BHG",
      name: "Brus Laguna Airport",
    },
    {
      city: "Bisha",
      code: "BHH",
      name: "Bisha Airport",
    },
    {
      city: "Punta Alta",
      code: "BHI",
      name: "Bahia Blanca Cte Espora Naval Air Base",
    },
    {
      city: "Bhuj",
      code: "BHJ",
      name: "Bhuj Airport",
    },
    {
      city: "Bukhara",
      code: "BHK",
      name: "Bukhara Airport",
    },
    {
      city: "Birmingham",
      code: "BHM",
      name: "Birmingham International Airport",
    },
    {
      city: "Bhopal",
      code: "BHO",
      name: "Bairagarh Airport",
    },
    {
      city: "Broken Hill",
      code: "BHQ",
      name: "Broken Hill Airport",
    },
    {
      city: "Bharatpur",
      code: "BHR",
      name: "Bharatpur",
    },
    {
      city: "Bathurst",
      code: "BHS",
      name: "Bathurst Airport",
    },
    {
      city: "Bhavnagar",
      code: "BHU",
      name: "Bhavnagar Airport",
    },
    {
      city: "Bahawalpur",
      code: "BHV",
      name: "Bahawalpur Airport",
    },
    {
      city: "Birmingham",
      code: "BHX",
      name: "Birmingham International Airport",
    },
    {
      city: "Beihai",
      code: "BHY",
      name: "Beihai",
    },
    {
      city: "Borgo",
      code: "BIA",
      name: "Poretta Airport",
    },
    {
      city: "Block Island",
      code: "BID",
      name: "Block Island State Airport",
    },
    {
      city: "Bikini Atoll",
      code: "BII",
      name: "Enyu Airfield",
    },
    {
      city: "Biak",
      code: "BIK",
      name: "Frans Kaisiepo Airport",
    },
    {
      city: "Billings",
      code: "BIL",
      name: "Logan International Airport",
    },
    {
      city: "Bailey's Town",
      code: "BIM",
      name: "South Bimini Airport",
    },
    {
      city: "Loiu",
      code: "BIO",
      name: "Bilbao Airport",
    },
    {
      city: "Anglet",
      code: "BIQ",
      name: "Anglet Airport",
    },
    {
      city: "Biratnagur",
      code: "BIR",
      name: "Biratnagar Airport",
    },
    {
      city: "Bismarck",
      code: "BIS",
      name: "Bismarck Municipal Airport",
    },
    {
      city: "Bejaia",
      code: "BJA",
      name: "Soummam Airport",
    },
    {
      city: "Bojnord",
      code: "BJB",
      name: "Bojnord",
    },
    {
      city: "Baasfjord",
      code: "BJF",
      name: "Batsfjord Airport",
    },
    {
      city: "Bemidji",
      code: "BJI",
      name: "Bemidji-Beltrami County Airport",
    },
    {
      city: "Yundum",
      code: "BJL",
      name: "Yundum International Airport",
    },
    {
      city: "Bujumbura",
      code: "BJM",
      name: "Bujumbura Airport",
    },
    {
      city: "Bahar Dar",
      code: "BJR",
      name: "Bahar Dar Airport",
    },
    {
      city: "Bodrum",
      code: "BJV",
      name: "Milas Airport",
    },
    {
      city: "Ende",
      code: "BJW",
      name: "Bajawa Airport",
    },
    {
      city: "Silao",
      code: "BJX",
      name: "Silao Airport",
    },
    {
      city: "Badajoz",
      code: "BJZ",
      name: "Talavera la Real Airport",
    },
    {
      city: "Lyubertsy",
      code: "BKA",
      name: "Bykovo Airport",
    },
    {
      city: "Buckland",
      code: "BKC",
      name: "Buckland Airport",
    },
    {
      city: "Kota Kinabalu",
      code: "BKI",
      name: "Kota Kinabalu Airport",
    },
    {
      city: "Lak Si",
      code: "BKK",
      name: "Bangkok International Airport",
    },
    {
      city: "Cleveland",
      code: "BKL",
      name: "Burke Lakefront Airport",
    },
    {
      city: "Bakalalan",
      code: "BKM",
      name: "Malaysia",
    },
    {
      city: "Kalaban",
      code: "BKO",
      name: "Bamako Senou Airport",
    },
    {
      city: "Mount Enniskillen",
      code: "BKQ",
      name: "Blackall Aerodrome",
    },
    {
      city: "Bengkulu",
      code: "BKS",
      name: "Padangkemiling Airport",
    },
    {
      city: "Beaver",
      code: "BKW",
      name: "Raleigh County Memorial Airport",
    },
    {
      city: "Bukavu",
      code: "BKY",
      name: "Bukavu Kavumu Airport",
    },
    {
      city: "Pariaguán",
      code: "BLA",
      name: "Jose Antonio Anzoategui Airport",
    },
    {
      city: "Borlange",
      code: "BLE",
      name: "Dala Airport",
    },
    {
      city: "Bellingham",
      code: "BLI",
      name: "Bellingham International Airport",
    },
    {
      city: "Batna",
      code: "BLJ",
      name: "Algeria",
    },
    {
      city: "Blackpool",
      code: "BLK",
      name: "Blackpool Airport",
    },
    {
      city: "Billund",
      code: "BLL",
      name: "Billund Airport",
    },
    {
      city: "Bologna",
      code: "BLQ",
      name: "Bologna Airport",
    },
    {
      city: "Bangalore",
      code: "BLR",
      name: "HAL Bangalore International Airport",
    },
    {
      city: "Baralaba",
      code: "BLT",
      name: "Blackwater Aerodrome",
    },
    {
      city: "Belleville",
      code: "BLV",
      name: "Belleville",
    },
    {
      city: "Lunzu",
      code: "BLZ",
      name: "Chileka International Airport",
    },
    {
      city: "Bromma",
      code: "BMA",
      name: "Bromma Airport",
    },
    {
      city: "Broome",
      code: "BME",
      name: "Broome International Airport",
    },
    {
      city: "Bloomington",
      code: "BMI",
      name: "Bloomington Normal Airport",
    },
    {
      city: "Borkum",
      code: "BMK",
      name: "Borkum Airport",
    },
    {
      city: "Bhamo",
      code: "BMO",
      name: "Bhamo Airport",
    },
    {
      city: "Raba",
      code: "BMU",
      name: "Mohammad Salahuddin Airport",
    },
    {
      city: "Banmethuot",
      code: "BMV",
      name: "Ban Me Thaut",
    },
    {
      city: "Bordj Badji Mokhtar",
      code: "BMW",
      name: "Algeria",
    },
    {
      city: "Belep Island",
      code: "BMY",
      name: "Belep Island",
    },
    {
      city: "Nashville",
      code: "BNA",
      name: "Nashville International Airport",
    },
    {
      city: "Bandar Abbas",
      code: "BND",
      name: "Bandar Abbass International Airport",
    },
    {
      city: "Brisbane",
      code: "BNE",
      name: "Brisbane International Airport",
    },
    {
      city: "Benin City",
      code: "BNI",
      name: "Benin Airport",
    },
    {
      city: "Bonn",
      code: "BNJ",
      name: "Hangelar",
    },
    {
      city: "Ballina",
      code: "BNK",
      name: "Ballina Airport",
    },
    {
      city: "Bronnoysund",
      code: "BNN",
      name: "Bronnoy Airport",
    },
    {
      city: "Barinas",
      code: "BNS",
      name: "Barinas Airport",
    },
    {
      city: "Banja Luka",
      code: "BNX",
      name: "Banja Luka Airport",
    },
    {
      city: "Bellona",
      code: "BNY",
      name: "Bellona Airport",
    },
    {
      city: "Papeete",
      code: "BOB",
      name: "Motu-Mute Airport",
    },
    {
      city: "Bocas del Toro",
      code: "BOC",
      name: "Bocas del Toro Airport",
    },
    {
      city: "Merignac",
      code: "BOD",
      name: "Bordeaux Airport",
    },
    {
      city: "Fontibón",
      code: "BOG",
      name: "Eldorado International Airport",
    },
    {
      city: "Christchurch",
      code: "BOH",
      name: "Bournemouth International Airport",
    },
    {
      city: "Boise",
      code: "BOI",
      name: "Boise Air Terminal",
    },
    {
      city: "Burgas",
      code: "BOJ",
      name: "Bourgas Airport",
    },
    {
      city: "Mumbai",
      code: "BOM",
      name: "Chhatrapati Shivaji International Airport",
    },
    {
      city: "Kralendijk",
      code: "BON",
      name: "Flamingo Airport",
    },
    {
      city: "Bodo",
      code: "BOO",
      name: "Bodo Airport",
    },
    {
      city: "Boston",
      code: "BOS",
      name: "Gen E L Logan International Airport",
    },
    {
      city: "Bartow",
      code: "BOW",
      name: "Bartow Municipal Airport",
    },
    {
      city: "Bobo-Dioulasso",
      code: "BOY",
      name: "Bobo Dioulasso Airport",
    },
    {
      city: "Balikpapan",
      code: "BPN",
      name: "Sepinggan Airport",
    },
    {
      city: "Porto Seguro",
      code: "BPS",
      name: "Porto Seguro Airport",
    },
    {
      city: "Beaumont",
      code: "BPT",
      name: "Jefferson County Airport",
    },
    {
      city: "Qamdo",
      code: "BPX",
      name: "Bangda Airport",
    },
    {
      city: "Besalampy",
      code: "BPY",
      name: "Besalampy Airport",
    },
    {
      city: "Brunswick",
      code: "BQK",
      name: "Glynco Jetport Airport",
    },
    {
      city: "Boulia",
      code: "BQL",
      name: "Boulia Aerodrome",
    },
    {
      city: "Aguadilla",
      code: "BQN",
      name: "Rafael Hernandez Airport",
    },
    {
      city: "Blagoveshchensk",
      code: "BQS",
      name: "Blagoveshchensk Northwest Airport",
    },
    {
      city: "Barreiras",
      code: "BRA",
      name: "Barreiras Airport",
    },
    {
      city: "San Carlos DeBariloche",
      code: "BRC",
      name: "San Carlos de Bariloche Airport",
    },
    {
      city: "Brainerd",
      code: "BRD",
      name: "Brainerd-Crow Wing County Regional Airport",
    },
    {
      city: "Bremen",
      code: "BRE",
      name: "Bremen Airport",
    },
    {
      city: "Bradford",
      code: "BRF",
      name: "United Kingdom",
    },
    {
      city: "Bari",
      code: "BRI",
      name: "Palese Macchie Airport",
    },
    {
      city: "Bourke",
      code: "BRK",
      name: "Bourke Airport",
    },
    {
      city: "Burlington",
      code: "BRL",
      name: "Burlington Municipal Airport",
    },
    {
      city: "Barquisimeto",
      code: "BRM",
      name: "Barquisimeto Airport",
    },
    {
      city: "Belp",
      code: "BRN",
      name: "Bern Belp Airport",
    },
    {
      city: "Brownsville",
      code: "BRO",
      name: "Brownsville-South Padre Island International Air",
    },
    {
      city: "Brno",
      code: "BRQ",
      name: "Turany Airport",
    },
    {
      city: "Barra",
      code: "BRR",
      name: "North Bay Airport",
    },
    {
      city: "Bristol",
      code: "BRS",
      name: "Bristol International Airport",
    },
    {
      city: "Bruxelles",
      code: "BRU",
      name: "Brussels Airport",
    },
    {
      city: "Bad Bederkesa",
      code: "BRV",
      name: "Bremerhaven Airport",
    },
    {
      city: "Barrow",
      code: "BRW",
      name: "Wiley Post Will Rogers Memorial Airport",
    },
    {
      city: "Bossaso",
      code: "BSA",
      name: "Somalia",
    },
    {
      city: "Lago Sul",
      code: "BSB",
      name: "Juscelino Kubitschek International Airport",
    },
    {
      city: "Bahía Solano",
      code: "BSC",
      name: "Jose Celestino Mutis Airport",
    },
    {
      city: "Baoshan",
      code: "BSD",
      name: "Baoshan Airport",
    },
    {
      city: "Bata",
      code: "BSG",
      name: "Bata Airport",
    },
    {
      city: "Brighton",
      code: "BSH",
      name: "Brighton Airport",
    },
    {
      city: "Biskra",
      code: "BSK",
      name: "Biskra Airport",
    },
    {
      city: "St-Ludwig",
      code: "BSL",
      name: "Euroairport Basel-Mulhouse-Freiburg",
    },
    {
      city: "Basco",
      code: "BSO",
      name: "Basco Airport",
    },
    {
      city: "Gawad",
      code: "BSR",
      name: "Basrah International Airport",
    },
    {
      city: "Bassein",
      code: "BSX",
      name: "Bassein",
    },
    {
      city: "Batam",
      code: "BTH",
      name: "Hang Nadim Airport",
    },
    {
      city: "Kaktovik",
      code: "BTI",
      name: "Barter Island Airport",
    },
    {
      city: "Banda Aceh",
      code: "BTJ",
      name: "Blangbintang Airport",
    },
    {
      city: "Bratsk",
      code: "BTK",
      name: "Bratsk",
    },
    {
      city: "Battle Creek",
      code: "BTL",
      name: "W K Kellogg Airport",
    },
    {
      city: "Butte",
      code: "BTM",
      name: "Bert Mooney Airport",
    },
    {
      city: "Baton Rouge",
      code: "BTR",
      name: "Baton Rouge Metropolitan Airport",
    },
    {
      city: "Bratislava",
      code: "BTS",
      name: "Bratislava Airport",
    },
    {
      city: "Bettles",
      code: "BTT",
      name: "Bettles Airport",
    },
    {
      city: "Bintulu",
      code: "BTU",
      name: "Bintulu Airport",
    },
    {
      city: "South Burlington",
      code: "BTV",
      name: "Burlington International Airport",
    },
    {
      city: "Bursa",
      code: "BTZ",
      name: "Bursa Airport",
    },
    {
      city: "Buka",
      code: "BUA",
      name: "Buka",
    },
    {
      city: "Burketown",
      code: "BUC",
      name: "Burketown Aerodrome",
    },
    {
      city: "Budapest",
      code: "BUD",
      name: "Ferihegy Airport",
    },
    {
      city: "Buffalo",
      code: "BUF",
      name: "Greater Buffalo International Airport",
    },
    {
      city: "Bulolo",
      code: "BUL",
      name: "Bulolo",
    },
    {
      city: "Buenaventura",
      code: "BUN",
      name: "Buenaventura Airport",
    },
    {
      city: "Burao",
      code: "BUO",
      name: "Burao",
    },
    {
      city: "Bulawayo",
      code: "BUQ",
      name: "Bulawayo Airport",
    },
    {
      city: "Burbank",
      code: "BUR",
      name: "Burbank Glendale Pasadena Airport",
    },
    {
      city: "Batumi",
      code: "BUS",
      name: "Batumi",
    },
    {
      city: "Bunia",
      code: "BUX",
      name: "Bunia Airport",
    },
    {
      city: "Bandar-e Bushehr",
      code: "BUZ",
      name: "Bushehr Airport",
    },
    {
      city: "Beauvais",
      code: "BVA",
      name: "Beauvais-Tille Airport",
    },
    {
      city: "Boa Vista",
      code: "BVB",
      name: "Boa Vista International Airport",
    },
    {
      city: "Boa Vista",
      code: "BVC",
      name: "Boa Vista Airport",
    },
    {
      city: "Brive-la-Gaillarde",
      code: "BVE",
      name: "La Roche Airport",
    },
    {
      city: "Berlevaag",
      code: "BVG",
      name: "Berlevag Airport",
    },
    {
      city: "Vilhena",
      code: "BVH",
      name: "Brigadeiro Camarao Airport",
    },
    {
      city: "Birdsville",
      code: "BVI",
      name: "Birdsville Airport",
    },
    {
      city: "Bhairawa",
      code: "BWA",
      name: "Bhairawa Airport",
    },
    {
      city: "Braunschweig",
      code: "BWE",
      name: "Braunschweig Airport",
    },
    {
      city: "Barrow in Furness",
      code: "BWF",
      name: "Walney Island Airport",
    },
    {
      city: "Baltimore",
      code: "BWI",
      name: "Baltimore-Washington International Thurgood Mars",
    },
    {
      city: "Bol",
      code: "BWK",
      name: "Bol",
    },
    {
      city: "Bandar Seri Begawan",
      code: "BWN",
      name: "Brunei International Airport",
    },
    {
      city: "Doctors Rocks",
      code: "BWT",
      name: "Burnie Wynyard Airport",
    },
    {
      city: "Santa Clara",
      code: "BWW",
      name: "Santa Clara Airport",
    },
    {
      city: "Bam",
      code: "BXR",
      name: "",
    },
    {
      city: "Buenavista",
      code: "BXU",
      name: "Butuan Airport",
    },
    {
      city: "Bayamo",
      code: "BYM",
      name: "Bayamo Airport",
    },
    {
      city: "Bayankhongor",
      code: "BYN",
      name: "",
    },
    {
      city: "Hattieville",
      code: "BZE",
      name: "Philip S W Goldson International Airport",
    },
    {
      city: "Białe Błota",
      code: "BZG",
      name: "Szwederowo Airport",
    },
    {
      city: "Briansk",
      code: "BZK",
      name: "Briansk",
    },
    {
      city: "Barisal",
      code: "BZL",
      name: "",
    },
    {
      city: "Bozeman",
      code: "BZN",
      name: "Gallatin Field Airport",
    },
    {
      city: "Botzen",
      code: "BZO",
      name: "Bolzano Airport",
    },
    {
      city: "Villeneuve",
      code: "BZR",
      name: "Vias Airport",
    },
    {
      city: "Brazzaville",
      code: "BZV",
      name: "Brazzaville Maya Maya Airport",
    },
    {
      city: "Carterton",
      code: "BZZ",
      name: "Brize Norton Airport",
    },
    {
      city: "Cabinda",
      code: "CAB",
      name: "Cabinda Airport",
    },
    {
      city: "Cascavel",
      code: "CAC",
      name: "Cascavel Airport",
    },
    {
      city: "West Columbia",
      code: "CAE",
      name: "Columbia Metropolitan Airport",
    },
    {
      city: "Sardara",
      code: "CAG",
      name: "Elmas Airport",
    },
    {
      city: "Ca Mau",
      code: "CAH",
      name: "Camo",
    },
    {
      city: "Cairo",
      code: "CAI",
      name: "Cairo International Airport",
    },
    {
      city: "Canton",
      code: "CAK",
      name: "Akron Canton Regional Airport",
    },
    {
      city: "Campbeltown",
      code: "CAL",
      name: "Campbeltown Airport",
    },
    {
      city: "Guangzhou",
      code: "CAN",
      name: "Baiyun Airport",
    },
    {
      city: "Cap-Haïtien",
      code: "CAP",
      name: "Cap Haitien Airport",
    },
    {
      city: "Carlisle",
      code: "CAX",
      name: "Carlisle Airport",
    },
    {
      city: "Cayenne",
      code: "CAY",
      name: "Rochambeau",
    },
    {
      city: "Canbelego",
      code: "CAZ",
      name: "Cobar Airport",
    },
    {
      city: "Cochabamba",
      code: "CBB",
      name: "Jorge Wilsterman Airport",
    },
    {
      city: "Cambridge",
      code: "CBG",
      name: "Cambridge Airport",
    },
    {
      city: "Bechar",
      code: "CBH",
      name: "Bechar Airport",
    },
    {
      city: "Sultan Kudarat",
      code: "CBO",
      name: "Cotabato Airport",
    },
    {
      city: "Duke Town",
      code: "CBQ",
      name: "Calabar Airport",
    },
    {
      city: "Canberra",
      code: "CBR",
      name: "Canberra International Airport",
    },
    {
      city: "Catumbela",
      code: "CBT",
      name: "Angola",
    },
    {
      city: "Cayo Coco",
      code: "CCC",
      name: "Cuba",
    },
    {
      city: "Carcassonne",
      code: "CCF",
      name: "Salvaza Airport",
    },
    {
      city: "Pallikkal",
      code: "CCJ",
      name: "Kozhikode Airport",
    },
    {
      city: "West Island",
      code: "CCK",
      name: "Cocos Airport",
    },
    {
      city: "Forquilhinha",
      code: "CCM",
      name: "Criciuma Airport",
    },
    {
      city: "Hualpencillo",
      code: "CCP",
      name: "Carriel Sur International Airport",
    },
    {
      city: "Catia la Mar",
      code: "CCS",
      name: "Simon Bolivar International Airport",
    },
    {
      city: "Kolkata",
      code: "CCU",
      name: "Netaji Subhash Chandra Bose International Airpor",
    },
    {
      city: "Craig Cove",
      code: "CCV",
      name: "Vanuatu",
    },
    {
      city: "Chub Cay",
      code: "CCZ",
      name: "Chub Cay Airport",
    },
    {
      city: "Cold Bay",
      code: "CDB",
      name: "Cold Bay Airport",
    },
    {
      city: "Cedar City",
      code: "CDC",
      name: "Cedar City Municipal Airport",
    },
    {
      city: "Le Mesnil-Amelot",
      code: "CDG",
      name: "Charles de Gaulle International Airport",
    },
    {
      city: "Chadron",
      code: "CDR",
      name: "Chadron Municipal Airport",
    },
    {
      city: "Cordova",
      code: "CDV",
      name: "Merle K Mudhole Smith Airport",
    },
    {
      city: "Fairfield",
      code: "CDW",
      name: "Essex County Airport",
    },
    {
      city: "Cebu",
      code: "CEB",
      name: "Lahug Airport",
    },
    {
      city: "Crescent City",
      code: "CEC",
      name: "Jack Mcnamara Field Airport",
    },
    {
      city: "Ceduna",
      code: "CED",
      name: "Ceduna Airport",
    },
    {
      city: "Cherepovets",
      code: "CEE",
      name: "Cherepovets Airport",
    },
    {
      city: "Chester",
      code: "CEG",
      name: "Hawarden Airport",
    },
    {
      city: "Chiang Rai",
      code: "CEI",
      name: "Chiang Rai Airport",
    },
    {
      city: "Zlatoust",
      code: "CEK",
      name: "Chelyabinsk Balandino Airport",
    },
    {
      city: "Circle",
      code: "CEM",
      name: "Central Airport",
    },
    {
      city: "Ciudad Obregón",
      code: "CEN",
      name: "Ciudad Obregon Airport",
    },
    {
      city: "Cortez",
      code: "CEZ",
      name: "Cortez-Montezuma County Airport",
    },
    {
      city: "Caçador",
      code: "CFC",
      name: "Cacador Airport",
    },
    {
      city: "Aulnat",
      code: "CFE",
      name: "Aulnat Airport",
    },
    {
      city: "Chlef",
      code: "CFK",
      name: "Abou Bakr Belkaid",
    },
    {
      city: "Mullaghduff",
      code: "CFN",
      name: "Carrickfin Airport",
    },
    {
      city: "Carpiquet",
      code: "CFR",
      name: "Carpiquet Airport",
    },
    {
      city: "Coffs Harbour",
      code: "CFS",
      name: "Coffs Harbour Airport",
    },
    {
      city: "Corcyra",
      code: "CFU",
      name: "Kerkira Airport",
    },
    {
      city: "Craig",
      code: "CGA",
      name: "Craig Seaplane Base",
    },
    {
      city: "Cuiaba",
      code: "CGB",
      name: "Marechal Rondon International Airport",
    },
    {
      city: "Changde",
      code: "CGD",
      name: "Changde Airport",
    },
    {
      city: "Sao Paulo",
      code: "CGH",
      name: "Congonhas International Airport",
    },
    {
      city: "Scott City",
      code: "CGI",
      name: "Cape Girardeau Municipal Airport",
    },
    {
      city: "Tangerang",
      code: "CGK",
      name: "Jakarta International Airport",
    },
    {
      city: "Camiguin",
      code: "CGM",
      name: "Philippines",
    },
    {
      city: "Cologne",
      code: "CGN",
      name: "Cologne Bonn Airport",
    },
    {
      city: "Zhengzhou",
      code: "CGO",
      name: "Zhengzhou Airport",
    },
    {
      city: "Chittagong",
      code: "CGP",
      name: "Chittagong Airport",
    },
    {
      city: "Changchun",
      code: "CGQ",
      name: "Dafang Shen Airport",
    },
    {
      city: "Campo Grande",
      code: "CGR",
      name: "Campo Grande International Airport",
    },
    {
      city: "Cagayan de Oro",
      code: "CGY",
      name: "Cagayan de Oro Airport",
    },
    {
      city: "Chattanooga",
      code: "CHA",
      name: "Chattanooga Metropolitan Airport",
    },
    {
      city: "Christchurch",
      code: "CHC",
      name: "Christchurch International Airport",
    },
    {
      city: "Earlysville",
      code: "CHO",
      name: "Charlottesville Albemarle Airport",
    },
    {
      city: "Canea",
      code: "CHQ",
      name: "Souda Airport",
    },
    {
      city: "North Charleston",
      code: "CHS",
      name: "Charleston International Airport",
    },
    {
      city: "Chatham Island",
      code: "CHT",
      name: "Karewa",
    },
    {
      city: "Chuathbaluk",
      code: "CHU",
      name: "Chuathbaluk",
    },
    {
      city: "Choiseul Bay",
      code: "CHY",
      name: "Choiseul Bay",
    },
    // {
    //   city: "Rome",
    //   code: "CIA",
    //   name: "Ciampino Airport",
    // },

    {
      city: "Chico",
      code: "CIC",
      name: "Chico Municipal Airport",
    },
    {
      city: "Cedar Rapids",
      code: "CID",
      name: "Cedar Rapids Municipal Airport",
    },
    {
      city: "Chifeng",
      code: "CIF",
      name: "Chifeng",
    },
    {
      city: "Changzhi",
      code: "CIH",
      name: "Changzhi",
    },
    {
      city: "Cobija",
      code: "CIJ",
      name: "E. Beltram Airport",
    },
    {
      city: "Chalkyitsik",
      code: "CIK",
      name: "Chalkyitsik Airport",
    },
    {
      city: "Chipata",
      code: "CIP",
      name: "",
    },
    {
      city: "Shimkent",
      code: "CIT",
      name: "",
    },
    {
      city: "Kincheloe",
      code: "CIU",
      name: "Chippewa County International Airport",
    },
    {
      city: "Canouan Island",
      code: "CIW",
      name: "Canouan Airport",
    },
    {
      city: "Chiclayo",
      code: "CIX",
      name: "Cap J A Quinones Gonzales Airport",
    },
    {
      city: "Cajamarca",
      code: "CJA",
      name: "Maj Gen Fap A R Iglesias Airport",
    },
    {
      city: "Coimbatore",
      code: "CJB",
      name: "Peelamedu Airport",
    },
    {
      city: "Calama",
      code: "CJC",
      name: "El Loa Airport",
    },
    {
      city: "Naesu-Eup",
      code: "CJJ",
      name: "Cheongju International Airport",
    },
    {
      city: "Chitral",
      code: "CJL",
      name: "Chitral Airport",
    },
    {
      city: "Ciudad Juarez",
      code: "CJS",
      name: "Ciudad Juarez International Airport",
    },
    {
      city: "Jeju-Si",
      code: "CJU",
      name: "Cheju International Airport",
    },
    {
      city: "Clarksburg",
      code: "CKB",
      name: "Benedum Airport",
    },
    {
      city: "Aniak",
      code: "CKD",
      name: "Crooked Creek Airport",
    },
    {
      city: "Chongqing",
      code: "CKG",
      name: "Chongqing Jiangbei International",
    },
    {
      city: "Chokurdah",
      code: "CKH",
      name: "Russia",
    },
    {
      city: "Carajas",
      code: "CKS",
      name: "Carajas Airport",
    },
    {
      city: "Tok",
      code: "CKX",
      name: "Chicken Airport",
    },
    {
      city: "Conakry",
      code: "CKY",
      name: "Conakry Airport",
    },
    {
      city: "Canakkale",
      code: "CKZ",
      name: "Abydus",
    },
    {
      city: "Carlsbad",
      code: "CLD",
      name: "Mcclellan Palomar Airport",
    },
    {
      city: "Cleveland",
      code: "CLE",
      name: "Hopkins International Airport",
    },
    {
      city: "Clausemburgo",
      code: "CLJ",
      name: "Someseni Airport",
    },
    {
      city: "College Station",
      code: "CLL",
      name: "Easterwood Field Airport",
    },
    {
      city: "Port Angeles",
      code: "CLM",
      name: "William R Fairchild International Airport",
    },
    {
      city: "Obando",
      code: "CLO",
      name: "Alfonso Bonilla Aragon International Airport",
    },
    {
      city: "Clarks Point",
      code: "CLP",
      name: "Clarks Point Airport",
    },
    {
      city: "Cuauhtémoc",
      code: "CLQ",
      name: "Colima Airport",
    },
    {
      city: "Charlotte",
      code: "CLT",
      name: "Douglas International Airport",
    },
    {
      city: "Calenzana",
      code: "CLY",
      name: "Ste Catherine Airport",
    },
    {
      city: "Cunnamulla",
      code: "CMA",
      name: "Cunnamulla",
    },
    {
      city: "Gampaha",
      code: "CMB",
      name: "Katunayake International Airport",
    },
    {
      city: "Carmen Olorón",
      code: "CME",
      name: "Ciudad del Carmen Airport",
    },
    {
      city: "La Motte",
      code: "CMF",
      name: "Aix les Bains Airport",
    },
    {
      city: "Corumba",
      code: "CMG",
      name: "Corumba International Airport",
    },
    {
      city: "Columbus",
      code: "CMH",
      name: "Port Columbus International Airport",
    },
    {
      city: "Savoy",
      code: "CMI",
      name: "University of Illinois-Willard Airport",
    },
    {
      city: "Casablanca",
      code: "CMN",
      name: "Mohamed V Airport",
    },
    {
      city: "Kundiawa",
      code: "CMU",
      name: "Chimbu Airport",
    },
    {
      city: "Camagüey",
      code: "CMW",
      name: "Ignacio Agramonte Airport",
    },
    {
      city: "Hancock",
      code: "CMX",
      name: "Houghton County Memorial Airport",
    },
    {
      city: "Coonamble",
      code: "CNB",
      name: "Coonamble Airport",
    },
    {
      city: "Coconut Island",
      code: "CNC",
      name: "Australia",
    },
    {
      city: "Kustenje",
      code: "CND",
      name: "Constanta Mihail Kogalniceanu Airport",
    },
    {
      city: "Confins",
      code: "CNF",
      name: "Tancredo Neves International Airport",
    },
    {
      city: "Cloncurry",
      code: "CNJ",
      name: "Cloncurry Aerodrome",
    },
    {
      city: "Carlsbad",
      code: "CNM",
      name: "Cavern City Air Terminal Airport",
    },
    {
      city: "Neerlerit Inaat",
      code: "CNP",
      name: "Neerlerit Inaat",
    },
    {
      city: "Corrientes",
      code: "CNQ",
      name: "Corrientes Airport",
    },
    {
      city: "Cairns",
      code: "CNS",
      name: "Cairns International Airport",
    },
    {
      city: "Chiang Mai",
      code: "CNX",
      name: "Chiang Mai International Airport",
    },
    {
      city: "Thompson",
      code: "CNY",
      name: "Canyonlands Field Airport",
    },
    {
      city: "Cody/Yellowstone",
      code: "COD",
      name: "Yellowstone Regional Airport",
    },
    {
      city: "Kochi",
      code: "COK",
      name: "Kochi Airport",
    },
    {
      city: "Isle of coll",
      code: "COL",
      name: "Coll Island Airport",
    },
    {
      city: "Cotonou",
      code: "COO",
      name: "Cotonou Cadjehon Airport",
    },
    {
      city: "Choibalsan",
      code: "COQ",
      name: "Choybalsan Northeast Airport",
    },
    {
      city: "Cordoba",
      code: "COR",
      name: "Ingeniero Ambrosio L.V. Taravella International ",
    },
    {
      city: "Colorado Springs",
      code: "COS",
      name: "City of Colorado Springs Municipal Airport",
    },
    {
      city: "Columbia",
      code: "COU",
      name: "Columbia Regional Airport",
    },
    {
      city: "San Martin DeLos Andes",
      code: "CPC",
      name: "Chapelco Airport",
    },
    {
      city: "Coober Pedy",
      code: "CPD",
      name: "Coober Pedy Aerodrome",
    },
    {
      city: "Campeche",
      code: "CPE",
      name: "Ignacio Alberto Acuna Ongay Airport",
    },
    {
      city: "Kastrup",
      code: "CPH",
      name: "Copenhagen Airport",
    },
    {
      city: "Copiapó",
      code: "CPO",
      name: "Chamonate Airport",
    },
    {
      city: "Campinas",
      code: "CPQ",
      name: "Campinas International Airport",
    },
    {
      city: "Casper",
      code: "CPR",
      name: "Natrona County International Airport",
    },
    {
      city: "Cape Town",
      code: "CPT",
      name: "D F Malan Airport",
    },
    {
      city: "Campina Grande",
      code: "CPV",
      name: "Presidente Joao Suassuna Airport",
    },
    {
      city: "Culebra",
      code: "CPX",
      name: "Culebra Airport",
    },
    {
      city: "Shahre-Kord",
      code: "CQD",
      name: "Shahre-Kord",
    },
    {
      city: "Croiova",
      code: "CRA",
      name: "Craiova Airport",
    },
    {
      city: "Comodoro Rivadavia",
      code: "CRD",
      name: "General Enrique Mosconi Airport",
    },
    {
      city: "Colonel Hill",
      code: "CRI",
      name: "Colonel Hill Airport",
    },
    {
      city: "Mabalacat",
      code: "CRK",
      name: "Clark Field Airport",
    },
    {
      city: "Charleroi",
      code: "CRL",
      name: "Gosselies Airport",
    },
    {
      city: "Catarman",
      code: "CRM",
      name: "Catarman Airport",
    },
    {
      city: "Corpus Christi",
      code: "CRP",
      name: "Corpus Christi International Airport",
    },
    {
      city: "Charleston",
      code: "CRW",
      name: "Yeager Airport",
    },
    {
      city: "Isle Of Colonsay",
      code: "CSA",
      name: "Isle Of Colonsay",
    },
    {
      city: "Columbus",
      code: "CSG",
      name: "Columbus Metropolitan Airport",
    },
    {
      city: "Solovetsky",
      code: "CSH",
      name: "Solovky",
    },
    {
      city: "Cap Skirring",
      code: "CSK",
      name: "Cap Skiring Airport",
    },
    {
      city: "Changsha",
      code: "CSX",
      name: "Huanghua Airport",
    },
    {
      city: "Cheboksary",
      code: "CSY",
      name: "Russia",
    },
    {
      city: "Catania",
      code: "CTA",
      name: "Catania Fontanarossa Airport",
    },
    {
      city: "San Isidro",
      code: "CTC",
      name: "Catamarca Airport",
    },
    {
      city: "La Boquilla",
      code: "CTG",
      name: "Rafael Nunez Airport",
    },
    {
      city: "Charleville",
      code: "CTL",
      name: "Charleville Aerodrome",
    },
    {
      city: "Chetumal",
      code: "CTM",
      name: "Chetumal International Airport",
    },
    {
      city: "Chitose-shi",
      code: "CTS",
      name: "New Chitose Airport",
    },
    {
      city: "Chengdu",
      code: "CTU",
      name: "Chengdushuang Liu Airport",
    },
    {
      city: "Cúcuta",
      code: "CUC",
      name: "Camilo Daza Airport",
    },
    {
      city: "Cuenca",
      code: "CUE",
      name: "Mariscal Lamar Airport",
    },
    {
      city: "Fossano",
      code: "CUF",
      name: "Levaldigi Airport",
    },
    {
      city: "Caye Caulker",
      code: "CUK",
      name: "Belize",
    },
    {
      city: "Culiacan",
      code: "CUL",
      name: "Culiacan Airport",
    },
    {
      city: "Cumaná",
      code: "CUM",
      name: "Antonio Jose de Sucre Airport",
    },
    {
      city: "Cancun",
      code: "CUN",
      name: "Cancun Airport",
    },
    {
      city: "El Pilar",
      code: "CUP",
      name: "Gen Jose Francisco Bermudez Airport",
    },
    {
      city: "Curacao",
      code: "CUR",
      name: "Hato Airport",
    },
    {
      city: "Chihuahua",
      code: "CUU",
      name: "General R F Villalobos International Airport",
    },
    {
      city: "San Sebastián",
      code: "CUZ",
      name: "Velazco Astete Airport",
    },
    {
      city: "Hebron",
      code: "CVG",
      name: "Greater Cincinnati International Airport",
    },
    {
      city: "Güémez",
      code: "CVM",
      name: "Ciudad Victoria Airport",
    },
    {
      city: "Texico",
      code: "CVN",
      name: "Clovis Municipal Airport",
    },
    {
      city: "Carnarvon",
      code: "CVQ",
      name: "Carnarvon Airport",
    },
    {
      city: "Coventry",
      code: "CVT",
      name: "Coventry Airport",
    },
    {
      city: "Corvo",
      code: "CVU",
      name: "Corvo Island Airport",
    },
    {
      city: "Mosinee",
      code: "CWA",
      name: "Central Wisconsin Airport",
    },
    {
      city: "Sao Jose dos Pinhais",
      code: "CWB",
      name: "Afonso Pena International Airport",
    },
    {
      city: "Chernivtsi",
      code: "CWC",
      name: "Chernovtsy Airport",
    },
    {
      city: "Barry",
      code: "CWL",
      name: "Cardiff International Airport",
    },
    {
      city: "Cox's Bazar",
      code: "CXB",
      name: "Coxs Bazar Airport",
    },
    {
      city: "Vancouver",
      code: "CXH",
      name: "Coal Harbour Airport",
    },
    {
      city: "Christmas Island",
      code: "CXI",
      name: "Christmas Island Airport",
    },
    {
      city: "Caxias do Sul",
      code: "CXJ",
      name: "Campo dos Bugres Airport",
    },
    {
      city: "Nha Trang",
      code: "CXR",
      name: "Nha-Trang Airport",
    },
    {
      city: "Cayman Brac Is",
      code: "CYB",
      name: "Gerrard Smith Airport",
    },
    {
      city: "Chefornak",
      code: "CYF",
      name: "Chefornak Airport",
    },
    {
      city: "Chiayi City",
      code: "CYI",
      name: "Chaiyi Airport",
    },
    {
      city: "Cayo Largo Del Sur",
      code: "CYO",
      name: "Cayo Largo del sur Airport",
    },
    {
      city: "Calbayog",
      code: "CYP",
      name: "Calbayog Airport",
    },
    {
      city: "Cheyenne",
      code: "CYS",
      name: "Cheyenne Airport",
    },
    {
      city: "Cuyo",
      code: "CYU",
      name: "Cuyo",
    },
    {
      city: "Cherskiy",
      code: "CYX",
      name: "Cherskiy",
    },
    {
      city: "Cauayan",
      code: "CYZ",
      name: "Cauayan Airport",
    },
    {
      city: "La Vela de Coro",
      code: "CZE",
      name: "Jose Leonardo Chirinos Airport",
    },
    {
      city: "Corozal",
      code: "CZH",
      name: "Corozal Airport",
    },
    {
      city: "Constantine",
      code: "CZL",
      name: "Ain El Bey Airport",
    },
    {
      city: "San Miguel de Cozumel",
      code: "CZM",
      name: "Cozumel International Airport",
    },
    {
      city: "Gakona",
      code: "CZN",
      name: "Chisana Airport",
    },
    {
      city: "Cruzeiro Do Sul",
      code: "CZS",
      name: "Cruzeiro do Sul International Airport",
    },
    {
      city: "Morroa",
      code: "CZU",
      name: "Las Brujas Airport",
    },
    {
      city: "Changzhou",
      code: "CZX",
      name: "Changzhou Airport",
    },
    {
      city: "Daytona Beach",
      code: "DAB",
      name: "Daytona Beach International Airport",
    },
    {
      city: "Dhaka",
      code: "DAC",
      name: "Zia International Airport Dhaka",
    },
    {
      city: "Tourane",
      code: "DAD",
      name: "Da Nang Airport",
    },
    {
      city: "Dallas",
      code: "DAL",
      name: "Dallas Love Field Airport",
    },
    {
      city: "Damascus",
      code: "DAM",
      name: "Damascus International Airport",
    },
    {
      city: "Dar es Salaam",
      code: "DAR",
      name: "Dar Es Salaam Airport",
    },
    {
      city: "Datong",
      code: "DAT",
      name: "",
    },
    {
      city: "Daru",
      code: "DAU",
      name: "Daru Airport",
    },
    {
      city: "Daxian",
      code: "DAX",
      name: "Daxian Airport",
    },
    {
      city: "Dayton",
      code: "DAY",
      name: "James M Cox Dayton International Airport",
    },
    {
      city: "Dalbandin",
      code: "DBA",
      name: "Pakistan",
    },
    {
      city: "Dubbo",
      code: "DBO",
      name: "Dubbo Airport",
    },
    {
      city: "Dubuque",
      code: "DBQ",
      name: "Dubuque Regional Airport",
    },
    {
      city: "Dubrovnik",
      code: "DBV",
      name: "Dubrovnik Airport",
    },
    {
      city: "Arlington",
      code: "DCA",
      name: "Washington National Airport",
    },
    {
      city: "Portsmouth",
      code: "DCF",
      name: "Cane Field Airport",
    },
    {
      city: "Labruguière",
      code: "DCM",
      name: "Mazamet Airport",
    },
    {
      city: "Dodge City",
      code: "DDC",
      name: "Dodge City Regional Airport",
    },
    {
      city: "Dandong",
      code: "DDG",
      name: "Dandong Airport",
    },
    {
      city: "Dera Ghazi Khan",
      code: "DEA",
      name: "",
    },
    {
      city: "Decatur",
      code: "DEC",
      name: "Decatur Airport",
    },
    {
      city: "Dehra Dun",
      code: "DED",
      name: "Dehra Dun",
    },
    {
      city: "Dembidolo",
      code: "DEM",
      name: "Dembidolo Airport",
    },
    {
      city: "Dezful",
      code: "DEF",
      name: "Dezful Airport",
    },
    {
      city: "New Delhi",
      code: "DEL",
      name: "Indira Gandhi International Airport",
    },
    {
      city: "Denver",
      code: "DEN",
      name: "Denver International Airport",
    },
    {
      city: "Dayr az Zawr",
      code: "DEZ",
      name: "Deir Zzor Airport",
    },
    {
      city: "Dallas",
      code: "DFW",
      name: "Fort Worth International Airport",
    },
    {
      city: "Dangriga",
      code: "DGA",
      name: "Dangriga Airport",
    },
    {
      city: "Apple Tree Flat",
      code: "DGE",
      name: "Mudgee Aerodrome",
    },
    {
      city: "Dongguan",
      code: "DGM",
      name: "Dongguan",
    },
    {
      city: "Durango",
      code: "DGO",
      name: "Durango Airport",
    },
    {
      city: "Sibulan",
      code: "DGT",
      name: "Dumaguete Airport",
    },
    {
      city: "Dharamsala",
      code: "DHM",
      name: "Gaggal Airport",
    },
    {
      city: "Dothan",
      code: "DHN",
      name: "Dothan Airport",
    },
    {
      city: "Dikhari",
      code: "DIB",
      name: "Mohanbari Airport",
    },
    {
      city: "Antseranana",
      code: "DIE",
      name: "Antsiranana Arrachart Airport",
    },
    {
      city: "Diqing",
      code: "DIG",
      name: "Diqing",
    },
    {
      city: "Dickinson",
      code: "DIK",
      name: "Dickinson Municipal Airport",
    },
    {
      city: "Dili",
      code: "DIL",
      name: "Comoro",
    },
    {
      city: "Dien Bien Phu",
      code: "DIN",
      name: "Dien Bien",
    },
    {
      city: "Dire Dawa",
      code: "DIR",
      name: "Aba Tenna Dejazmatch Yilma Airport",
    },
    {
      city: "Loubomo",
      code: "DIS",
      name: "Loubomo",
    },
    {
      city: "Diu",
      code: "DIU",
      name: "Diu Airport",
    },
    {
      city: "Diyaribakir",
      code: "DIY",
      name: "Diyarbakir Airport",
    },
    {
      city: "Jambi",
      code: "DJB",
      name: "Sultan Taha Airport",
    },
    {
      city: "Hawmat as Suq",
      code: "DJE",
      name: "Zarzis Airport",
    },
    {
      city: "Illizi",
      code: "DJG",
      name: "Tiska Airport",
    },
    {
      city: "Jayapura",
      code: "DJJ",
      name: "Sentani Airport",
    },
    {
      city: "Ngor",
      code: "DKR",
      name: "Dakar Yoff Airport",
    },
    {
      city: "Douala",
      code: "DLA",
      name: "Douala Airport",
    },
    {
      city: "Dalian",
      code: "DLC",
      name: "Chou Shui Tzu Airport",
    },
    {
      city: "Dillingham",
      code: "DLG",
      name: "Dillingham Municipal Airport",
    },
    {
      city: "Duluth",
      code: "DLH",
      name: "Duluth International Airport",
    },
    {
      city: "Lam Dong",
      code: "DLI",
      name: "Lien Khuong Airport",
    },
    {
      city: "Ortaca",
      code: "DLM",
      name: "Dalaman Airport",
    },
    {
      city: "Dali City",
      code: "DLU",
      name: "Dali",
    },
    {
      city: "Dillons Bay",
      code: "DLY",
      name: "Vanuatu",
    },
    {
      city: "Dalanzadgad",
      code: "DLZ",
      name: "Dalanzadgad",
    },
    {
      city: "Doomadgee",
      code: "DMD",
      name: "Doomadgee",
    },
    {
      city: "Podol'sk",
      code: "DME",
      name: "Domodedovo Airport",
    },
    {
      city: "Don Muang",
      code: "DMK",
      name: "Don Mueang",
    },
    {
      city: "Khuwaylidiyah",
      code: "DMM",
      name: "King Fahd International Airport",
    },
    {
      city: "Dimapur",
      code: "DMU",
      name: "Dimapur Airport",
    },
    {
      city: "Dundee",
      code: "DND",
      name: "Dundee Airport",
    },
    {
      city: "Dunhuang",
      code: "DNH",
      name: "Dunhuang",
    },
    {
      city: "Dnipropetrovs'k",
      code: "DNK",
      name: "Voloskoye Airport",
    },
    {
      city: "Pleurtuit",
      code: "DNR",
      name: "Pleurtuit Airport",
    },
    {
      city: "Denizli",
      code: "DNZ",
      name: "Cardak Airport",
    },
    {
      city: "Dongola",
      code: "DOG",
      name: "Dongola Airport",
    },
    {
      city: "Doha",
      code: "DOH",
      name: "Doha International Airport",
    },
    {
      city: "Donets'k",
      code: "DOK",
      name: "Donetsk Airport",
    },
    {
      city: "Marigot",
      code: "DOM",
      name: "Melville Hall Airport",
    },
    {
      city: "Dolpa",
      code: "DOP",
      name: "Nepal",
    },
    {
      city: "Dourados",
      code: "DOU",
      name: "Dourados Airport",
    },
    {
      city: "Dongying",
      code: "DOY",
      name: "",
    },
    {
      city: "Polanco",
      code: "DPL",
      name: "Dipolog Airport",
    },
    {
      city: "Devonport",
      code: "DPO",
      name: "Devonport Airport",
    },
    {
      city: "Denpasar",
      code: "DPS",
      name: "Bali International Airport",
    },
    {
      city: "Deering",
      code: "DRG",
      name: "Deering Airport",
    },
    {
      city: "Durango",
      code: "DRO",
      name: "Durango la Plata County Airport",
    },
    {
      city: "Ottendorf-Okrilla",
      code: "DRS",
      name: "Ottendorf Okrilla Highway Strip Airport",
    },
    {
      city: "Del Rio",
      code: "DRT",
      name: "Del Rio International Airport",
    },
    {
      city: "Darwin",
      code: "DRW",
      name: "Darwin International Airport",
    },
    {
      city: "Doncaster Sheffield",
      code: "DSA",
      name: "Sheffield Airport",
    },
    {
      city: "Des Moines",
      code: "DSM",
      name: "Des Moines International Airport",
    },
    {
      city: "Dongsheng",
      code: "DSN",
      name: "Dongsheng Airport",
    },
    {
      city: "Dakar, Senegal",
      code: "DSS",
      name: "Blaise Diagne International Airport",
    },
    {
      city: "Dortmund",
      code: "DTM",
      name: "Dortmund Airport",
    },
    {
      city: "Dessie / Kombolcha",
      code: "DSE",
      name: "Dessie / Kombolcha Airport",
    },
    {
      city: "Detroit",
      code: "DTW",
      name: "Detroit Metropolitan Wayne County Airport",
    },
    {
      city: "Cloghran",
      code: "DUB",
      name: "Dublin Airport",
    },
    {
      city: "Outram",
      code: "DUD",
      name: "Dunedin Airport",
    },
    {
      city: "Dundo",
      code: "DUE",
      name: "Dundo Airport",
    },
    {
      city: "Reynoldsville",
      code: "DUJ",
      name: "Du Bois Jefferson County Airport",
    },
    {
      city: "Duncan",
      code: "DUQ",
      name: "Duncan-Quamichan Lake Airport",
    },
    {
      city: "Durban",
      code: "DUR",
      name: "Louis Botha Airport",
    },
    {
      city: "Dusseldorf",
      code: "DUS",
      name: "Dusseldorf International Airport",
    },
    {
      city: "Unalaska",
      code: "DUT",
      name: "Unalaska Airport",
    },
    {
      city: "Devils Lake",
      code: "DVL",
      name: "Devils Lake Municipal Airport",
    },
    {
      city: "Davao City",
      code: "DVO",
      name: "Francisco Bangoy International Airport",
    },
    {
      city: "Soalala",
      code: "DWB",
      name: "Soalala",
    },
    {
      city: "Dawadmi",
      code: "DWD",
      name: "Saudi Arabia",
    },
    {
      city: "Dubai",
      code: "DXB",
      name: "Dubai International Airport",
    },
    {
      city: "Dayong",
      code: "DYG",
      name: "Dayong",
    },
    {
      city: "Anadyr'",
      code: "DYR",
      name: "Anadyr-Ugolnyye Kopi Airport",
    },
    {
      city: "Dushanbe",
      code: "DYU",
      name: "Tajikistan",
    },
    {
      city: "Mamoudzou",
      code: "DZA",
      name: "Dzaoudzi Pamanzi Airport",
    },
    {
      city: "Zhezqazghan",
      code: "DZN",
      name: "Dzhezkazgan South Airport",
    },
    {
      city: "Tok",
      code: "EAA",
      name: "Eagle Airport",
    },
    {
      city: "Emae",
      code: "EAE",
      name: "Vanuatu",
    },
    {
      city: "Kwajalein Atoll",
      code: "EAL",
      name: "Elenak",
    },
    {
      city: "Najran",
      code: "EAM",
      name: "Nejran Airport",
    },
    {
      city: "Kearney",
      code: "EAR",
      name: "Kearney Municipal Airport",
    },
    {
      city: "Hondarribia",
      code: "EAS",
      name: "San Sebastian Airport",
    },
    {
      city: "East Wenatchee",
      code: "EAT",
      name: "Pangborn Memorial Airport",
    },
    {
      city: "Eau Claire",
      code: "EAU",
      name: "Eau Claire County Airport",
    },
    {
      city: "Pianosa",
      code: "EBA",
      name: "Marina de Campo Airport",
    },
    {
      city: "Entebbe",
      code: "EBB",
      name: "Entebbe International Airport",
    },
    {
      city: "Al Ubayyid",
      code: "EBD",
      name: "El Obeid Airport",
    },
    {
      city: "Esbjerg",
      code: "EBJ",
      name: "Esbjerg Airport",
    },
    {
      city: "Erbil",
      code: "EBL",
      name: "Erbil",
    },
    {
      city: "Nicosia",
      code: "ECN",
      name: "Ercan Airport",
    },
    {
      city: "Ketchikan",
      code: "EDA",
      name: "Edna Bay Seaplane Base",
    },
    {
      city: "Edinburgh",
      code: "EDI",
      name: "Edinburgh International Airport",
    },
    {
      city: "Nakuru",
      code: "EDL",
      name: "Eldoret Airport",
    },
    {
      city: "Edremit",
      code: "EDO",
      name: "Edremit-Korfez Airport",
    },
    {
      city: "Edward River",
      code: "EDR",
      name: "Edward River",
    },
    {
      city: "Eek",
      code: "EEK",
      name: "Eek Airport",
    },
    {
      city: "Dhilianata",
      code: "EFL",
      name: "Kefallinia Airport",
    },
    {
      city: "Bergerac",
      code: "EGC",
      name: "Bergerac-Roumaniere Airport",
    },
    {
      city: "Gypsum",
      code: "EGE",
      name: "Eagle County Regional Airport",
    },
    {
      city: "Geneina",
      code: "EGN",
      name: "",
    },
    {
      city: "Belgorod",
      code: "EGO",
      name: "Belgorod North Airport",
    },
    {
      city: "Egilsstadir",
      code: "EGS",
      name: "Egilsstadir Airport",
    },
    {
      city: "Eagle River",
      code: "EGV",
      name: "Eagle River Union Airport",
    },
    {
      city: "Egegik",
      code: "EGX",
      name: "Egegik Airport",
    },
    {
      city: "Eisenach",
      code: "EIB",
      name: "Haina Airport",
    },
    {
      city: "Eniseysk",
      code: "EIE",
      name: "Russia",
    },
    {
      city: "Tonsberg",
      code: "EIK",
      name: "",
    },
    {
      city: "Eindhoven",
      code: "EIN",
      name: "Eindhoven Airport",
    },
    {
      city: "Beef Island",
      code: "EIS",
      name: "Beef Island-Roadtown Airport",
    },
    {
      city: "Barrancabermeja",
      code: "EJA",
      name: "Yariguies Airport",
    },
    {
      city: "Wedjh",
      code: "EJH",
      name: "Wejh Airport",
    },
    {
      city: "Elko",
      code: "EKO",
      name: "Elko Municipal Airport-J C Harris Field",
    },
    {
      city: "",
      code: "EKS",
      name: "Ennis Big Sky Airport",
    },
    {
      city: "Darwin",
      code: "ELC",
      name: "Elcho Island Airport",
    },
    {
      city: "El Fasher",
      code: "ELF",
      name: "",
    },
    {
      city: "El Golea",
      code: "ELG",
      name: "El Golea Airport",
    },
    {
      city: "The Bluff",
      code: "ELH",
      name: "North Eleuthera Airport",
    },
    {
      city: "Elim",
      code: "ELI",
      name: "Elim Airport",
    },
    {
      city: "Horseheads",
      code: "ELM",
      name: "Elmira Corning Regional Airport",
    },
    {
      city: "El Paso",
      code: "ELP",
      name: "El Paso International Airport",
    },
    {
      city: "Al Qara'",
      code: "ELQ",
      name: "Gassim Airport",
    },
    {
      city: "East London",
      code: "ELS",
      name: "Ben Schoeman Airport",
    },
    {
      city: "El Oued",
      code: "ELU",
      name: "Guemar Airport",
    },
    {
      city: "Elfin Cove",
      code: "ELV",
      name: "Elfin Cove Airport",
    },
    {
      city: "Ely",
      code: "ELY",
      name: "Yelland Field Airport",
    },
    {
      city: "Derby",
      code: "EMA",
      name: "East Midlands International Airport",
    },
    {
      city: "Emerald",
      code: "EMD",
      name: "Emerald Aerodrome",
    },
    {
      city: "Emden",
      code: "EME",
      name: "Emden Airport",
    },
    {
      city: "Alakanuk",
      code: "EMK",
      name: "Emmonak Airport",
    },
    {
      city: "Kenai",
      code: "ENA",
      name: "Kenai Municipal Airport",
    },
    {
      city: "Ende",
      code: "ENE",
      name: "Ende Airport",
    },
    {
      city: "Leppäjärvi",
      code: "ENF",
      name: "Enontekio Airport",
    },
    {
      city: "Enshi",
      code: "ENH",
      name: "Enshi Airport",
    },
    {
      city: "El Nido",
      code: "ENI",
      name: "",
    },
    {
      city: "Enschede",
      code: "ENS",
      name: "Enschede Twente",
    },
    {
      city: "Enugu",
      code: "ENU",
      name: "Enugu Airport",
    },
    {
      city: "Kenosha",
      code: "ENW",
      name: "Kenosha Regional Airport",
    },
    {
      city: "Yan'an",
      code: "ENY",
      name: "Yan'an",
    },
    {
      city: "Medellin",
      code: "EOH",
      name: "Olaya Herrera Airport",
    },
    {
      city: "Elorza",
      code: "EOZ",
      name: "Elorza Airport",
    },
    {
      city: "Gibson",
      code: "EPR",
      name: "Esperance Aerodrome",
    },
    {
      city: "Esquel",
      code: "EQS",
      name: "Esquel Airport",
    },
    {
      city: "Erzincan",
      code: "ERC",
      name: "Erzincan Airport",
    },
    {
      city: "Erfurt",
      code: "ERF",
      name: "Erfurt Airport",
    },
    {
      city: "Errachidia",
      code: "ERH",
      name: "Er Rachidia Airport",
    },
    {
      city: "Erie",
      code: "ERI",
      name: "Erie International Airport",
    },
    {
      city: "Erechim",
      code: "ERM",
      name: "Comandante Kraemer Airport",
    },
    {
      city: "Windhoek",
      code: "ERS",
      name: "Eros Airport",
    },
    {
      city: "Erzurum",
      code: "ERZ",
      name: "Erzurum Airport",
    },
    {
      city: "Çubuk",
      code: "ESB",
      name: "Esenboga Airport",
    },
    {
      city: "Escanaba",
      code: "ESC",
      name: "Delta County Airport",
    },
    {
      city: "Eastsound",
      code: "ESD",
      name: "Orcas Island Airport",
    },
    {
      city: "Tachina",
      code: "ESM",
      name: "General Rivadeneira Airport",
    },
    {
      city: "Diego de Almagro",
      code: "ESR",
      name: "El Salvador Bajo Airport",
    },
    {
      city: "Mulheim-on-Ruhr",
      code: "ESS",
      name: "Essen-Mulheim Airport",
    },
    {
      city: "Essaouira",
      code: "ESU",
      name: "Morocco",
    },
    {
      city: "Elat",
      code: "ETH",
      name: "J Hozman Airport",
    },
    {
      city: "Verny",
      code: "ETZ",
      name: "Lorraine Airport",
    },
    {
      city: "Eua",
      code: "EUA",
      name: "Eua Island",
    },
    {
      city: "Eugene",
      code: "EUG",
      name: "Mahlon Sweet Field Airport",
    },
    {
      city: "Bordesholm",
      code: "EUM",
      name: "Wasbek Airport",
    },
    {
      city: "Laayoune",
      code: "EUN",
      name: "Hassan I",
    },
    {
      city: "Oranjestad",
      code: "EUX",
      name: "St. Eustatius Airport",
    },
    {
      city: "Tarnstad",
      code: "EVE",
      name: "Evenes Airport",
    },
    {
      city: "Sveg",
      code: "EVG",
      name: "Sveg Airport",
    },
    {
      city: "Yerevan",
      code: "EVN",
      name: "Yerevan-Parakar Airport",
    },
    {
      city: "Evansville",
      code: "EVV",
      name: "Evansville Regional Airport",
    },
    {
      city: "New Bedford",
      code: "EWB",
      name: "New Bedford Municipal Airport",
    },
    {
      city: "Wildman Lake",
      code: "EWD",
      name: "Wildman Lake",
    },
    {
      city: "New Bern",
      code: "EWN",
      name: "Craven County Regional Airport",
    },
    {
      city: "Newark",
      code: "EWR",
      name: "Newark International Airport",
    },
    {
      city: "Exeter",
      code: "EXT",
      name: "Exeter Airport",
    },
    {
      city: "Yopal",
      code: "EYP",
      name: "El Yopal Airport",
    },
    {
      city: "Key West",
      code: "EYW",
      name: "Key West International Airport",
    },
    {
      city: "Ezeiza",
      code: "EZE",
      name: "Ministro Pistarini International Airport",
    },
    {
      city: "Elazığ",
      code: "EZS",
      name: "Elazig Airport",
    },
    {
      city: "Farnborough",
      code: "FAB",
      name: "Farnborough Airport",
    },
    {
      city: "Faroe Islands",
      code: "FAE",
      name: "Vagar Airport",
    },
    {
      city: "Fairbanks",
      code: "FAI",
      name: "Fairbanks International Airport",
    },
    {
      city: "Faro",
      code: "FAO",
      name: "Faro Airport",
    },
    {
      city: "Fargo",
      code: "FAR",
      name: "Hector International Airport",
    },
    {
      city: "Fresno",
      code: "FAT",
      name: "Fresno Yosemite International Airport",
    },
    {
      city: "Fakarava",
      code: "FAV",
      name: "French Polynesia",
    },
    {
      city: "Fayetteville",
      code: "FAY",
      name: "Fayetteville Regional Airport",
    },
    {
      city: "Lubumbashi",
      code: "FBM",
      name: "Lubumbashi Luano International Airport",
    },
    {
      city: "Kalispell",
      code: "FCA",
      name: "Glacier Park International Airport",
    },
    {
      city: "Cuxhaven",
      code: "FCN",
      name: "Nordholz",
    },
    {
      city: "Rome",
      code: "FCO",
      name: "Leonardo da Vinci International Airport",
    },
    {
      city: "Bygstad",
      code: "FDE",
      name: "Bringeland Airport",
    },
    {
      city: "Ducos",
      code: "FDF",
      name: "Le Lamentin Airport",
    },
    {
      city: "Meckenbeuren",
      code: "FDH",
      name: "Friedrichshafen Airport",
    },
    {
      city: "Fergana",
      code: "FEG",
      name: "Fergana",
    },
    {
      city: "Vila dos Remédios",
      code: "FEN",
      name: "Fernando de Noronha Airport",
    },
    {
      city: "Fez",
      code: "FEZ",
      name: "Saiss Airport",
    },
    {
      city: "Fangatau",
      code: "FGU",
      name: "French Polynesia",
    },
    {
      city: "Fakahina",
      code: "FHZ",
      name: "French Polynesia",
    },
    {
      city: "Kinshasa",
      code: "FIH",
      name: "Kinshasa N Djili International Airport",
    },
    {
      city: "Al Fujayrah",
      code: "FJR",
      name: "Fujairah Airport",
    },
    {
      city: "Rheinmünster",
      code: "FKB",
      name: "Baden-Airpark",
    },
    {
      city: "Kisangani",
      code: "FKI",
      name: "Kisangani Bangoka International Airport",
    },
    {
      city: "Franklin",
      code: "FKL",
      name: "Chess Lamberton Airport",
    },
    {
      city: "Fak Fak",
      code: "FKQ",
      name: "Indonesia",
    },
    {
      city: "Sukagawa-shi",
      code: "FKS",
      name: "Fukushima Airport",
    },
    {
      city: "Florencia",
      code: "FLA",
      name: "Gustavo Artunduaga Paredes Airport",
    },
    {
      city: "Flagstaff",
      code: "FLG",
      name: "Flagstaff Pulliam Airport",
    },
    {
      city: "Dania Beach",
      code: "FLL",
      name: "Fort Lauderdale Hollywood International Airport",
    },
    {
      city: "Florianopolis",
      code: "FLN",
      name: "Hercilio Luz International Airport",
    },
    {
      city: "Florence",
      code: "FLO",
      name: "Florence Regional Airport",
    },
    {
      city: "Florence",
      code: "FLR",
      name: "Florence Airport",
    },
    {
      city: "Flores Island",
      code: "FLW",
      name: "Flores Airport",
    },
    {
      city: "Formosa",
      code: "FMA",
      name: "Formosa Airport",
    },
    {
      city: "Memmingen",
      code: "FMM",
      name: "Memmingen-Allgäu Airport",
    },
    {
      city: "Farmington",
      code: "FMN",
      name: "Four Corners Regional Airport",
    },
    {
      city: "Greven",
      code: "FMO",
      name: "Munster-Osnabruck International Airport",
    },
    {
      city: "Fort Myers",
      code: "FMY",
      name: "Page Field Airport",
    },
    {
      city: "Freetown",
      code: "FNA",
      name: "Freetown Lungi Airport",
    },
    {
      city: "Madeira",
      code: "FNC",
      name: "Funchal Airport",
    },
    {
      city: "St-Gilles-du-Gard",
      code: "FNI",
      name: "Garons Airport",
    },
    {
      city: "Sunan",
      code: "FNJ",
      name: "Sunan Airport",
    },
    {
      city: "Loveland",
      code: "FNL",
      name: "Fort Collins Loveland Municipal Airport",
    },
    {
      city: "Flint",
      code: "FNT",
      name: "Bishop International Airport",
    },
    {
      city: "Fuzhou",
      code: "FOC",
      name: "Fuzhou Airport",
    },
    {
      city: "Fort Dodge",
      code: "FOD",
      name: "Fort Dodge Regional Airport",
    },
    {
      city: "Foggia",
      code: "FOG",
      name: "Gino Lisa Airport",
    },
    {
      city: "Fortaleza",
      code: "FOR",
      name: "Pinto Martins International Airport",
    },
    {
      city: "Freeport City",
      code: "FPO",
      name: "Freeport International Airport",
    },
    {
      city: "Frankfurt",
      code: "FRA",
      name: "Frankfurt International Airport",
    },
    {
      city: "Franca",
      code: "FRC",
      name: "Franca Airport",
    },
    {
      city: "Friday Harbor",
      code: "FRD",
      name: "Friday Harbor Airport",
    },
    {
      city: "Fera Island",
      code: "FRE",
      name: "Fera Island",
    },
    {
      city: "Forli",
      code: "FRL",
      name: "Forli Airport",
    },
    {
      city: "Floro",
      code: "FRO",
      name: "Flora Airport",
    },
    {
      city: "Flores",
      code: "FRS",
      name: "Santa Elena Airport",
    },
    {
      city: "Bishkek",
      code: "FRU",
      name: "Vasilyevka Airport",
    },
    {
      city: "Francistown",
      code: "FRW",
      name: "Francistown Airport",
    },
    {
      city: "Figari",
      code: "FSC",
      name: "Sud Corse Airport",
    },
    {
      city: "Sioux Falls",
      code: "FSD",
      name: "Sioux Falls Regional Airport",
    },
    {
      city: "Fort Smith",
      code: "FSM",
      name: "Smith Field Airport",
    },
    {
      city: "St Pierre",
      code: "FSP",
      name: "St Pierre Airport",
    },
    {
      city: "Futuna Island",
      code: "FTA",
      name: "Futuna",
    },
    {
      city: "El Calafate",
      code: "FTE",
      name: "El Calafate International Airport",
    },
    {
      city: "Faradofay",
      code: "FTU",
      name: "Tolagnaro Airport",
    },
    {
      city: "Antigua",
      code: "FUE",
      name: "Puerto del Rosario Airport",
    },
    {
      city: "Fuyang",
      code: "FUG",
      name: "",
    },
    {
      city: "Goto-shi",
      code: "FUJ",
      name: "Fukue Airport",
    },
    {
      city: "Fukuoka-shi",
      code: "FUK",
      name: "Fukuoka Airport",
    },
    {
      city: "Funafuti",
      code: "FUN",
      name: "Funafuti International Airport",
    },
    {
      city: "Futuna Island",
      code: "FUT",
      name: "Futuna Island",
    },
    {
      city: "Fort Wayne",
      code: "FWA",
      name: "Fort Wayne Municipal Airport-Baer Field",
    },
    {
      city: "Fort William",
      code: "FWM",
      name: "Fort William Heliport",
    },
    {
      city: "Fort Yukon",
      code: "FYU",
      name: "Fort Yukon Airport",
    },
    {
      city: "Bristol",
      code: "FZO",
      name: "Filton Airport",
    },
    {
      city: "Gabes",
      code: "GAE",
      name: "Gabes Airport",
    },
    {
      city: "Gafsa",
      code: "GAF",
      name: "Gafsa",
    },
    {
      city: "Higashine-shi",
      code: "GAJ",
      name: "Yamagata Airport",
    },
    {
      city: "Galena",
      code: "GAL",
      name: "Galena Airport",
    },
    {
      city: "Gambell",
      code: "GAM",
      name: "Gambell Airport",
    },
    {
      city: "Hithadhoo",
      code: "GAN",
      name: "Gan Island Airport",
    },
    {
      city: "Guantanamo",
      code: "GAO",
      name: "Cuba",
    },
    {
      city: "Gawahati",
      code: "GAU",
      name: "Borjhar",
    },
    {
      city: "Gamba",
      code: "GAX",
      name: "Gamba",
    },
    {
      city: "Gaya",
      code: "GAY",
      name: "Gaya Airport",
    },
    {
      city: "Great Bend",
      code: "GBD",
      name: "Great Bend Municipal Airport",
    },
    {
      city: "Tlokweng",
      code: "GBE",
      name: "Sir Seretse Khama International Airport",
    },
    {
      city: "Grand-Bourg",
      code: "GBJ",
      name: "Marie Galante Airport",
    },
    {
      city: "Gorgan",
      code: "GBT",
      name: "Iran",
    },
    {
      city: "Gillette",
      code: "GCC",
      name: "Gillette Campbell County Airport",
    },
    {
      city: "St. Peter Port",
      code: "GCI",
      name: "Guernsey Airport",
    },
    {
      city: "Pierceville",
      code: "GCK",
      name: "Garden City Regional Airport",
    },
    {
      city: "Georgetown",
      code: "GCM",
      name: "Owen Roberts International Airport",
    },
    {
      city: "Gode/Iddidole",
      code: "GDE",
      name: "Ididole",
    },
    {
      city: "Tlajomulco de Zúñiga",
      code: "GDL",
      name: "Don Miguel Hidalgo International Airport",
    },
    {
      city: "Gdansk",
      code: "GDN",
      name: "Rebiechowo Airport",
    },
    {
      city: "Barinas",
      code: "GDO",
      name: "Vare Maria Airport",
    },
    {
      city: "Azezo",
      code: "GDQ",
      name: "Gondar Airport",
    },
    {
      city: "Grand Turk",
      code: "GDT",
      name: "Grand Turk International Airport",
    },
    {
      city: "Magadan",
      code: "GDX",
      name: "Magadan Northwest Airport",
    },
    {
      city: "Noumea",
      code: "GEA",
      name: "Magenta Airport",
    },
    {
      city: "Spokane",
      code: "GEG",
      name: "Spokane International Airport",
    },
    {
      city: "Santo Angelo",
      code: "GEL",
      name: "Santo Angelo Airport",
    },
    {
      city: "Hyde Park",
      code: "GEO",
      name: "Timehri International Airport",
    },
    {
      city: "Nueva Gerona",
      code: "GER",
      name: "Nueva Gerona Airport",
    },
    {
      city: "General Santos City",
      code: "GES",
      name: "General Santos Airport",
    },
    {
      city: "Geraldton",
      code: "GET",
      name: "Geraldton Airport",
    },
    {
      city: "Koskullskulle",
      code: "GEV",
      name: "Lappland Airport",
    },
    {
      city: "Griffith",
      code: "GFF",
      name: "Griffith Airport",
    },
    {
      city: "Grand Forks",
      code: "GFK",
      name: "Grand Forks Mark Andrews International Airport",
    },
    {
      city: "Grafton",
      code: "GFN",
      name: "Grafton Airport",
    },
    {
      city: "Longview",
      code: "GGG",
      name: "Gregg County Airport",
    },
    {
      city: "Garowe, Somalia",
      code: "GGR",
      name: "General Mohamed Abshir International Airport",
    },
    {
      city: "George Town",
      code: "GGT",
      name: "Exuma International Airport",
    },
    {
      city: "Ghardaia",
      code: "GHA",
      name: "Noumerate Airport",
    },
    {
      city: "Governor Harbour",
      code: "GHB",
      name: "Governors Harbour Airport",
    },
    {
      city: "Ghat",
      code: "GHT",
      name: "Ghat Airport",
    },
    {
      city: "Gibraltar",
      code: "GIB",
      name: "Gibraltar Airport",
    },
    {
      city: "Kubin Village",
      code: "GIC",
      name: "Boigu Island Airport",
    },
    {
      city: "Rio de Janeiro",
      code: "GIG",
      name: "Rio de Janeiro-Antonio Carlos Jobim Internationa",
    },
    {
      city: "Gilgit",
      code: "GIL",
      name: "Gilgit Airport",
    },
    {
      city: "Gisborne",
      code: "GIS",
      name: "Gisborne Airport",
    },
    {
      city: "Jizan",
      code: "GIZ",
      name: "Gizan Airport",
    },
    {
      city: "Guanaja",
      code: "GJA",
      name: "Guanaja Airport",
    },
    {
      city: "Jijel",
      code: "GJL",
      name: "Taher Airport",
    },
    {
      city: "Grand Junction",
      code: "GJT",
      name: "Walker Field Airport",
    },
    {
      city: "Goroka",
      code: "GKA",
      name: "Goroka Airport",
    },
    {
      city: "Paisley",
      code: "GLA",
      name: "Glasgow International Airport",
    },
    {
      city: "Palmar Sur",
      code: "GLF",
      name: "Golfito Airport",
    },
    {
      city: "Greenville",
      code: "GLH",
      name: "Mid Delta Regional Airport",
    },
    {
      city: "Gaalkacyo",
      code: "GLK",
      name: "Galcaio Airport",
    },
    {
      city: "Goulimime",
      code: "GLN",
      name: "Guelmim",
    },
    {
      city: "Cheltenham",
      code: "GLO",
      name: "Gloucestershire Airport",
    },
    {
      city: "Gladstone",
      code: "GLT",
      name: "Gladstone Airport",
    },
    {
      city: "Golovin",
      code: "GLV",
      name: "Golovin",
    },
    {
      city: "Gemena",
      code: "GMA",
      name: "Gemena Airport",
    },
    {
      city: "Gambela",
      code: "GMB",
      name: "",
    },
    {
      city: "Seoul",
      code: "GMP",
      name: "Gimpo International Airport",
    },
    {
      city: "Gambier Is",
      code: "GMR",
      name: "Gambier Is",
    },
    {
      city: "Alajeró",
      code: "GMZ",
      name: "La Gomera Airport",
    },
    {
      city: "Sillans",
      code: "GNB",
      name: "St Geoirs Airport",
    },
    {
      city: "Grenada",
      code: "GND",
      name: "Point Salines International Airport",
    },
    {
      city: "Goodnews Bay",
      code: "GNU",
      name: "Alaska",
    },
    {
      city: "Gainesville",
      code: "GNV",
      name: "Gainesville Regional Airport",
    },
    {
      city: "Genoa",
      code: "GOA",
      name: "Genoa Cristoforo Colombo Airport",
    },
    {
      city: "Goba",
      code: "GOB",
      name: "Goba Airport",
    },
    {
      city: "Nuuk",
      code: "GOH",
      name: "Godthaab Airport",
    },
    {
      city: "Vasco Da Gama",
      code: "GOI",
      name: "Dabolim Airport",
    },
    {
      city: "Dzerzinsk",
      code: "GOJ",
      name: "Strigino Airport",
    },
    {
      city: "Goma",
      code: "GOM",
      name: "Goma International Airport",
    },
    {
      city: "Gorakhpur",
      code: "GOP",
      name: "Gorakhpur",
    },
    {
      city: "Golmud",
      code: "GOQ",
      name: "Golmud Airport",
    },
    {
      city: "Härryda",
      code: "GOT",
      name: "Gothenburg Airport",
    },
    {
      city: "Garoua",
      code: "GOU",
      name: "Garoua Airport",
    },
    {
      city: "Gove",
      code: "GOV",
      name: "Gove Aerodrome",
    },
    {
      city: "Lakkopetra",
      code: "GPA",
      name: "Araxos Airport",
    },
    {
      city: "Guapí",
      code: "GPI",
      name: "Guapi Airport",
    },
    {
      city: "Puerto Ayora",
      code: "GPS",
      name: "Seymour Airport",
    },
    {
      city: "Gulfport",
      code: "GPT",
      name: "Gulfport Biloxi Regional Airport",
    },
    {
      city: "Green Bay",
      code: "GRB",
      name: "Austin Straubel International Airport",
    },
    {
      city: "George",
      code: "GRJ",
      name: "P W Botha Airport",
    },
    {
      city: "Killeen",
      code: "GRK",
      name: "Killeen-Fort Hood Regional Airport",
    },
    {
      city: "Vilobí d'Onyar",
      code: "GRO",
      name: "Gerona Airport",
    },
    {
      city: "Eelde",
      code: "GRQ",
      name: "Groningen Eelde",
    },
    {
      city: "Grand Rapids",
      code: "GRR",
      name: "Gerald R. Ford International Airport",
    },
    {
      city: "Guarulhos",
      code: "GRU",
      name: "Governador Andre Franco Montoro International Ai",
    },
    {
      city: "Groznyy",
      code: "GRV",
      name: "Groznyy Airport",
    },
    {
      city: "Graciosa Island",
      code: "GRW",
      name: "Graciosa Airport",
    },
    {
      city: "Chauchina",
      code: "GRX",
      name: "Granada Airport",
    },
    {
      city: "Akureyri",
      code: "GRY",
      name: "Grimsey Airport",
    },
    {
      city: "Feldkirchen",
      code: "GRZ",
      name: "Graz Airport",
    },
    {
      city: "Kyrkobyn",
      code: "GSE",
      name: "Save Airport",
    },
    {
      city: "Gheshm",
      code: "GSM",
      name: "",
    },
    {
      city: "Greensboro",
      code: "GSO",
      name: "Triad International Airport",
    },
    {
      city: "Greer",
      code: "GSP",
      name: "Greenville Spartanburg International Airport",
    },
    {
      city: "Gustavus",
      code: "GST",
      name: "Gustavus Airport",
    },
    {
      city: "Market Rasen",
      code: "GSY",
      name: "Binbrook Airport",
    },
    {
      city: "Gatokae",
      code: "GTA",
      name: "Solomon Islands",
    },
    {
      city: "Darwin",
      code: "GTE",
      name: "Groote Eylandt Airport",
    },
    {
      city: "Great Falls",
      code: "GTF",
      name: "Great Falls International Airport",
    },
    {
      city: "Gorontalo",
      code: "GTO",
      name: "Jalaluddin Airport",
    },
    {
      city: "Columbus",
      code: "GTR",
      name: "Golden Triangle Regional Airport",
    },
    {
      city: "Granites",
      code: "GTS",
      name: "Australia",
    },
    {
      city: "Guatemala City",
      code: "GUA",
      name: "La Aurora Airport",
    },
    {
      city: "Gunnison",
      code: "GUC",
      name: "Gunnison County Airport",
    },
    {
      city: "Hagåtña",
      code: "GUM",
      name: "Antonio B Won Pat International Airport",
    },
    {
      city: "Alotau",
      code: "GUR",
      name: "Gurney Airport",
    },
    {
      city: "Atyrau",
      code: "GUW",
      name: "Guryev Airport",
    },
    {
      city: "Geneva",
      code: "GVA",
      name: "Geneva Airport",
    },
    {
      city: "Governador Valadares",
      code: "GVR",
      name: "Governador Valadares Airport",
    },
    {
      city: "Gawadar",
      code: "GWD",
      name: "Gwadar Airport",
    },
    {
      city: "Gwalior",
      code: "GWL",
      name: "Gwalior Airport",
    },
    {
      city: "Westerland",
      code: "GWT",
      name: "Westerland Airport",
    },
    {
      city: "Carnmore",
      code: "GWY",
      name: "Carnmore Airport",
    },
    {
      city: "Seiyun",
      code: "GXF",
      name: "Sayun Airport",
    },
    {
      city: "Negage",
      code: "GXG",
      name: "Negage Airport",
    },
    {
      city: "Guayaramerín",
      code: "GYA",
      name: "Guayaramerin Airport",
    },
    {
      city: "Baku",
      code: "GYD",
      name: "Azerbaijan",
    },
    {
      city: "Guayaquil",
      code: "GYE",
      name: "Simon Bolivar International Airport",
    },
    {
      city: "Argyle",
      code: "GYL",
      name: "Argyle Airport",
    },
    {
      city: "Guaymas",
      code: "GYM",
      name: "General Jose Maria Yanez in Airport",
    },
    {
      city: "Goiania",
      code: "GYN",
      name: "Santa Genoveva Airport",
    },
    {
      city: "Gizo",
      code: "GZO",
      name: "Nusatupe Airport",
    },
    {
      city: "Oğuzeli",
      code: "GZT",
      name: "Gaziantep Airport",
    },
    {
      city: "Hasvik",
      code: "HAA",
      name: "Hasvik Airport",
    },
    {
      city: "Hachijo-machi",
      code: "HAC",
      name: "Hachijojima Airport",
    },
    {
      city: "Hamstad",
      code: "HAD",
      name: "Halmstad Airport",
    },
    {
      city: "Havasupai",
      code: "HAE",
      name: "Havasupai",
    },
    {
      city: "Hahaia",
      code: "HAH",
      name: "Moroni Hahaia Airport",
    },
    {
      city: "Langenhagen",
      code: "HAJ",
      name: "Hannover International Airport",
    },
    {
      city: "Haikou",
      code: "HAK",
      name: "Haikou Airport",
    },
    {
      city: "Hamburg",
      code: "HAM",
      name: "Hamburg Airport",
    },
    {
      city: "Hanoi",
      code: "HAN",
      name: "Noi Bai Airport",
    },
    {
      city: "Hanimaadhoo",
      code: "HAQ",
      name: "Hanimadu",
    },
    {
      city: "Ha'il",
      code: "HAS",
      name: "Hail Airport",
    },
    {
      city: "Avaldsnes",
      code: "HAU",
      name: "Haugesund Karmoy Airport",
    },
    {
      city: "Wajay",
      code: "HAV",
      name: "Jose Marti International Airport",
    },
    {
      city: "Hobart",
      code: "HBA",
      name: "Hobart International Airport",
    },
    {
      city: "Alexandria",
      code: "HBE",
      name: "Borg El Arab International Airport",
    },
    {
      city: "Hafr Albatin",
      code: "HBT",
      name: "Saudi Arabia",
    },
    {
      city: "Hubli",
      code: "HBX",
      name: "India",
    },
    {
      city: "Hengchun",
      code: "HCN",
      name: "Hengchun Airport",
    },
    {
      city: "Holy Cross",
      code: "HCR",
      name: "Alaska",
    },
    {
      city: "Heidelberg",
      code: "HDB",
      name: "Heidelberg Airport",
    },
    // {
    //   city: "Hyderabad",
    //   code: "HYD",
    //   name: "Hyderabad Airport",
    // },
    {
      city: "Heringsdorf",
      code: "HDF",
      name: "Heringsdorf",
    },
    {
      city: "Hamadan",
      code: "HDM",
      name: "Hamadan Airport",
    },
    {
      city: "Hayden",
      code: "HDN",
      name: "Yampa Valley Airport",
    },
    {
      city: "Phalaborwa",
      code: "HDS",
      name: "Hoedspruit Afs Airport",
    },
    {
      city: "Hat Yai",
      code: "HDY",
      name: "Hat Yai International Airport",
    },
    {
      city: "Herat",
      code: "HEA",
      name: "Herat Airport",
    },
    {
      city: "Heho",
      code: "HEH",
      name: "Heho Airport",
    },
    {
      city: "Heide-Buesum",
      code: "HEI",
      name: "Heide-Busum Airport",
    },
    {
      city: "Heihe",
      code: "HEK",
      name: "Heihe Airport",
    },
    {
      city: "Vantaa",
      code: "HEL",
      name: "Helsinki Vantaa Airport",
    },
    {
      city: "Iraklio",
      code: "HER",
      name: "Iraklion Airport",
    },
    {
      city: "Hohhot",
      code: "HET",
      name: "Huhehaote Airport",
    },
    {
      city: "Hefa",
      code: "HFA",
      name: "U Michaeli Airport",
    },
    {
      city: "Hefei",
      code: "HFE",
      name: "Hefei-Luogang Airport",
    },
    {
      city: "Hagfors",
      code: "HFS",
      name: "Hagfors Airport",
    },
    {
      city: "Hammerfest",
      code: "HFT",
      name: "Hammerfest Airport",
    },
    {
      city: "Hargeysa",
      code: "HGA",
      name: "Hargeisa Airport",
    },
    {
      city: "Hughenden",
      code: "HGD",
      name: "Hughenden Aerodrome",
    },
    {
      city: "Hangzhou",
      code: "HGH",
      name: "Jianoiao Airport",
    },
    {
      city: "Helgoland",
      code: "HGL",
      name: "Germany",
    },
    {
      city: "Mae Hong Son",
      code: "HGN",
      name: "Mae Hongson Airport",
    },
    {
      city: "Mount Hagen",
      code: "HGU",
      name: "Mount Hagen Airport",
    },
    {
      city: "Hilton Head Island",
      code: "HHH",
      name: "Hilton Head Airport",
    },
    {
      city: "Dickenschied",
      code: "HHN",
      name: "Frankfurt-Hahn Airport",
    },
    {
      city: "Hua Hin",
      code: "HHQ",
      name: "Hua Hin Airport",
    },
    {
      city: "Hikueru",
      code: "HHZ",
      name: "French Polynesia",
    },
    {
      city: "Hibbing",
      code: "HIB",
      name: "Chisholm Hibbing Airport",
    },
    {
      city: "Horn Island",
      code: "HID",
      name: "Horn Island",
    },
    {
      city: "Mihara-shi",
      code: "HIJ",
      name: "Hiroshima Airport",
    },
    {
      city: "Shillavo",
      code: "HIL",
      name: "",
    },
    {
      city: "Sacheon-Si",
      code: "HIN",
      name: "Sacheon Airport",
    },
    {
      city: "Honiara",
      code: "HIR",
      name: "Henderson Airport",
    },
    {
      city: "Hayman Island",
      code: "HIS",
      name: "Hayman Island Airport",
    },
    {
      city: "Zhi Jiang",
      code: "HJJ",
      name: "Zhi Jiang",
    },
    {
      city: "Khajuraho",
      code: "HJR",
      name: "Khajuraho Airport",
    },
    {
      city: "Healy Lake",
      code: "HKB",
      name: "Healy Lake Airport",
    },
    {
      city: "Hakodate-shi",
      code: "HKD",
      name: "Hakodate Airport",
    },
    {
      city: "Hong Kong",
      code: "HKG",
      name: "Hong Kong International Airport",
    },
    {
      city: "Hokitika",
      code: "HKK",
      name: "Hokitika Airport",
    },
    {
      city: "Kimbe",
      code: "HKN",
      name: "Hoskins Airport",
    },
    {
      city: "Thalang",
      code: "HKT",
      name: "Phuket International Airport",
    },
    {
      city: "Johannesburg",
      code: "HLA",
      name: "Lanseria Airport",
    },
    {
      city: "Hailar",
      code: "HLD",
      name: "Hailar",
    },
    {
      city: "Ulan Hot",
      code: "HLH",
      name: "Ulanhot Airport",
    },
    {
      city: "Helena",
      code: "HLN",
      name: "Helena Regional Airport",
    },
    {
      city: "Holyhead",
      code: "HLY",
      name: "Holyhead Airport",
    },
    {
      city: "Hamilton",
      code: "HLZ",
      name: "Hamilton Airport",
    },
    {
      city: "Khanty-Mansiysk",
      code: "HMA",
      name: "Khanty-Nansiysk",
    },
    {
      city: "Ouargla",
      code: "HME",
      name: "Oued Irara Airport",
    },
    {
      city: "Hermosillo",
      code: "HMO",
      name: "Gen Ignacio P Garcia International Airport",
    },
    {
      city: "Hemavan",
      code: "HMV",
      name: "Sweden",
    },
    {
      city: "Hanamaki-shi",
      code: "HNA",
      name: "Hanamaki Airport",
    },
    {
      city: "Tokyo",
      code: "HND",
      name: "Tokyo International Airport",
    },
    {
      city: "Hoonah",
      code: "HNH",
      name: "Hoonah Airport",
    },
    {
      city: "Honolulu",
      code: "HNL",
      name: "Honolulu International Airport",
    },
    {
      city: "Hana",
      code: "HNM",
      name: "Hana Airport",
    },
    {
      city: "Haines",
      code: "HNS",
      name: "Haines Airport",
    },
    {
      city: "Hobbs",
      code: "HOB",
      name: "Lea County Regional Airport",
    },
    {
      city: "Al Hudaydah",
      code: "HOD",
      name: "Hodeidah Airport",
    },
    {
      city: "Houeisay",
      code: "HOE",
      name: "Houeisay",
    },
    {
      city: "Alahsa",
      code: "HOF",
      name: "Saudi Arabia",
    },
    {
      city: "San Pedro de Cacocum",
      code: "HOG",
      name: "Holguin Airport",
    },
    {
      city: "Hohenems",
      code: "HOH",
      name: "Hohenems Airport",
    },
    {
      city: "Papeete",
      code: "HOI",
      name: "Hao Airport",
    },
    {
      city: "Homer",
      code: "HOM",
      name: "Homer Airport",
    },
    {
      city: "Huron",
      code: "HON",
      name: "Howes",
    },
    {
      city: "Hof",
      code: "HOQ",
      name: "Hof-Plauen Airport",
    },
    {
      city: "Horta",
      code: "HOR",
      name: "Horta Airport",
    },
    {
      city: "Houston",
      code: "HOU",
      name: "William P Hobby Airport",
    },
    {
      city: "Hovdebygda",
      code: "HOV",
      name: "Hovden Airport",
    },
    {
      city: "Hirasar",
      code: "HSR",
      name: "Hirasar Airport",
    },
    {
      city: "Pangai",
      code: "HPA",
      name: "Salote Pilolevu Airport",
    },
    {
      city: "Hooper Bay",
      code: "HPB",
      name: "Hooper Bay Airport",
    },
    {
      city: "Häi Phòng",
      code: "HPH",
      name: "Hai Phong Cat Bi Airport",
    },
    {
      city: "Purchase",
      code: "HPN",
      name: "Westchester County Airport",
    },
    {
      city: "Harbin",
      code: "HRB",
      name: "Harbin Yangjiagang Airport",
    },
    {
      city: "Harare",
      code: "HRE",
      name: "Harare International Airport",
    },
    {
      city: "Bor Safajah",
      code: "HRG",
      name: "Hurghada Airport",
    },
    {
      city: "Kharkiv",
      code: "HRK",
      name: "Kharkov Airport",
    },
    {
      city: "Harlingen",
      code: "HRL",
      name: "Grande Valley International Airport",
    },
    {
      city: "Harrogate",
      code: "HRT",
      name: "Linton-On-Ouse",
    },
    {
      city: "Saga",
      code: "HSG",
      name: "Saga Airport",
    },
    {
      city: "Huslia",
      code: "HSL",
      name: "Alaska",
    },
    {
      city: "Shenjiamen Town",
      code: "HSN",
      name: "Zhoushan Airport",
    },
    {
      city: "Huntsville",
      code: "HSV",
      name: "Huntsville International Airport",
    },
    {
      city: "Chita",
      code: "HTA",
      name: "Chita Airport",
    },
    {
      city: "Dudinka",
      code: "HTG",
      name: "Hatanga Airport",
    },
    {
      city: "Hamilton Island",
      code: "HTI",
      name: "Hamilton Island Airport",
    },
    {
      city: "Hotan",
      code: "HTN",
      name: "Hotan",
    },
    {
      city: "Huntington",
      code: "HTS",
      name: "Tri State Walker Long Field Airport",
    },
    {
      city: "Papeete",
      code: "HUH",
      name: "Huahine Airport",
    },
    {
      city: "Hue",
      code: "HUI",
      name: "Hue-Phu Bai Airport",
    },
    {
      city: "Hualien City",
      code: "HUN",
      name: "Hwmlien Airport",
    },
    {
      city: "Houn",
      code: "HUQ",
      name: "Hon Airport",
    },
    {
      city: "Hughes",
      code: "HUS",
      name: "Hughes",
    },
    {
      city: "Hudiksvall",
      code: "HUV",
      name: "Hudiksvall Airport",
    },
    {
      city: "Santo Domingo de Morelos",
      code: "HUX",
      name: "Bahias de Huatulco International Airport",
    },
    {
      city: "Ulceby",
      code: "HUY",
      name: "Humberside International Airport",
    },
    {
      city: "Huizhou",
      code: "HUZ",
      name: "Huizhou",
    },
    {
      city: "Analalava",
      code: "HVA",
      name: "Analalava Airport",
    },
    {
      city: "Hervey Bay",
      code: "HVB",
      name: "Hervey Bay Airport",
    },
    {
      city: "Khovd",
      code: "HVD",
      name: "Mongolia",
    },
    {
      city: "Honningsvåg",
      code: "HVG",
      name: "Valan Airport",
    },
    {
      city: "East Haven",
      code: "HVN",
      name: "Tweed New Haven Airport",
    },
    {
      city: "Havre",
      code: "HVR",
      name: "Havre City-County Airport",
    },
    {
      city: "Hawassa",
      code: "AWA",
      name: "Hawassa Airport",
    },
    {
      city: "Hyannis",
      code: "HYA",
      name: "Barnstable Boardman Polando Airport",
    },
    {
      city: "Hyderabad",
      code: "HYD",
      name: "Begumpet Airport",
    },
    {
      city: "Hydaburg",
      code: "HYG",
      name: "Alaska",
    },
    {
      city: "Hollis",
      code: "HYL",
      name: "SPB",
    },
    {
      city: "Huangyan",
      code: "HYN",
      name: "",
    },
    {
      city: "Hays",
      code: "HYS",
      name: "Hays Municipal Airport",
    },
    {
      city: "Hanzhong",
      code: "HZG",
      name: "Hanzhong Airport",
    },
    {
      city: "Liping City",
      code: "HZH",
      name: "Liping",
    },
    {
      city: "Igarka",
      code: "IAA",
      name: "Russia",
    },
    {
      city: "Washington",
      code: "IAD",
      name: "Dulles International Airport",
    },
    {
      city: "Niagara Falls",
      code: "IAG",
      name: "Niagara Falls International Airport",
    },
    {
      city: "Houston",
      code: "IAH",
      name: "George Bush Intercontinental Airport",
    },
    {
      city: "Illizi",
      code: "IAM",
      name: "In Amenas Airport",
    },
    {
      city: "Kiana",
      code: "IAN",
      name: "Kiana",
    },
    {
      city: "Ilasi",
      code: "IAS",
      name: "Iasi North Airport",
    },
    {
      city: "Ibadan",
      code: "IBA",
      name: "Ibadan Airport",
    },
    {
      city: "Ibague",
      code: "IBE",
      name: "Perales Airport",
    },
    {
      city: "San José",
      code: "IBZ",
      name: "Ibiza Airport",
    },
    {
      city: "Cicia",
      code: "ICI",
      name: "Cicia Airport",
    },
    {
      city: "Incheon",
      code: "ICN",
      name: "New Incheon International Airport",
    },
    {
      city: "Wichita",
      code: "ICT",
      name: "Wichita Mid-Continent Airport",
    },
    {
      city: "Idaho Falls",
      code: "IDA",
      name: "Fanning Field Airport",
    },
    {
      city: "Indore",
      code: "IDR",
      name: "Devi Ahilyabai Holkar International Airport",
    },
    {
      city: "Babimost",
      code: "IEG",
      name: "Babimost Airport",
    },
    {
      city: "Kiev",
      code: "IEV",
      name: "Zhulyany Airport",
    },
    {
      city: "Isafjordur",
      code: "IFJ",
      name: "Isafjordur Airport",
    },
    {
      city: "Esfahan",
      code: "IFN",
      name: "Esfahan International Airport",
    },
    {
      city: "Ivano-Frankivs'k",
      code: "IFO",
      name: "Ivano-Frankovsk Airport",
    },
    {
      city: "Bullhead City",
      code: "IFP",
      name: "Laughlin-Bullhead International Airport",
    },
    {
      city: "Matthew Town",
      code: "IGA",
      name: "Great Inagua Airport",
    },
    {
      city: "Igiugig",
      code: "IGG",
      name: "Igiugig",
    },
    {
      city: "Kingman",
      code: "IGM",
      name: "Kingman Airport",
    },
    {
      city: "Puerto Esperanza",
      code: "IGR",
      name: "Cataratas del Iguazu Airport",
    },
    {
      city: "Ingolstadt-Manching",
      code: "IGS",
      name: "Ingolstadt-Manching",
    },
    {
      city: "Foz do Iguacu",
      code: "IGU",
      name: "Cataratas International Airport",
    },
    {
      city: "Iran Shahr",
      code: "IHR",
      name: "Iran",
    },
    {
      city: "Ilaam",
      code: "IIL",
      name: "Iran",
    },
    {
      city: "Izhevsk",
      code: "IJK",
      name: "Russia",
    },
    {
      city: "Tehran",
      code: "IKA",
      name: "Imam Khomeini International Airport",
    },
    {
      city: "Nikolski",
      code: "IKO",
      name: "Nikolski",
    },
    {
      city: "Tiksi",
      code: "IKS",
      name: "Tiksi Airport",
    },
    {
      city: "Irkutsk",
      code: "IKT",
      name: "Irkutsk Southeast Airport",
    },
    {
      city: "Nelson House",
      code: "ILF",
      name: "Ilford Airport",
    },
    {
      city: "Iliamna",
      code: "ILI",
      name: "Iliamna Airport",
    },
    {
      city: "Wilmington",
      code: "ILM",
      name: "Wilmington International Airport",
    },
    {
      city: "Wilmington",
      code: "ILN",
      name: "Airborne Airpark",
    },
    {
      city: "Iloilo",
      code: "ILO",
      name: "Iloilo Airport",
    },
    {
      city: "Vao",
      code: "ILP",
      name: "Moue Airport",
    },
    {
      city: "Ilorin",
      code: "ILR",
      name: "Ilorin Airport",
    },
    {
      city: "Glenegedale",
      code: "ILY",
      name: "Islay Airport",
    },
    {
      city: "Bytča",
      code: "ILZ",
      name: "Kotesovo Airport",
    },
    {
      city: "Lilong (Imphal West)",
      code: "IMF",
      name: "Imphal Airport",
    },
    {
      city: "Simikot",
      code: "IMK",
      name: "Nepal",
    },
    {
      city: "Imperatriz",
      code: "IMP",
      name: "Prefeito Renato Moreira Airport",
    },
    {
      city: "Kingsford",
      code: "IMT",
      name: "Ford Airport",
    },
    {
      city: "Yinchuan",
      code: "INC",
      name: "Yinchuan Airport",
    },
    {
      city: "Indianapolis",
      code: "IND",
      name: "Indianapolis International Airport",
    },
    {
      city: "Inhambane",
      code: "INH",
      name: "Inhambane Airport",
    },
    {
      city: "Nis",
      code: "INI",
      name: "Nis Airport",
    },
    {
      city: "International Falls",
      code: "INL",
      name: "Falls International Airport",
    },
    {
      city: "Innsbruck",
      code: "INN",
      name: "Innsbruck Airport",
    },
    {
      city: "Winston-Salem",
      code: "INT",
      name: "Smith Reynolds Airport",
    },
    {
      city: "Yaren",
      code: "INU",
      name: "Nauru International Airport",
    },
    {
      city: "Inverness",
      code: "INV",
      name: "Inverness Airport",
    },
    {
      city: "In Salah",
      code: "INZ",
      name: "In Salah Airport",
    },
    {
      city: "Ioannina",
      code: "IOA",
      name: "Ioannina Airport",
    },
    {
      city: "Castletown",
      code: "IOM",
      name: "Ronaldsway Airport",
    },
    {
      city: "Impfondo",
      code: "ION",
      name: "Impfondo Airport",
    },
    {
      city: "Ilhéus",
      code: "IOS",
      name: "Jorge Amado Airport",
    },
    {
      city: "Ipota",
      code: "IPA",
      name: "",
    },
    {
      city: "Easter Island",
      code: "IPC",
      name: "Mataveri International Airport",
    },
    {
      city: "Ipoh",
      code: "IPH",
      name: "Ipoh Airport",
    },
    {
      city: "Ipiales",
      code: "IPI",
      name: "San Luis Airport",
    },
    {
      city: "Imperial",
      code: "IPL",
      name: "Imperial County Airport",
    },
    {
      city: "Santana do Paraíso",
      code: "IPN",
      name: "Usiminas Airport",
    },
    {
      city: "Montoursville",
      code: "IPT",
      name: "Williamsport-Lycoming County Airport",
    },
    {
      city: "Ipswich",
      code: "IPW",
      name: "Ipswich Airport",
    },
    {
      city: "Qiemo",
      code: "IQM",
      name: "Qiemo",
    },
    {
      city: "Qingyang",
      code: "IQN",
      name: "",
    },
    {
      city: "Alto Hospicio",
      code: "IQQ",
      name: "Diego Aracena International Airport",
    },
    {
      city: "Iquitos",
      code: "IQT",
      name: "Cnl Fap Fran Seca Vignetta Airport",
    },
    {
      city: "Kirakira",
      code: "IRA",
      name: "Kirakira",
    },
    {
      city: "Circle",
      code: "IRC",
      name: "Circle",
    },
    {
      city: "Chamical",
      code: "IRJ",
      name: "Capitan Vicente A Almonacid Airport",
    },
    {
      city: "Irma",
      code: "IRM",
      name: "",
    },
    {
      city: "Mount Isa",
      code: "ISA",
      name: "Mount Isa Airport",
    },
    {
      city: "Råwalpindi",
      code: "ISB",
      name: "Islamabad International Airport",
    },
    {
      city: "Rams Valley",
      code: "ISC",
      name: "Saint Mary's Airport",
    },
    {
      city: "Ishigaki-shi",
      code: "ISG",
      name: "Ishigaki Airport",
    },
    {
      city: "Williston",
      code: "ISN",
      name: "Sloulin Field International Airport",
    },
    {
      city: "Kinston",
      code: "ISO",
      name: "Kinston Jetport Stallings Airport",
    },
    {
      city: "Ronkonkoma",
      code: "ISP",
      name: "Long Island Macarthur Airport",
    },
    {
      city: "Istanbul",
      code: "IST",
      name: "Istanbul Airport",
    },
    {
      city: "Ithaca",
      code: "ITH",
      name: "Tompkins County Airport",
    },
    {
      city: "Itami-shi",
      code: "ITM",
      name: "Osaka International Airport",
    },
    {
      city: "Hilo",
      code: "ITO",
      name: "Hilo International Airport",
    },
    {
      city: "Niue Island",
      code: "IUE",
      name: "Niue",
    },
    {
      city: "Invercargill",
      code: "IVC",
      name: "Invercargill Airport",
    },
    {
      city: "Ivalo",
      code: "IVL",
      name: "Ivalo Airport",
    },
    {
      city: "Inverell",
      code: "IVR",
      name: "Inverell Airport",
    },
    {
      city: "Ironwood",
      code: "IWD",
      name: "Gogebic-Iron County Airport",
    },
    {
      city: "Iwami",
      code: "IWJ",
      name: "Iwami Airport",
    },
    {
      city: "Gandhigram",
      code: "IXA",
      name: "Agartala Airport",
    },
    {
      city: "Bagdogra",
      code: "IXB",
      name: "Bagdogra Airport",
    },
    {
      city: "Bhabat",
      code: "IXC",
      name: "Chandigarh Airport",
    },
    {
      city: "Allahabad",
      code: "IXD",
      name: "Bamrauli Airport",
    },
    {
      city: "Mulur",
      code: "IXE",
      name: "Mangalore Airport",
    },
    {
      city: "Kangrali",
      code: "IXG",
      name: "Belgaum Airport",
    },
    {
      city: "Lilabari",
      code: "IXI",
      name: "India",
    },
    {
      city: "Jammu Cantt",
      code: "IXJ",
      name: "Jammu Airport",
    },
    {
      city: "Leh",
      code: "IXL",
      name: "Leh Airport",
    },
    {
      city: "Harveypatti",
      code: "IXM",
      name: "Madurai Airport",
    },
    {
      city: "Ranchi",
      code: "IXR",
      name: "Birsa Munda Airport",
    },
    {
      city: "Tarapur",
      code: "IXS",
      name: "Kumbhirgram Airport",
    },
    {
      city: "Aurangabad",
      code: "IXU",
      name: "Aurangabad Airport",
    },
    {
      city: "Jamshedpur",
      code: "IXW",
      name: "Sonari Airport",
    },
    {
      city: "Gandhidham",
      code: "IXY",
      name: "Kandla Airport",
    },
    {
      city: "Port Blair",
      code: "IXZ",
      name: "Vir Savarkar Airport",
    },
    {
      city: "Inyokern",
      code: "IYK",
      name: "Inyokern Airport",
    },
    {
      city: "Hikawa-cho",
      code: "IZO",
      name: "Izumo Airport",
    },
    {
      city: "Jackson",
      code: "JAC",
      name: "Jackson Hole Airport",
    },
    {
      city: "Jaipur",
      code: "JAI",
      name: "Sanganer Airport",
    },
    {
      city: "Jalapa",
      code: "JAL",
      name: "Jalapa Airport",
    },
    {
      city: "Pearl",
      code: "JAN",
      name: "Jackson International Airport",
    },
    {
      city: "Ilulissat",
      code: "JAV",
      name: "Ilulissat Airport",
    },
    {
      city: "Jacksonville",
      code: "JAX",
      name: "Jacksonville International Airport",
    },
    {
      city: "Joaçaba",
      code: "JCB",
      name: "Joacaba Airport",
    },
    {
      city: "Qasigiannguit",
      code: "JCH",
      name: "Christianshab Airport",
    },
    {
      city: "Jinka",
      code: "BCO",
      name: "Jinka Airport",
    },
    {
      city: "Julia Creek",
      code: "JCK",
      name: "Julia Creek Aerodrome",
    },
    {
      city: "Ceuta",
      code: "JCU",
      name: "Ceuta Heliport",
    },
    {
      city: "Juiz de Fora",
      code: "JDF",
      name: "Francisco de Assis Airport",
    },
    {
      city: "Jodhpur",
      code: "JDH",
      name: "Jodhpur Airport",
    },
    {
      city: "Juazeiro do Norte",
      code: "JDO",
      name: "Cariri Regional Airport",
    },
    {
      city: "Jingdezhen",
      code: "JDZ",
      name: "Jingde Town",
    },
    {
      city: "Jeddah",
      code: "JED",
      name: "King Abdul Aziz International Airport",
    },
    {
      city: "Holts Summit",
      code: "JEF",
      name: "Jefferson City Memorial Airport",
    },
    {
      city: "Aasiaat",
      code: "JEG",
      name: "Auisiait Airport",
    },
    {
      city: "Jeh",
      code: "JEJ",
      name: "Jeh",
    },
    {
      city: "St. Peter",
      code: "JER",
      name: "Jersey Airport",
    },
    {
      city: "New York",
      code: "JFK",
      name: "John F Kennedy International Airport",
    },
    {
      city: "Paamiut",
      code: "JFR",
      name: "Paamiut",
    },
    {
      city: "Jamnagar",
      code: "JGA",
      name: "Jamnagar Airport",
    },
    {
      city: "Williams",
      code: "JGC",
      name: "Grand Canyon Heliport",
    },
    {
      city: "Jiayuguan",
      code: "JGN",
      name: "Jiayuguan Airport",
    },
    {
      city: "Qeqertarsuaq",
      code: "JGO",
      name: "Godhavn Airport",
    },
    {
      city: "Ji An",
      code: "JGS",
      name: "Ji An/Jing Gang Shan",
    },
    {
      city: "Senai",
      code: "JHB",
      name: "Sultan Ismail Airport",
    },
    {
      city: "Jinghong",
      code: "JHG",
      name: "Gasa",
    },
    {
      city: "Lahaina",
      code: "JHM",
      name: "Kapalua West Maui Airport",
    },
    {
      city: "Sisimiut",
      code: "JHS",
      name: "Holsteinsborg Airport",
    },
    {
      city: "Jamestown",
      code: "JHW",
      name: "Chautauqua County-Jamestown Airport",
    },
    {
      city: "Djibouti City",
      code: "JIB",
      name: "Djibouti Ambouli Airport",
    },
    {
      city: "Jijiga",
      code: "JIJ",
      name: "Jijiga",
    },
    {
      city: "Evdilos",
      code: "JIK",
      name: "Ikaria Island Airport",
    },
    {
      city: "Jimma",
      code: "JIM",
      name: "Jimma Airport",
    },
    {
      city: "Jiujiang",
      code: "JIU",
      name: "",
    },
    {
      city: "Jinjiang",
      code: "JJN",
      name: "Jinjiang",
    },
    {
      city: "Qaqortoq",
      code: "JJU",
      name: "Julianehab Heliport",
    },
    {
      city: "Jonkoping",
      code: "JKG",
      name: "Jonkoping Airport",
    },
    {
      city: "Khios",
      code: "JKH",
      name: "Chios Airport",
    },
    {
      city: "Jackson",
      code: "JKL",
      name: "Julian Carroll Airport",
    },
    {
      city: "Landskrona",
      code: "JLD",
      name: "Landskrona Heliport",
    },
    {
      city: "Webb City",
      code: "JLN",
      name: "Joplin Regional Airport",
    },
    {
      city: "Bilpura",
      code: "JLR",
      name: "Jabalpur Airport",
    },
    {
      city: "Mikonos",
      code: "JMK",
      name: "Mikonos Airport",
    },
    {
      city: "Jamestown",
      code: "JMS",
      name: "Jamestown Municipal Airport",
    },
    {
      city: "Jiamusi",
      code: "JMU",
      name: "Jiamusi",
    },
    {
      city: "Johannesburg",
      code: "JNB",
      name: "OR Tambo International Airport",
    },
    {
      city: "Nanortalik",
      code: "JNN",
      name: "Nanortalik Airport",
    },
    {
      city: "Narsaq",
      code: "JNS",
      name: "Narsaq Heliport",
    },
    {
      city: "Juneau",
      code: "JNU",
      name: "Juneau International Airport",
    },
    {
      city: "Naxos",
      code: "JNX",
      name: "Naxos Airport",
    },
    {
      city: "Jinzhou",
      code: "JNZ",
      name: "Jinzhou",
    },
    {
      city: "Ylämylly",
      code: "JOE",
      name: "Joensuu Airport",
    },
    {
      city: "Yogyakarta",
      code: "JOG",
      name: "Adisucipto Airport",
    },
    {
      city: "Joinville",
      code: "JOI",
      name: "Lauro Carneiro de Loyola Airport",
    },
    {
      city: "Jolo",
      code: "JOL",
      name: "Jolo Airport",
    },
    {
      city: "Santa Rita",
      code: "JPA",
      name: "Presidente Castro Pinto International Airport",
    },
    {
      city: "Ji-Paraná",
      code: "JPR",
      name: "Ji Parana Airport",
    },
    {
      city: "Qaarsut",
      code: "JQA",
      name: "Greenland",
    },
    {
      city: "New York",
      code: "JRB",
      name: "Downtown Manhattan Heliport",
    },
    {
      city: "Senchoa Gaon",
      code: "JRH",
      name: "Jorhat Airport",
    },
    {
      city: "Sanya",
      code: "JRO",
      name: "Kilimanjaro International Airport",
    },
    {
      city: "Jaisalmer",
      code: "JSA",
      name: "Jaisalmer Airport",
    },
    {
      city: "Seteia",
      code: "JSH",
      name: "Sitia Airport",
    },
    {
      city: "Skiathos",
      code: "JSI",
      name: "Skiathos Airport",
    },
    {
      city: "Jessore",
      code: "JSR",
      name: "Jessore Airport",
    },
    {
      city: "Johnstown",
      code: "JST",
      name: "Johnstown Cambria County Airport",
    },
    {
      city: "Maitsoq",
      code: "JSU",
      name: "Maniitsoq Heliport",
    },
    {
      city: "Ano Siros",
      code: "JSY",
      name: "Syros Island Airport",
    },
    {
      city: "Emborion",
      code: "JTR",
      name: "Santorini Airport",
    },
    {
      city: "Astypalaea",
      code: "JTY",
      name: "Astypalaia Island Airport",
    },
    {
      city: "Juba",
      code: "JUB",
      name: "Juba Airport",
    },
    {
      city: "Santa Catalina",
      code: "JUJ",
      name: "Jujuy Airport",
    },
    {
      city: "Juliaca",
      code: "JUL",
      name: "Juliaca Airport",
    },
    {
      city: "Jumla",
      code: "JUM",
      name: "",
    },
    {
      city: "Upernavik",
      code: "JUV",
      name: "Upernavik Heliport",
    },
    {
      city: "Juzhou",
      code: "JUZ",
      name: "Juzhou",
    },
    {
      city: "Ankavandra",
      code: "JVA",
      name: "Toliara",
    },
    {
      city: "Jiroft",
      code: "JYR",
      name: "Jiroft",
    },
    {
      city: "Tikkakoski",
      code: "JYV",
      name: "Jyvaskyla Airport",
    },
    {
      city: "Song Pan",
      code: "JZH",
      name: "Jiu Zhai Huang Long",
    },
    {
      city: "Kariba",
      code: "KAB",
      name: "Kariba Airport",
    },
    {
      city: "Al Qamishli",
      code: "KAC",
      name: "Kamishly Airport",
    },
    {
      city: "Afaka",
      code: "KAD",
      name: "Kaduna Airport",
    },
    {
      city: "Paltaniemi",
      code: "KAJ",
      name: "Kajaani Airport",
    },
    {
      city: "Kaltag",
      code: "KAL",
      name: "Kaltag",
    },
    {
      city: "",
      code: "KAN",
      name: "Kano Mallam Aminu International Airport",
    },
    {
      city: "Kuusamo",
      code: "KAO",
      name: "Kuusamo Airport",
    },
    {
      city: "Kaitaia",
      code: "KAT",
      name: "Kaitaia Aerodrome",
    },
    {
      city: "Kawthaung",
      code: "KAW",
      name: "Kawthaung Airport",
    },
    {
      city: "Kalbarri",
      code: "KAX",
      name: "Kalbarri",
    },
    {
      city: "Birch Creek",
      code: "KBC",
      name: "Birch Creek Airport",
    },
    {
      city: "Kabul",
      code: "KBL",
      name: "Kabul International Airport",
    },
    {
      city: "Kiev",
      code: "KBP",
      name: "Borispol Airport",
    },
    {
      city: "Kota Baharu",
      code: "KBR",
      name: "Sultan Ismail Petra Airport",
    },
    {
      city: "Krabi",
      code: "KBV",
      name: "Thailand",
    },
    {
      city: "Kuqa",
      code: "KCA",
      name: "Kuqa",
    },
    {
      city: "Coffman Cove",
      code: "KCC",
      name: "Alaska",
    },
    {
      city: "Kadanwari",
      code: "KCF",
      name: "Pakistan",
    },
    {
      city: "Chignik Lagoon",
      code: "KCG",
      name: "Chignik Fisheries Airport",
    },
    {
      city: "Kuching",
      code: "KCH",
      name: "Kuching Airport",
    },
    {
      city: "Chignik Lagoon",
      code: "KCL",
      name: "Chignik Lagoon Airport",
    },
    {
      city: "Kahramanmaraş",
      code: "KCM",
      name: "Kahramanmaras Airport",
    },
    {
      city: "Chignik",
      code: "KCQ",
      name: "Chignik Lake Airport",
    },
    {
      city: "Nankoku-shi",
      code: "KCZ",
      name: "Kochi Airport",
    },
    {
      city: "Alaqadari Daman",
      code: "KDH",
      name: "Kandahar International Airport",
    },
    {
      city: "Kendari",
      code: "KDI",
      name: "Wolter Monginsidi Airport",
    },
    {
      city: "Kerdlya",
      code: "KDL",
      name: "Kardla East Airport",
    },
    {
      city: "Kaadedhdhoo",
      code: "KDM",
      name: "Kaadedhdhoo",
    },
    {
      city: "Kadhdhoo",
      code: "KDO",
      name: "Kudadu",
    },
    {
      city: "Skardu",
      code: "KDU",
      name: "Skardu Airport",
    },
    {
      city: "Kandavu",
      code: "KDV",
      name: "Kandavu Airport",
    },
    {
      city: "Nanwalek",
      code: "KEB",
      name: "Nanwalek",
    },
    {
      city: "Reykjavik",
      code: "KEF",
      name: "Keflavik International",
    },
    {
      city: "Kemerovo",
      code: "KEJ",
      name: "Kemerovo Airport",
    },
    {
      city: "Ekwok",
      code: "KEK",
      name: "Ekwok",
    },
    {
      city: "Kemi",
      code: "KEM",
      name: "Kemi Airport",
    },
    {
      city: "Nepalganj",
      code: "KEP",
      name: "",
    },
    {
      city: "Kerman",
      code: "KER",
      name: "Kerman Airport",
    },
    {
      city: "Keng Tung",
      code: "KET",
      name: "Kengtung Airport",
    },
    {
      city: "Keewaywin",
      code: "KEW",
      name: "",
    },
    {
      city: "Kiffa",
      code: "KFA",
      name: "Kiffa Airport",
    },
    {
      city: "False Pass",
      code: "KFP",
      name: "",
    },
    {
      city: "Kananga",
      code: "KGA",
      name: "Kananga Airport",
    },
    {
      city: "Kingscote",
      code: "KGC",
      name: "Kingscote Airport",
    },
    {
      city: "Kaliningrad",
      code: "KGD",
      name: "Kaliningradskaya Oblast",
    },
    {
      city: "Kagau",
      code: "KGE",
      name: "Kagau",
    },
    {
      city: "Qaraghandy",
      code: "KGF",
      name: "Karaganda Airport",
    },
    {
      city: "Kalgoorlie",
      code: "KGI",
      name: "Kalgoorlie Bolder Airport",
    },
    {
      city: "New Koliganek",
      code: "KGK",
      name: "Koliganek Airport",
    },
    {
      city: "Kigali",
      code: "KGL",
      name: "Kigali Airport",
    },
    {
      city: "Kogalym",
      code: "KGP",
      name: "Kogalym International",
    },
    {
      city: "Antimacheia",
      code: "KGS",
      name: "Kos Airport",
    },
    {
      city: "Grayling",
      code: "KGX",
      name: "Alaska",
    },
    {
      city: "Kashi",
      code: "KHG",
      name: "Kashi Airport",
    },
    {
      city: "Kaohsiung City",
      code: "KHH",
      name: "Kaohsiung International Airport",
    },
    {
      city: "Karachi",
      code: "KHI",
      name: "Karachi Civil Airport",
    },
    {
      city: "Khamti",
      code: "KHM",
      name: "",
    },
    {
      city: "Nanchang",
      code: "KHN",
      name: "Nanchang New Airport",
    },
    {
      city: "Khasab",
      code: "KHS",
      name: "Khasab Airport",
    },
    {
      city: "Khabarovsk",
      code: "KHV",
      name: "Khabarovsk Northeast Airport",
    },
    {
      city: "Khoy",
      code: "KHY",
      name: "Iran",
    },
    {
      city: "Kauehi",
      code: "KHZ",
      name: "French Polynesia",
    },
    {
      city: "Tollarp",
      code: "KID",
      name: "Kristianstad Airport",
    },
    {
      city: "Casummit Lake",
      code: "KIF",
      name: "Kingfisher Lake Airport",
    },
    {
      city: "Bandar Abbas",
      code: "KIH",
      name: "Kish Island Airport",
    },
    {
      city: "Niigata-shi",
      code: "KIJ",
      name: "Niigata Airport",
    },
    {
      city: "Kirkuk",
      code: "KIK",
      name: "Kirkuk Airport",
    },
    {
      city: "Kimberley",
      code: "KIM",
      name: "B J Vorster Airport",
    },
    {
      city: "Kingston",
      code: "KIN",
      name: "Norman Manley",
    },
    {
      city: "Farranfore",
      code: "KIR",
      name: "Kerry County Airport",
    },
    {
      city: "Kisumu",
      code: "KIS",
      name: "Kisumu Airport",
    },
    {
      city: "Potamos Kythiron",
      code: "KIT",
      name: "Kithira Airport",
    },
    {
      city: "Chisinau",
      code: "KIV",
      name: "Kishinev Southeast Airport",
    },
    {
      city: "Tajiri-cho",
      code: "KIX",
      name: "Kansai International Airport",
    },
    {
      city: "Kansk",
      code: "KJA",
      name: "Yelovaya Airport",
    },
    {
      city: "Koyuk",
      code: "KKA",
      name: "Koyuk",
    },
    {
      city: "Kitoi Bay",
      code: "KKB",
      name: "Kitoi Seaplane Base",
    },
    {
      city: "Khon Kaen",
      code: "KKC",
      name: "Khon Kaen Airport",
    },
    {
      city: "Kokoda",
      code: "KKD",
      name: "Northern",
    },
    {
      city: "Kerikeri",
      code: "KKE",
      name: "Bay of Islands Airport",
    },
    {
      city: "Kongiganak",
      code: "KKH",
      name: "Alaska",
    },
    {
      city: "Akiachak",
      code: "KKI",
      name: "Akiachak",
    },
    {
      city: "Kita Kyushu",
      code: "KKJ",
      name: "New Kitakyushu Airport",
    },
    {
      city: "Hesseng",
      code: "KKN",
      name: "Kirkenes Hoybuktmoen Airport",
    },
    {
      city: "Kaukura Atoll",
      code: "KKR",
      name: "French Polynesia",
    },
    {
      city: "Clarks Point",
      code: "KKU",
      name: "Ekuk Airport",
    },
    {
      city: "Kalskag",
      code: "KLG",
      name: "Kalskag",
    },
    {
      city: "Kolhapur",
      code: "KLH",
      name: "Kolhapur Airport",
    },
    {
      city: "Levelock",
      code: "KLL",
      name: "Levelock",
    },
    {
      city: "Larsen Bay",
      code: "KLN",
      name: "Larsen Bay",
    },
    {
      city: "Kalibo",
      code: "KLO",
      name: "Kalib0 Airport",
    },
    {
      city: "Kalmar",
      code: "KLR",
      name: "Kalmar Airport",
    },
    {
      city: "Celovec",
      code: "KLU",
      name: "Klagenfurt Airport",
    },
    {
      city: "Carlsbad",
      code: "KLV",
      name: "Karlovy Vary Airport",
    },
    {
      city: "Klawock",
      code: "KLW",
      name: "Klawock Seaplane Base",
    },
    {
      city: "Kalamae",
      code: "KLX",
      name: "Kalamata Airport",
    },
    {
      city: "Kerema",
      code: "KMA",
      name: "Kerema Airport",
    },
    {
      city: "King Khalid Mil. City",
      code: "KMC",
      name: "King Khalid Military",
    },
    {
      city: "Kamembe",
      code: "KME",
      name: "Kamembe Airport",
    },
    {
      city: "Kunming",
      code: "KMG",
      name: "Wuchia Pa Airport",
    },
    {
      city: "Miyazaki-shi",
      code: "KMI",
      name: "Miyazaki Airport",
    },
    {
      city: "Kikuyo-machi",
      code: "KMJ",
      name: "Kumamoto Airport",
    },
    {
      city: "Manokotak",
      code: "KMO",
      name: "Manokotak",
    },
    {
      city: "Komatsu-shi",
      code: "KMQ",
      name: "Komatsu Airport",
    },
    {
      city: "New Tafo",
      code: "KMS",
      name: "Kumasi Airport",
    },
    {
      city: "Kalemyo",
      code: "KMV",
      name: "Kalemyo Airport",
    },
    {
      city: "Moser Bay",
      code: "KMY",
      name: "Moser Bay",
    },
    {
      city: "Kindu",
      code: "KND",
      name: "Kindu Airport",
    },
    {
      city: "Kings Lynn",
      code: "KNF",
      name: "",
    },
    {
      city: "Kaimana",
      code: "KNG",
      name: "West Irian Jaya",
    },
    {
      city: "Kinmen",
      code: "KNH",
      name: "Kinmen County",
    },
    {
      city: "Kakhonak",
      code: "KNK",
      name: "",
    },
    {
      city: "Kone",
      code: "KNQ",
      name: "",
    },
    {
      city: "King Island",
      code: "KNS",
      name: "King Island Airport",
    },
    {
      city: "Kanpur",
      code: "KNU",
      name: "Kanpur Airport",
    },
    {
      city: "New Stuyahok",
      code: "KNW",
      name: "New Stuyahok",
    },
    {
      city: "Durack",
      code: "KNX",
      name: "Kununurra Airport",
    },
    {
      city: "Kailua Kona",
      code: "KOA",
      name: "Kailua-Kona International Airport",
    },
    {
      city: "Koumac",
      code: "KOC",
      name: "",
    },
    {
      city: "Kupang",
      code: "KOE",
      name: "El Tari Airport",
    },
    {
      city: "Kirkwall",
      code: "KOI",
      name: "Kirkwall Airport",
    },
    {
      city: "Kirishima-shi",
      code: "KOJ",
      name: "Kagoshima Airport",
    },
    {
      city: "Kruunupyy",
      code: "KOK",
      name: "Kruunupyy Airport",
    },
    {
      city: "Nakhon Phanom",
      code: "KOP",
      name: "Nakhon Phanom Airport",
    },
    {
      city: "Kotlik",
      code: "KOT",
      name: "Kotlik",
    },
    {
      city: "Koulamoutou",
      code: "KOU",
      name: "",
    },
    {
      city: "Ganzhou",
      code: "KOW",
      name: "Ganzhou Airport",
    },
    {
      city: "Olga Bay",
      code: "KOY",
      name: "Olga Bay",
    },
    {
      city: "Ouzinkie",
      code: "KOZ",
      name: "Alaska",
    },
    {
      city: "Point Baker",
      code: "KPB",
      name: "Point Baker Seaplane Base",
    },
    {
      city: "Brevig Mission",
      code: "KPC",
      name: "Port Clarence Coast Guard Station",
    },
    {
      city: "Kipnuk",
      code: "KPN",
      name: "Kipnuk",
    },
    {
      city: "Pohang-Si",
      code: "KPO",
      name: "Pohang Airport",
    },
    {
      city: "Port Williams",
      code: "KPR",
      name: "Port Williams",
    },
    {
      city: "Perryville",
      code: "KPV",
      name: "Alaska",
    },
    {
      city: "Port Bailey",
      code: "KPY",
      name: "Alaska",
    },
    {
      city: "Akutan",
      code: "KQA",
      name: "Akutan Airport",
    },
    {
      city: "Nyland",
      code: "KRF",
      name: "Kramfors Airport",
    },
    {
      city: "Kikori",
      code: "KRI",
      name: "Papua New Guinea",
    },
    {
      city: "Zabierzów",
      code: "KRK",
      name: "Balice Airport",
    },
    {
      city: "Korla",
      code: "KRL",
      name: "Korla",
    },
    {
      city: "Kiruna",
      code: "KRN",
      name: "Kiruna Airport",
    },
    {
      city: "Kårup",
      code: "KRP",
      name: "Karup Airport",
    },
    {
      city: "Krasnodar",
      code: "KRR",
      name: "Krasnodar-Pashovskiy Airport",
    },
    {
      city: "Kjevic",
      code: "KRS",
      name: "Kristiansand Airport",
    },
    {
      city: "Khartoum",
      code: "KRT",
      name: "Khartoum Airport",
    },
    {
      city: "Karamay",
      code: "KRY",
      name: "Karamay Airport",
    },
    {
      city: "Tofol",
      code: "KSA",
      name: "Kosrae Island Airport",
    },
    {
      city: "Kosice",
      code: "KSC",
      name: "Barca Airport",
    },
    {
      city: "Karlstad",
      code: "KSD",
      name: "Karlstad Airport",
    },
    {
      city: "Kalden",
      code: "KSF",
      name: "Kassel Calden Airport",
    },
    {
      city: "Kermanshah",
      code: "KSH",
      name: "Bakhtaran Airport",
    },
    {
      city: "St. Marina",
      code: "KSJ",
      name: "Kasos Airport",
    },
    {
      city: "Kassala",
      code: "KSL",
      name: "Kassala Airport",
    },
    {
      city: "Saint Marys",
      code: "KSM",
      name: "Alaska",
    },
    {
      city: "Qostanay",
      code: "KSN",
      name: "Kustanay Airport",
    },
    {
      city: "Argos Orestiko",
      code: "KSO",
      name: "Kastoria Airport",
    },
    {
      city: "Qarshi",
      code: "KSQ",
      name: "Karshi South Airport",
    },
    {
      city: "Kristiansund Nord",
      code: "KSU",
      name: "Kristiansund Kvernberget Airport",
    },
    {
      city: "Kars",
      code: "KSY",
      name: "Kars (abandoned) Airport",
    },
    {
      city: "Vel'sk",
      code: "KSZ",
      name: "Kotlas Southeast Airport",
    },
    {
      city: "Karratha",
      code: "KTA",
      name: "Karratha Airport",
    },
    {
      city: "Thorne Bay",
      code: "KTB",
      name: "Alaska",
    },
    {
      city: "Kerteh",
      code: "KTE",
      name: "Kerteh Airport",
    },
    {
      city: "Kathmandu",
      code: "KTM",
      name: "Tribhuvan International Airport",
    },
    {
      city: "Ketchikan",
      code: "KTN",
      name: "Ketchikan International Airport",
    },
    {
      city: "Teller Mission",
      code: "KTS",
      name: "",
    },
    {
      city: "Kittila",
      code: "KTT",
      name: "Kittila Airport",
    },
    {
      city: "Ożarowice",
      code: "KTW",
      name: "Zendek Airport",
    },
    {
      city: "Gambang",
      code: "KUA",
      name: "Kuantan Airport",
    },
    {
      city: "Kudat",
      code: "KUD",
      name: "",
    },
    {
      city: "Syzran'",
      code: "KUF",
      name: "Kurumoch Airport",
    },
    {
      city: "Kubin Island",
      code: "KUG",
      name: "Australia",
    },
    {
      city: "Kushiro",
      code: "KUH",
      name: "Kushiro Airport",
    },
    {
      city: "Kasigluk",
      code: "KUK",
      name: "Kasigluk",
    },
    {
      city: "Sepang",
      code: "KUL",
      name: "Kuala Lumpur International Airport",
    },
    {
      city: "Kovno",
      code: "KUN",
      name: "Karmilava Airport",
    },
    {
      city: "Toivala",
      code: "KUO",
      name: "Kuopio Airport",
    },
    {
      city: "Kulusuk",
      code: "KUS",
      name: "Kulusuk Airport",
    },
    {
      city: "Kutaisi",
      code: "KUT",
      name: "Kopitnari",
    },
    {
      city: "Bhuntar",
      code: "KUU",
      name: "Bhuntar Airport",
    },
    {
      city: "Gunsan-Si",
      code: "KUV",
      name: "Gunsan Airport",
    },
    {
      city: "Khrysoupolis",
      code: "KVA",
      name: "Chrisoupolis Airport",
    },
    {
      city: "Väring",
      code: "KVB",
      name: "Skovde Airport",
    },
    {
      city: "King Cove",
      code: "KVC",
      name: "",
    },
    {
      city: "Gyandzha",
      code: "KVD",
      name: "Elisavetpol",
    },
    {
      city: "Kavieng",
      code: "KVG",
      name: "Kavieng Airport",
    },
    {
      city: "Kirovsk",
      code: "KVK",
      name: "Kirovsk Airport",
    },
    {
      city: "Kivalina",
      code: "KVL",
      name: "Kivalina",
    },
    {
      city: "Carpiquet",
      code: "KVR",
      name: "Carpiquet Airport",
    },
    {
      city: "Kwajalein",
      code: "KWA",
      name: "Bucholz Army Air Field",
    },
    {
      city: "Guiyang",
      code: "KWE",
      name: "Guizhou",
    },
    {
      city: "Kuwait City",
      code: "KWI",
      name: "Kuwait International Airport",
    },
    {
      city: "Gwangju",
      code: "KWJ",
      name: "Gwangju Airport",
    },
    {
      city: "Kwigillingok",
      code: "KWK",
      name: "Kwigillingok",
    },
    {
      city: "Guilin",
      code: "KWL",
      name: "Li Chia Tsun Airport",
    },
    {
      city: "Kowanyama",
      code: "KWM",
      name: "Kowanyama",
    },
    {
      city: "Quinhagak",
      code: "KWN",
      name: "Quinhagak",
    },
    {
      city: "West Point",
      code: "KWP",
      name: "Village Seaplane Base-West Point",
    },
    {
      city: "Kwethluk",
      code: "KWT",
      name: "Kwethluk Airport",
    },
    {
      city: "Kolwezi",
      code: "KWZ",
      name: "Kolwezi Airport",
    },
    {
      city: "Kasaan",
      code: "KXA",
      name: "Kasaan SPB",
    },
    {
      city: "Koro Island",
      code: "KXF",
      name: "Koro Island",
    },
    {
      city: "Komsomol'sk-na-Amure",
      code: "KXK",
      name: "Komsomolsk South Airport",
    },
    {
      city: "Katiu",
      code: "KXU",
      name: "Katiu",
    },
    {
      city: "Konya",
      code: "KYA",
      name: "Konya Airport",
    },
    {
      city: "Kodiak",
      code: "KYK",
      name: "Karluk Airport",
    },
    {
      city: "Milton Keynes",
      code: "KYN",
      name: "England",
    },
    {
      city: "Kyaukpyu",
      code: "KYP",
      name: "Kyaukpyu Airport",
    },
    {
      city: "Kayes",
      code: "KYS",
      name: "Kayes Airport",
    },
    {
      city: "Koyukuk",
      code: "KYU",
      name: "Koyukuk",
    },
    {
      city: "Kyzyl",
      code: "KYZ",
      name: "Tyva",
    },
    {
      city: "Zachar Bay",
      code: "KZB",
      name: "Zachar Bay",
    },
    {
      city: "Kozani",
      code: "KZI",
      name: "Kozani Airport",
    },
    {
      city: "Zelenodol'sk",
      code: "KZN",
      name: "Kirbi Airport",
    },
    {
      city: "Kzyl-Orda",
      code: "KZO",
      name: "Kzyl Orda Airport",
    },
    {
      city: "Kastelorizo",
      code: "KZS",
      name: "Kastelorizo Airport",
    },
    {
      city: "Luanda",
      code: "LAD",
      name: "Luanda 4 de Fevereiro Airport",
    },
    {
      city: "Lae",
      code: "LAE",
      name: "Nadzab Airport",
    },
    {
      city: "Lannion",
      code: "LAI",
      name: "Servel Airport",
    },
    {
      city: "Lages",
      code: "LAJ",
      name: "Lajes Airport",
    },
    {
      city: "Hay River",
      code: "LAK",
      name: "Aklavik Airport",
    },
    {
      city: "Lansing",
      code: "LAN",
      name: "Lansing Capital City Airport",
    },
    {
      city: "San Nicolas",
      code: "LAO",
      name: "Laoag International Airport",
    },
    {
      city: "La Paz",
      code: "LAP",
      name: "General Manuel Marquez de Leon International Air",
    },
    {
      city: "Beida",
      code: "LAQ",
      name: "Al Bayda'",
    },
    {
      city: "Laramie",
      code: "LAR",
      name: "General Brees Field",
    },
    {
      city: "Las Vegas",
      code: "LAS",
      name: "Mccarran International Airport",
    },
    {
      city: "Lamu",
      code: "LAU",
      name: "Lamu Airport",
    },
    {
      city: "Lawton",
      code: "LAW",
      name: "Lawton Municipal Airport",
    },
    {
      city: "Los Angeles",
      code: "LAX",
      name: "Los Angeles International Airport",
    },
    {
      city: "Leeds",
      code: "LBA",
      name: "Leeds Bradford Airport",
    },
    {
      city: "Lubbock",
      code: "LBB",
      name: "Lubbock International Airport",
    },
    {
      city: "Lubeque",
      code: "LBC",
      name: "Lubeck Airport",
    },
    {
      city: "Khudzhand",
      code: "LBD",
      name: "Khujand",
    },
    {
      city: "Latrobe",
      code: "LBE",
      name: "Westmoreland County Airport",
    },
    {
      city: "North Platte",
      code: "LBF",
      name: "Lee Bird Field Airport",
    },
    {
      city: "Ende",
      code: "LBJ",
      name: "Mutiara Airport",
    },
    {
      city: "Liberal",
      code: "LBL",
      name: "Liberal Municipal Airport",
    },
    {
      city: "Long Banga",
      code: "LBP",
      name: "",
    },
    {
      city: "Labasa",
      code: "LBS",
      name: "Northern",
    },
    {
      city: "Victoria",
      code: "LBU",
      name: "Labuan Airport",
    },
    {
      city: "Libreville",
      code: "LBV",
      name: "Libreville Leon M Ba Airport",
    },
    {
      city: "Larnaca",
      code: "LCA",
      name: "Larnaca Airport",
    },
    {
      city: "La Ceiba",
      code: "LCE",
      name: "Goloson International Airport",
    },
    {
      city: "S Esteban",
      code: "LCG",
      name: "La Coruna Airport",
    },
    {
      city: "Lake Charles",
      code: "LCH",
      name: "Lake Charles Regional Airport",
    },
    {
      city: "Lodz",
      code: "LCJ",
      name: "Lodz Lublinek",
    },
    {
      city: "Columbus",
      code: "LCK",
      name: "Rickenbacker International Airport",
    },
    {
      city: "La Chorrera",
      code: "LCR",
      name: "La Chorrera Airport",
    },
    {
      city: "Longyan",
      code: "LCX",
      name: "",
    },
    {
      city: "London",
      code: "LCY",
      name: "London City Airport",
    },
    {
      city: "Londrina",
      code: "LDB",
      name: "Londrina Airport",
    },
    {
      city: "Juillan",
      code: "LDE",
      name: "Ossun Airport",
    },
    {
      city: "Leshukonskoye",
      code: "LDG",
      name: "Leshukonskoye Airport",
    },
    {
      city: "Lord Howe Island",
      code: "LDH",
      name: "Lord Howe Island Airport",
    },
    {
      city: "Lamidanda",
      code: "LDN",
      name: "Lamidanda Airport",
    },
    {
      city: "Lahad Datu",
      code: "LDU",
      name: "Lahad Datu Airport",
    },
    {
      city: "Landivisiau",
      code: "LDV",
      name: "Landivisiau Airport",
    },
    {
      city: "Londonderry",
      code: "LDY",
      name: "City of Derry Airport",
    },
    {
      city: "Learmonth",
      code: "LEA",
      name: "Learmonth Airport",
    },
    {
      city: "West Lebanon",
      code: "LEB",
      name: "Lebanon Municipal Airport",
    },
    {
      city: "St. Petersburg",
      code: "LED",
      name: "Pulkuvo 2 Airport",
    },
    {
      city: "Le Havre",
      code: "LEH",
      name: "",
    },
    {
      city: "Almeria",
      code: "LEI",
      name: "Almeria Airport",
    },
    {
      city: "Schkeuditz",
      code: "LEJ",
      name: "Leipzig-Halle Airport",
    },
    {
      city: "Leon",
      code: "LEN",
      name: "Aeropuero de Bajio",
    },
    {
      city: "Sir Samuel",
      code: "LER",
      name: "Leinster Airport",
    },
    {
      city: "Leticia",
      code: "LET",
      name: "Gen. A.V. Cobo",
    },
    {
      city: "Bureta",
      code: "LEV",
      name: "",
    },
    {
      city: "Lexington",
      code: "LEX",
      name: "Blue Grass Field",
    },
    {
      city: "Lamerd",
      code: "LFM",
      name: "Lamerd",
    },
    {
      city: "Lafayette",
      code: "LFT",
      name: "Lafayette Regional Airport",
    },
    {
      city: "Lome",
      code: "LFW",
      name: "Lome Tokoin Airport",
    },
    {
      city: "Flushing",
      code: "LGA",
      name: "LaGuardia Airport",
    },
    {
      city: "Long Beach",
      code: "LGB",
      name: "Long Beach Daugherty Field Airport",
    },
    {
      city: "Velroux",
      code: "LGG",
      name: "Bierset Airport",
    },
    {
      city: "Deadmans Cay",
      code: "LGI",
      name: "Deadmans Cay Airport",
    },
    {
      city: "Kuah",
      code: "LGK",
      name: "Langkawi International Airport",
    },
    {
      city: "Long Lellang",
      code: "LGL",
      name: "Long Lellang",
    },
    {
      city: "Daraga",
      code: "LGP",
      name: "Legazpi Airport",
    },
    {
      city: "Lago Agrio",
      code: "LGQ",
      name: "Lago Agrio Airport",
    },
    {
      city: "Horley",
      code: "LGW",
      name: "London Gatwick Airport",
    },
    {
      city: "Lahore",
      code: "LHE",
      name: "Lahore Airport",
    },
    {
      city: "Lightning Ridge",
      code: "LHG",
      name: "New South Wales",
    },
    {
      city: "Hounslow",
      code: "LHR",
      name: "London Heathrow Airport",
    },
    {
      city: "Lanzhou",
      code: "LHW",
      name: "Lanzhou Airport",
    },
    {
      city: "Wé",
      code: "LIF",
      name: "Loyalty Islands Airport",
    },
    {
      city: "Limoges",
      code: "LIG",
      name: "Bellegarde Airport",
    },
    {
      city: "Lihue",
      code: "LIH",
      name: "Lihue Airport",
    },
    {
      city: "Fretin",
      code: "LIL",
      name: "Lesquin Airport",
    },
    {
      city: "Ventanilla",
      code: "LIM",
      name: "Jorge Chavez Airport",
    },
    {
      city: "Peschiera Borromeo",
      code: "LIN",
      name: "Linate Airport",
    },
    {
      city: "Pto. Limon",
      code: "LIO",
      name: "Limon International Airport",
    },
    {
      city: "Liberia",
      code: "LIR",
      name: "Tomas Guardia International Airport",
    },
    {
      city: "Lisbon",
      code: "LIS",
      name: "Lisbon Airport",
    },
    {
      city: "Little Rock",
      code: "LIT",
      name: "Adams Field Airport",
    },
    {
      city: "Loi-kaw",
      code: "LIW",
      name: "Loikaw Airport",
    },
    {
      city: "Lijiang City",
      code: "LJG",
      name: "Lijiang",
    },
    {
      city: "Ljubljana",
      code: "LJU",
      name: "Ljubljana Airport",
    },
    {
      city: "Larantuka",
      code: "LKA",
      name: "Larantuka",
    },
    {
      city: "Lakeba",
      code: "LKB",
      name: "Lakemba Island",
    },
    {
      city: "Seattle",
      code: "LKE",
      name: "Lake Union Seaplane Base",
    },
    {
      city: "Lokichoggio",
      code: "LKG",
      name: "Lokichoggio Airport",
    },
    {
      city: "Long Akah",
      code: "LKH",
      name: "Long Akah",
    },
    {
      city: "Lakeselv",
      code: "LKL",
      name: "Banak Airport",
    },
    {
      city: "Leknes",
      code: "LKN",
      name: "Leknes Airport",
    },
    {
      city: "Lucknow",
      code: "LKO",
      name: "Amausi International Airport",
    },
    {
      city: "Lulea",
      code: "LLA",
      name: "Kallax Airport",
    },
    {
      city: "LingLing",
      code: "LLF",
      name: "Lingling Airport",
    },
    {
      city: "Lalibela",
      code: "LLI",
      name: "Ethiopia",
    },
    {
      city: "Alluitsup Paa",
      code: "LLU",
      name: "Alluitsup Paa Airport",
    },
    {
      city: "Lumbadzi",
      code: "LLW",
      name: "Kamuzu International Airport",
    },
    {
      city: "Lake Minchumina",
      code: "LMA",
      name: "Lake Minchumina",
    },
    {
      city: "San Juan de Arama",
      code: "LMC",
      name: "Lamacarena Airport",
    },
    {
      city: "Ahome",
      code: "LMM",
      name: "Los Mochis Airport",
    },
    {
      city: "Limbang",
      code: "LMN",
      name: "Sarawak",
    },
    {
      city: "Caltabellotta",
      code: "LMP",
      name: "Lampedusa Airport",
    },
    {
      city: "Klamath Falls",
      code: "LMT",
      name: "Klamath Falls International Airport",
    },
    {
      city: "Lake Murray",
      code: "LMY",
      name: "Western",
    },
    {
      city: "Lamen Bay",
      code: "LNB",
      name: "Lamen Bay Airport",
    },
    {
      city: "Panngi",
      code: "LNE",
      name: "Lonorore Airport",
    },
    {
      city: "Lincang",
      code: "LNJ",
      name: "Yunnan",
    },
    {
      city: "Lincoln",
      code: "LNK",
      name: "Lincoln Municipal Airport",
    },
    {
      city: "Leonora",
      code: "LNO",
      name: "Leonora Aerodrome",
    },
    {
      city: "Lihir Island",
      code: "LNV",
      name: "Gerrit Denys Island",
    },
    {
      city: "Lanai City",
      code: "LNY",
      name: "Lanai Airport",
    },
    {
      city: "Hoersching",
      code: "LNZ",
      name: "Linz Airport",
    },
    {
      city: "Longana",
      code: "LOD",
      name: "",
    },
    {
      city: "Loja",
      code: "LOH",
      name: "Loja Airport",
    },
    {
      city: "Ikeja",
      code: "LOS",
      name: "Lagos Murtala Muhammed Airport",
    },
    {
      city: "Louisville",
      code: "LOU",
      name: "Bowman Field Airport",
    },
    {
      city: "Frontera",
      code: "LOV",
      name: "Monclova Airport",
    },
    {
      city: "Telde",
      code: "LPA",
      name: "Las Palmas Airport",
    },
    {
      city: "La Paz",
      code: "LPB",
      name: "El Alto International Airport",
    },
    {
      city: "La Pedrera",
      code: "LPD",
      name: "La Pedrera Airport",
    },
    {
      city: "Linkoping",
      code: "LPI",
      name: "Saab Airport",
    },
    {
      city: "Lipetsk",
      code: "LPK",
      name: "Russia",
    },
    {
      city: "Liverpool",
      code: "LPL",
      name: "Liverpool John Lennon Airport",
    },
    {
      city: "Lamap",
      code: "LPM",
      name: "Lamap Airport",
    },
    {
      city: "Lappeenranta",
      code: "LPP",
      name: "Lappeenranta Airport",
    },
    {
      city: "Louangphrabang",
      code: "LPQ",
      name: "Louangphrabang Airport",
    },
    {
      city: "Lopez Island",
      code: "LPS",
      name: "Lopez Island Airport",
    },
    {
      city: "Lampang",
      code: "LPT",
      name: "Lampang Airport",
    },
    {
      city: "Liepaya",
      code: "LPX",
      name: "Liepaja East Airport",
    },
    {
      city: "Le Puy",
      code: "LPY",
      name: "Loudes Airport",
    },
    {
      city: "Puerto Leguízamo",
      code: "LQM",
      name: "Puerto Leguizamo Airport",
    },
    {
      city: "Laredo",
      code: "LRD",
      name: "Laredo International Airport",
    },
    {
      city: "Longreach",
      code: "LRE",
      name: "Longreach Aerodrome",
    },
    {
      city: "La Rochelle",
      code: "LRH",
      name: "Laleu Airport",
    },
    {
      city: "La Romana",
      code: "LRM",
      name: "La Romana Airport",
    },
    {
      city: "Lar",
      code: "LRR",
      name: "Lar",
    },
    {
      city: "Lero",
      code: "LRS",
      name: "Leros Airport",
    },
    {
      city: "Ploemeur",
      code: "LRT",
      name: "Lann Bihoue Airport",
    },
    {
      city: "Losuia",
      code: "LSA",
      name: "Papua New Guinea",
    },
    {
      city: "Compañía Alta",
      code: "LSC",
      name: "La Florida Airport",
    },
    {
      city: "La Crosse",
      code: "LSE",
      name: "La Crosse Municipal Airport",
    },
    {
      city: "Lashio",
      code: "LSH",
      name: "Lashio Airport",
    },
    {
      city: "Shetland",
      code: "LSI",
      name: "Sumburgh Airport",
    },
    {
      city: "Las Piedras",
      code: "LSP",
      name: "Josefa Camejo Airport",
    },
    {
      city: "Trois-Rivières",
      code: "LSS",
      name: "Terre-De-Haut Airport",
    },
    {
      city: "Blessington",
      code: "LST",
      name: "Launceston Airport",
    },
    {
      city: "Lismore",
      code: "LSY",
      name: "Lismore Airport",
    },
    {
      city: "Ghadames",
      code: "LTD",
      name: "Ghadames",
    },
    {
      city: "Altai",
      code: "LTI",
      name: "Altay",
    },
    {
      city: "Djeble",
      code: "LTK",
      name: "Latakia Airport",
    },
    {
      city: "Luton",
      code: "LTN",
      name: "London Luton Airport",
    },
    {
      city: "Comondú",
      code: "LTO",
      name: "Loreto Airport",
    },
    {
      city: "Grimaud",
      code: "LTT",
      name: "La Mole Airport",
    },
    {
      city: "Latacunga",
      code: "LTX",
      name: "",
    },
    {
      city: "Solukhumbu",
      code: "LUA",
      name: "Lukla Airport",
    },
    {
      city: "Luderitz",
      code: "LUD",
      name: "Luderitz Airport",
    },
    {
      city: "Webb",
      code: "LUF",
      name: "Luke Air Force Base",
    },
    {
      city: "Agno",
      code: "LUG",
      name: "Lugano Airport",
    },
    {
      city: "Luxi",
      code: "LUM",
      name: "Mangshi",
    },
    {
      city: "Lusaka",
      code: "LUN",
      name: "Lusaka International Airport",
    },
    {
      city: "Luena",
      code: "LUO",
      name: "Luena",
    },
    {
      city: "Kalaupapa",
      code: "LUP",
      name: "Kalaupapa Airport",
    },
    {
      city: "Villa General Roca",
      code: "LUQ",
      name: "San Luis Airport",
    },
    {
      city: "Point Hope",
      code: "LUR",
      name: "Cape Lisburne Long-Range Radar Station",
    },
    {
      city: "Langgur",
      code: "LUV",
      name: "Indonesia",
    },
    {
      city: "Sandweiler",
      code: "LUX",
      name: "Luxembourg Airport",
    },
    {
      city: "Livingstone",
      code: "LVI",
      name: "Livingstone Airport",
    },
    {
      city: "Laverton",
      code: "LVO",
      name: "Laverton Aerodrome",
    },
    {
      city: "Lewisburg",
      code: "LWB",
      name: "Greenbrier Valley Airport",
    },
    {
      city: "Lewoleba",
      code: "LWE",
      name: "Indonesia",
    },
    {
      city: "Gyumri",
      code: "LWN",
      name: "Gyumri Airport",
    },
    {
      city: "L'viv",
      code: "LWO",
      name: "Sknilov Airport",
    },
    {
      city: "Lewiston",
      code: "LWS",
      name: "Lewiston Nez Perce County Airport",
    },
    {
      city: "Lewistown",
      code: "LWT",
      name: "Lewistown Municipal Airport",
    },
    {
      city: "Lawas",
      code: "LWY",
      name: "Lawas",
    },
    {
      city: "Lhasa",
      code: "LXA",
      name: "Lhasa",
    },
    {
      city: "Luang Namtha",
      code: "LXG",
      name: "Luang Namtha",
    },
    {
      city: "Luxor",
      code: "LXR",
      name: "Luxor Airport",
    },
    {
      city: "Moudhros",
      code: "LXS",
      name: "Limnos Airport",
    },
    {
      city: "Luoyang",
      code: "LYA",
      name: "Luoyang Airport",
    },
    {
      city: "Little Cayman",
      code: "LYB",
      name: "Boddenfield Airport",
    },
    {
      city: "Lyoksele",
      code: "LYC",
      name: "Lycksele Airport",
    },
    {
      city: "Lianyungang",
      code: "LYG",
      name: "Lianyungang",
    },
    {
      city: "Lynchburg",
      code: "LYH",
      name: "Lynchburg Regional Airport",
    },
    {
      city: "Linyi",
      code: "LYI",
      name: "Linyi",
    },
    {
      city: "Shah Faisalabad",
      code: "LYP",
      name: "Faisalabad Airport",
    },
    {
      city: "Longyearbyen",
      code: "LYR",
      name: "Svalbard Longyear Airport",
    },
    {
      city: "Colombier",
      code: "LYS",
      name: "Lyon Airport",
    },
    {
      city: "Arteaga",
      code: "LZC",
      name: "Lazaro Cardenas Airport",
    },
    {
      city: "Linzhou",
      code: "LZH",
      name: "Liuzhou Airport",
    },
    {
      city: "Nangan",
      code: "LZN",
      name: "Nankan",
    },
    {
      city: "Luzhou",
      code: "LZO",
      name: "Luzhou Airport",
    },
    {
      city: "Kanchipuram",
      code: "MAA",
      name: "Chennai International Airport",
    },
    {
      city: "Marabá",
      code: "MAB",
      name: "Maraba Airport",
    },
    {
      city: "Madrid",
      code: "MAD",
      name: "Barajas Airport",
    },
    {
      city: "Midland",
      code: "MAF",
      name: "Midland International Airport",
    },
    {
      city: "Madang",
      code: "MAG",
      name: "Madang Airport",
    },
    {
      city: "Mao",
      code: "MAH",
      name: "Menorca Airport",
    },
    {
      city: "Majuro",
      code: "MAJ",
      name: "Marshall Islands International Airport",
    },
    {
      city: "Malakal",
      code: "MAK",
      name: "Malakal Airport",
    },
    {
      city: "Matamoros",
      code: "MAM",
      name: "General Sevando Canales Airport",
    },
    {
      city: "Manchester",
      code: "MAN",
      name: "Manchester International Airport",
    },
    {
      city: "Manaus",
      code: "MAO",
      name: "Eduardo Gomes International Airport",
    },
    {
      city: "Maracaibo",
      code: "MAR",
      name: "La Chinita International Airport",
    },
    {
      city: "Lorengau",
      code: "MAS",
      name: "Manus Island Airport",
    },
    {
      city: "Papeete",
      code: "MAU",
      name: "Society Islands Airport",
    },
    {
      city: "Mayaguez",
      code: "MAZ",
      name: "Eugenio Maria de Hostos Airport",
    },
    {
      city: "Mombasa",
      code: "MBA",
      name: "Moi International Airport",
    },
    {
      city: "Mmabatho",
      code: "MBD",
      name: "Mmabatho International Airport",
    },
    {
      city: "Monbetsu-shi",
      code: "MBE",
      name: "Okhotsk-Monbetsu Airport",
    },
    {
      city: "Maryborough",
      code: "MBH",
      name: "Maryborough Airport",
    },
    {
      city: "Montego Bay",
      code: "MBJ",
      name: "Sangster International Airport",
    },
    {
      city: "Manistee",
      code: "MBL",
      name: "Manistee County-Blacker Airport",
    },
    {
      city: "Freeland",
      code: "MBS",
      name: "MBS International Airport",
    },
    {
      city: "Masbate",
      code: "MBT",
      name: "Masbate Airport",
    },
    {
      city: "Mbambanakira",
      code: "MBU",
      name: "Mbambanakira",
    },
    {
      city: "Merced",
      code: "MCE",
      name: "Merced Municipal Airport-Macready Field",
    },
    {
      city: "Mcgrath",
      code: "MCG",
      name: "Mcgrath Airport",
    },
    {
      city: "Kansas City",
      code: "MCI",
      name: "Kansas City International Airport",
    },
    {
      city: "Mccook",
      code: "MCK",
      name: "Mccook Municipal Airport",
    },
    {
      city: "Monaco-Ville",
      code: "MCM",
      name: "Monte Carlo Heliport",
    },
    {
      city: "Macon",
      code: "MCN",
      name: "Middle Georgia Regional Airport",
    },
    {
      city: "Orlando",
      code: "MCO",
      name: "Orlando International Airport",
    },
    {
      city: "Macapá",
      code: "MCP",
      name: "Macapa International Airport",
    },
    {
      city: "Muscat",
      code: "MCT",
      name: "Seeb International Airport",
    },
    {
      city: "Mcarthur River",
      code: "MCV",
      name: "",
    },
    {
      city: "Clear Lake",
      code: "MCW",
      name: "Mason City Municipal Airport",
    },
    {
      city: "Khasavyurt",
      code: "MCX",
      name: "Makhachkala-Uytash Airport",
    },
    {
      city: "Mudjimba",
      code: "MCY",
      name: "Maroochydore Aerodrome",
    },
    {
      city: "Maceio",
      code: "MCZ",
      name: "Zumbi dos Palmares International Airport",
    },
    {
      city: "Manado",
      code: "MDC",
      name: "Sam Ratulangi Airport",
    },
    {
      city: "Ríonegro",
      code: "MDE",
      name: "Jose Maria Cordova Airport",
    },
    {
      city: "Mudanjiang",
      code: "MDG",
      name: "Mudanjiang",
    },
    {
      city: "Mbandaka",
      code: "MDK",
      name: "Mbandaka Airport",
    },
    {
      city: "Mandalay",
      code: "MDL",
      name: "Mandalay Airport",
    },
    {
      city: "Mar del Plata",
      code: "MDQ",
      name: "Mar del Plata Airport",
    },
    {
      city: "Lorimers",
      code: "MDS",
      name: "Middle Caicos Airport",
    },
    {
      city: "Middletown",
      code: "MDT",
      name: "Harrisburg International Airport",
    },
    {
      city: "Mendi",
      code: "MDU",
      name: "Papua New Guinea",
    },
    {
      city: "Chicago",
      code: "MDW",
      name: "Chicago Midway International Airport",
    },
    {
      city: "Mendoza",
      code: "MDZ",
      name: "El Plumerillo Airport",
    },
    {
      city: "Macae",
      code: "MEA",
      name: "Macae Airport",
    },
    {
      city: "Manta",
      code: "MEC",
      name: "Eloy Alfaro Airport",
    },
    {
      city: "Al Madinah",
      code: "MED",
      name: "Madinah International Airport",
    },
    {
      city: "Tadine",
      code: "MEE",
      name: "Loyalty Islands Airport",
    },
    {
      city: "Malanje",
      code: "MEG",
      name: "Malanje Airport",
    },
    {
      city: "Mehavn",
      code: "MEH",
      name: "Mehamn Airport",
    },
    {
      city: "Meridian",
      code: "MEI",
      name: "Key Field Airport",
    },
    {
      city: "Melbourne",
      code: "MEL",
      name: "Melbourne International Airport",
    },
    {
      city: "Memphis",
      code: "MEM",
      name: "Memphis International Airport",
    },
    {
      city: "Medan",
      code: "MES",
      name: "Polonia Airport",
    },
    {
      city: "Mexico City",
      code: "MEX",
      name: "Lic Benito Juarez International Airport",
    },
    {
      city: "Meghauli",
      code: "MEY",
      name: "",
    },
    {
      city: "Mcallen",
      code: "MFE",
      name: "Miller International Airport",
    },
    {
      city: "Moala",
      code: "MFJ",
      name: "Moala Airport",
    },
    {
      city: "Matsu",
      code: "MFK",
      name: "Taiwan",
    },
    {
      city: "Macau",
      code: "MFM",
      name: "Macau Airport",
    },
    {
      city: "Central Point",
      code: "MFR",
      name: "Rogue Valley International-Medford Airport",
    },
    {
      city: "Mfuwe",
      code: "MFU",
      name: "Mfuwe Airport",
    },
    {
      city: "Tipitapa",
      code: "MGA",
      name: "Augusto Cesar Sandino International Airport",
    },
    {
      city: "Mount Gambier",
      code: "MGB",
      name: "Mount Gambier Airport",
    },
    {
      city: "Maringa",
      code: "MGF",
      name: "Maringa Domestic Airport",
    },
    {
      city: "Port Shepstone",
      code: "MGH",
      name: "Margate Airport",
    },
    {
      city: "Montgomery",
      code: "MGM",
      name: "Montgomery Regional Airport",
    },
    {
      city: "Mogadishu",
      code: "MGQ",
      name: "Mogadishu Airport",
    },
    {
      city: "Mangaia Island",
      code: "MGS",
      name: "Mangaia",
    },
    {
      city: "Milingimbi",
      code: "MGT",
      name: "Northern Territory",
    },
    {
      city: "Morgantown",
      code: "MGW",
      name: "Morgantown Municipal Airport-Hart Field",
    },
    {
      city: "Mergui",
      code: "MGZ",
      name: "Mergui Airport",
    },
    {
      city: "Mashhad",
      code: "MHD",
      name: "Mashhad Airport",
    },
    {
      city: "Mannheim",
      code: "MHG",
      name: "Mannheim City Airport",
    },
    {
      city: "Marsh Harbour",
      code: "MHH",
      name: "Marsh Harbour Airport",
    },
    {
      city: "Manhattan",
      code: "MHK",
      name: "Manhattan Municipal Airport",
    },
    {
      city: "Minsk",
      code: "MHP",
      name: "Minsk International 1",
    },
    {
      city: "Maarianhamina",
      code: "MHQ",
      name: "Mariehamn Airport",
    },
    {
      city: "Mather",
      code: "MHR",
      name: "Mather Airport",
    },
    {
      city: "Manchester",
      code: "MHT",
      name: "Manchester-Boston Regional Airport",
    },
    {
      city: "Miami",
      code: "MIA",
      name: "Miami International Airport",
    },
    {
      city: "Mérida",
      code: "MID",
      name: "Lic M Crecencio Rejon International Airport",
    },
    {
      city: "Mian Yang",
      code: "MIG",
      name: "Mian Yang",
    },
    {
      city: "Marilia",
      code: "MII",
      name: "Dr Gastao Vidigal Airport",
    },
    {
      city: "Merimbula",
      code: "MIM",
      name: "Merimbula Aerodrome",
    },
    {
      city: "Sidi al Ghudamisi",
      code: "MIR",
      name: "Habib Bourguiba International Airport",
    },
    {
      city: "Misima Island",
      code: "MIS",
      name: "Saint Aignan Island",
    },
    {
      city: "Manja",
      code: "MJA",
      name: "Toliara",
    },
    {
      city: "Mohenjodaro",
      code: "MJD",
      name: "Moenjodaro Airport",
    },
    {
      city: "Mosjoen",
      code: "MJF",
      name: "Kjaerstad Airport",
    },
    {
      city: "Mitiga",
      code: "MJI",
      name: "Libya",
    },
    {
      city: "Monkey Mia",
      code: "MJK",
      name: "Shark Bay Airport",
    },
    {
      city: "Mouila",
      code: "MJL",
      name: "Ngounie",
    },
    {
      city: "Mbuji Mayi",
      code: "MJM",
      name: "Mbuji Mayi Airport",
    },
    {
      city: "Mahajanga",
      code: "MJN",
      name: "Mahajanga Amborovy Airport",
    },
    {
      city: "Mitilini",
      code: "MJT",
      name: "Mitilini Airport",
    },
    {
      city: "San Javier",
      code: "MJV",
      name: "Murcia San Javier Airport",
    },
    {
      city: "Mirnyj",
      code: "MJZ",
      name: "Mirnyy",
    },
    {
      city: "Kansas City",
      code: "MKC",
      name: "Kansas City Downtown Airport",
    },
    {
      city: "Milwaukee",
      code: "MKE",
      name: "General Mitchell International Airport",
    },
    {
      city: "Muskegon",
      code: "MKG",
      name: "Muskegon County Airport",
    },
    {
      city: "Hoolehua",
      code: "MKK",
      name: "Molokai Airport",
    },
    {
      city: "Mukah",
      code: "MKM",
      name: "Sarawak",
    },
    {
      city: "Makemo",
      code: "MKP",
      name: "Makemo Airport",
    },
    {
      city: "Merauke",
      code: "MKQ",
      name: "Mopah Airport",
    },
    {
      city: "Kumarina",
      code: "MKR",
      name: "Meekatharra Airport",
    },
    {
      city: "Makokou",
      code: "MKU",
      name: "Makokou Airport",
    },
    {
      city: "Manokwari",
      code: "MKW",
      name: "Rendani Airport",
    },
    {
      city: "Mackay",
      code: "MKY",
      name: "Mackay Airport",
    },
    {
      city: "Curmi",
      code: "MLA",
      name: "Luqa Airport",
    },
    {
      city: "Melbourne",
      code: "MLB",
      name: "Melbourne International Airport",
    },
    {
      city: "Male",
      code: "MLE",
      name: "Male International Airport",
    },
    {
      city: "Malang",
      code: "MLG",
      name: "Malang",
    },
    {
      city: "Basel Mulhouse Freiburg",
      code: "MLH",
      name: "Bale Mulhouse Airport",
    },
    {
      city: "Coal Valley",
      code: "MLI",
      name: "Quad City Airport",
    },
    {
      city: "Marshall",
      code: "MLL",
      name: "Marshall",
    },
    {
      city: "Álvaro Obregón",
      code: "MLM",
      name: "General Francisco J Mujica Airport",
    },
    {
      city: "Melilla",
      code: "MLN",
      name: "Melilla Airport",
    },
    {
      city: "Apollonia",
      code: "MLO",
      name: "Milos Island Airport",
    },
    {
      city: "Monroe",
      code: "MLU",
      name: "Monroe Regional Airport",
    },
    {
      city: "Monrovia",
      code: "MLW",
      name: "Monrovia Spriggs Payne Airport",
    },
    {
      city: "Arga",
      code: "MLX",
      name: "Erhac Airport",
    },
    {
      city: "Manley Hot Springs",
      code: "MLY",
      name: "Manley Hot Springs",
    },
    {
      city: "Ozora-cho",
      code: "MMB",
      name: "Memanbetsu Airport",
    },
    {
      city: "Darlington",
      code: "MME",
      name: "Durham Tees Valley Airport",
    },
    {
      city: "Mount Magnet",
      code: "MMG",
      name: "Western Australia",
    },
    {
      city: "Mammoth Lakes",
      code: "MMH",
      name: "Mammoth June Lakes Airport",
    },
    {
      city: "Apatity",
      code: "MMK",
      name: "Murmashi Airport",
    },
    {
      city: "Vila do Maio",
      code: "MMO",
      name: "Maio Airport",
    },
    {
      city: "Svedala",
      code: "MMX",
      name: "Sturup Airport",
    },
    {
      city: "Miyako Jima",
      code: "MMY",
      name: "Miyako Airport",
    },
    {
      city: "Mana Island",
      code: "MNF",
      name: "Mana Island Airstrip",
    },
    {
      city: "Maningrida",
      code: "MNG",
      name: "Maningrida Airport",
    },
    {
      city: "Mananjary",
      code: "MNJ",
      name: "Mananjary Airport",
    },
    {
      city: "Parañaque",
      code: "MNL",
      name: "Ninoy Aquino International Airport",
    },
    {
      city: "Minto",
      code: "MNT",
      name: "Minto",
    },
    {
      city: "Moulmein",
      code: "MNU",
      name: "Moulmein Airport",
    },
    {
      city: "Moa",
      code: "MOA",
      name: "",
    },
    {
      city: "Mobile",
      code: "MOB",
      name: "Mobile Regional Airport",
    },
    {
      city: "Montes Claros",
      code: "MOC",
      name: "Montes Claros Airport",
    },
    {
      city: "Modesto",
      code: "MOD",
      name: "Modesto City County Airport-Harry Sham Field",
    },
    {
      city: "Maumere",
      code: "MOF",
      name: "Wai Oti Airport",
    },
    {
      city: "Mong Hsat",
      code: "MOG",
      name: "",
    },
    {
      city: "Mitiaro Island",
      code: "MOI",
      name: "",
    },
    {
      city: "Bolsøya",
      code: "MOL",
      name: "Aro Airport",
    },
    {
      city: "Morondava",
      code: "MOQ",
      name: "Morondava Airport",
    },
    {
      city: "Minot",
      code: "MOT",
      name: "Minot International Airport",
    },
    {
      city: "Mountain Village",
      code: "MOU",
      name: "Mountain Village",
    },
    {
      city: "Moranbah",
      code: "MOV",
      name: "Moranbah Airport",
    },
    {
      city: "Papeete",
      code: "MOZ",
      name: "Society Islands Airport",
    },
    {
      city: "Mpacha",
      code: "MPA",
      name: "Mpacha Airport",
    },
    {
      city: "Caticlan",
      code: "MPH",
      name: "Malay",
    },
    {
      city: "Mauguio",
      code: "MPL",
      name: "Frejorgues Airport",
    },
    {
      city: "Maputo",
      code: "MPM",
      name: "Maputo Airport",
    },
    {
      city: "Mount Pleasant",
      code: "MPN",
      name: "Mount Pleasant Airport",
    },
    {
      city: "Chelyabinsk",
      code: "MQF",
      name: "Magnitogorsk",
    },
    {
      city: "Mildura",
      code: "MQL",
      name: "Mildura Airport",
    },
    {
      city: "Mardin",
      code: "MQM",
      name: "Mardin",
    },
    {
      city: "Skonseng",
      code: "MQN",
      name: "Rossvoll Airport",
    },
    {
      city: "Nelspruit",
      code: "MQP",
      name: "Nelspruit Airport",
    },
    {
      city: "Marquette",
      code: "MQT",
      name: "Sawyer International Airport",
    },
    {
      city: "Mekelle, Ethiopia",
      code: "MQX",
      name: "Alula Aba Nega Airport",
    },
    {
      city: "Misratah",
      code: "MRA",
      name: "Misurata Airport",
    },
    {
      city: "Ejido",
      code: "MRD",
      name: "Alberto Carnevalli Airport",
    },
    {
      city: "Mara Lodges",
      code: "MRE",
      name: "Mara Serena Airport",
    },
    {
      city: "Marignane",
      code: "MRS",
      name: "Marignane Airport",
    },
    {
      city: "Mahebourg",
      code: "MRU",
      name: "Plaisance International Airport",
    },
    {
      city: "Mineralnye Vody",
      code: "MRV",
      name: "Mineral'nyye Vody",
    },
    {
      city: "Monterey",
      code: "MRY",
      name: "Monterey Peninsula Airport",
    },
    {
      city: "Moree",
      code: "MRZ",
      name: "Moree Airport",
    },
    {
      city: "Muskrat Dam",
      code: "MSA",
      name: "Muskrat Dam",
    },
    {
      city: "Manston",
      code: "MSE",
      name: "Kent International Airport",
    },
    {
      city: "Misawa-shi",
      code: "MSJ",
      name: "Misawa Airport",
    },
    {
      city: "Muscle Shoals",
      code: "MSL",
      name: "Muscle Shoals Regional Airport",
    },
    {
      city: "Madison",
      code: "MSN",
      name: "Dane County Regional Airport-Truax Field",
    },
    {
      city: "Missoula",
      code: "MSO",
      name: "Missoula International Airport",
    },
    {
      city: "St. Paul",
      code: "MSP",
      name: "Minneapolis St Paul International Airport",
    },
    {
      city: "Minsk",
      code: "MSQ",
      name: "Velikiydvor Airport",
    },
    {
      city: "Mush",
      code: "MSR",
      name: "Mus Airport",
    },
    {
      city: "Massena",
      code: "MSS",
      name: "Massena International Airport",
    },
    {
      city: "Maastricht-Airport",
      code: "MST",
      name: "Maastricht Airport",
    },
    {
      city: "Maseru",
      code: "MSU",
      name: "Maseru Moshoeshoe Airport",
    },
    {
      city: "Massawa",
      code: "MSW",
      name: "Massawa",
    },
    {
      city: "Kenner",
      code: "MSY",
      name: "New Orleans International Airport",
    },
    {
      city: "Namibe",
      code: "MSZ",
      name: "Namibe",
    },
    {
      city: "Montrose",
      code: "MTJ",
      name: "Montrose Regional Airport",
    },
    {
      city: "Metlakatla",
      code: "MTM",
      name: "Metlakatla Sea Plane Base",
    },
    {
      city: "Los Garzones",
      code: "MTR",
      name: "Los Garzones Airport",
    },
    {
      city: "Manzini",
      code: "MTS",
      name: "Matsapa International Airport",
    },
    {
      city: "Minatitlan",
      code: "MTT",
      name: "Minatitlan Airport",
    },
    {
      city: "Mota Lava",
      code: "MTV",
      name: "Mota Lava",
    },
    {
      city: "Pesquería",
      code: "MTY",
      name: "Gen Mariano Escobedo International Airport",
    },
    {
      city: "Munda",
      code: "MUA",
      name: "Munda Airport",
    },
    {
      city: "Maun",
      code: "MUB",
      name: "Maun Airport",
    },
    {
      city: "Oberding",
      code: "MUC",
      name: "Franz-Josef-Strauss Airport",
    },
    {
      city: "Kamuela",
      code: "MUE",
      name: "Waimea Kohala Airport",
    },
    {
      city: "Marsa Matruh",
      code: "MUH",
      name: "Mersa Matruh Airport",
    },
    {
      city: "Mauke Island",
      code: "MUK",
      name: "Mauke Island",
    },
    {
      city: "Aguasay",
      code: "MUN",
      name: "Maturin Airport",
    },
    {
      city: "Miri",
      code: "MUR",
      name: "Marudi Airport",
    },
    {
      city: "Multan",
      code: "MUX",
      name: "Multan Airport",
    },
    {
      city: "Musoma",
      code: "MUZ",
      name: "Mara",
    },
    {
      city: "Franceville",
      code: "MVB",
      name: "Franceville Mvengue Airport",
    },
    {
      city: "Montevideo",
      code: "MVD",
      name: "Carrasco International Airport",
    },
    {
      city: "Mitu",
      code: "MVP",
      name: "Mitu Airport",
    },
    {
      city: "Maroua",
      code: "MVR",
      name: "Maroua Salak Airport",
    },
    {
      city: "Mucuri",
      code: "MVS",
      name: "Aeroporto Max Feffer",
    },
    {
      city: "Mataiva",
      code: "MVT",
      name: "French Polynesia",
    },
    {
      city: "Vineyard Haven",
      code: "MVY",
      name: "Marthas Vineyard Airport",
    },
    {
      city: "Marion",
      code: "MWA",
      name: "Williamson County Regional Airport",
    },
    {
      city: "Maewo",
      code: "MWF",
      name: "Vanuatu",
    },
    {
      city: "Mwadui",
      code: "MWN",
      name: "Mwadui",
    },
    {
      city: "Magwe",
      code: "MWQ",
      name: "Magwe",
    },
    {
      city: "Ilemera",
      code: "MWZ",
      name: "Mwanza Airport",
    },
    {
      city: "Moro",
      code: "MXH",
      name: "Papua New Guinea",
    },
    {
      city: "Mexicali",
      code: "MXL",
      name: "Gen Rodolfo Sanchez T International Airport",
    },
    {
      city: "Morombe",
      code: "MXM",
      name: "Morombe Airport",
    },
    {
      city: "Morlaix",
      code: "MXN",
      name: "Ploujean Airport",
    },
    {
      city: "Cardano al Campo",
      code: "MXP",
      name: "Malpensa International Airport",
    },
    {
      city: "Maintirano",
      code: "MXT",
      name: "",
    },
    {
      city: "Mörön",
      code: "MXV",
      name: "Moron Airport",
    },
    {
      city: "Mora",
      code: "MXX",
      name: "Siljan Airport",
    },
    {
      city: "Meixian",
      code: "MXZ",
      name: "Meixian",
    },
    {
      city: "Bingie",
      code: "MYA",
      name: "Moruya Aerodrome",
    },
    {
      city: "Mombasa",
      code: "MYD",
      name: "Malindi Airport",
    },
    {
      city: "Miyake-mura",
      code: "MYE",
      name: "Miyakejima Airport",
    },
    {
      city: "Abraham Bay",
      code: "MYG",
      name: "Miltary & Civil Airport",
    },
    {
      city: "Murray Island",
      code: "MYI",
      name: "Murray Island",
    },
    {
      city: "Matsuyama-shi",
      code: "MYJ",
      name: "Matsuyama Airport",
    },
    {
      city: "Mccall",
      code: "MYL",
      name: "Mccall Airport",
    },
    {
      city: "Myrtle Beach",
      code: "MYR",
      name: "Myrtle Beach International Airport",
    },
    {
      city: "Myitkyina",
      code: "MYT",
      name: "Myitkyina Airport",
    },
    {
      city: "Mekoryuk",
      code: "MYU",
      name: "Mekoryuk",
    },
    {
      city: "Ziwani",
      code: "MYW",
      name: "Mtwara Airport",
    },
    {
      city: "Miri",
      code: "MYY",
      name: "Miri Airport",
    },
    {
      city: "Makung City",
      code: "MZG",
      name: "Magong Airport",
    },
    {
      city: "Merzifon",
      code: "MZH",
      name: "Merzifon",
    },
    {
      city: "Mopti",
      code: "MZI",
      name: "Mopti Barbe Airport",
    },
    {
      city: "Villamaría",
      code: "MZL",
      name: "La Nubia Airport",
    },
    {
      city: "Manzanillo",
      code: "MZO",
      name: "Manzanillo Airport",
    },
    {
      city: "Mazar-i-Sharif",
      code: "MZR",
      name: "Mazar I Sharif Airport",
    },
    {
      city: "Mazatlán",
      code: "MZT",
      name: "General Rafael Buelna International Airport",
    },
    {
      city: "Mulu",
      code: "MZV",
      name: "Mulu Airport",
    },
    {
      city: "Bohena Creek",
      code: "NAA",
      name: "Narrabri Airport",
    },
    {
      city: "Naracoorte",
      code: "NAC",
      name: "Naracoorte Airport",
    },
    {
      city: "Nagpur",
      code: "NAG",
      name: "Sonegaon Airport",
    },
    {
      city: "Nakhichevan",
      code: "NAJ",
      name: "",
    },
    {
      city: "Nadi",
      code: "NAN",
      name: "Nadi International Airport",
    },
    {
      city: "Nanchong",
      code: "NAO",
      name: "Nanchong Airport",
    },
    {
      city: "Naples",
      code: "NAP",
      name: "Naples International Airport",
    },
    {
      city: "Qaanaaq",
      code: "NAQ",
      name: "Qaanaaq",
    },
    {
      city: "Nassau",
      code: "NAS",
      name: "Nassau International Airport",
    },
    {
      city: "Natal",
      code: "NAT",
      name: "Augusto Severo International Airport",
    },
    {
      city: "Napuka Island",
      code: "NAU",
      name: "Napuka Island",
    },
    {
      city: "Nevsehir",
      code: "NAV",
      name: "",
    },
    {
      city: "Narathiwat",
      code: "NAW",
      name: "Narathiwat Airport",
    },
    {
      city: "Naberevnye Chelny",
      code: "NBC",
      name: "Russia",
    },
    {
      city: "Nairobi",
      code: "NBO",
      name: "Jomo Kenyatta International Airport",
    },
    {
      city: "Caimanera",
      code: "NBW",
      name: "Guantanamo Bay Naval Air Station",
    },
    {
      city: "Nabire",
      code: "NBX",
      name: "Nabire Airport",
    },
    {
      city: "Bottle Creek Settlements",
      code: "NCA",
      name: "North Caicos Airport",
    },
    {
      city: "Nice",
      code: "NCE",
      name: "Nice-Cote d'Azur Airport",
    },
    {
      city: "Newcastle",
      code: "NCL",
      name: "Newcastle International Airport",
    },
    {
      city: "New Chenega",
      code: "NCN",
      name: "Chenega",
    },
    {
      city: "Nukus",
      code: "NCU",
      name: "",
    },
    {
      city: "Pringy",
      code: "NCY",
      name: "Meythet Airport",
    },
    {
      city: "Port-Etienne",
      code: "NDB",
      name: "Nouadhibou Airport",
    },
    {
      city: "Qiqihar",
      code: "NDG",
      name: "Qiqihar",
    },
    {
      city: "Berlin",
      code: "QYG",
      name: "Railway Germany Airport",
    },
    {
      city: "N'Djamena",
      code: "NDJ",
      name: "Ndjamena Airport",
    },
    {
      city: "Nador",
      code: "NDR",
      name: "Nador Airport",
    },
    {
      city: "Neryungri",
      code: "NER",
      name: "",
    },
    {
      city: "Nevis",
      code: "NEV",
      name: "Newcastle Airport",
    },
    {
      city: "",
      code: "NFO",
      name: "Niuafo'ou Airport",
    },
    {
      city: "Jiangshan",
      code: "NGB",
      name: "Ningbo Airport",
    },
    {
      city: "Ngaoundéré",
      code: "NGE",
      name: "Ngaoundere Airport",
    },
    {
      city: "Ngau Island",
      code: "NGI",
      name: "Ngau Island",
    },
    {
      city: "Tokoname-shi",
      code: "NGO",
      name: "Chubu International Airport",
    },
    {
      city: "Omura-shi",
      code: "NGS",
      name: "Nagasaki Airport",
    },
    {
      city: "Nha Trang",
      code: "NHA",
      name: "Nha-Trang Airport",
    },
    {
      city: "Nuku Hiva",
      code: "NHV",
      name: "Marquesas Islands Airport",
    },
    {
      city: "Nikolai",
      code: "NIB",
      name: "Nikolai",
    },
    {
      city: "Niamey",
      code: "NIM",
      name: "Niamey Airport",
    },
    {
      city: "Jacksonville",
      code: "NIP",
      name: "Jacksonville Naval Air Station",
    },
    {
      city: "Honolulu",
      code: "NIU",
      name: "Honolulu International Airport",
    },
    {
      city: "Nizhnevartovsk",
      code: "NJC",
      name: "Nizhnevartovsk Northwest Airport",
    },
    {
      city: "Nouakchott",
      code: "NKC",
      name: "Nouakchott Airport",
    },
    {
      city: "Nanjing",
      code: "NKG",
      name: "Nanjing Lukou International Airport",
    },
    {
      city: "Naukiti",
      code: "NKI",
      name: "Naukiti Airport",
    },
    {
      city: "Toyoyama-cho",
      code: "NKM",
      name: "Nagoya Airport",
    },
    {
      city: "Ndola",
      code: "NLA",
      name: "Ndola Airport",
    },
    {
      city: "Nuevo Laredo",
      code: "NLD",
      name: "Quetzalcoatl International Airport",
    },
    {
      city: "Kubin Village",
      code: "NLF",
      name: "Darnley Island Airport",
    },
    {
      city: "Nelson Lagoon",
      code: "NLG",
      name: "",
    },
    {
      city: "Kingston",
      code: "NLK",
      name: "Norfolk Island Airport",
    },
    {
      city: "Mykolayiv",
      code: "NLV",
      name: "Nikolaev Airport",
    },
    {
      city: "Namangan",
      code: "NMA",
      name: "Namangan Airport",
    },
    {
      city: "Nightmute",
      code: "NME",
      name: "Nightmute Airport",
    },
    {
      city: "Santa Ana",
      code: "NNB",
      name: "Makira",
    },
    {
      city: "Wuxu",
      code: "NNG",
      name: "Nanning-Wuyu Airport",
    },
    {
      city: "Nondalton",
      code: "NNL",
      name: "",
    },
    {
      city: "Naryan-Mar",
      code: "NNM",
      name: "",
    },
    {
      city: "Nan",
      code: "NNT",
      name: "Nan Airport",
    },
    {
      city: "Nanyang",
      code: "NNY",
      name: "Nanyang",
    },
    {
      city: "Nosara",
      code: "NOB",
      name: "Nosara Beach Airport",
    },
    {
      city: "Knock",
      code: "NOC",
      name: "Connaught Airport",
    },
    {
      city: "Nojabrxsk",
      code: "NOJ",
      name: "",
    },
    {
      city: "Hell-Ville",
      code: "NOS",
      name: "Nosy Be Fascene Airport",
    },
    {
      city: "",
      code: "NOU",
      name: "La Tontouta Airport",
    },
    {
      city: "Huambo",
      code: "NOV",
      name: "Huambo Airport",
    },
    {
      city: "Novokuznetsk",
      code: "NOZ",
      name: "Kemerovskaya Oblast",
    },
    {
      city: "Napier",
      code: "NPE",
      name: "Hawkes Bay Airport",
    },
    {
      city: "New Plymouth",
      code: "NPL",
      name: "New Plymouth Airport",
    },
    {
      city: "Neuquen",
      code: "NQN",
      name: "Neuquen Airport",
    },
    {
      city: "Nuquí",
      code: "NQU",
      name: "Nuqui Airport",
    },
    {
      city: "Newquay",
      code: "NQY",
      name: "St Mawgan Airport",
    },
    {
      city: "Narrandera",
      code: "NRA",
      name: "Narrandera Leeton Aerodrome",
    },
    {
      city: "Norrkoping",
      code: "NRK",
      name: "Kungsangen Airport",
    },
    {
      city: "Weeze",
      code: "NRN",
      name: "Airport Weeze",
    },
    {
      city: "Narita-shi",
      code: "NRT",
      name: "Narita International Airport",
    },
    {
      city: "Now Shahr",
      code: "NSH",
      name: "",
    },
    {
      city: "Yaounde",
      code: "NSI",
      name: "Nsimalen Airport",
    },
    {
      city: "Kansk",
      code: "NSK",
      name: "Norilsk Alykel Airport",
    },
    {
      city: "Nelson",
      code: "NSN",
      name: "Nelson Airport",
    },
    {
      city: "Phra Phrom",
      code: "NST",
      name: "Nakhon Si Thammarat Airport",
    },
    {
      city: "Bouguenais",
      code: "NTE",
      name: "Chateau Bougon Airport",
    },
    {
      city: "Nantong",
      code: "NTG",
      name: "Nantong Airport",
    },
    {
      city: "Ferodale",
      code: "NTL",
      name: "Williamtown Airport",
    },
    {
      city: "Normanton",
      code: "NTN",
      name: "Normanton",
    },
    {
      city: "Anamizu-machi",
      code: "NTQ",
      name: "Noto Airport",
    },
    {
      city: "Niuatoputapu",
      code: "NTT",
      name: "Niuatoputapu Airport",
    },
    {
      city: "Nuremberg",
      code: "NUE",
      name: "Nurnberg Airport",
    },
    {
      city: "Nuiqsut",
      code: "NUI",
      name: "Nuiqsut",
    },
    {
      city: "Nukutavake",
      code: "NUK",
      name: "Nukutavake",
    },
    {
      city: "Nulato",
      code: "NUL",
      name: "Nulato Airport",
    },
    {
      city: "Nunapitchuk",
      code: "NUP",
      name: "Alaska",
    },
    {
      city: "Norsup",
      code: "NUS",
      name: "",
    },
    {
      city: "Novy Urengoy",
      code: "NUX",
      name: "Urengoy Airport",
    },
    {
      city: "Neiva",
      code: "NVA",
      name: "Neiva Lamarguita Airport",
    },
    {
      city: "Navoi",
      code: "NVI",
      name: "Navoi Airport",
    },
    {
      city: "Narvik",
      code: "NVK",
      name: "Framnes Airport",
    },
    {
      city: "Novgorod",
      code: "NVR",
      name: "",
    },
    {
      city: "Navegantes",
      code: "NVT",
      name: "Ministro Victor Konder International Airport",
    },
    {
      city: "Moheli",
      code: "NWA",
      name: "",
    },
    {
      city: "Norwich",
      code: "NWI",
      name: "Norwich Airport",
    },
    {
      city: "Norwood Young America",
      code: "NYA",
      name: "",
    },
    {
      city: "Nyeri",
      code: "NYK",
      name: "Nanyuki Airport",
    },
    {
      city: "Nadym",
      code: "NYM",
      name: "Nadym Airport",
    },
    {
      city: "Nykoping",
      code: "NYO",
      name: "Skavsta Airport",
    },
    {
      city: "Nyaung-u",
      code: "NYU",
      name: "Nyaung U Airport",
    },
    {
      city: "Manzhouli",
      code: "NZH",
      name: "Manzhouli",
    },
    {
      city: "Arthurville",
      code: "OAG",
      name: "Springhill Airport",
    },
    {
      city: "Richlands",
      code: "OAJ",
      name: "Albert J Ellis Airport",
    },
    {
      city: "Oakland",
      code: "OAK",
      name: "Oakland International Airport",
    },
    {
      city: "Oamaru",
      code: "OAM",
      name: "Oamaru Airport",
    },
    {
      city: "San Bernardo Mixtepec",
      code: "OAX",
      name: "Xoxocotlan Airport",
    },
    {
      city: "Oban",
      code: "OBN",
      name: "Oban Connel Airport",
    },
    {
      city: "Obihiro-shi",
      code: "OBO",
      name: "Obihiro Airport",
    },
    {
      city: "Kobuk",
      code: "OBU",
      name: "Kobuk Airport",
    },
    {
      city: "Obo",
      code: "OBX",
      name: "Obo",
    },
    {
      city: "Chontapunta",
      code: "OCC",
      name: "Coca Airport",
    },
    {
      city: "Long Seridan",
      code: "ODN",
      name: "Sarawak",
    },
    {
      city: "Odesa",
      code: "ODS",
      name: "Odessa Central Airport",
    },
    {
      city: "Oak Harbor",
      code: "ODW",
      name: "Oak Harbor Airpark",
    },
    {
      city: "Oudomxay",
      code: "ODY",
      name: "Oudomxay",
    },
    {
      city: "Husum",
      code: "OER",
      name: "Ornskoldsvik Airport",
    },
    {
      city: "Ouango Fitini",
      code: "OFI",
      name: "Ouango Fitini",
    },
    {
      city: "Kahului",
      code: "OGG",
      name: "Kahului Airport",
    },
    {
      city: "Yonaguni-cho",
      code: "OGN",
      name: "Yonaguni Airport",
    },
    {
      city: "Ogdensburg",
      code: "OGS",
      name: "Ogdensburg International Airport",
    },
    {
      city: "Ouargla",
      code: "OGX",
      name: "Ain Beida",
    },
    {
      city: "Mozdok",
      code: "OGZ",
      name: "Ordzhonikidze North Airport",
    },
    {
      city: "Ohrid",
      code: "OHD",
      name: "Ohrid Airport",
    },
    {
      city: "Hamburg",
      code: "OHE",
      name: "Hamburg Airport",
    },
    {
      city: "Okhotsk",
      code: "OHO",
      name: "Okhotsk Airport",
    },
    {
      city: "Oshima-machi",
      code: "OIM",
      name: "Oshima Airport",
    },
    {
      city: "Kunisaki-shi",
      code: "OIT",
      name: "Oita Airport",
    },
    {
      city: "Naha-shi",
      code: "OKA",
      name: "Shimojishima Airport",
    },
    {
      city: "Oklahoma City",
      code: "OKC",
      name: "Will Rogers World Airport",
    },
    {
      city: "Sapporo-shi",
      code: "OKD",
      name: "Okadama Airport",
    },
    {
      city: "Okayama-shi",
      code: "OKJ",
      name: "Okayama Airport",
    },
    {
      city: "Yorke Island",
      code: "OKR",
      name: "",
    },
    {
      city: "Oakey",
      code: "OKY",
      name: "Oakey Aerodrome",
    },
    {
      city: "Orland",
      code: "OLA",
      name: "Orland Airport",
    },
    {
      city: "Terranova",
      code: "OLB",
      name: "Olbia Costa Smeralda Airport",
    },
    {
      city: "Wolf Point",
      code: "OLF",
      name: "L. M. Clayton Airport",
    },
    {
      city: "Old Harbor",
      code: "OLH",
      name: "Alaska",
    },
    {
      city: "Olpoi",
      code: "OLJ",
      name: "Malampa",
    },
    {
      city: "Roxby Downs",
      code: "OLP",
      name: "Olympic Dam Aerodrome",
    },
    {
      city: "Omaha",
      code: "OMA",
      name: "Eppley Airfield",
    },
    {
      city: "Omboue",
      code: "OMB",
      name: "Omboué",
    },
    {
      city: "Ormoc",
      code: "OMC",
      name: "Ormoc Airport",
    },
    {
      city: "Oranjemund",
      code: "OMD",
      name: "Oranjemund Airport",
    },
    {
      city: "Nome",
      code: "OME",
      name: "Nome Airport",
    },
    {
      city: "Urmieh",
      code: "OMH",
      name: "Uromiyeh Airport",
    },
    {
      city: "Mostar",
      code: "OMO",
      name: "Mostar Airport",
    },
    {
      city: "Oradea-Mare",
      code: "OMR",
      name: "Oradea Airport",
    },
    {
      city: "Omsk",
      code: "OMS",
      name: "Omsk Southwest Airport",
    },
    {
      city: "Ondangwa",
      code: "OND",
      name: "Ondangwa Airport",
    },
    {
      city: "Mornington",
      code: "ONG",
      name: "Mornington Island Airport",
    },
    {
      city: "Kitakita-shi",
      code: "ONJ",
      name: "Odate-Noshiro Airport",
    },
    {
      city: "O'neill",
      code: "ONL",
      name: "The Oneill Municipal Airport",
    },
    {
      city: "Ontario",
      code: "ONT",
      name: "Ontario International Airport",
    },
    {
      city: "Toksook Bay",
      code: "OOK",
      name: "Toksook Bay",
    },
    {
      city: "Gold Coast",
      code: "OOL",
      name: "Gold Coast (Coolangatta)",
    },
    {
      city: "Opa-Locka",
      code: "OPF",
      name: "Opa Locka Airport",
    },
    {
      city: "Maia",
      code: "OPO",
      name: "Porto Airport",
    },
    {
      city: "Sinop",
      code: "OPS",
      name: "Sinop Airport",
    },
    {
      city: "Balimo",
      code: "OPU",
      name: "Balimo",
    },
    {
      city: "Orebro",
      code: "ORB",
      name: "Orebro Airport",
    },
    {
      city: "Chicago",
      code: "ORD",
      name: "Chicago O'Hare International Airport",
    },
    {
      city: "Norfolk",
      code: "ORF",
      name: "Norfolk International Airport",
    },
    {
      city: "Worcester",
      code: "ORH",
      name: "Worcester Municipal Airport",
    },
    {
      city: "Port Lions",
      code: "ORI",
      name: "",
    },
    {
      city: "Rera",
      code: "ORJ",
      name: "Orinduik Airport",
    },
    {
      city: "Fivemilebridge",
      code: "ORK",
      name: "Cork Airport",
    },
    {
      city: "Northampton",
      code: "ORM",
      name: "Sywell Airport",
    },
    {
      city: "Oran Rp",
      code: "ORN",
      name: "Es Senia Airport",
    },
    {
      city: "Noorvik",
      code: "ORV",
      name: "Curtis Memorial",
    },
    {
      city: "Paris",
      code: "ORY",
      name: "Paris Orly Airport",
    },
    {
      city: "Frösö",
      code: "OSD",
      name: "Ostersunds Airport",
    },
    {
      city: "Osijek",
      code: "OSI",
      name: "Osijek Airport",
    },
    {
      city: "Fårbo",
      code: "OSK",
      name: "Oskarshamn Airport",
    },
    {
      city: "Gardermoen",
      code: "OSL",
      name: "Oslo Gardermoen Airport",
    },
    {
      city: "Mosul",
      code: "OSM",
      name: "Mosul Airport",
    },
    {
      city: "Mošnov",
      code: "OSR",
      name: "Mosnov Airport",
    },
    {
      city: "Osh",
      code: "OSS",
      name: "Osh Airport",
    },
    {
      city: "Oostende",
      code: "OST",
      name: "Oostende Airport",
    },
    {
      city: "Orsk",
      code: "OSW",
      name: "Orsk",
    },
    {
      city: "Namsos",
      code: "OSY",
      name: "Namsos Airport",
    },
    {
      city: "Sianów",
      code: "OSZ",
      name: "Koszalin Airport",
    },
    {
      city: "North Bend",
      code: "OTH",
      name: "North Bend Municipal Airport",
    },
    {
      city: "Bucharest",
      code: "OTP",
      name: "Otopeni Airport",
    },
    {
      city: "Coto 47",
      code: "OTR",
      name: "Coto 47 Airport",
    },
    {
      city: "Kotzebue",
      code: "OTZ",
      name: "Ralph Wien Memorial Airport",
    },
    {
      city: "Ouagadougou",
      code: "OUA",
      name: "Ouagadougou Airport",
    },
    {
      city: "Oujda",
      code: "OUD",
      name: "Angads Airport",
    },
    {
      city: "Ouesso",
      code: "OUE",
      name: "Ouesso Airport",
    },
    {
      city: "Oulunsalo",
      code: "OUL",
      name: "Oulu Airport",
    },
    {
      city: "Zouerate",
      code: "OUZ",
      name: "Mauritania",
    },
    {
      city: "Novosibirsk",
      code: "OVB",
      name: "Tolmachevo Airport",
    },
    {
      city: "Castrillón",
      code: "OVD",
      name: "Asturias Airport",
    },
    {
      city: "Boscobel",
      code: "OVS",
      name: "Boscobel Airport",
    },
    {
      city: "Bissau",
      code: "OXB",
      name: "Bissau Oswaldo Vieira Airport",
    },
    {
      city: "Kidlington",
      code: "OXF",
      name: "Oxford Airport",
    },
    {
      city: "Oxnard",
      code: "OXR",
      name: "Oxnard Airport",
    },
    {
      city: "Oyem",
      code: "OYE",
      name: "Oyem Airport",
    },
    {
      city: "Moyo",
      code: "OYG",
      name: "Moyo",
    },
    {
      city: "Ozamis City",
      code: "OZC",
      name: "Ozamis-Mindanao Island Airport",
    },
    {
      city: "Zaporizhzhya",
      code: "OZH",
      name: "Zaporozhye East Airport",
    },
    {
      city: "Ouarzazate",
      code: "OZZ",
      name: "Ouarzazate Airport",
    },
    {
      city: "Büren",
      code: "PAD",
      name: "Paderborn-Lippstadt Airport",
    },
    {
      city: "West Paducah",
      code: "PAH",
      name: "Barkley Regional Airport",
    },
    {
      city: "Pailin",
      code: "PAI",
      name: "Pailin Airport",
    },
    {
      city: "Port Au Prince",
      code: "PAP",
      name: "",
    },
    {
      city: "Levkai",
      code: "PAS",
      name: "Paros Island Airport",
    },
    {
      city: "Patna",
      code: "PAT",
      name: "Lok Nayak Jaiprakash Airport",
    },
    {
      city: "Castillo de Teayo",
      code: "PAZ",
      name: "Tajin Airport",
    },
    {
      city: "Tlaltenango",
      code: "PBC",
      name: "Puebla Airport",
    },
    {
      city: "Porbandar",
      code: "PBD",
      name: "Porbandar Airport",
    },
    {
      city: "Plattsburgh",
      code: "PBG",
      name: "Plattsburgh Air Force Base",
    },
    {
      city: "Paro",
      code: "PBH",
      name: "Paro Airport",
    },
    {
      city: "West Palm Beach",
      code: "PBI",
      name: "Palm Beach International Airport",
    },
    {
      city: "Paama",
      code: "PBJ",
      name: "Malampa",
    },
    {
      city: "Sabakoe",
      code: "PBM",
      name: "Zandery Airport",
    },
    {
      city: "Paraburdoo",
      code: "PBO",
      name: "Paraburdoo Airport",
    },
    {
      city: "Punta Islita",
      code: "PBP",
      name: "Punta Islita Airport",
    },
    {
      city: "Putao",
      code: "PBU",
      name: "Putao Airport",
    },
    {
      city: "Painter Creek",
      code: "PCE",
      name: "Painter Creek",
    },
    {
      city: "Callaria",
      code: "PCL",
      name: "Pucallpa Airport",
    },
    {
      city: "Puerto Carreño",
      code: "PCR",
      name: "Puerto Carreno Airport",
    },
    {
      city: "Guaviare",
      code: "PDA",
      name: "Puerto Inirida Airport",
    },
    {
      city: "Pedro Bay",
      code: "PDB",
      name: "Pedro Bay",
    },
    {
      city: "Padang",
      code: "PDG",
      name: "Tabing Airport",
    },
    {
      city: "Ponta Delgada",
      code: "PDL",
      name: "Ponta Delgada Airport",
    },
    {
      city: "Punta del Este",
      code: "PDP",
      name: "Maldonado Airport",
    },
    {
      city: "Piedras Negras",
      code: "PDS",
      name: "Piedras Negras International Airport",
    },
    {
      city: "Pendleton",
      code: "PDT",
      name: "Eastern Oregon Regional Airport",
    },
    {
      city: "Portland",
      code: "PDX",
      name: "Portland International Airport",
    },
    {
      city: "Pelican",
      code: "PEC",
      name: "Pelican Sea Plane Base",
    },
    {
      city: "Pardubice",
      code: "PED",
      name: "Pardubice",
    },
    {
      city: "Perm'",
      code: "PEE",
      name: "Bolshesavino Airport",
    },
    {
      city: "Assisi",
      code: "PEG",
      name: "Perugia Airport",
    },
    {
      city: "Pereira",
      code: "PEI",
      name: "Matecana Airport",
    },
    {
      city: "Shunyi",
      code: "PEK",
      name: "Beijing Capital Airport",
    },
    {
      city: "Puerto Maldonado",
      code: "PEM",
      name: "Padre Aldamiz Airport",
    },
    {
      city: "Batu Maung",
      code: "PEN",
      name: "Penang International Airport",
    },
    {
      city: "Perth",
      code: "PER",
      name: "Perth International Airport",
    },
    {
      city: "Petrozavodsk",
      code: "PES",
      name: "Petrozavodsk Northwest Airport",
    },
    {
      city: "Pelotas",
      code: "PET",
      name: "Pelotas Airport",
    },
    {
      city: "Puerto Lempira",
      code: "PEU",
      name: "Puerto Lempira Airport",
    },
    {
      city: "Peshawar",
      code: "PEW",
      name: "Peshawar Airport",
    },
    {
      city: "Ukhta",
      code: "PEX",
      name: "Pechora Southwest Airport",
    },
    {
      city: "Penza",
      code: "PEZ",
      name: "Penza",
    },
    {
      city: "Passo Fundo",
      code: "PFB",
      name: "Lauro Kurtz Airport",
    },
    {
      city: "Panama City",
      code: "PFN",
      name: "Panama City Bay County Airport",
    },
    {
      city: "Paphos",
      code: "PFO",
      name: "Paphos International Airport",
    },
    {
      city: "Parsabad",
      code: "PFQ",
      name: "",
    },
    {
      city: "Page",
      code: "PGA",
      name: "Page Municipal Airport",
    },
    {
      city: "Perpignan",
      code: "PGF",
      name: "Rivesaltes Airport",
    },
    {
      city: "Pangkalpinang",
      code: "PGK",
      name: "Pangkalpinang Airport",
    },
    {
      city: "Port Graham",
      code: "PGM",
      name: "Port Graham",
    },
    {
      city: "Asalouyeh",
      code: "PGU",
      name: "Page Municipal Airport",
    },
    {
      city: "Greenville",
      code: "PGV",
      name: "Pitt Greenville Airport",
    },
    {
      city: "St-Pierre",
      code: "PGX",
      name: "Bassillac Airport",
    },
    {
      city: "Port Harcourt",
      code: "PHC",
      name: "Port Harcourt International Airport",
    },
    {
      city: "Port Hedland",
      code: "PHE",
      name: "Port Hedland Airport",
    },
    {
      city: "Newport News",
      code: "PHF",
      name: "Newport News-Williamsburg International Airport",
    },
    {
      city: "Port Harcourt",
      code: "PHG",
      name: "Port Harcourt City",
    },
    {
      city: "Philadelphia",
      code: "PHL",
      name: "Philadelphia International Airport",
    },
    {
      city: "Point Hope",
      code: "PHO",
      name: "Point Hope Airport",
    },
    {
      city: "Phitsanulok",
      code: "PHS",
      name: "Phitsanulok Airport",
    },
    {
      city: "Phalaborwa",
      code: "PHW",
      name: "Hendrik Van Eck Airport",
    },
    {
      city: "Phoenix",
      code: "PHX",
      name: "Sky Harbor International Airport",
    },
    {
      city: "Peoria",
      code: "PIA",
      name: "Greater Peoria Regional Airport",
    },
    {
      city: "Moselle",
      code: "PIB",
      name: "Pine Belt Regional Airport",
    },
    {
      city: "Clearwater",
      code: "PIE",
      name: "St. Petersburg-Clearwater International Airport",
    },
    {
      city: "Pingtung",
      code: "PIF",
      name: "Pingdong Airport",
    },
    {
      city: "Pocatello",
      code: "PIH",
      name: "Pocatello Municipal Airport",
    },
    {
      city: "Prestwick",
      code: "PIK",
      name: "Glasgow Prestwick International Airport",
    },
    {
      city: "Parintins",
      code: "PIN",
      name: "Parintins Airport",
    },
    {
      city: "Pilot Point",
      code: "PIP",
      name: "Pilot Point Airport",
    },
    {
      city: "Pierre",
      code: "PIR",
      name: "Pierre Municipal Airport",
    },
    {
      city: "Vouneuil-sous-Biard",
      code: "PIS",
      name: "Biard Airport",
    },
    {
      city: "Coraopolis",
      code: "PIT",
      name: "Pittsburgh International Airport",
    },
    {
      city: "Piura",
      code: "PIU",
      name: "Capitan Concha Airport",
    },
    {
      city: "Nelson House",
      code: "PIW",
      name: "Pikwitonei Airport",
    },
    {
      city: "Madalena",
      code: "PIX",
      name: "Pico Airport",
    },
    {
      city: "Point Lay",
      code: "PIZ",
      name: "Dew Station",
    },
    {
      city: "Pajala",
      code: "PJA",
      name: "Sweden",
    },
    {
      city: "Panjgur",
      code: "PJG",
      name: "Panjgur Airport",
    },
    {
      city: "Puerto Jiménez",
      code: "PJM",
      name: "Puerto Jimenez Airport",
    },
    {
      city: "Napaskiak",
      code: "PKA",
      name: "Alaska",
    },
    {
      city: "Williamstown",
      code: "PKB",
      name: "Wood County Airport-Gill Robb Wilson Field",
    },
    {
      city: "Elizovo",
      code: "PKC",
      name: "Petropavlovsk Yelizovo Airport",
    },
    {
      city: "Parkes",
      code: "PKE",
      name: "Parkes Airport",
    },
    {
      city: "Pangkor",
      code: "PKG",
      name: "Pangkor Airport",
    },
    {
      city: "Pakokku",
      code: "PKK",
      name: "Pakokku",
    },
    {
      city: "Puka Puka",
      code: "PKP",
      name: "French Polynesia",
    },
    {
      city: "Pokhara",
      code: "PKR",
      name: "Pokhara Airport",
    },
    {
      city: "Pekanbaru",
      code: "PKU",
      name: "Simpang Tiga Airport",
    },
    {
      city: "Buntok",
      code: "PKY",
      name: "Tjilik Riwut Airport",
    },
    {
      city: "Pakxe",
      code: "PKZ",
      name: "Pakse Airport",
    },
    {
      city: "Nicoya",
      code: "PLD",
      name: "Playa Samara Airport",
    },
    {
      city: "Plymouth",
      code: "PLH",
      name: "Roborough Airport",
    },
    {
      city: "Placencia",
      code: "PLJ",
      name: "Belize",
    },
    {
      city: "Palembang",
      code: "PLM",
      name: "Sultan Mahmud Badaruddin Ii Airport",
    },
    {
      city: "Pellston",
      code: "PLN",
      name: "Pellston Regional Airport",
    },
    {
      city: "Port Lincoln",
      code: "PLO",
      name: "Port Lincoln Airport",
    },
    {
      city: "Klaipeda/Palanga",
      code: "PLQ",
      name: "Palanga International",
    },
    {
      city: "The Bight Settlements",
      code: "PLS",
      name: "Providenciales Airport",
    },
    {
      city: "Belo Horizonte",
      code: "PLU",
      name: "Pampulha Airport",
    },
    {
      city: "Palu",
      code: "PLW",
      name: "Mutiara Airport",
    },
    {
      city: "Semipalatinsk",
      code: "PLX",
      name: "",
    },
    {
      city: "Port Elizabeth",
      code: "PLZ",
      name: "H F Verwoerd Airport",
    },
    {
      city: "Pemba",
      code: "PMA",
      name: "Pemba Airport",
    },
    {
      city: "Los Quemas",
      code: "PMC",
      name: "El Tepual International Airport",
    },
    {
      city: "Palmdale",
      code: "PMD",
      name: "Air Force Plant Nr 42 Palmdale",
    },
    {
      city: "Portsmouth",
      code: "PME",
      name: "Portsmouth Airport",
    },
    {
      city: "Parma",
      code: "PMF",
      name: "Parma Airport",
    },
    {
      city: "Palma",
      code: "PMI",
      name: "Palma de Mallorca Airport",
    },
    {
      city: "Port Moller",
      code: "PML",
      name: "",
    },
    {
      city: "Cinisi",
      code: "PMO",
      name: "Palermo Airport",
    },
    {
      city: "Palmerston North",
      code: "PMR",
      name: "Palmerston North Airport",
    },
    {
      city: "Pampatar",
      code: "PMV",
      name: "Del Caribe International Airport",
    },
    {
      city: "Palmas",
      code: "PMW",
      name: "Palmas Airport",
    },
    {
      city: "Puerto Madryn",
      code: "PMY",
      name: "El Tehuelche Airport",
    },
    {
      city: "Palmar Sur",
      code: "PMZ",
      name: "Palmar Sur Airport",
    },
    {
      city: "Noáin",
      code: "PNA",
      name: "Pamplona Airport",
    },
    {
      city: "Punta Gorda",
      code: "PND",
      name: "Punta Gorda Airport",
    },
    {
      city: "Phnom Penh",
      code: "PNH",
      name: "Pochentong Airport",
    },
    {
      city: "Palikir",
      code: "PNI",
      name: "Pohnpei International Airport",
    },
    {
      city: "Pontianak",
      code: "PNK",
      name: "Supadio Airport",
    },
    {
      city: "Pantelleria",
      code: "PNL",
      name: "Pantelleria Airport",
    },
    {
      city: "Popondetta",
      code: "PNP",
      name: "Girua Airport",
    },
    {
      city: "Pune",
      code: "PNQ",
      name: "Pune Airport",
    },
    {
      city: "Pointe-Noire",
      code: "PNR",
      name: "Pointe Noire Airport",
    },
    {
      city: "Pensacola",
      code: "PNS",
      name: "Pensacola Regional Airport",
    },
    {
      city: "Petrolina",
      code: "PNZ",
      name: "Senador Nilo Coelho Airport",
    },
    {
      city: "Porto Alegre",
      code: "POA",
      name: "Salgado Filho International Airport",
    },
    {
      city: "Port-Gentil",
      code: "POG",
      name: "Port Gentil Airport",
    },
    {
      city: "Pemba",
      code: "POL",
      name: "Pemba Airport",
    },
    {
      city: "Port Moresby",
      code: "POM",
      name: "Port Moresby International Airport",
    },
    {
      city: "San Felipe de Puerto Plata",
      code: "POP",
      name: "Puerto Plata International Airport",
    },
    {
      city: "Pori",
      code: "POR",
      name: "Pori Airport",
    },
    {
      city: "Trinidad",
      code: "POS",
      name: "Piarco Airport",
    },
    {
      city: "Poznan",
      code: "POZ",
      name: "Lawica Airport",
    },
    {
      city: "Presidente Prudente",
      code: "PPB",
      name: "Presidente Prudente Airport",
    },
    {
      city: "Pto. Penasco",
      code: "PPE",
      name: "Punta Penasco Airport",
    },
    {
      city: "Pago Pago",
      code: "PPG",
      name: "Pago Pago International Airport",
    },
    {
      city: "Petropavlovsk",
      code: "PPK",
      name: "Petropavlovsk",
    },
    {
      city: "Phaplu",
      code: "PPL",
      name: "Nepal",
    },
    {
      city: "Popayán",
      code: "PPN",
      name: "Guillermo Leon Valencia Airport",
    },
    {
      city: "Brandy Creek",
      code: "PPP",
      name: "Proserpine Aerodrome",
    },
    {
      city: "Puerto Princesa",
      code: "PPS",
      name: "Puerto Princesa International Airport",
    },
    {
      city: "Papeete",
      code: "PPT",
      name: "Tahiti Faaa Airport",
    },
    {
      city: "Port Protection",
      code: "PPV",
      name: "Alaska",
    },
    {
      city: "Kien Giang",
      code: "PQC",
      name: "Duong Dong Airport",
    },
    {
      city: "Presque Isle",
      code: "PQI",
      name: "Northern Maine Regional Airport",
    },
    {
      city: "Port Macquarie",
      code: "PQQ",
      name: "Port Macquarie Airport",
    },
    {
      city: "Pilot Station",
      code: "PQS",
      name: "Pilot Station",
    },
    {
      city: "Prescott",
      code: "PRC",
      name: "Ernest A Love Field Airport",
    },
    {
      city: "Prague 6",
      code: "PRG",
      name: "Prague Ruzyne Airport",
    },
    {
      city: "Praslin Island",
      code: "PRI",
      name: "Praslin Airport",
    },
    {
      city: "Prishtina",
      code: "PRN",
      name: "Pristina Airport",
    },
    {
      city: "Pisa",
      code: "PSA",
      name: "Pisa Airport",
    },
    {
      city: "Pasco",
      code: "PSC",
      name: "Tri Cities Airport",
    },
    {
      city: "Coto Laurel",
      code: "PSE",
      name: "Mercedita Airport",
    },
    {
      city: "Petersburg",
      code: "PSG",
      name: "Petersburg James A Johnson Airport",
    },
    {
      city: "Chachagüí",
      code: "PSO",
      name: "Antonio Narino Airport",
    },
    {
      city: "Palm Springs",
      code: "PSP",
      name: "Palm Springs International Airport",
    },
    {
      city: "Pescara",
      code: "PSR",
      name: "Pescara Airport",
    },
    {
      city: "Posadas",
      code: "PSS",
      name: "Posadas Airport",
    },
    {
      city: "Puerto Suárez",
      code: "PSZ",
      name: "Salvador Ogaya Gutierrez Airport",
    },
    {
      city: "Port Alsworth",
      code: "PTA",
      name: "Port Alsworth",
    },
    {
      city: "Malololailai",
      code: "PTF",
      name: "Malololailai Airport",
    },
    {
      city: "Pietersburg",
      code: "PTG",
      name: "Pietersburg Municipal Airport",
    },
    {
      city: "Port Heiden",
      code: "PTH",
      name: "Port Heiden Airport",
    },
    {
      city: "Les Abymes",
      code: "PTP",
      name: "Le Raizet Airport",
    },
    {
      city: "Platinum",
      code: "PTU",
      name: "Platinum",
    },
    {
      city: "Tocumen",
      code: "PTY",
      name: "Tocumen International Airport",
    },
    {
      city: "Pueblo",
      code: "PUB",
      name: "Pueblo Memorial Airport",
    },
    {
      city: "Lescar",
      code: "PUF",
      name: "Pont Long Uzein Airport",
    },
    {
      city: "Salvaleón de Higüey",
      code: "PUJ",
      name: "Punta Cana Airport",
    },
    {
      city: "Pukarua",
      code: "PUK",
      name: "",
    },
    {
      city: "Punta Arenas",
      code: "PUQ",
      name: "Carlos Ibanez de Campo International Airport",
    },
    {
      city: "Busan",
      code: "PUS",
      name: "Kimhae International Airport",
    },
    {
      city: "Puerto Asís",
      code: "PUU",
      name: "Puerto Asis Airport",
    },
    {
      city: "Pullman",
      code: "PUW",
      name: "Pullman-Moscow Regional Airport",
    },
    {
      city: "Pluj",
      code: "PUY",
      name: "Pula Airport",
    },
    {
      city: "San Andrés",
      code: "PVA",
      name: "Providencia Island Airport",
    },
    {
      city: "Provincetown",
      code: "PVC",
      name: "Provincetown Municipal Airport",
    },
    {
      city: "Warwick",
      code: "PVD",
      name: "Theodore Francis Green State Airport",
    },
    {
      city: "Huinan",
      code: "PVG",
      name: "Pudong International Airport",
    },
    {
      city: "Pôrto Velho",
      code: "PVH",
      name: "Governador Jorge Teixeira de Oliveira Internatio",
    },
    {
      city: "Paliambela",
      code: "PVK",
      name: "Preveza Airport",
    },
    {
      city: "Puerto Vallarta",
      code: "PVR",
      name: "Lic Gustavo Diaz Ordaz International Airport",
    },
    {
      city: "Anadyr'",
      code: "PWE",
      name: "Under Construction Pevek Airport",
    },
    {
      city: "Wheeling",
      code: "PWK",
      name: "Pal Waukee Airport",
    },
    {
      city: "Portland",
      code: "PWM",
      name: "Jetport International Airport",
    },
    {
      city: "Pavlodar",
      code: "PWQ",
      name: "Pavlodar South Airport",
    },
    {
      city: "San Pedro Juchatengo",
      code: "PXM",
      name: "Puerto Escondido Airport",
    },
    {
      city: "Porto Santo",
      code: "PXO",
      name: "Porto Santo Airport",
    },
    {
      city: "Gia Lai",
      code: "PXU",
      name: "Pleiku Area Airport",
    },
    {
      city: "Maroa",
      code: "PYH",
      name: "Puerto Ayacucho Airport",
    },
    {
      city: "Polyarnyj",
      code: "PYJ",
      name: "Russia",
    },
    {
      city: "Pietermaritzburg",
      code: "PZB",
      name: "Pietermaritzburg Airport",
    },
    {
      city: "Penzance",
      code: "PZE",
      name: "Penzance Heliport",
    },
    {
      city: "Pan Zhi Hua",
      code: "PZI",
      name: "Pan Zhi Hua Bao AnYing",
    },
    {
      city: "Ciudad Guayana",
      code: "PZO",
      name: "Puerto Ordaz Airport",
    },
    {
      city: "Port Sudan",
      code: "PZU",
      name: "Port Sudan International Airport",
    },
    {
      city: "Masset",
      code: "QBC",
      name: "Bella Coola Airport",
    },
    {
      city: "Ashford",
      code: "QDH",
      name: "Ashford International Rail Station",
    },
    {
      city: "Dusseldorf",
      code: "QDU",
      name: "Germany",
    },
    {
      city: "Freiburg",
      code: "QFB",
      name: "Freiburg Hauptbahnhof",
    },
    {
      city: "Sarrebruck",
      code: "QFZ",
      name: "Saarbruecken Rail Station",
    },
    {
      city: "Jeju-Si",
      code: "QJY",
      name: "Cheju International Airport",
    },
    {
      city: "Nantes",
      code: "QJZ",
      name: "France",
    },
    {
      city: "Cologne",
      code: "QKL",
      name: "Koeln Hauptbahnhof",
    },
    {
      city: "Owerri",
      code: "QOW",
      name: "Owerri",
    },
    {
      city: "Dover",
      code: "QQD",
      name: "Dover Rail Station",
    },
    {
      city: "Harwich",
      code: "QQH",
      name: "Harwich Rail Station",
    },
    {
      city: "London",
      code: "QQK",
      name: "England",
    },
    {
      city: "Manchester",
      code: "QQM",
      name: "Manchester International Airport",
    },
    {
      city: "Birmingham",
      code: "QQN",
      name: "Birmingham International Airport",
    },
    {
      city: "London",
      code: "QQP",
      name: "Paddington Station",
    },
    {
      city: "Britrail Rail Zone S",
      code: "QQS",
      name: "St Pancras International",
    },
    {
      city: "London",
      code: "QQU",
      name: "Birmingham International Airport",
    },
    {
      city: "London",
      code: "QQW",
      name: "Waterloo Railway Station",
    },
    {
      city: "Bath",
      code: "QQX",
      name: "Bath Rail Service",
    },
    {
      city: "York",
      code: "QQY",
      name: "Birmingham International Airport",
    },
    {
      city: "Rotterdam",
      code: "QRH",
      name: "Rotterdam Airport",
    },
    {
      city: "Queretaro",
      code: "QRO",
      name: "Queretaro Airport",
    },
    {
      city: "Warri",
      code: "QRW",
      name: "Delta",
    },
    {
      city: "Setif",
      code: "QSF",
      name: "Setif",
    },
    {
      city: "Qulin",
      code: "QUL",
      name: "",
    },
    {
      city: "Sao Jose dos Pinhais",
      code: "QWB",
      name: "Afonso Pena International Airport",
    },
    {
      city: "Aix-les-milles",
      code: "QXB",
      name: "Aix Les Milles Airport",
    },
    {
      city: "Angers",
      code: "QXG",
      name: "Pays de la Loire",
    },
    {
      city: "Gefle",
      code: "QYU",
      name: "Gavleborg",
    },
    {
      city: "Uppsala",
      code: "QYX",
      name: "Uppsala Station",
    },
    {
      city: "Rabaul",
      code: "RAB",
      name: "Rabaul Airport",
    },
    {
      city: "Arar",
      code: "RAE",
      name: "Arar Airport",
    },
    {
      city: "Rafha",
      code: "RAH",
      name: "Rafha Airport",
    },
    {
      city: "Praia",
      code: "RAI",
      name: "Francisco Mendes Airport",
    },
    {
      city: "Rajkot",
      code: "RAJ",
      name: "Rajkot Airport",
    },
    {
      city: "Marrakesh",
      code: "RAK",
      name: "Menara Airport",
    },
    {
      city: "Ribeirão Prêto",
      code: "RAO",
      name: "Leite Lopes Airport",
    },
    {
      city: "Rapid City",
      code: "RAP",
      name: "Rapid City Regional Airport",
    },
    {
      city: "Avarua",
      code: "RAR",
      name: "Rarotonga International Airport",
    },
    {
      city: "Rasht",
      code: "RAS",
      name: "Rasht Airport",
    },
    {
      city: "Rabat",
      code: "RBA",
      name: "Sale Airport",
    },
    {
      city: "Brooks Lodge",
      code: "RBH",
      name: "Brooks Lodge",
    },
    {
      city: "Rurrenabaque",
      code: "RBQ",
      name: "Rurrenabaque Airport",
    },
    {
      city: "Rio Branco",
      code: "RBR",
      name: "Presidente Medici International Airport",
    },
    {
      city: "Ramata",
      code: "RBV",
      name: "Ramata",
    },
    {
      city: "Roundup",
      code: "RBX",
      name: "Roundup Airport",
    },
    {
      city: "Ruby",
      code: "RBY",
      name: "Ruby Airport",
    },
    {
      city: "Empangeni",
      code: "RCB",
      name: "Richards Bay Airport",
    },
    {
      city: "Friday Harbor",
      code: "RCE",
      name: "Roche Harbor Airport",
    },
    {
      city: "Ríohacha",
      code: "RCH",
      name: "Almirante Padilla Airport",
    },
    {
      city: "Redcliffe",
      code: "RCL",
      name: "",
    },
    {
      city: "Bellfield",
      code: "RCM",
      name: "Richmond Aerodrome",
    },
    {
      city: "Cinder River",
      code: "RCP",
      name: "",
    },
    {
      city: "Red Dog",
      code: "RDB",
      name: "Red Dog",
    },
    {
      city: "Redding",
      code: "RDD",
      name: "Redding Municipal Airport",
    },
    {
      city: "Redmond",
      code: "RDM",
      name: "Roberts Field Airport",
    },
    {
      city: "Redang",
      code: "RDN",
      name: "Malaysia",
    },
    {
      city: "Raleigh/Durham",
      code: "RDU",
      name: "Durham International Airport",
    },
    {
      city: "Red Devil",
      code: "RDV",
      name: "Red Devil",
    },
    {
      city: "Marcillac",
      code: "RDZ",
      name: "Marcillac Airport",
    },
    {
      city: "Reao",
      code: "REA",
      name: "Reao",
    },
    {
      city: "Recife",
      code: "REC",
      name: "Gilberto Freyre International Airport",
    },
    {
      city: "Reggio di Calabria",
      code: "REG",
      name: "Reggio Calabria Airport",
    },
    {
      city: "Trelew",
      code: "REL",
      name: "Trelew Almirante Zar Airport",
    },
    {
      city: "Orenburg",
      code: "REN",
      name: "Orenburg East Airport",
    },
    {
      city: "Siemrap",
      code: "REP",
      name: "Siem Reap Airport",
    },
    {
      city: "Makallé",
      code: "RES",
      name: "Resistencia Airport",
    },
    {
      city: "Reus",
      code: "REU",
      name: "Reus Airport",
    },
    {
      city: "Reynosa",
      code: "REX",
      name: "Reynosa International Airport",
    },
    {
      city: "Rockford",
      code: "RFD",
      name: "Greater Rockford Airport",
    },
    {
      city: "Papeete",
      code: "RFP",
      name: "Raiatea Island Airport",
    },
    {
      city: "Río Grande",
      code: "RGA",
      name: "Rio Grande Airport",
    },
    {
      city: "Papeete",
      code: "RGI",
      name: "Rangiroa Airport",
    },
    {
      city: "Río Gallegos",
      code: "RGL",
      name: "Rio Gallegos Airport",
    },
    {
      city: "Insein",
      code: "RGN",
      name: "Mingaladon Airport",
    },
    {
      city: "Rhinelander",
      code: "RHI",
      name: "Rhinelander-Oneida County Airport",
    },
    {
      city: "Rodhos",
      code: "RHO",
      name: "Paradisi Airport",
    },
    {
      city: "Santa Maria",
      code: "RIA",
      name: "Santa Maria Airport",
    },
    {
      city: "Riberalta",
      code: "RIB",
      name: "Gen. Buech Airport",
    },
    {
      city: "Richmond",
      code: "RIC",
      name: "Richmond International Airport",
    },
    {
      city: "Rio Grande",
      code: "RIG",
      name: "Rio Grande Airport",
    },
    {
      city: "Rishirifuji-cho",
      code: "RIS",
      name: "Rishiri Airport",
    },
    {
      city: "Alessandro",
      code: "RIV",
      name: "March Air Force Base",
    },
    {
      city: "Riverton",
      code: "RIW",
      name: "Riverton Regional Airport",
    },
    {
      city: "Marupe",
      code: "RIX",
      name: "Riga Airport",
    },
    {
      city: "Shuhayr",
      code: "RIY",
      name: "Riyan Airport",
    },
    {
      city: "Kapavaram",
      code: "RJA",
      name: "Rajahmundry Airport",
    },
    {
      city: "Rijeka",
      code: "RJK",
      name: "Rijeka Krk Airport",
    },
    {
      city: "Logrono",
      code: "RJL",
      name: "Agoncillo",
    },
    {
      city: "Rafsanjan",
      code: "RJN",
      name: "Kerman",
    },
    {
      city: "Owls Head",
      code: "RKD",
      name: "Knox County Regional Airport",
    },
    {
      city: "Rock Springs",
      code: "RKS",
      name: "Rock Springs-Sweetwater County Airport",
    },
    {
      city: "",
      code: "RKT",
      name: "Ras Al Khaimah International Airport",
    },
    {
      city: "Reykjavik",
      code: "RKV",
      name: "Reykjavik Airport",
    },
    {
      city: "Rostock-laage",
      code: "RLG",
      name: "",
    },
    {
      city: "Blythdale",
      code: "RMA",
      name: "Roma Aerodrome",
    },
    {
      city: "Marsa Alam",
      code: "RMF",
      name: "Marsa Alam International",
    },
    {
      city: "Rimini",
      code: "RMI",
      name: "Rimini Airport",
    },
    {
      city: "Rampart",
      code: "RMP",
      name: "Rampart",
    },
    {
      city: "Taichung",
      code: "RMQ",
      name: "Taiwan",
    },
    {
      city: "Tatawin",
      code: "RMT",
      name: "Remada Airport",
    },
    {
      city: "Arona",
      code: "RNA",
      name: "Ulawa Airport",
    },
    {
      city: "Kallinge",
      code: "RNB",
      name: "Ronneby Airport",
    },
    {
      city: "Rennell",
      code: "RNL",
      name: "Rennell",
    },
    {
      city: "Ronne",
      code: "RNN",
      name: "Bornholm Airport",
    },
    {
      city: "Reno",
      code: "RNO",
      name: "Reno-Tahoe International Airport",
    },
    {
      city: "Rongelap Island",
      code: "RNP",
      name: "Rongelap Island",
    },
    {
      city: "St-Jacques",
      code: "RNS",
      name: "St Jacques Airport",
    },
    {
      city: "Roanoke",
      code: "ROA",
      name: "Roanoke Regional Airport-Woodrum Field",
    },
    {
      city: "Harbelville",
      code: "ROB",
      name: "Roberts International Airport",
    },
    {
      city: "Rochester",
      code: "ROC",
      name: "Greater Rochester International Airport",
    },
    {
      city: "Roi Et",
      code: "ROI",
      name: "Mueang Poi Et",
    },
    {
      city: "Rockhampton",
      code: "ROK",
      name: "Rockhampton Airport",
    },
    {
      city: "Rondonópolis",
      code: "ROO",
      name: "Rondonopolis Airport",
    },
    {
      city: "Rota",
      code: "ROP",
      name: "Rota International Airport",
    },
    {
      city: "Koror",
      code: "ROR",
      name: "Koror Airport",
    },
    {
      city: "Rosario",
      code: "ROS",
      name: "Rosario Airport",
    },
    {
      city: "Rotorua",
      code: "ROT",
      name: "Rotorua Airport",
    },
    {
      city: "Taganrog",
      code: "ROV",
      name: "Rostov East Airport",
    },
    {
      city: "Roswell",
      code: "ROW",
      name: "Roswell Industrial Air Center",
    },
    {
      city: "Banarsi",
      code: "RPR",
      name: "Raipur Airport",
    },
    {
      city: "Rodrigues Is",
      code: "RRG",
      name: "Mauritius",
    },
    {
      city: "Roros",
      code: "RRS",
      name: "Roeros Airport",
    },
    {
      city: "Santa Rosa",
      code: "RSA",
      name: "Santa Rosa Airport",
    },
    {
      city: "Rock Sound",
      code: "RSD",
      name: "Rock Sound Airport",
    },
    {
      city: "Russian Mission",
      code: "RSH",
      name: "Russian",
    },
    {
      city: "Olga",
      code: "RSJ",
      name: "Rosario Seaplane Base",
    },
    {
      city: "Rochester",
      code: "RST",
      name: "Rochester International Airport",
    },
    {
      city: "Yeosu-Si",
      code: "RSU",
      name: "Yeosu Airport",
    },
    {
      city: "Fort Myers",
      code: "RSW",
      name: "Southwest Florida International Airport",
    },
    {
      city: "Rotuma Island",
      code: "RTA",
      name: "Rotuma",
    },
    {
      city: "Roatán",
      code: "RTB",
      name: "Roatan Island Airport",
    },
    {
      city: "Ruteng",
      code: "RTG",
      name: "Satartacik Airport",
    },
    {
      city: "Rotterdam",
      code: "RTM",
      name: "Rotterdam Airport",
    },
    {
      city: "Saratov",
      code: "RTW",
      name: "Saratov Airport",
    },
    {
      city: "Arua",
      code: "RUA",
      name: "",
    },
    {
      city: "Oslo",
      code: "RUD",
      name: "",
    },
    {
      city: "Riyadh",
      code: "RUH",
      name: "King Khalid International Airport",
    },
    {
      city: "Rukumkot",
      code: "RUK",
      name: "Nepal",
    },
    {
      city: "Rumjatar",
      code: "RUM",
      name: "Rumjatar Airport",
    },
    {
      city: "Sainte-Marie",
      code: "RUN",
      name: "Saint Denis Gillot Airport",
    },
    {
      city: "Rurutu",
      code: "RUR",
      name: "",
    },
    {
      city: "North Clarendon",
      code: "RUT",
      name: "Rutland State Airport",
    },
    {
      city: "Farafangana",
      code: "RVA",
      name: "",
    },
    {
      city: "Saravena",
      code: "RVE",
      name: "Saravena El Eden Airport",
    },
    {
      city: "Rorvik",
      code: "RVK",
      name: "Ryumsjoen Airport",
    },
    {
      city: "Saarenkylä",
      code: "RVN",
      name: "Rovaniemi Airport",
    },
    {
      city: "Ravensthorpe",
      code: "RVT",
      name: "Western Australia",
    },
    {
      city: "Rairua",
      code: "RVV",
      name: "Raivavae Airport",
    },
    {
      city: "Ivisan",
      code: "RXS",
      name: "Roxas Airport",
    },
    {
      city: "Rygge",
      code: "RYG",
      name: "Moss Airport",
    },
    {
      city: "Rahim Yar Khan",
      code: "RYK",
      name: "Rahim Yar Khan",
    },
    {
      city: "Trzebownisko",
      code: "RZE",
      name: "Jasionka Airport",
    },
    {
      city: "Taytay Sandoval",
      code: "RZP",
      name: "Philippines",
    },
    {
      city: "Ramsar",
      code: "RZR",
      name: "Ramsar Airport",
    },
    {
      city: "Sawan",
      code: "RZS",
      name: "",
    },
    {
      city: "",
      code: "SAB",
      name: "Yrausquin Airport",
    },
    {
      city: "Ar Raudha",
      code: "SAH",
      name: "Sanaa International Airport",
    },
    {
      city: "San Luis",
      code: "SAL",
      name: "El Salvador International Airport",
    },
    {
      city: "San Diego",
      code: "SAN",
      name: "San Diego International Airport",
    },
    {
      city: "San Pedro Sula",
      code: "SAP",
      name: "La Mesa International Airport",
    },
    {
      city: "San Andros",
      code: "SAQ",
      name: "San Andros Municipal Airport",
    },
    {
      city: "San Antonio",
      code: "SAT",
      name: "San Antonio International Airport",
    },
    {
      city: "Savannah",
      code: "SAV",
      name: "Savannah International Airport",
    },
    {
      city: "Umraniye",
      code: "SAW",
      name: "Istanbul Sabiha Gokcen Airport",
    },
    {
      city: "Goleta",
      code: "SBA",
      name: "Santa Barbara Municipal Airport",
    },
    {
      city: "Gustavia",
      code: "SBH",
      name: "Gustavia Airport",
    },
    {
      city: "Santa Ana",
      code: "SBL",
      name: "Santa Ana de Yacuma Airport",
    },
    {
      city: "South Bend",
      code: "SBN",
      name: "South Bend Regional Airport",
    },
    {
      city: "San Luis Obispo",
      code: "SBP",
      name: "San Luis Obispo County Airport",
    },
    {
      city: "Saibai Island",
      code: "SBR",
      name: "Saibai Island",
    },
    {
      city: "Sibu",
      code: "SBW",
      name: "Sibu Airport",
    },
    {
      city: "Salisbury",
      code: "SBY",
      name: "Salisbury-Wicomico County Regional Airport",
    },
    {
      city: "Sibiu",
      code: "SBZ",
      name: "Turnisor Airport",
    },
    {
      city: "Prudhoe Bay",
      code: "SCC",
      name: "Deadhorse Airport",
    },
    {
      city: "State College",
      code: "SCE",
      name: "University Park Airport",
    },
    {
      city: "Stockton",
      code: "SCK",
      name: "Stockton Metropolitan Airport",
    },
    {
      city: "Lo Amor",
      code: "SCL",
      name: "Arturo Merino Benitez International Airport",
    },
    {
      city: "Scammon Bay",
      code: "SCM",
      name: "Scammon Bay",
    },
    {
      city: "Sarrebruck",
      code: "SCN",
      name: "Saarbrucken Airport",
    },
    {
      city: "Aktau",
      code: "SCO",
      name: "Aktau",
    },
    {
      city: "Santiago",
      code: "SCQ",
      name: "Santiago Airport",
    },
    {
      city: "Socotra",
      code: "SCT",
      name: "",
    },
    {
      city: "",
      code: "SCU",
      name: "Antonio Maceo Airport",
    },
    {
      city: "",
      code: "SCV",
      name: "Suceava Salcea Airport",
    },
    {
      city: "Syktyvkar",
      code: "SCW",
      name: "Russia",
    },
    {
      city: "Salina Cruz",
      code: "SCX",
      name: "Salina Cruz",
    },
    {
      city: "Puerto Baquerizo Moreno",
      code: "SCY",
      name: "San Cristobal Airport",
    },
    {
      city: "Santa Cruz Is",
      code: "SCZ",
      name: "Santa Cruz Is",
    },
    {
      city: "Lubango",
      code: "SDD",
      name: "Lubango Airport",
    },
    {
      city: "Frías",
      code: "SDE",
      name: "Santiago del Estero Airport",
    },
    {
      city: "Louisville",
      code: "SDF",
      name: "Louisville International Airport",
    },
    {
      city: "Sanandaj",
      code: "SDG",
      name: "Sanandaj Airport",
    },
    {
      city: "Natori-shi",
      code: "SDJ",
      name: "Sendai Airport",
    },
    {
      city: "Sandakan",
      code: "SDK",
      name: "Sandakan Airport",
    },
    {
      city: "Bergeforsen",
      code: "SDL",
      name: "Sundsvall Harnosand Airport",
    },
    {
      city: "Sandene",
      code: "SDN",
      name: "Sandane Airport",
    },
    {
      city: "Sand Point",
      code: "SDP",
      name: "Sand Point Airport",
    },
    {
      city: "Santo Domingo",
      code: "SDQ",
      name: "De Las Americas International Airport",
    },
    {
      city: "Santander",
      code: "SDR",
      name: "Santander Airport",
    },
    {
      city: "Saidu",
      code: "SDT",
      name: "Saidu Sharif Airport",
    },
    {
      city: "Rio de Janeiro",
      code: "SDU",
      name: "Santos Dumont Airport",
    },
    {
      city: "Tel Aviv Yafo",
      code: "SDV",
      name: "Sde Dov Airport",
    },
    {
      city: "Sidney",
      code: "SDY",
      name: "Sidney Richland Municipal Airport",
    },
    {
      city: "Seattle",
      code: "SEA",
      name: "Tacoma International Airport",
    },
    {
      city: "Sabha",
      code: "SEB",
      name: "Sebha Airport",
    },
    {
      city: "Southend on Sea",
      code: "SEN",
      name: "Southend Airport",
    },
    {
      city: "Siwa",
      code: "SEW",
      name: "",
    },
    {
      city: "Victoria",
      code: "SEZ",
      name: "Seychelles International Airport",
    },
    {
      city: "Safaqis",
      code: "SFA",
      name: "El Maou Airport",
    },
    {
      city: "Sanford",
      code: "SFB",
      name: "Orlando Sanford International Airport",
    },
    {
      city: "San Fernando de Apure",
      code: "SFD",
      name: "San Fernando de Apure Airport",
    },
    {
      city: "San Fernando",
      code: "SFE",
      name: "San Fernando Airport",
    },
    {
      city: "",
      code: "SFG",
      name: "Grand Case-Esperance Airport",
    },
    {
      city: "Kangerlussuaq",
      code: "SFJ",
      name: "Kangerlussuaq",
    },
    {
      city: "Sao Filipe",
      code: "SFL",
      name: "",
    },
    {
      city: "Santa Fe",
      code: "SFN",
      name: "Sauce Viejo Airport",
    },
    {
      city: "San Francisco",
      code: "SFO",
      name: "San Francisco International Airport",
    },
    {
      city: "Skelleftea",
      code: "SFT",
      name: "Skelleftea Airport",
    },
    {
      city: "Khanty-Mansiysk",
      code: "SGC",
      name: "Surgut North Airport",
    },
    {
      city: "Sonderborg",
      code: "SGD",
      name: "Sonderborg Airport",
    },
    {
      city: "Springfield",
      code: "SGF",
      name: "Springfield Regional Airport",
    },
    {
      city: "Ho Chi Minh City",
      code: "SGN",
      name: "Tan Son Nhut Airport",
    },
    {
      city: "St George",
      code: "SGO",
      name: "St George",
    },
    {
      city: "St. George",
      code: "SGU",
      name: "St George Municipal Airport",
    },
    {
      city: "Songea",
      code: "SGX",
      name: "Ruvuma",
    },
    {
      city: "Skagway",
      code: "SGY",
      name: "Skagway Airport",
    },
    {
      city: "Shanghai",
      code: "SHA",
      name: "Hongqiao Airport",
    },
    {
      city: "Nakashibetsu-cho",
      code: "SHB",
      name: "Nakashibetsu Airport",
    },
    {
      city: "Indaselassie",
      code: "SHC",
      name: "Shire Inda Selassie Airport",
    },
    {
      city: "Weyers Cave",
      code: "SHD",
      name: "Shenandoah Valley Regional Airport",
    },
    {
      city: "Shenyang",
      code: "SHE",
      name: "Dongta Airport",
    },
    {
      city: "Shungnak",
      code: "SHG",
      name: "Shungnak Airport",
    },
    {
      city: "Shishmaref",
      code: "SHH",
      name: "Shishmaref",
    },
    {
      city: "Ajman",
      code: "SHJ",
      name: "Sharjah International Airport",
    },
    {
      city: "Shillong",
      code: "SHL",
      name: "",
    },
    {
      city: "Shirahama-cho",
      code: "SHM",
      name: "Nanki-Shirahama Airport",
    },
    {
      city: "Qinhuangdao",
      code: "SHP",
      name: "Qinhuangdao",
    },
    {
      city: "Sheridan",
      code: "SHR",
      name: "Sheridan County Airport",
    },
    {
      city: "Shreveport",
      code: "SHV",
      name: "Shreveport Regional Airport",
    },
    {
      city: "As-Saraura",
      code: "SHW",
      name: "Sharurah Airport",
    },
    {
      city: "Shageluk",
      code: "SHX",
      name: "Shageluk",
    },
    {
      city: "Shinyanga",
      code: "SHY",
      name: "Shinyanga",
    },
    {
      city: "Xi'an",
      code: "SIA",
      name: "Xiguan Airport",
    },
    {
      city: "Sinop",
      code: "SIC",
      name: "",
    },
    {
      city: "Santa Maria",
      code: "SID",
      name: "Amilcar Cabral International Airport",
    },
    {
      city: "Simara",
      code: "SIF",
      name: "Simara Airport",
    },
    {
      city: "San Juan",
      code: "SIG",
      name: "Isla Grande Airport",
    },
    {
      city: "Singapore",
      code: "SIN",
      name: "Singapore Changi Airport",
    },
    {
      city: "Simferopol'",
      code: "SIP",
      name: "Simferopol North Airport",
    },
    {
      city: "Sitka",
      code: "SIT",
      name: "Sitka Airport",
    },
    {
      city: "San Jose",
      code: "SJC",
      name: "Norman Y Mineta San Jose International Airport",
    },
    {
      city: "S. Jose del Cabo",
      code: "SJD",
      name: "Los Cabos International Airport",
    },
    {
      city: "San Jose del Guavuare",
      code: "SJE",
      name: "San Jose del Guaviaro Airport",
    },
    {
      city: "San Jose",
      code: "SJI",
      name: "San Jose Airport",
    },
    {
      city: "Ilidža",
      code: "SJJ",
      name: "Sarajevo Airport",
    },
    {
      city: "Sao Jose dos Campos",
      code: "SJK",
      name: "Sao Jose dos Campos Airport",
    },
    {
      city: "Heredia",
      code: "SJO",
      name: "Juan Santamaria International Airport",
    },
    {
      city: "São José do Rio Prêto",
      code: "SJP",
      name: "Sao Jose do Rio Preto Airport",
    },
    {
      city: "San Angelo",
      code: "SJT",
      name: "Mathis Field Airport",
    },
    {
      city: "Carolina",
      code: "SJU",
      name: "Luis Munoz Marin Airport",
    },
    {
      city: "Shijiazhuang",
      code: "SJW",
      name: "Shijiazhuang",
    },
    {
      city: "Seinajoki",
      code: "SJY",
      name: "Ilmajoki Airport",
    },
    {
      city: "Velas",
      code: "SJZ",
      name: "Sao Jorge Airport",
    },
    {
      city: "Basseterre",
      code: "SKB",
      name: "Golden Rock Airport",
    },
    {
      city: "Suki",
      code: "SKC",
      name: "Papua New Guinea",
    },
    {
      city: "Samarkand",
      code: "SKD",
      name: "Samarqand",
    },
    {
      city: "Skien",
      code: "SKE",
      name: "Geiterygen Airport",
    },
    {
      city: "Thessaloniki",
      code: "SKG",
      name: "Thessaloniki Airport",
    },
    {
      city: "Surkhet",
      code: "SKH",
      name: "Surkhet",
    },
    {
      city: "Shaktoolik",
      code: "SKK",
      name: "Shaktoolik",
    },
    {
      city: "Stokkmarknes",
      code: "SKN",
      name: "Stokmarknes Airport",
    },
    {
      city: "Shunni",
      code: "SKO",
      name: "Sadiq Abubakar Iii Airport",
    },
    {
      city: "Skopje",
      code: "SKP",
      name: "Petrovec",
    },
    {
      city: "Sialkot",
      code: "SKT",
      name: "Sialkot",
    },
    {
      city: "Skiros",
      code: "SKU",
      name: "Skiros Airport",
    },
    {
      city: "Sukkur",
      code: "SKZ",
      name: "Sukkur Airport",
    },
    {
      city: "La Caldera",
      code: "SLA",
      name: "Salta Airport",
    },
    {
      city: "Salt Lake City",
      code: "SLC",
      name: "Salt Lake City International Airport",
    },
    {
      city: "Salem",
      code: "SLE",
      name: "Mcnary Field Airport",
    },
    {
      city: "Sola",
      code: "SLH",
      name: "Torba",
    },
    {
      city: "Solwezi",
      code: "SLI",
      name: "",
    },
    {
      city: "Saranac Lake",
      code: "SLK",
      name: "Adirondack Regional Airport",
    },
    {
      city: "Salalah",
      code: "SLL",
      name: "Salalah Airport",
    },
    {
      city: "Villagonzalo de Tormes",
      code: "SLM",
      name: "Salamanca Airport",
    },
    {
      city: "Salina",
      code: "SLN",
      name: "Salina Municipal Airport",
    },
    {
      city: "S. Luis Potosi",
      code: "SLP",
      name: "San Luis Potosi Airport",
    },
    {
      city: "Sleetmute",
      code: "SLQ",
      name: "Sleetmute Airport",
    },
    {
      city: "St Lucia",
      code: "SLU",
      name: "Vigie Airport",
    },
    {
      city: "Jutogh",
      code: "SLV",
      name: "Simla Airport",
    },
    {
      city: "General Cepeda",
      code: "SLW",
      name: "Plan de Guadalupe Airport",
    },
    {
      city: "Salt Cay",
      code: "SLX",
      name: "Salt Cay Airport",
    },
    {
      city: "Salekhard",
      code: "SLY",
      name: "Russia",
    },
    {
      city: "Salvador",
      code: "SLZ",
      name: "Marechal Cunha Machado International Airport",
    },
    {
      city: "Vila do Porto",
      code: "SMA",
      name: "Santa Maria Airport",
    },
    {
      city: "Sacramento",
      code: "SMF",
      name: "Sacramento International Airport",
    },
    {
      city: "Pithagorion",
      code: "SMI",
      name: "Samos Airport",
    },
    {
      city: "St Michael",
      code: "SMK",
      name: "Alaska",
    },
    {
      city: "Simms",
      code: "SML",
      name: "Stella Maris Airport",
    },
    {
      city: "Salmon",
      code: "SMN",
      name: "Lemhi County Airport",
    },
    {
      city: "",
      code: "SMR",
      name: "Simon Bolivar Airport",
    },
    {
      city: "Toamasina",
      code: "SMS",
      name: "Sainte Marie Airport",
    },
    {
      city: "Santa Maria",
      code: "SMX",
      name: "Santa Maria Public Airport",
    },
    {
      city: "Santa Ana",
      code: "SNA",
      name: "John Wayne Airport",
    },
    {
      city: "Salinas",
      code: "SNC",
      name: "General Ulpiano Paez Airport",
    },
    {
      city: "Ribeira Brava",
      code: "SNE",
      name: "Preguica Airport",
    },
    {
      city: "Shannon Airport",
      code: "SNN",
      name: "Shannon Airport",
    },
    {
      city: "Sakon Nakhon",
      code: "SNO",
      name: "Sakon Nakhon Airport",
    },
    {
      city: "Saint Paul Island",
      code: "SNP",
      name: "Saint Paul Island",
    },
    {
      city: "Montoir-de-Bretagne",
      code: "SNR",
      name: "Montoir Airport",
    },
    {
      city: "Esperanza",
      code: "SNU",
      name: "Santa Clara Airport",
    },
    {
      city: "Thandwe",
      code: "SNW",
      name: "Myanmar",
    },
    {
      city: "Zalavár",
      code: "SOB",
      name: "Sarmellek Airport",
    },
    {
      city: "Surakarta",
      code: "SOC",
      name: "Adi Sumarmo Wiryokusumo Airport",
    },
    {
      city: "Sofia",
      code: "SOF",
      name: "Vrazhdebna Airport",
    },
    {
      city: "Kaupanger",
      code: "SOG",
      name: "Haukasen Airport",
    },
    {
      city: "Sørkjosen",
      code: "SOJ",
      name: "Sorkjosen Airport",
    },
    {
      city: "Cantaura",
      code: "SOM",
      name: "San Tome Airport",
    },
    {
      city: "",
      code: "SON",
      name: "Santo Pekoa International Airport",
    },
    {
      city: "Soderhamn",
      code: "SOO",
      name: "Soderhamn Airport",
    },
    {
      city: "Sorong",
      code: "SOQ",
      name: "Jefman Airport",
    },
    {
      city: "Southampton",
      code: "SOU",
      name: "Southampton International Airport",
    },
    {
      city: "Seldovia",
      code: "SOV",
      name: "",
    },
    {
      city: "Show Low",
      code: "SOW",
      name: "Show Low Municipal Airport",
    },
    {
      city: "Charlotte Amalie",
      code: "SPB",
      name: "St Thomas Seaplane Base",
    },
    {
      city: "Breña Alta",
      code: "SPC",
      name: "Santa Cruz de la Palma Airport",
    },
    {
      city: "Dinajpur",
      code: "SPD",
      name: "Saidpur Airport",
    },
    {
      city: "Springfield",
      code: "SPI",
      name: "Capital Airport",
    },
    {
      city: "Vila do Porto",
      code: "SPL",
      name: "Santa Maria Airport",
    },
    {
      city: "Saipan",
      code: "SPN",
      name: "Saipan International Airport",
    },
    {
      city: "Menongue",
      code: "SPP",
      name: "Menongue East Airport",
    },
    {
      city: "San Pedro",
      code: "SPR",
      name: "San Pedro Airport",
    },
    {
      city: "Wichita Falls",
      code: "SPS",
      name: "Sheppard Air Force Base",
    },
    {
      city: "Split",
      code: "SPU",
      name: "Split Airport",
    },
    {
      city: "Storuman",
      code: "SQO",
      name: "Sweden",
    },
    {
      city: "Santa Rosa",
      code: "SRA",
      name: "Santa Rosa Airport",
    },
    {
      city: "Sucre",
      code: "SRE",
      name: "Juana Azurduy de Padilla Airport",
    },
    {
      city: "Semarang",
      code: "SRG",
      name: "Achmad Yani Airport",
    },
    {
      city: "San Borja",
      code: "SRJ",
      name: "Capitan G Q Guardia",
    },
    {
      city: "Skjold",
      code: "SRP",
      name: "Sorstukken Airport",
    },
    {
      city: "Sarasota",
      code: "SRQ",
      name: "Sarasota Bradenton Airport",
    },
    {
      city: "Stony River",
      code: "SRV",
      name: "Alaska",
    },
    {
      city: "Sert",
      code: "SRX",
      name: "Surt",
    },
    {
      city: "Sary",
      code: "SRY",
      name: "",
    },
    {
      city: "Santa Cruz de la Sierra",
      code: "SRZ",
      name: "El Trompillo Airport",
    },
    {
      city: "Salvador",
      code: "SSA",
      name: "Deputado Luis Eduardo Magalhaes International Ai",
    },
    {
      city: "Christiansted",
      code: "SSB",
      name: "Christiansted Harbor Seaplane Base",
    },
    {
      city: "Malabo",
      code: "SSG",
      name: "Malabo Airport",
    },
    {
      city: "Al Arish",
      code: "SSH",
      name: "Ras Nasrani Airport",
    },
    {
      city: "Sandnessjoen",
      code: "SSJ",
      name: "Stokka Airport",
    },
    {
      city: "Sara",
      code: "SSR",
      name: "Vanuatu",
    },
    {
      city: "M'banza Congo",
      code: "SSY",
      name: "Mbanza Congo Airport",
    },
    {
      city: "St. Cloud",
      code: "STC",
      name: "St Cloud Regional Airport",
    },
    {
      city: "Santa Ana del Tachira",
      code: "STD",
      name: "Mayor Buenaventura Vivas Airport",
    },
    {
      city: "St George Island",
      code: "STG",
      name: "Alaska",
    },
    {
      city: "La Lomota",
      code: "STI",
      name: "Cibao International Airport",
    },
    {
      city: "St. Louis",
      code: "STL",
      name: "Lambert St Louis International Airport",
    },
    {
      city: "Santarém",
      code: "STM",
      name: "Santarem International Airport",
    },
    {
      city: "Stansted Mountfitchet",
      code: "STN",
      name: "London Stansted International Airport",
    },
    {
      city: "Stuttgart",
      code: "STR",
      name: "Stuttgart Airport",
    },
    {
      city: "Windsor",
      code: "STS",
      name: "Sonoma County Airport",
    },
    {
      city: "Charlotte Amalie",
      code: "STT",
      name: "Cyril E King International Airport",
    },
    {
      city: "Un",
      code: "STV",
      name: "Surat Airport",
    },
    {
      city: "Stavropol'",
      code: "STW",
      name: "Mikhaylovskoye Airport",
    },
    {
      city: "Frederiksted",
      code: "STX",
      name: "Henry E Rohlson International Airport",
    },
    {
      city: "Sidoarjo",
      code: "SUB",
      name: "Juanda Airport",
    },
    {
      city: "Case Cervi",
      code: "SUF",
      name: "Lamezia Terme Airport",
    },
    {
      city: "Surigao City",
      code: "SUG",
      name: "Surigao Airport",
    },
    {
      city: "Satu Mare",
      code: "SUJ",
      name: "Satu Mare Airport",
    },
    {
      city: "Hailey",
      code: "SUN",
      name: "Friedman Memorial Airport",
    },
    {
      city: "Summer Beaver",
      code: "SUR",
      name: "Summer Beaver",
    },
    {
      city: "Nausori",
      code: "SUV",
      name: "Nausori International Airport",
    },
    {
      city: "Sioux City",
      code: "SUX",
      name: "Sioux Gateway Airport",
    },
    {
      city: "Savoonga",
      code: "SVA",
      name: "Alaska",
    },
    {
      city: "Sambava",
      code: "SVB",
      name: "Sambava",
    },
    {
      city: "Silver City",
      code: "SVC",
      name: "Silver City-Grant County Airport",
    },
    {
      city: "Kingstown",
      code: "SVD",
      name: "E T Joshua Airport",
    },
    {
      city: "Rage",
      code: "SVG",
      name: "Stavanger Sola Airport",
    },
    {
      city: "San Vicente del Caguán",
      code: "SVI",
      name: "San Vicente del Caguan Airport",
    },
    {
      city: "Svolvar",
      code: "SVJ",
      name: "Helle Airport",
    },
    {
      city: "Savonlinna",
      code: "SVL",
      name: "Savonlinna Airport",
    },
    {
      city: "Zelenograd",
      code: "SVO",
      name: "Sheremtyevo Airport",
    },
    {
      city: "Seville",
      code: "SVQ",
      name: "Sevilla Airport",
    },
    {
      city: "Stevens Village",
      code: "SVS",
      name: "Stevens Village",
    },
    {
      city: "Labasa",
      code: "SVU",
      name: "Savusavu Airport",
    },
    {
      city: "Yekaterinburg",
      code: "SVX",
      name: "Koltsovo Airport",
    },
    {
      city: "Táriba",
      code: "SVZ",
      name: "San Antonio del Tachira Airport",
    },
    {
      city: "Chenghai",
      code: "SWA",
      name: "Shantou Northeast Airport",
    },
    {
      city: "New Windsor",
      code: "SWF",
      name: "Stewart International Airport",
    },
    {
      city: "South West Bay",
      code: "SWJ",
      name: "South West Bay Airport",
    },
    {
      city: "Trezzano sul Naviglio",
      code: "SWK",
      name: "Segrate Airport",
    },
    {
      city: "Stillwater",
      code: "SWO",
      name: "Stillwater Municipal Airport",
    },
    {
      city: "Sumbawa Besar",
      code: "SWQ",
      name: "Brang Bidji Airport",
    },
    {
      city: "Swansea",
      code: "SWS",
      name: "Swansea Airport",
    },
    {
      city: "Entzheim",
      code: "SXB",
      name: "Entzheim Airport",
    },
    {
      city: "Schönefeld",
      code: "SXF",
      name: "Berlin-Schonefeld International Airport",
    },
    {
      city: "Larass",
      code: "SXL",
      name: "Sligo Airport",
    },
    {
      city: "",
      code: "SXM",
      name: "Prinses Juliana International Airport",
    },
    {
      city: "Sheldon Point",
      code: "SXP",
      name: "Sheldon SPB",
    },
    {
      city: "Srinagar",
      code: "SXR",
      name: "Srinagar Airport",
    },
    {
      city: "Kodiak",
      code: "SYB",
      name: "Seal Bay Airport",
    },
    {
      city: "Sydney",
      code: "SYD",
      name: "Kingsford Smith Airport",
    },
    {
      city: "Sirjan",
      code: "SYJ",
      name: "",
    },
    {
      city: "Simao",
      code: "SYM",
      name: "Simao",
    },
    {
      city: "Shonai",
      code: "SYO",
      name: "Shonai",
    },
    {
      city: "Syracuse",
      code: "SYR",
      name: "Hancock International Airport",
    },
    {
      city: "Sue Island",
      code: "SYU",
      name: "Australia",
    },
    {
      city: "Sanya",
      code: "SYX",
      name: "Sanya",
    },
    {
      city: "Isle of lewis",
      code: "SYY",
      name: "Stornoway Airport",
    },
    {
      city: "Shiraz",
      code: "SYZ",
      name: "Shiraz International Airport",
    },
    {
      city: "Santo António do Zaire",
      code: "SZA",
      name: "Soyo Airport",
    },
    {
      city: "Kampong Baru Subang",
      code: "SZB",
      name: "Sultan Abdul Aziz Shah Airport",
    },
    {
      city: "Sheffield",
      code: "SZD",
      name: "Sheffield City Airport",
    },
    {
      city: "Semera",
      code: "SZE",
      name: "Semera Airport",
    },
    {
      city: "Samsun",
      code: "SZF",
      name: "Çarşamba",
    },
    {
      city: "Salzburg",
      code: "SZG",
      name: "Salzburg Airport",
    },
    {
      city: "Suzhou",
      code: "SZV",
      name: "China",
    },
    {
      city: "Shenzhen",
      code: "SZX",
      name: "Shenzhen Airport",
    },
    {
      city: "Goleniów",
      code: "SZZ",
      name: "Golenow Airport",
    },
    {
      city: "Plymouth",
      code: "TAB",
      name: "Crown Point Airport",
    },
    {
      city: "Tacloban City",
      code: "TAC",
      name: "Daniel Z Romualdez Airport",
    },
    {
      city: "Daegu",
      code: "TAE",
      name: "Daegu International Airport",
    },
    {
      city: "Tagbilaran City",
      code: "TAG",
      name: "Tagbilaran Airport",
    },
    {
      city: "Isangel",
      code: "TAH",
      name: "Tanna Airport",
    },
    {
      city: "Al-Ganad",
      code: "TAI",
      name: "Taiz Ganed Airport",
    },
    {
      city: "Takamatsu",
      code: "TAK",
      name: "Japan",
    },
    {
      city: "Tanana",
      code: "TAL",
      name: "Ralph Calhoun",
    },
    {
      city: "Tampico",
      code: "TAM",
      name: "Gen Francisco J Mina International Airport",
    },
    {
      city: "Wanggezhuang",
      code: "TAO",
      name: "Liuting Airport",
    },
    {
      city: "Tapachula",
      code: "TAP",
      name: "Tapachula International Airport",
    },
    {
      city: "Tashkent",
      code: "TAS",
      name: "Tashkent South Airport",
    },
    {
      city: "Poprad",
      code: "TAT",
      name: "Poprad Tatry Airport",
    },
    {
      city: "Tuy Hoa",
      code: "TBB",
      name: "Tuy Hoa Airport",
    },
    {
      city: "Tabubil",
      code: "TBG",
      name: "Western",
    },
    {
      city: "Alcantara",
      code: "TBH",
      name: "Romblon Airport",
    },
    {
      city: "New Bight",
      code: "TBI",
      name: "The Bight Airport",
    },
    {
      city: "Jundobah",
      code: "TBJ",
      name: "Tabarka Airport",
    },
    {
      city: "Fort Leonard Wood",
      code: "TBN",
      name: "Waynesville Regional Airport At Forney Field",
    },
    {
      city: "Tabora",
      code: "TBO",
      name: "",
    },
    {
      city: "Tumbes",
      code: "TBP",
      name: "Pedro Canga Airport",
    },
    {
      city: "Tbilisi",
      code: "TBS",
      name: "Tbilisi-Noyo Alekseyevka Airport",
    },
    {
      city: "Nuku'alofa",
      code: "TBU",
      name: "Fua'amotu International Airport",
    },
    {
      city: "Tambov",
      code: "TBW",
      name: "",
    },
    {
      city: "Tabriz",
      code: "TBZ",
      name: "Tabriz Airport",
    },
    {
      city: "Green Turtle Cay",
      code: "TCB",
      name: "Treasure Cay Airport",
    },
    {
      city: "Tulcea",
      code: "TCE",
      name: "Cataloi Airport",
    },
    {
      city: "Tchibanga",
      code: "TCH",
      name: "",
    },
    {
      city: "Tumaco",
      code: "TCO",
      name: "La Florida Airport",
    },
    {
      city: "Taba",
      code: "TCP",
      name: "Taba",
    },
    {
      city: "Tacna",
      code: "TCQ",
      name: "Cor Fap Carlos C Santa Rosa Airport",
    },
    {
      city: "Tuticorin",
      code: "TCR",
      name: "",
    },
    {
      city: "Takotna",
      code: "TCT",
      name: "Takotna",
    },
    {
      city: "Trinidad",
      code: "TDD",
      name: "Jorge Henrich Arauz Airport",
    },
    {
      city: "Trat",
      code: "TDX",
      name: "Mueang Trat",
    },
    {
      city: "Teterboro",
      code: "TEB",
      name: "Teterboro Airport",
    },
    {
      city: "Tebessa",
      code: "TEE",
      name: "Tebessa Airport",
    },
    {
      city: "Valdez",
      code: "TEK",
      name: "Tatitlek Seaplane Base",
    },
    {
      city: "Tongren",
      code: "TEN",
      name: "Tongren",
    },
    {
      city: "Praia da Vitória",
      code: "TER",
      name: "Lajes Airport",
    },
    {
      city: "Tete",
      code: "TET",
      name: "Tete Chingozi Airport",
    },
    {
      city: "Telluride",
      code: "TEX",
      name: "Telluride Regional Airport",
    },
    {
      city: "Tufi",
      code: "TFI",
      name: "",
    },
    {
      city: "Tegueste",
      code: "TFN",
      name: "Norte-Los Rodeos Airport",
    },
    {
      city: "Granadilla",
      code: "TFS",
      name: "Sur-Reina Sofia Airport",
    },
    {
      city: "Milan",
      code: "TGC",
      name: "Gibson County Airport",
    },
    {
      city: "Podgorica",
      code: "TGD",
      name: "Titograd Airport",
    },
    {
      city: "Kuala Terengganu",
      code: "TGG",
      name: "Sultan Mahmud Airport",
    },
    {
      city: "",
      code: "TGH",
      name: "Tongoa Airport",
    },
    {
      city: "Tiga",
      code: "TGJ",
      name: "Loyaute",
    },
    {
      city: "",
      code: "TGM",
      name: "Vidrasau Airport",
    },
    {
      city: "Tongliao",
      code: "TGO",
      name: "Tongliao",
    },
    {
      city: "Ouargla",
      code: "TGR",
      name: "Touggourt Airport",
    },
    {
      city: "Tegucigalpa",
      code: "TGU",
      name: "Toncontin International Airport",
    },
    {
      city: "San Fernando",
      code: "TGZ",
      name: "Tuxtla Gutierrez Airport",
    },
    {
      city: "Teresina",
      code: "THE",
      name: "Senador Petronio Portella Airport",
    },

    {
      city: "Tachilek",
      code: "THL",
      name: "Tachilek Airport",
    },
    {
      city: "Trollhatan",
      code: "THN",
      name: "Trollhattan Vanersborg Airport",
    },
    {
      city: "Thorshofn",
      code: "THO",
      name: "Thorshofn Airport",
    },
    {
      city: "Tehran",
      code: "THR",
      name: "Mehrabad International Airport",
    },
    {
      city: "Sawankhalok",
      code: "THS",
      name: "Sukhothai Airport",
    },
    {
      city: "Pituffik",
      code: "THU",
      name: "Pituffik",
    },
    {
      city: "Krna",
      code: "TIA",
      name: "Tirane Rinas Airport",
    },
    {
      city: "",
      code: "TIF",
      name: "Taif Airport",
    },
    {
      city: "Tikehau Atoll",
      code: "TIH",
      name: "French Polynesia",
    },
    {
      city: "Tijuana",
      code: "TIJ",
      name: "General Abelardo L Rodriguez International Airpo",
    },
    {
      city: "Nabire",
      code: "TIM",
      name: "Tembagapura Airport",
    },
    {
      city: "Tindouf",
      code: "TIN",
      name: "",
    },
    {
      city: "Tripoli",
      code: "TIP",
      name: "International",
    },
    {
      city: "Tinian",
      code: "TIQ",
      name: "Tinian",
    },
    {
      city: "Renigunta",
      code: "TIR",
      name: "Tirupathi Airport",
    },
    {
      city: "Kubin Village",
      code: "TIS",
      name: "Thursday Island Airport",
    },
    {
      city: "Timaru",
      code: "TIU",
      name: "Richard Pearse Airport",
    },
    {
      city: "Teodo",
      code: "TIV",
      name: "Tivat Airport",
    },
    {
      city: "Mendi",
      code: "TIZ",
      name: "Tari Airport",
    },
    {
      city: "Tarija",
      code: "TJA",
      name: "Capitan Oriel Lea Plaza Airport",
    },
    {
      city: "Tyumen'",
      code: "TJM",
      name: "Tyumen Northwest Airport",
    },
    {
      city: "Takume",
      code: "TJN",
      name: "French Polynesia",
    },
    {
      city: "Tanjungpandan",
      code: "TJQ",
      name: "Bulutumbang Airport",
    },
    {
      city: "Tenakee Springs",
      code: "TKE",
      name: "Tenakee Springs",
    },
    {
      city: "Tanjungkarang",
      code: "TKG",
      name: "Branti Airport",
    },
    {
      city: "Tok",
      code: "TKJ",
      name: "",
    },
    {
      city: "Weno",
      code: "TKK",
      name: "Truk International Airport",
    },
    {
      city: "Amagi-cho",
      code: "TKN",
      name: "Tokunoshima Airport",
    },
    {
      city: "Takapoto",
      code: "TKP",
      name: "French Polynesia",
    },
    {
      city: "Kigoma",
      code: "TKQ",
      name: "Tanzania",
    },
    {
      city: "Matsushige-cho",
      code: "TKS",
      name: "Tokushima Airport",
    },
    {
      city: "Turku",
      code: "TKU",
      name: "Turku Airport",
    },
    {
      city: "Tatakoto",
      code: "TKV",
      name: "French Polynesia",
    },
    {
      city: "Takaroa",
      code: "TKX",
      name: "Takaroa",
    },
    {
      city: "Teller",
      code: "TLA",
      name: "Teller",
    },
    {
      city: "Toluca de Lerdo",
      code: "TLC",
      name: "Jose Maria Morelos Y Pavon Airport",
    },
    {
      city: "Toliara",
      code: "TLE",
      name: "Toliara Airport",
    },
    {
      city: "Tallahassee",
      code: "TLH",
      name: "Tallahassee Regional Airport",
    },
    {
      city: "Tatalina",
      code: "TLJ",
      name: "",
    },
    {
      city: "Tallinn",
      code: "TLL",
      name: "Ulemiste Airport",
    },
    {
      city: "Tlemcen",
      code: "TLM",
      name: "Zenata Airport",
    },
    {
      city: "Hyeres",
      code: "TLN",
      name: "Le Palyvestre Airport",
    },
    {
      city: "Blagnac",
      code: "TLS",
      name: "Blagnac Airport",
    },
    {
      city: "Tuluksak",
      code: "TLT",
      name: "Tuluksak",
    },
    {
      city: "Tel Aviv",
      code: "TLV",
      name: "Ben Gurion Airport",
    },
    {
      city: "Tambolaka",
      code: "TMC",
      name: "Tambolaka Airport",
    },
    {
      city: "Tame",
      code: "TME",
      name: "Tame Airport",
    },
    {
      city: "Termiz",
      code: "TMJ",
      name: "Termez Airport",
    },
    {
      city: "Savelugu",
      code: "TML",
      name: "Tamale Airport",
    },
    {
      city: "Toamasina",
      code: "TMM",
      name: "Toamasina Airport",
    },
    {
      city: "Pitkäniemi",
      code: "TMP",
      name: "Tampere Pirkkala Airport",
    },
    {
      city: "Tamanrasset",
      code: "TMR",
      name: "Tamanrasset Airport",
    },
    {
      city: "Sao Tome",
      code: "TMS",
      name: "Sao Tome Salazar Airport",
    },
    {
      city: "Trombetas",
      code: "TMT",
      name: "Trombetas",
    },
    {
      city: "Lepanto",
      code: "TMU",
      name: "Tambor Airport",
    },
    {
      city: "Barry",
      code: "TMW",
      name: "Tamworth Airport",
    },
    {
      city: "Timimoun",
      code: "TMX",
      name: "Adrar",
    },
    {
      city: "Jinan",
      code: "TNA",
      name: "Shandong",
    },
    {
      city: "Tin City",
      code: "TNC",
      name: "Tin City AFS",
    },
    {
      city: "Tangiers",
      code: "TNG",
      name: "Boukhalf Airport",
    },
    {
      city: "Tanjungpinang",
      code: "TNJ",
      name: "Kijang Airport",
    },
    {
      city: "Tununak",
      code: "TNK",
      name: "Tununak Airport",
    },
    {
      city: "Tainan City",
      code: "TNN",
      name: "Tainan Airport",
    },
    {
      city: "Liberia",
      code: "TNO",
      name: "Tamarindo Airport",
    },
    {
      city: "Ambohidratrimo",
      code: "TNR",
      name: "Antananarivo Ivato Airport",
    },
    {
      city: "Tosontsengel",
      code: "TNZ",
      name: "Hovsgol",
    },
    {
      city: "Kampung Genting",
      code: "TOD",
      name: "Tioman Airport",
    },
    {
      city: "Tawzar",
      code: "TOE",
      name: "Nefta Airport",
    },
    {
      city: "Tomsk",
      code: "TOF",
      name: "",
    },
    {
      city: "Togiak Village",
      code: "TOG",
      name: "Togiak Village",
    },
    {
      city: "Torres",
      code: "TOH",
      name: "Vanuatu",
    },
    {
      city: "Swanton",
      code: "TOL",
      name: "Toledo Express Airport",
    },
    {
      city: "",
      code: "TOM",
      name: "Tombouctou Airport",
    },
    {
      city: "Tromso",
      code: "TOS",
      name: "Tromso Langnes Airport",
    },
    {
      city: "Touho",
      code: "TOU",
      name: "",
    },
    {
      city: "Toyama-shi",
      code: "TOY",
      name: "Toyama Airport",
    },
    {
      city: "Tampa",
      code: "TPA",
      name: "Tampa International Airport",
    },
    {
      city: "Taoyuan City",
      code: "TPE",
      name: "Taiwan Taoyuan International Airport",
    },
    {
      city: "Taplejung",
      code: "TPJ",
      name: "Taplejung Suketar",
    },
    {
      city: "Tarapoto",
      code: "TPP",
      name: "Tarapoto Airport",
    },
    {
      city: "Tepic",
      code: "TPQ",
      name: "Tepic Airport",
    },
    {
      city: "Trapani",
      code: "TPS",
      name: "Trapani Birgi Airport",
    },
    {
      city: "San Domino Island",
      code: "TQR",
      name: "",
    },
    {
      city: "Torreón",
      code: "TRC",
      name: "Torreon International Airport",
    },
    {
      city: "Stjordal",
      code: "TRD",
      name: "Trondheim Vaernes Airport",
    },
    {
      city: "Crossapol",
      code: "TRE",
      name: "Tiree Aerodrome",
    },
    {
      city: "Sandefjord",
      code: "TRF",
      name: "Torp Airport",
    },
    {
      city: "Tauranga",
      code: "TRG",
      name: "Tauranga Airport",
    },
    {
      city: "Blountville",
      code: "TRI",
      name: "Tri-Cities Regional Airport",
    },
    {
      city: "Tarakan",
      code: "TRK",
      name: "Tarakan Airport",
    },
    {
      city: "Caselle",
      code: "TRN",
      name: "Turin International Airport",
    },
    {
      city: "Taree",
      code: "TRO",
      name: "Taree Airport",
    },
    {
      city: "Ronchi",
      code: "TRS",
      name: "Ronchi Dei Legionari Airport",
    },
    {
      city: "Huanchaco",
      code: "TRU",
      name: "Cap C Martinez de Pinillos Airport",
    },
    {
      city: "Thiruvananthapuram",
      code: "TRV",
      name: "Thiruvananthapuram Airport",
    },
    {
      city: "Tarawa",
      code: "TRW",
      name: "Bonriki International Airport",
    },
    {
      city: "Tiruchirapally",
      code: "TRZ",
      name: "",
    },
    {
      city: "Taipei City",
      code: "TSA",
      name: "Taipei Songshan Airport",
    },
    {
      city: "Aqmola",
      code: "TSE",
      name: "Tselinograd South Airport",
    },
    {
      city: "Treviso",
      code: "TSF",
      name: "Treviso Airport",
    },
    {
      city: "Tsushima-shi",
      code: "TSJ",
      name: "Tsushima Airport",
    },
    {
      city: "Ciudad Valles",
      code: "TSL",
      name: "Tamuin Airport",
    },
    {
      city: "Tanggu",
      code: "TSN",
      name: "Zhangguizhuang Airport",
    },
    {
      city: "Isles Of Scilly",
      code: "TSO",
      name: "",
    },
    {
      city: "",
      code: "TSR",
      name: "Timisoara Northeast Airport",
    },
    {
      city: "New York",
      code: "TSS",
      name: "East 34th Street Heliport",
    },
    {
      city: "Muang Trang",
      code: "TST",
      name: "Trang Airport",
    },
    {
      city: "Townsville",
      code: "TSV",
      name: "Townsville Airport",
    },
    {
      city: "Tan Tan",
      code: "TTA",
      name: "Tan-Tan",
    },
    {
      city: "Ternate",
      code: "TTE",
      name: "Babullah Airport",
    },
    {
      city: "Tottori-shi",
      code: "TTJ",
      name: "Tottori Airport",
    },
    {
      city: "Colorado",
      code: "TTQ",
      name: "Tortuquero Airport",
    },
    {
      city: "Taitung City",
      code: "TTT",
      name: "Taitung Airport",
    },
    {
      city: "Tetouan",
      code: "TTU",
      name: "Sania Ramel Airport",
    },
    {
      city: "Urbina",
      code: "TUA",
      name: "El Rosal Teniente Guerrero Airport",
    },
    {
      city: "Tubuai",
      code: "TUB",
      name: "",
    },
    {
      city: "Banda del Río Salí",
      code: "TUC",
      name: "Teniente Benjamin Matienzo Airport",
    },
    {
      city: "Tambacounda",
      code: "TUD",
      name: "Tambacounda Airport",
    },
    {
      city: "Tours",
      code: "TUF",
      name: "St Symphorien Airport",
    },
    {
      city: "Tuguegarao",
      code: "TUG",
      name: "Tuguegarao Airport",
    },
    {
      city: "Turayf",
      code: "TUI",
      name: "Turaif Airport",
    },
    {
      city: "Turbat",
      code: "TUK",
      name: "Turbat Airport",
    },
    {
      city: "Tulsa",
      code: "TUL",
      name: "Tulsa International Airport",
    },
    {
      city: "Tunis",
      code: "TUN",
      name: "Aeroport Tunis",
    },
    {
      city: "Taupo",
      code: "TUO",
      name: "Taupo Aerodrome",
    },
    {
      city: "Tupelo",
      code: "TUP",
      name: "Tupelo Municipal-C D Lemons Airport",
    },
    {
      city: "Tucuruí",
      code: "TUR",
      name: "Tucurui Airport",
    },
    {
      city: "Tucson",
      code: "TUS",
      name: "Tucson International Airport",
    },
    {
      city: "Tabuk",
      code: "TUU",
      name: "Tabuk Airport",
    },
    {
      city: "Traverse City",
      code: "TVC",
      name: "Cherry Capital Airport",
    },
    {
      city: "Thief River Falls",
      code: "TVF",
      name: "Thief River Falls Regional Airport",
    },
    {
      city: "Taveuni",
      code: "TVU",
      name: "Taveuni Airport",
    },
    {
      city: "Dawe",
      code: "TVY",
      name: "",
    },
    {
      city: "Twin Hills",
      code: "TWA",
      name: "Twin Hills",
    },
    {
      city: "Toowoomba",
      code: "TWB",
      name: "Toowoomba Airport",
    },
    {
      city: "Twin Falls",
      code: "TWF",
      name: "Twin Falls-Sun Valley Regional Airport",
    },
    {
      city: "Tawitawi",
      code: "TWT",
      name: "Tawitawi",
    },
    {
      city: "Tawau",
      code: "TWU",
      name: "Tawau Airport",
    },
    {
      city: "Texarkana",
      code: "TXK",
      name: "Texarkana Regional Airport",
    },
    {
      city: "Haiyang",
      code: "TXN",
      name: "Tunxi Airport",
    },
    {
      city: "Torsby",
      code: "TYF",
      name: "Fryklanda Airport",
    },
    {
      city: "Taiyuan",
      code: "TYN",
      name: "Taiyuan Wusu Airport",
    },
    {
      city: "Tyler",
      code: "TYR",
      name: "Tyler Pounds Field Airport",
    },
    {
      city: "Knoxville",
      code: "TYS",
      name: "Mcghee Tyson Airport",
    },
    {
      city: "Hattieville",
      code: "TZA",
      name: "Belize City Municipal Airport",
    },
    {
      city: "Mangrove Cay",
      code: "TZN",
      name: "South Andros Airport",
    },
    {
      city: "Trabzon",
      code: "TZX",
      name: "Trabzon Air Base",
    },
    {
      city: "Ua Huka",
      code: "UAH",
      name: "French Polynesia",
    },
    {
      city: "Narsarssurk",
      code: "UAK",
      name: "Narsarsuaq Airport",
    },
    {
      city: "Ua Pou",
      code: "UAP",
      name: "French Polynesia",
    },
    {
      city: "9 de Julio",
      code: "UAQ",
      name: "San Juan Airport",
    },
    {
      city: "Samburu",
      code: "UAS",
      name: "Samburu Airport",
    },
    {
      city: "Uberaba",
      code: "UBA",
      name: "Uberaba",
    },
    {
      city: "Ube-shi",
      code: "UBJ",
      name: "Yamaguchi-Ube Airport",
    },
    {
      city: "Don Mot Daeng",
      code: "UBP",
      name: "Ubon Airport",
    },
    {
      city: "Ukhta",
      code: "UCT",
      name: "Ust Ukhta Airport",
    },
    {
      city: "Uberlandia",
      code: "UDI",
      name: "Coronel Aviador Cesar Bombonato Airport",
    },
    {
      city: "Uzhhorod",
      code: "UDJ",
      name: "Uzhgorod Airport",
    },
    {
      city: "Debari",
      code: "UDR",
      name: "Udaipur Airport",
    },
    {
      city: "Quelimane",
      code: "UEL",
      name: "Quelimane Airport",
    },
    {
      city: "Kumejima-cho",
      code: "UEO",
      name: "Kumejima Airport",
    },
    {
      city: "",
      code: "UET",
      name: "Quetta Airport",
    },
    {
      city: "Oufa",
      code: "UFA",
      name: "Ufa South Airport",
    },
    {
      city: "Pilot Point",
      code: "UGB",
      name: "Ugashik Bay Airport",
    },
    {
      city: "Urgench",
      code: "UGC",
      name: "Urganch",
    },
    {
      city: "Kodiak",
      code: "UGI",
      name: "Uganik Airport",
    },
    {
      city: "Quibdó",
      code: "UIB",
      name: "El Carano Airport",
    },
    {
      city: "Qui Nhon",
      code: "UIH",
      name: "Vietnam",
    },
    {
      city: "Utila",
      code: "UII",
      name: "Utila Airport",
    },
    {
      city: "Quincy",
      code: "UIN",
      name: "Quincy Municipal Airport-Baldwin Field",
    },
    {
      city: "Quito",
      code: "UIO",
      name: "Mariscal Sucre International Airport",
    },
    {
      city: "Plomelin",
      code: "UIP",
      name: "Pluguffan Airport",
    },
    {
      city: "Kobe-shi",
      code: "UKB",
      name: "Kobe Airport",
    },
    {
      city: "Ust-kamenogorsk",
      code: "UKK",
      name: "",
    },
    {
      city: "Port-Vato",
      code: "ULB",
      name: "Ulei Airport",
    },
    {
      city: "Ulan Bator",
      code: "ULN",
      name: "Ulaanbaatar Southwest Airport",
    },
    {
      city: "Ulaangom",
      code: "ULO",
      name: "Ulaangom",
    },
    {
      city: "Quilpie",
      code: "ULP",
      name: "Quilpie Aerodrome",
    },
    {
      city: "Gulu",
      code: "ULU",
      name: "Gulu Airport",
    },
    {
      city: "Dimitrovgrad",
      code: "ULY",
      name: "Ulyanovsk Northeast Airport",
    },
    {
      city: "Uummannaq",
      code: "UMD",
      name: "Uummannaq Airport",
    },
    {
      city: "Umea",
      code: "UME",
      name: "Umea Airport",
    },
    {
      city: "Kiunga",
      code: "UNG",
      name: "",
    },
    {
      city: "Unalakleet",
      code: "UNK",
      name: "Unalakleet",
    },
    {
      city: "Ranong",
      code: "UNN",
      name: "Ranong Airport",
    },
    {
      city: "Havana",
      code: "UPB",
      name: "Playa Baracoa",
    },
    {
      city: "Maros",
      code: "UPG",
      name: "Hasanuddin Airport",
    },
    {
      city: "Oral",
      code: "URA",
      name: "Podstepnyy Airport",
    },
    {
      city: "Urumqi",
      code: "URC",
      name: "Diwopu Airport",
    },
    {
      city: "Kingissepa",
      code: "URE",
      name: "Kuressarre Airport",
    },
    {
      city: "Uruguaiana",
      code: "URG",
      name: "Rubem Berta International Airport",
    },
    {
      city: "Uraj",
      code: "URJ",
      name: "Uraj",
    },
    {
      city: "St-Pierre",
      code: "URO",
      name: "Boos Airport",
    },
    {
      city: "Kursk",
      code: "URS",
      name: "",
    },
    {
      city: "Phun Phin",
      code: "URT",
      name: "Surat Thani Airport",
    },
    {
      city: "Gurayat",
      code: "URY",
      name: "Guriat Airport",
    },
    {
      city: "Ushuaia",
      code: "USH",
      name: "Ushuaia Airport",
    },
    {
      city: "Usinsk",
      code: "USK",
      name: "",
    },
    {
      city: "Ko Samui",
      code: "USM",
      name: "Koh Samui Airport",
    },
    {
      city: "Ulsan",
      code: "USN",
      name: "Ulsan Airport",
    },
    {
      city: "Usak",
      code: "USQ",
      name: "",
    },
    {
      city: "Coron",
      code: "USU",
      name: "Busuanga Airport",
    },
    {
      city: "Changwat Udon Thani; Udon Thani",
      code: "UTH",
      name: "Udon Airport",
    },
    {
      city: "Upington",
      code: "UTN",
      name: "Pierre Van Ryneveld Airport",
    },
    {
      city: "Ban Chang",
      code: "UTP",
      name: "Rayong Airport",
    },
    {
      city: "Umtata",
      code: "UTT",
      name: "K D Matanzima Airport",
    },
    {
      city: "Kabansk",
      code: "UUD",
      name: "Ulan Ude-Mukhino Airport",
    },
    {
      city: "Baruun-Urt",
      code: "UUN",
      name: "Baruun Urt Airport",
    },
    {
      city: "Kholmsk",
      code: "UUS",
      name: "Yuzhno Sakhalinsk South Airport",
    },
    {
      city: "Fayaoué",
      code: "UVE",
      name: "Ouloup Airport",
    },
    {
      city: "Vieux Fort",
      code: "UVF",
      name: "Hewanorra International Airport",
    },
    {
      city: "Wiesbaden",
      code: "UWE",
      name: "Metropolitan Area",
    },
    {
      city: "Nyala",
      code: "UYL",
      name: "Janub Darfur",
    },
    {
      city: "Yulin",
      code: "UYN",
      name: "Yulin Airport",
    },
    {
      city: "Vaasa",
      code: "VAA",
      name: "Vaasa Airport",
    },
    {
      city: "Vanimo",
      code: "VAI",
      name: "",
    },
    {
      city: "Hooper Bay",
      code: "VAK",
      name: "Chevak Airport",
    },
    {
      city: "Van",
      code: "VAN",
      name: "Van Airport",
    },
    {
      city: "Suavanao",
      code: "VAO",
      name: "Suavanao Airstrip",
    },
    {
      city: "Varna",
      code: "VAR",
      name: "Topoli Airport",
    },
    {
      city: "Sivas",
      code: "VAS",
      name: "Sivas Airport",
    },
    {
      city: "Neiafu",
      code: "VAV",
      name: "Lupepau'u Airport",
    },
    {
      city: "Vardo",
      code: "VAW",
      name: "Vardoe Airport",
    },
    {
      city: "Blue Mountain",
      code: "VBM",
      name: "Alaska",
    },
    {
      city: "Verona",
      code: "VBS",
      name: "Brescia Montichiari",
    },
    {
      city: "Vanuabalavu",
      code: "VBV",
      name: "",
    },
    {
      city: "Visby",
      code: "VBY",
      name: "Visby Airport",
    },
    {
      city: "Venice",
      code: "VCE",
      name: "Marco Polo International Airport",
    },
    {
      city: "Tamky",
      code: "VCL",
      name: "Chulai",
    },
    {
      city: "Campinas",
      code: "VCP",
      name: "Viracopos International Airport",
    },
    {
      city: "Con Dao",
      code: "VCS",
      name: "Vietnam",
    },
    {
      city: "Victoria",
      code: "VCT",
      name: "Victoria Regional Airport",
    },
    {
      city: "Victorville",
      code: "VCV",
      name: "Southern California Logistics Airport",
    },
    {
      city: "Ovda",
      code: "VDA",
      name: "Ovda Airport",
    },
    {
      city: "Fagernes",
      code: "VDB",
      name: "Fagernes Leirin Airport",
    },
    {
      city: "Vitória da Conquista",
      code: "VDC",
      name: "Vitoria da Conquista Airport",
    },
    {
      city: "Valverde",
      code: "VDE",
      name: "Valverde Airport",
    },
    {
      city: "Viedma",
      code: "VDM",
      name: "Gobernador Castello Airport",
    },
    {
      city: "Vadso",
      code: "VDS",
      name: "Vadso Airport",
    },
    {
      city: "Valdez",
      code: "VDZ",
      name: "Valdez Airport",
    },
    {
      city: "Venetie",
      code: "VEE",
      name: "Venetie",
    },
    {
      city: "Vernal",
      code: "VEL",
      name: "Vernal Airport",
    },
    {
      city: "Teocelo",
      code: "VER",
      name: "General Heriberto Jara International Airport",
    },
    {
      city: "Vestmannaeyjar",
      code: "VEY",
      name: "Vestmannaeyjar Airport",
    },
    {
      city: "",
      code: "VFA",
      name: "Victoria Falls Airport",
    },
    {
      city: "Manthena",
      code: "VGA",
      name: "Vijaywada Airport",
    },
    {
      city: "Vigo",
      code: "VGO",
      name: "Vigo Airport",
    },
    {
      city: "Villagarzon",
      code: "VGZ",
      name: "Villagarzon Airport",
    },
    {
      city: "Saurimo",
      code: "VHC",
      name: "Saurimo Airport",
    },
    {
      city: "Vilhelmina",
      code: "VHM",
      name: "Vilhelmina Airport",
    },
    {
      city: "Vahitahi",
      code: "VHZ",
      name: "French Polynesia",
    },
    {
      city: "Klein-Neusiedl",
      code: "VIE",
      name: "Vienna Schwechat International Airport",
    },
    {
      city: "El Vigia",
      code: "VIG",
      name: "",
    },
    {
      city: "Vinh City",
      code: "VII",
      name: "Vinh",
    },
    {
      city: "Virgin Gorda",
      code: "VIJ",
      name: "Virgin Gorda Airport",
    },
    {
      city: "Ad Dakhla",
      code: "VIL",
      name: "Dakhla Airport",
    },
    {
      city: "Visalia",
      code: "VIS",
      name: "Visalia Municipal Airport",
    },
    {
      city: "Vitoria",
      code: "VIT",
      name: "Vitoria Airport",
    },
    {
      city: "Vitoria",
      code: "VIX",
      name: "Goiabeiras Airport",
    },
    {
      city: "Kien Giang",
      code: "VKG",
      name: "Kien Giang Airport",
    },
    {
      city: "Podol'sk",
      code: "VKO",
      name: "Ynukovo Airport",
    },
    {
      city: "Ukhta",
      code: "VKT",
      name: "Vorkuta Airport",
    },
    {
      city: "Manises",
      code: "VLC",
      name: "Valencia Airport",
    },
    {
      city: "Valdosta",
      code: "VLD",
      name: "Valdosta Regional Airport",
    },
    {
      city: "Port-Vila",
      code: "VLI",
      name: "Port Vila Bauerfield Airport",
    },
    {
      city: "Valladolid",
      code: "VLL",
      name: "Valladolid Airport",
    },
    {
      city: "Valencia",
      code: "VLN",
      name: "Zim Valencia Airport",
    },
    {
      city: "Valesdir",
      code: "VLS",
      name: "Vanuatu",
    },
    {
      city: "Siquisique",
      code: "VLV",
      name: "Dr Antonio Nicolas Briceno Airport",
    },
    {
      city: "Anglesey",
      code: "VLY",
      name: "Wales",
    },
    {
      city: "Vilnius",
      code: "VNO",
      name: "Vilnius Airport",
    },
    {
      city: "Baragaon",
      code: "VNS",
      name: "Varanasi Airport",
    },
    {
      city: "Vilanculos",
      code: "VNX",
      name: "Vilanculos Airport",
    },
    {
      city: "Kamyshin",
      code: "VOG",
      name: "Gumrak Airport",
    },
    {
      city: "Almiros",
      code: "VOL",
      name: "Nea Anchialos Airport",
    },
    {
      city: "Semiluki",
      code: "VOZ",
      name: "Voronezh-Chertovitskoye Airport",
    },
    {
      city: "Ongiva",
      code: "VPE",
      name: "Ondjiva",
    },
    {
      city: "Egilsstadir",
      code: "VPN",
      name: "Vopnafjordur Airport",
    },
    {
      city: "Eglin Village",
      code: "VPS",
      name: "Eglin Air Force Base",
    },
    {
      city: "Chimoio",
      code: "VPY",
      name: "Chimoio Airport",
    },
    {
      city: "Puerto Ferro",
      code: "VQS",
      name: "Aeropuerto Antonio Rivera Rodríguez",
    },
    {
      city: "Varadero",
      code: "VRA",
      name: "Varadero Airport",
    },
    {
      city: "Virac",
      code: "VRC",
      name: "Virac Airport",
    },
    {
      city: "Varkaus",
      code: "VRK",
      name: "Varkaus Airport",
    },
    {
      city: "Sommacampagna",
      code: "VRN",
      name: "Verona Airport",
    },
    {
      city: "Varoy",
      code: "VRY",
      name: "Vaeroy Airport",
    },
    {
      city: "Villahermosa",
      code: "VSA",
      name: "Villahermosa Airport",
    },
    {
      city: "Luhans'k",
      code: "VSG",
      name: "Lugansk Airport",
    },
    {
      city: "Vasteras",
      code: "VST",
      name: "Hasslo Airport",
    },
    {
      city: "Vientiane",
      code: "VTE",
      name: "Vientiane Airport",
    },
    {
      city: "Becerra",
      code: "VTU",
      name: "Las Tunas Airport",
    },
    {
      city: "Visakhapatnam",
      code: "VTZ",
      name: "Vishakapatnam Airport",
    },
    {
      city: "Valledupar",
      code: "VUP",
      name: "Alfonso Lopez Airport",
    },
    {
      city: "Villavicencio",
      code: "VVC",
      name: "Vanguardia Airport",
    },
    {
      city: "Santa Cruz",
      code: "VVI",
      name: "Viru Viru International Airport",
    },
    {
      city: "Nakhodka",
      code: "VVO",
      name: "Artem North Airport",
    },
    {
      city: "Illizi",
      code: "VVZ",
      name: "Illizi",
    },
    {
      city: "Lichinga",
      code: "VXC",
      name: "Lichinga Airport",
    },
    {
      city: "Mindelo",
      code: "VXE",
      name: "San Pedro Airport",
    },
    {
      city: "Vaxjo",
      code: "VXO",
      name: "Kronoberg Airport",
    },
    {
      city: "Wales",
      code: "WAA",
      name: "Wales",
    },
    {
      city: "Wadi Ad Dawasir",
      code: "WAE",
      name: "Saudi Arabia",
    },
    {
      city: "Wanganui",
      code: "WAG",
      name: "Wanganui Airport",
    },
    {
      city: "Antsalova",
      code: "WAQ",
      name: "Mahajanga",
    },
    {
      city: "Waterford",
      code: "WAT",
      name: "Waterford Airport",
    },
    {
      city: "Warsaw",
      code: "WAW",
      name: "Okecie International Airport",
    },
    {
      city: "Stebbins",
      code: "WBB",
      name: "Stebbins",
    },
    {
      city: "Beaver",
      code: "WBQ",
      name: "Alaska",
    },
    {
      city: "",
      code: "WDH",
      name: "Windhoek Airport",
    },
    {
      city: "Weifang",
      code: "WEF",
      name: "Shandong",
    },
    {
      city: "Weihai",
      code: "WEH",
      name: "Shandong",
    },
    {
      city: "Weipa",
      code: "WEI",
      name: "Weipa Aerodrome",
    },
    {
      city: "Forest Hill",
      code: "WGA",
      name: "Wagga Wagga Airport",
    },
    {
      city: "Walgett",
      code: "WGE",
      name: "Walgett Airport",
    },
    {
      city: "Waingapu",
      code: "WGP",
      name: "Mau Hau Airport",
    },
    {
      city: "Wadi Halfa",
      code: "WHF",
      name: "Wadi Halfa'",
    },
    {
      city: "Whakatane",
      code: "WHK",
      name: "Whakatane Airport",
    },
    {
      city: "Wick",
      code: "WIC",
      name: "Wick Airport",
    },
    {
      city: "Nairobi",
      code: "WIL",
      name: "Nairobi Wilson Airport",
    },
    {
      city: "Nekemte",
      code: "NEK",
      name: "Nekemte Airport",
    },
    {
      city: "Corfield",
      code: "WIN",
      name: "Winton Aerodrome",
    },
    {
      city: "Woja",
      code: "WJA",
      name: "Woja",
    },
    {
      city: "WonJu",
      code: "WJU",
      name: "Wonju Airport",
    },
    {
      city: "Wanaka",
      code: "WKA",
      name: "Wanaka Airport",
    },
    {
      city: "Wakkanai-shi",
      code: "WKJ",
      name: "Wakkanai Airport",
    },
    {
      city: "Aleknagik",
      code: "WKK",
      name: "Aleknagik",
    },
    {
      city: "Wellington",
      code: "WLG",
      name: "Wellington International Airport",
    },
    {
      city: "Walaha",
      code: "WLH",
      name: "Vanuatu",
    },
    {
      city: "Selawik",
      code: "WLK",
      name: "Alaska",
    },
    {
      city: "Wallis Island",
      code: "WLS",
      name: "Wallis Island",
    },
    {
      city: "Meyers Chuck",
      code: "WMK",
      name: "Meyers Chuck",
    },
    {
      city: "Maroantsetra",
      code: "WMN",
      name: "Maroantsetra Airport",
    },
    {
      city: "White Mountain",
      code: "WMO",
      name: "White Mountain",
    },
    {
      city: "Mananara",
      code: "WMR",
      name: "Mananara",
    },
    {
      city: "Napakiak",
      code: "WNA",
      name: "Napaskiak",
    },
    {
      city: "Casummit Lake",
      code: "WNN",
      name: "Wunnummin Lake Airport",
    },
    {
      city: "Pili",
      code: "WNP",
      name: "Naga Airport",
    },
    {
      city: "Tanbar",
      code: "WNR",
      name: "Windorah Airport",
    },
    {
      city: "Nawabshah",
      code: "WNS",
      name: "Nawabshah Airport",
    },
    {
      city: "Wenzhou",
      code: "WNZ",
      name: "Wenzhou Airport",
    },
    {
      city: "Wipim",
      code: "WPM",
      name: "Wipim",
    },
    {
      city: "Whangarei",
      code: "WRE",
      name: "Whangarei Airport",
    },
    {
      city: "Wrangell",
      code: "WRG",
      name: "Wrangell Airport",
    },
    {
      city: "Worland",
      code: "WRL",
      name: "Worland Municipal Airport",
    },
    {
      city: "Wroclaw",
      code: "WRO",
      name: "Strachowice Airport",
    },
    {
      city: "South Naknek",
      code: "WSN",
      name: "Naknek",
    },
    {
      city: "Westerly",
      code: "WST",
      name: "Westerly State Airport",
    },
    {
      city: "Westsound",
      code: "WSX",
      name: "United States",
    },
    {
      city: "Westport",
      code: "WSZ",
      name: "Westport Airport",
    },
    {
      city: "Noatak",
      code: "WTK",
      name: "Alaska",
    },
    {
      city: "Tuntutuliak",
      code: "WTL",
      name: "Tuntutuliak",
    },
    {
      city: "Tsiroanomandidy",
      code: "WTS",
      name: "Antananarivo",
    },
    {
      city: "Wu Hai",
      code: "WUA",
      name: "Wu Hai",
    },
    {
      city: "Wuhan",
      code: "WUH",
      name: "Wuchang Nanhu Airport",
    },
    {
      city: "Wiluna",
      code: "WUN",
      name: "",
    },
    {
      city: "Wuyishan",
      code: "WUS",
      name: "Wuyishan",
    },
    {
      city: "Wuxi",
      code: "WUX",
      name: "Wuxi",
    },
    {
      city: "Walvis Bay",
      code: "WVB",
      name: "Rooikop",
    },
    {
      city: "Wewak",
      code: "WWK",
      name: "Wewak International Airport",
    },
    {
      city: "Whale Pass",
      code: "WWP",
      name: "Alaska",
    },
    {
      city: "Newtok",
      code: "WWT",
      name: "Alaska",
    },
    {
      city: "Wanxian",
      code: "WXN",
      name: "Wanxian",
    },
    {
      city: "Whyalla",
      code: "WYA",
      name: "Whyalla Airport",
    },
    {
      city: "West Yellowstone",
      code: "WYS",
      name: "Yellowstone Airport",
    },
    {
      city: "Oro-Medonte",
      code: "XAD",
      name: "Churchill Rail Station",
    },
    {
      city: "Chapeco",
      code: "XAP",
      name: "Chapeco Airport",
    },
    {
      city: "Capreol",
      code: "XAW",
      name: "Capreol Rail Service",
    },
    {
      city: "Dorval",
      code: "XAX",
      name: "Dorval Rail Station",
    },
    {
      city: "Cambellton",
      code: "XAZ",
      name: "Cambellton",
    },
    {
      city: "Casummit Lake",
      code: "XBE",
      name: "Bearskin Lake Airport",
    },
    {
      city: "Birjand",
      code: "XBJ",
      name: "Birjand Airport",
    },
    {
      city: "Brockville",
      code: "XBR",
      name: "Brockville Airport",
    },
    {
      city: "The Settlement",
      code: "XCH",
      name: "Christmas Island Airport",
    },
    {
      city: "Oro-Medonte",
      code: "XCI",
      name: "Ontario",
    },
    {
      city: "Chatham",
      code: "XCM",
      name: "Chatham Airport",
    },
    {
      city: "Coole",
      code: "XCR",
      name: "Europort Vatry",
    },
    {
      city: "Lille",
      code: "XDB",
      name: "Lille-Europe Railway Station",
    },
    {
      city: "",
      code: "XDD",
      name: "",
    },
    {
      city: "Halifax",
      code: "XDG",
      name: "Halifax Rail Service",
    },
    {
      city: "",
      code: "XDH",
      name: "",
    },
    {
      city: "Drummondville",
      code: "XDM",
      name: "Drummondville Airport",
    },
    {
      city: "Moncton",
      code: "XDP",
      name: "Moncton Rail Service",
    },
    {
      city: "London",
      code: "XDQ",
      name: "London Ontario Rail Service",
    },
    {
      city: "Oro-Medonte",
      code: "XDS",
      name: "Ontario",
    },
    {
      city: "",
      code: "XDU",
      name: "",
    },
    {
      city: "Prince George",
      code: "XDV",
      name: "",
    },
    {
      city: "Prince Rupert",
      code: "XDW",
      name: "Canada",
    },
    {
      city: "Sarina",
      code: "XDX",
      name: "Sarina Rail Station",
    },
    {
      city: "Sudbury",
      code: "XDY",
      name: "",
    },
    {
      city: "The Pas",
      code: "XDZ",
      name: "The Pas Rail Service",
    },
    {
      city: "Vancouver",
      code: "XEA",
      name: "Vancouver Rail Service",
    },
    {
      city: "Windsor",
      code: "XEC",
      name: "Windsor Ontario Rail Service",
    },
    {
      city: "Paris",
      code: "XED",
      name: "Disneyland Railway Station",
    },
    {
      city: "Lac Edouard",
      code: "XEE",
      name: "Lac Edouard Rail Service",
    },
    {
      city: "Winnipeg",
      code: "XEF",
      name: "Winnipeg Rail Service",
    },
    {
      city: "Kingston",
      code: "XEG",
      name: "Kingston Rail Service",
    },
    {
      city: "Ladysmith",
      code: "XEH",
      name: "Ladysmith Rail Service",
    },
    {
      city: "Melville",
      code: "XEK",
      name: "Saskatchewan",
    },
    {
      city: "New Carlisle",
      code: "XEL",
      name: "Quebec",
    },
    {
      city: "Strasbourg",
      code: "XER",
      name: "Strasbourg Bus Station",
    },
    {
      city: "London",
      code: "XET",
      name: "London City Airport",
    },
    {
      city: "Stockholm",
      code: "XEV",
      name: "Stockholm Central Station",
    },
    {
      city: "Sodertalje",
      code: "XEZ",
      name: "Sodertalje",
    },
    {
      city: "Stratford",
      code: "XFD",
      name: "Stratford",
    },
    {
      city: "Parent",
      code: "XFE",
      name: "Parent Rail Service",
    },
    {
      city: "Perce",
      code: "XFG",
      name: "Perce Rail Service",
    },
    {
      city: "Eskilstuna",
      code: "XFJ",
      name: "Eskilstuna Station",
    },
    {
      city: "Senneterre",
      code: "XFK",
      name: "Senneterre Rail Service",
    },
    {
      city: "Shawinigan",
      code: "XFL",
      name: "Shawinigan Rail Station",
    },
    {
      city: "Cowichan Valley",
      code: "XFM",
      name: "Shawnigan Rail Service",
    },
    {
      city: "Xiangfan",
      code: "XFN",
      name: "Xiangfan",
    },
    {
      city: "Malmo",
      code: "XFP",
      name: "Malmö Station",
    },
    {
      city: "Weymont",
      code: "XFQ",
      name: "Weymont Rail Service",
    },
    {
      city: "Malmo",
      code: "XFR",
      name: "Malmo South Railway",
    },
    {
      city: "Alexandria",
      code: "XFS",
      name: "Alexandria",
    },
    {
      city: "Tierp",
      code: "XFU",
      name: "Tierp Station",
    },
    {
      city: "Brantford",
      code: "XFV",
      name: "Brantford",
    },
    {
      city: "Hamburg",
      code: "XFW",
      name: "Finkenwerder Airport",
    },
    {
      city: "Quebec",
      code: "XFY",
      name: "Sainte Foy Rail Service",
    },
    {
      city: "Charny",
      code: "XFZ",
      name: "Charny",
    },
    {
      city: "Lund",
      code: "XGC",
      name: "Lund",
    },
    {
      city: "Cobourg",
      code: "XGJ",
      name: "Cobourg Rail Station",
    },
    {
      city: "Coteau",
      code: "XGK",
      name: "Coteau Rail Station",
    },
    {
      city: "Grantham",
      code: "XGM",
      name: "England",
    },
    {
      city: "Chisasibi",
      code: "XGR",
      name: "Kangiqsualujjuaq Airport",
    },
    {
      city: "Grimsby",
      code: "XGY",
      name: "Ontario",
    },
    {
      city: "Aix La Chapelle",
      code: "XHJ",
      name: "Railway Station",
    },
    {
      city: "Valence",
      code: "XHK",
      name: "Valence Station",
    },
    {
      city: "Georgetown",
      code: "XHM",
      name: "Georgetown Rail Station",
    },
    {
      city: "Liege",
      code: "XHN",
      name: "Belgium",
    },
    {
      city: "Chemainus",
      code: "XHS",
      name: "British Columbia",
    },
    {
      city: "Guelph",
      code: "XIA",
      name: "Guelph Airport",
    },
    {
      city: "Ingersoll",
      code: "XIB",
      name: "Ontario",
    },
    {
      city: "Xichang",
      code: "XIC",
      name: "Xichang North Airport",
    },
    {
      city: "Maxville",
      code: "XID",
      name: "Maxville Rail Station",
    },
    {
      city: "Napanee",
      code: "XIF",
      name: "Ontario",
    },
    {
      city: "Xilin Hot",
      code: "XIL",
      name: "Xilinhot Airport",
    },
    {
      city: "Saint Hyacinthe",
      code: "XIM",
      name: "Quebec",
    },
    {
      city: "St Marys",
      code: "XIO",
      name: "St Marys Rail Station",
    },
    {
      city: "Woodstock",
      code: "XIP",
      name: "Woodstock Rail Service",
    },
    {
      city: "London",
      code: "XIT",
      name: "London City Airport",
    },
    {
      city: "Xianyang",
      code: "XIY",
      name: "Hsien Yang Airport",
    },
    {
      city: "Joliette",
      code: "XJL",
      name: "Quebec",
    },
    {
      city: "Jonquiere",
      code: "XJQ",
      name: "Jonquiere Rail Station",
    },
    {
      city: "Xieng Khouang",
      code: "XKH",
      name: "Xieng Khouang",
    },
    {
      city: "Kuala Lumpur",
      code: "XKL",
      name: "Kuala Lumpur Central Station",
    },
    {
      city: "Casummit Lake",
      code: "XKS",
      name: "Kasabonika Airport",
    },
    {
      city: "Sackville",
      code: "XKV",
      name: "Sackville Rail Station",
    },
    {
      city: "Lac Brochet",
      code: "XLB",
      name: "Lac Brochet",
    },
    {
      city: "Quebec",
      code: "XLJ",
      name: "Quebec Stn. Rail Svce.",
    },
    {
      city: "Montreal",
      code: "XLM",
      name: "St Lambert Rail Svce.",
    },
    {
      city: "Guildwood",
      code: "XLQ",
      name: "",
    },
    {
      city: "",
      code: "XLS",
      name: "Saint Louis Airport",
    },
    {
      city: "Niagara Falls",
      code: "XLV",
      name: "Ontario",
    },
    {
      city: "Aldershot",
      code: "XLY",
      name: "Aldershot Rail Station",
    },
    {
      city: "Truro",
      code: "XLZ",
      name: "Nova Scotia",
    },
    {
      city: "Manihi",
      code: "XMH",
      name: "Manihi Airport",
    },
    {
      city: "Xiamen",
      code: "XMN",
      name: "Xiamen Airport",
    },
    {
      city: "Zúñac",
      code: "XMS",
      name: "Macas Airport",
    },
    {
      city: "Yam Island",
      code: "XMY",
      name: "Yam Island",
    },
    {
      city: "Bentonville",
      code: "XNA",
      name: "Northwest Arkansas Regional Airport",
    },
    {
      city: "Santo Antônio do Içá",
      code: "XNB",
      name: "Sinop Airport",
    },
    {
      city: "Nottingham",
      code: "XNM",
      name: "Nottingham Airport",
    },
    {
      city: "Xining",
      code: "XNN",
      name: "Xining Airport",
    },
    {
      city: "Northallerton",
      code: "XNO",
      name: "",
    },
    {
      city: "Nuneaton",
      code: "XNV",
      name: "Nuneaton Rail Station",
    },
    {
      city: "New York",
      code: "XNY",
      name: "York",
    },
    {
      city: "Oakville",
      code: "XOK",
      name: "Ontario",
    },
    {
      city: "Poitiers",
      code: "XOP",
      name: "Poitou-Charentes",
    },
    {
      city: "Parksville",
      code: "XPB",
      name: "Parksville Rail Service",
    },
    {
      city: "Penrith",
      code: "XPF",
      name: "Penrith Rail Station",
    },
    {
      city: "Paris",
      code: "XPG",
      name: "Gare du Nord Rail Stn",
    },
    {
      city: "Montpellier",
      code: "XPJ",
      name: "Montpellier Railway Station",
    },
    {
      city: "Brampton",
      code: "XPN",
      name: "",
    },
    {
      city: "Port Klang",
      code: "XPQ",
      name: "",
    },
    {
      city: "Preston",
      code: "XPT",
      name: "Preston Rail Station",
    },
    {
      city: "Pointe-aux-Trembles",
      code: "XPX",
      name: "Pointe-aux-Trembles Rail Station",
    },
    {
      city: "London",
      code: "XQE",
      name: "United Kingdom",
    },
    {
      city: "Berwick",
      code: "XQG",
      name: "Berwick Station",
    },
    {
      city: "Nottingham",
      code: "XQH",
      name: "United Kingdom",
    },
    {
      city: "Lancaster",
      code: "XQL",
      name: "Lancaster Rail Station",
    },
    {
      city: "Quepos",
      code: "XQP",
      name: "Quepos Managua Airport",
    },
    {
      city: "Qualicum Beach",
      code: "XQU",
      name: "Qualicum Beach Airport",
    },
    {
      city: "Runcorn",
      code: "XRC",
      name: "Runcorn Rail Station",
    },
    {
      city: "Marseille",
      code: "XRF",
      name: "Marseille Railway",
    },
    {
      city: "Pine Ridge",
      code: "XRP",
      name: "Pine Ridge Rail Station",
    },
    {
      city: "Rugby",
      code: "XRU",
      name: "Rugby Rail Station",
    },
    {
      city: "Jerez",
      code: "XRY",
      name: "Jerez Airport",
    },
    {
      city: "Cockburn Harbour",
      code: "XSC",
      name: "South Caicos Airport",
    },
    {
      city: "Tours",
      code: "XSH",
      name: "Centre",
    },
    {
      city: "Nelson House",
      code: "XSI",
      name: "South Indian Lake Airport",
    },
    {
      city: "Singapore",
      code: "XSP",
      name: "Seletar Airport",
    },
    {
      city: "Salisbury",
      code: "XSR",
      name: "England",
    },
    {
      city: "Thargomindah",
      code: "XTG",
      name: "Thargomindah Aerodrome",
    },
    {
      city: "Thirsk",
      code: "XTK",
      name: "England",
    },
    {
      city: "Nelson House",
      code: "XTL",
      name: "Tadoule Lake Airport",
    },
    {
      city: "Strathroy",
      code: "XTY",
      name: "Strathroy Rail Station",
    },
    {
      city: "Xuzhou",
      code: "XUZ",
      name: "Jiangsu",
    },
    {
      city: "Stockport",
      code: "XVA",
      name: "England",
    },
    {
      city: "Stafford",
      code: "XVB",
      name: "England",
    },
    {
      city: "Crewe",
      code: "XVC",
      name: "United Kingdom",
    },
    {
      city: "Longville",
      code: "XVG",
      name: "Longville Municipal Airport",
    },
    {
      city: "Peterborough",
      code: "XVH",
      name: "United Kingdom",
    },
    {
      city: "Stevenage",
      code: "XVJ",
      name: "Stevenage Rail Station",
    },
    {
      city: "Durham",
      code: "XVU",
      name: "Durham Rail Station",
    },
    {
      city: "Belleville",
      code: "XVV",
      name: "Belleville Rail Service",
    },
    {
      city: "Belleville",
      code: "XVW",
      name: "",
    },
    {
      city: "Wakefield Westgate",
      code: "XWD",
      name: "Wakefield Westgate Rail Station",
    },
    {
      city: "Stroke on Trent",
      code: "XWH",
      name: "Stroke on Trent Rail Station",
    },
    {
      city: "Karlskrona",
      code: "XWK",
      name: "Karlskrona Rail Svc.",
    },
    {
      city: "Gothenburg",
      code: "XWL",
      name: "Gothenburg",
    },
    {
      city: "Hallsberg",
      code: "XWM",
      name: "Hallsberg Rail Station",
    },
    {
      city: "Warrington",
      code: "XWN",
      name: "England",
    },
    {
      city: "Hassleholm",
      code: "XWP",
      name: "Sweden",
    },
    {
      city: "Enkoping",
      code: "XWQ",
      name: "Sweden",
    },
    {
      city: "Orebro",
      code: "XWR",
      name: "Orebro Railway Station",
    },
    {
      city: "Swindon",
      code: "XWS",
      name: "United Kingdom",
    },
    {
      city: "Varberg",
      code: "XWV",
      name: "Varberg Rail Station",
    },
    {
      city: "Wyoming",
      code: "XWY",
      name: "Wyoming Rail Station",
    },
    {
      city: "Nykoping",
      code: "XWZ",
      name: "Sweden",
    },
    {
      city: "Alvesta",
      code: "XXA",
      name: "Sweden",
    },
    {
      city: "Degerfors",
      code: "XXD",
      name: "Degerfors Rail Station",
    },
    {
      city: "Katrineholm",
      code: "XXK",
      name: "Katrineholm",
    },
    {
      city: "Mjolby",
      code: "XXM",
      name: "",
    },
    {
      city: "Riyadh",
      code: "XXN",
      name: "Riyadh Air Base",
    },
    {
      city: "Leksand",
      code: "XXO",
      name: "Leksand Rail Station",
    },
    {
      city: "Valbonne",
      code: "XXT",
      name: "Sophia Antipolis Heliport",
    },
    {
      city: "Hedemora",
      code: "XXU",
      name: "Sweden",
    },
    {
      city: "Sundsvall",
      code: "XXZ",
      name: "Sundsvall Railway Station",
    },
    {
      city: "Yandina",
      code: "XYA",
      name: "Yandina",
    },
    {
      city: "Borlänge",
      code: "XYB",
      name: "Borlange",
    },
    {
      city: "Herrljunga",
      code: "XYC",
      name: "Sweden",
    },
    {
      city: "Lyon",
      code: "XYD",
      name: "Lyon Part-Dieu Railway Station",
    },
    {
      city: "Falkoping",
      code: "XYF",
      name: "Falkoping Rail Station",
    },
    {
      city: "Helsingborg",
      code: "XYH",
      name: "Helsingborg Railway",
    },
    {
      city: "Flen",
      code: "XYI",
      name: "Sweden",
    },
    {
      city: "Norrkoping",
      code: "XYK",
      name: "Norrkoping Railway Service",
    },
    {
      city: "Kristinehamn",
      code: "XYN",
      name: "Kristinehamn",
    },
    {
      city: "Avesta Krylbo",
      code: "XYP",
      name: "Kyrlbo",
    },
    {
      city: "Angelholm/Helsingborg",
      code: "XYQ",
      name: "Angelholm Railway Svc.",
    },
    {
      city: "Sala",
      code: "XYX",
      name: "Sala",
    },
    {
      city: "Arvika",
      code: "XYY",
      name: "Arvika Airport",
    },
    {
      city: "Harnosand",
      code: "XYZ",
      name: "Harnosand Rail Station",
    },
    {
      city: "Casselman",
      code: "XZB",
      name: "Casselman Rail Station",
    },
    {
      city: "Glencoe",
      code: "XZC",
      name: "",
    },
    {
      city: "Rail (Generic)",
      code: "XZJ",
      name: "",
    },
    {
      city: "Edmonton",
      code: "XZL",
      name: "Edmonton International Airport",
    },
    {
      city: "Macau",
      code: "XZM",
      name: "Macau Ferry",
    },
    {
      city: "Avignon",
      code: "XZN",
      name: "Avignon",
    },
    {
      city: "Oslo",
      code: "XZO",
      name: "Oslo Central Station",
    },
    {
      city: "Rail (Generic)",
      code: "XZU",
      name: "Off line Point",
    },
    {
      city: "Toulon",
      code: "XZV",
      name: "TGV Station",
    },
    {
      city: "Alexis Creek",
      code: "YAA",
      name: "Anahim Lake Airport",
    },
    {
      city: "Casummit Lake",
      code: "YAC",
      name: "Cat Lake Airport",
    },
    {
      city: "Fort Frances",
      code: "YAG",
      name: "Fort Frances Municipal Airport",
    },
    {
      city: "Yakutat",
      code: "YAK",
      name: "Yakutat Airport",
    },
    {
      city: "Sault Ste. Marie",
      code: "YAM",
      name: "Sault Ste Marie Airport",
    },
    {
      city: "Yaounde",
      code: "YAO",
      name: "Yaounde Airport",
    },
    {
      city: "Colonia",
      code: "YAP",
      name: "Yap International Airport",
    },
    {
      city: "Winisk",
      code: "YAT",
      name: "Attawapiskat Airport",
    },
    {
      city: "Casummit Lake",
      code: "YAX",
      name: "Angling Lake Airport",
    },
    {
      city: "St. Anthony",
      code: "YAY",
      name: "St Anthony Airport",
    },
    {
      city: "Lake Cowichan",
      code: "YAZ",
      name: "Tofino Airport",
    },
    {
      city: "Cambridge Bay",
      code: "YBB",
      name: "Pelly Bay Townsite Airport",
    },
    {
      city: "Betsiamites",
      code: "YBC",
      name: "Baie Comeau Airport",
    },
    {
      city: "Saguenay",
      code: "YBG",
      name: "Bagotville Airport",
    },
    {
      city: "St. Anthony",
      code: "YBI",
      name: "Black Tickle Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YBK",
      name: "Baker Lake Airport",
    },
    {
      city: "Gold River",
      code: "YBL",
      name: "Campbell River Airport",
    },
    {
      city: "Yibin",
      code: "YBP",
      name: "Yibin",
    },
    {
      city: "Killarney",
      code: "YBR",
      name: "Brandon Airport",
    },
    {
      city: "Brochet",
      code: "YBT",
      name: "Brochet",
    },
    {
      city: "Nelson House",
      code: "YBV",
      name: "Berens River Airport",
    },
    {
      city: "Bedwell Harbor",
      code: "YBW",
      name: "",
    },
    {
      city: "Havre-St-Pierre",
      code: "YBX",
      name: "Lourdes-De-Blanc-Sablon Airport",
    },
    {
      city: "Toronto",
      code: "YBZ",
      name: "Toronto Downtown Airport",
    },
    {
      city: "Courtenay",
      code: "YCA",
      name: "Courtenay Airport",
    },
    {
      city: "Cambridge Bay",
      code: "YCB",
      name: "Cambridge Bay Airport",
    },
    {
      city: "Moose Creek",
      code: "YCC",
      name: "Cornwall Regional Airport",
    },
    {
      city: "Nanaimo",
      code: "YCD",
      name: "Nanaimo Airport",
    },
    {
      city: "Castlegar",
      code: "YCG",
      name: "Castlegar Airport",
    },
    {
      city: "Hay River",
      code: "YCK",
      name: "Colville Lake Airport",
    },
    {
      city: "Fonthill",
      code: "YCM",
      name: "St Catharines Airport",
    },
    {
      city: "Cambridge Bay",
      code: "YCO",
      name: "Coppermine Airport",
    },
    {
      city: "Nelson House",
      code: "YCR",
      name: "Cross Lake Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YCS",
      name: "Chesterfield Inlet Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YCU",
      name: "Cullaton Lake Airport",
    },
    {
      city: "Iqaluit",
      code: "YCY",
      name: "Clyde River Airport",
    },
    {
      city: "Dawson",
      code: "YDA",
      name: "Dawson Airport",
    },
    {
      city: "St. Anthony",
      code: "YDF",
      name: "Deer Lake Airport",
    },
    {
      city: "Dauphin",
      code: "YDN",
      name: "Dauphin Airport",
    },
    {
      city: "Happy Valley-Goose Bay",
      code: "YDP",
      name: "Nain Airport",
    },
    {
      city: "Dawson Creek",
      code: "YDQ",
      name: "Dawson Creek Airport",
    },
    {
      city: "Leduc",
      code: "YEG",
      name: "Edmonton International Airport",
    },
    {
      city: "Bursa",
      code: "YEI",
      name: "",
    },
    {
      city: "Chesterfield Inlet",
      code: "YEK",
      name: "Arviat Airport",
    },
    {
      city: "Casummit Lake",
      code: "YER",
      name: "Fort Severn Airport",
    },
    {
      city: "Yasouj",
      code: "YES",
      name: "",
    },
    {
      city: "Hay River",
      code: "YEV",
      name: "Inuvik Airport",
    },
    {
      city: "Winisk",
      code: "YFA",
      name: "Fort Albany Airport",
    },
    {
      city: "Iqaluit",
      code: "YFB",
      name: "Iqaluit Airport",
    },
    {
      city: "Fredericton",
      code: "YFC",
      name: "Fredericton Airport",
    },
    {
      city: "Marathon",
      code: "YFH",
      name: "Fort Hope Airport",
    },
    {
      city: "Snare Lake",
      code: "YFJ",
      name: "Snare Lake",
    },
    {
      city: "Flin Flon",
      code: "YFO",
      name: "Flin Flon Airport",
    },
    {
      city: "Hay River",
      code: "YFS",
      name: "Fort Simpson Airport",
    },
    {
      city: "St. Anthony",
      code: "YFX",
      name: "Fox Harbour Aerodrome",
    },
    {
      city: "Gibsons",
      code: "YGB",
      name: "Gillies Bay Airport",
    },
    {
      city: "Saltspring Island",
      code: "YGG",
      name: "Ganges Harbor Airport",
    },
    {
      city: "Hay River",
      code: "YGH",
      name: "Fort Good Hope Airport",
    },
    {
      city: "Sakaiminato-shi",
      code: "YGJ",
      name: "Yonago Airport",
    },
    {
      city: "Kingston",
      code: "YGK",
      name: "Kingston Airport",
    },
    {
      city: "Chisasibi",
      code: "YGL",
      name: "La Grande Riviere Airport",
    },
    {
      city: "Nelson House",
      code: "YGO",
      name: "Gods Lake Narrows Airport",
    },
    {
      city: "Gaspé",
      code: "YGP",
      name: "Gaspe Airport",
    },
    {
      city: "Fatima",
      code: "YGR",
      name: "Iles de la Madeleine Airport",
    },
    {
      city: "Iqaluit",
      code: "YGT",
      name: "Igloolik Airport",
    },
    {
      city: "Havre St Pierre",
      code: "YGV",
      name: "Harve-St-Pierre Airport",
    },
    {
      city: "Chisasibi",
      code: "YGW",
      name: "Kuujjuarapik Airport",
    },
    {
      city: "Nelson House",
      code: "YGX",
      name: "Gillam Airport",
    },
    {
      city: "Iqaluit",
      code: "YGZ",
      name: "Grise Fiord Airport",
    },
    {
      city: "St. Anthony",
      code: "YHA",
      name: "Port Hope Simpson Aerodrome",
    },
    {
      city: "Melfort",
      code: "YHB",
      name: "Hudson Bay Airport",
    },
    {
      city: "Dryden",
      code: "YHD",
      name: "Dryden Regional Airport",
    },
    {
      city: "St. Anthony",
      code: "YHG",
      name: "Charlottetown Airport",
    },
    {
      city: "Holman",
      code: "YHI",
      name: "Holman Airport",
    },
    {
      city: "Cambridge Bay",
      code: "YHK",
      name: "Gjoa Haven Airport",
    },
    {
      city: "Beamsville",
      code: "YHM",
      name: "Hamilton Airport",
    },
    {
      city: "Happy Valley-Goose Bay",
      code: "YHO",
      name: "Hopedale Airport",
    },
    {
      city: "Casummit Lake",
      code: "YHP",
      name: "Poplar Hill Airport",
    },
    {
      city: "Havre-St-Pierre",
      code: "YHR",
      name: "Harrington Harbour Airport",
    },
    {
      city: "Gibsons",
      code: "YHS",
      name: "Sechelt Water Aerodrome",
    },
    {
      city: "St-Hubert",
      code: "YHU",
      name: "Montreal St Hubert Airport",
    },
    {
      city: "Hay River",
      code: "YHY",
      name: "Hay River Airport",
    },
    {
      city: "Fall River",
      code: "YHZ",
      name: "Halifax International Airport",
    },
    {
      city: "Havre-St-Pierre",
      code: "YIF",
      name: "Pakuashipi Airport",
    },
    {
      city: "Yichang",
      code: "YIH",
      name: "China",
    },
    {
      city: "Chisasibi",
      code: "YIK",
      name: "Ivujivik Airport",
    },
    {
      city: "Yining",
      code: "YIN",
      name: "Yining",
    },
    {
      city: "Iqaluit",
      code: "YIO",
      name: "Pond Inlet Airport",
    },
    {
      city: "Ypsilanti",
      code: "YIP",
      name: "Willow Run Airport",
    },
    {
      city: "Nelson House",
      code: "YIV",
      name: "Island Lake-Garden Hill Airport",
    },
    {
      city: "Yiwu",
      code: "YIW",
      name: "",
    },
    {
      city: "Stephenville",
      code: "YJT",
      name: "Stephenville International Airport",
    },
    {
      city: "Kamloops",
      code: "YKA",
      name: "Kamloops Airport",
    },
    {
      city: "Kitchener",
      code: "YKF",
      name: "Kitchener Airport",
    },
    {
      city: "Chisasibi",
      code: "YKG",
      name: "Kangirsuk Airport",
    },
    {
      city: "Chisasibi",
      code: "YKL",
      name: "Schefferville Airport",
    },
    {
      city: "Yakima",
      code: "YKM",
      name: "Yakima Air Terminal",
    },
    {
      city: "Chisasibi",
      code: "YKQ",
      name: "Waskaganish Airport",
    },
    {
      city: "Yakutsk",
      code: "YKS",
      name: "Yakutsk Airport",
    },
    {
      city: "Masset",
      code: "YKT",
      name: "Klemtu Water Aerodrome",
    },
    {
      city: "Chisasibi",
      code: "YKU",
      name: "Chisasibi Aerodrome",
    },
    {
      city: "Iqaluit",
      code: "YLC",
      name: "Lake Harbour Airport",
    },
    {
      city: "Hay River",
      code: "YLE",
      name: "Lac la Martre Aerodrome",
    },
    {
      city: "Marathon",
      code: "YLH",
      name: "Lansdowne House Airport",
    },
    {
      city: "Vegreville",
      code: "YLL",
      name: "Lloydminster Airport",
    },
    {
      city: "La Tuque",
      code: "YLQ",
      name: "La Tuque Airport",
    },
    {
      city: "Kelowna",
      code: "YLW",
      name: "Kelowna International Airport",
    },
    {
      city: "St. Anthony",
      code: "YMH",
      name: "Mary's Harbour Airport",
    },
    {
      city: "Fort Chipewyan",
      code: "YMM",
      name: "Fort Mcmurray Airport",
    },
    {
      city: "Happy Valley-Goose Bay",
      code: "YMN",
      name: "Makkovik Airport",
    },
    {
      city: "Winisk",
      code: "YMO",
      name: "Moosonee Airport",
    },
    {
      city: "Métabetchouan",
      code: "YMT",
      name: "Aéroport de Chapais-Chibougamau",
    },
    {
      city: "Mirabel",
      code: "YMX",
      name: "Mirabel International Airport",
    },
    {
      city: "Montreal",
      code: "YMY",
      name: "Downtown Rail Station",
    },
    {
      city: "Havre-St-Pierre",
      code: "YNA",
      name: "Natashquan Airport",
    },
    {
      city: "Yanbu al Bahr",
      code: "YNB",
      name: "Yenbo Airport",
    },
    {
      city: "Chisasibi",
      code: "YNC",
      name: "Wemindji Airport",
    },
    {
      city: "Gatineau",
      code: "YND",
      name: "Ottawa Gatineau Airport",
    },
    {
      city: "Nelson House",
      code: "YNE",
      name: "Norway House Airport",
    },
    {
      city: "Vienna",
      code: "YNG",
      name: "Youngstown-Warren Regional Airport",
    },
    {
      city: "Yanji",
      code: "YNJ",
      name: "Yanji",
    },
    {
      city: "Casummit Lake",
      code: "YNO",
      name: "North Spirit Lake Airport",
    },
    {
      city: "Natuashish",
      code: "YNP",
      name: "",
    },
    {
      city: "Chisasibi",
      code: "YNS",
      name: "Nemiscau Airport",
    },
    {
      city: "Yantai",
      code: "YNT",
      name: "Yantai Airport",
    },
    {
      city: "Sonyang-Myeon",
      code: "YNY",
      name: "Yang Yang International Airport",
    },
    {
      city: "Yancheng",
      code: "YNZ",
      name: "",
    },
    {
      city: "Dawson",
      code: "YOC",
      name: "Old Crow Airport",
    },
    {
      city: "Marathon",
      code: "YOG",
      name: "Ogoki Aerodrome",
    },
    {
      city: "Nelson House",
      code: "YOH",
      name: "Oxford House Airport",
    },
    {
      city: "Brownvale",
      code: "YOJ",
      name: "High Level Airport",
    },
    {
      city: "Oshawa",
      code: "YOO",
      name: "Oshawa Airport",
    },
    {
      city: "Brownvale",
      code: "YOP",
      name: "Rainbow Lake Airport",
    },
    {
      city: "Ottawa",
      code: "YOW",
      name: "Ottawa International Airport",
    },
    {
      city: "Lake Cowichan",
      code: "YPB",
      name: "Port Alberni Airport",
    },
    {
      city: "Hay River",
      code: "YPC",
      name: "Paulatuk Airport",
    },
    {
      city: "Brownvale",
      code: "YPE",
      name: "Peace River Airport",
    },
    {
      city: "Chisasibi",
      code: "YPH",
      name: "Inukjuak Airport",
    },
    {
      city: "Chisasibi",
      code: "YPJ",
      name: "Aupaluk Airport",
    },
    {
      city: "Casummit Lake",
      code: "YPL",
      name: "Pickle Lake Airport",
    },
    {
      city: "Casummit Lake",
      code: "YPM",
      name: "Pikangikum Airport",
    },
    {
      city: "Winisk",
      code: "YPO",
      name: "Peawanuck Airport",
    },
    {
      city: "Prince Rupert",
      code: "YPR",
      name: "Prince Rupert Airport",
    },
    {
      city: "Powell River",
      code: "YPW",
      name: "Powell River Airport",
    },
    {
      city: "Chisasibi",
      code: "YPX",
      name: "Povungnituk Airport",
    },
    {
      city: "Smithers",
      code: "YPZ",
      name: "Burns Lake Airport",
    },
    {
      city: "Ste-Foy",
      code: "YQB",
      name: "Quebec Airport",
    },
    {
      city: "Chisasibi",
      code: "YQC",
      name: "Quaqtaq Airport",
    },
    {
      city: "The Pas",
      code: "YQD",
      name: "The Pas Airport",
    },
    {
      city: "Sylvan Lake",
      code: "YQF",
      name: "Red Deer Regional Airport",
    },
    {
      city: "Windsor",
      code: "YQG",
      name: "Windsor Airport",
    },
    {
      city: "Kenora",
      code: "YQK",
      name: "Kenora Airport",
    },
    {
      city: "Pincher",
      code: "YQL",
      name: "Lethbridge Airport",
    },
    {
      city: "Moncton",
      code: "YQM",
      name: "Greater Moncton International Airport",
    },
    {
      city: "Marathon",
      code: "YQN",
      name: "Nakina Airport",
    },
    {
      city: "Comox",
      code: "YQQ",
      name: "Comox Airport",
    },
    {
      city: "Regina",
      code: "YQR",
      name: "Regina Airport",
    },
    {
      city: "Thunder Bay",
      code: "YQT",
      name: "Thunder Bay International Airport",
    },
    {
      city: "Brownvale",
      code: "YQU",
      name: "Grande Prairie Airport",
    },
    {
      city: "Gander",
      code: "YQX",
      name: "Gander International Airport",
    },
    {
      city: "Sydney",
      code: "YQY",
      name: "Sydney Airport",
    },
    {
      city: "Quesnel",
      code: "YQZ",
      name: "Quesnel Airport",
    },
    {
      city: "Hay River",
      code: "YRA",
      name: "Rae Lakes Aerodrome",
    },
    {
      city: "Iqaluit",
      code: "YRB",
      name: "Resolute Airport",
    },
    {
      city: "St. Anthony",
      code: "YRF",
      name: "Cartwright Airport",
    },
    {
      city: "Happy Valley-Goose Bay",
      code: "YRG",
      name: "Rigolet Aerodrome",
    },
    {
      city: "Roberval",
      code: "YRJ",
      name: "Roberval Airport",
    },
    {
      city: "Casummit Lake",
      code: "YRL",
      name: "Red Lake Airport",
    },
    {
      city: "Nelson House",
      code: "YRS",
      name: "Red Sucker Lake Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YRT",
      name: "Rankin Inlet Airport",
    },
    {
      city: "Garson",
      code: "YSB",
      name: "Sudbury Airport",
    },
    {
      city: "Hay River",
      code: "YSG",
      name: "Snowdrift Aerodrome",
    },
    {
      city: "Smiths Falls",
      code: "YSH",
      name: "Smiths Falls Montague Township Russ Beach Airpor",
    },
    {
      city: "St. John",
      code: "YSJ",
      name: "Saint John Airport",
    },
    {
      city: "Iqaluit",
      code: "YSK",
      name: "Sanikiluaq Airport",
    },
    {
      city: "Hay River",
      code: "YSM",
      name: "Fort Smith Airport",
    },
    {
      city: "Happy Valley-Goose Bay",
      code: "YSO",
      name: "Postville Aerodrome",
    },
    {
      city: "Iqaluit",
      code: "YSR",
      name: "Nanisivik Airport",
    },
    {
      city: "Meiktila",
      code: "YST",
      name: "Shante Airport",
    },
    {
      city: "Hay River",
      code: "YSY",
      name: "Sachs Harbour Airport",
    },
    {
      city: "Nelson House",
      code: "YTD",
      name: "Thicket Portage Airport",
    },
    {
      city: "Iqaluit",
      code: "YTE",
      name: "Cape Dorset Airport",
    },
    {
      city: "Alma",
      code: "YTF",
      name: "Alma",
    },
    {
      city: "Nelson House",
      code: "YTH",
      name: "Thompson Airport",
    },
    {
      city: "Casummit Lake",
      code: "YTL",
      name: "Big Trout Lake Airport",
    },
    {
      city: "Mont Tremblant",
      code: "YTM",
      name: "La Macaza",
    },
    {
      city: "Chisasibi",
      code: "YTQ",
      name: "Tasiujuaq Airport",
    },
    {
      city: "South Porcupine",
      code: "YTS",
      name: "Timmins Airport",
    },
    {
      city: "Toronto",
      code: "YTZ",
      name: "Toronto City Centre Airport",
    },
    {
      city: "Hay River",
      code: "YUB",
      name: "Tuktoyaktuk Airport",
    },
    {
      city: "Chisasibi",
      code: "YUD",
      name: "Umiujaq Airport",
    },
    {
      city: "Dorval",
      code: "YUL",
      name: "Aéroport International Pierre-Elliott-Trudeau d",
    },
    {
      city: "Yuma",
      code: "YUM",
      name: "Yuma International Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YUT",
      name: "Repulse Bay Airport",
    },
    {
      city: "Iqaluit",
      code: "YUX",
      name: "Hall Beach Airport",
    },
    {
      city: "Évain",
      code: "YUY",
      name: "Rouyn Noranda Airport",
    },
    {
      city: "Moroni",
      code: "YVA",
      name: "Moroni Iconi Airport",
    },
    {
      city: "Percé",
      code: "YVB",
      name: "Bonaventure Airport",
    },
    {
      city: "Iqaluit",
      code: "YVM",
      name: "Broughton Island Airport",
    },
    {
      city: "Val-d'or",
      code: "YVO",
      name: "Val d'or Airport",
    },
    {
      city: "Chisasibi",
      code: "YVP",
      name: "Kuujjuaq Airport",
    },
    {
      city: "Hay River",
      code: "YVQ",
      name: "Norman Wells Airport",
    },
    {
      city: "Richmond",
      code: "YVR",
      name: "Vancouver International Airport",
    },
    {
      city: "Casummit Lake",
      code: "YVZ",
      name: "Deer Lake Airport",
    },
    {
      city: "Chisasibi",
      code: "YWB",
      name: "Kangiqsujuaq Airport",
    },
    {
      city: "Winnipeg",
      code: "YWG",
      name: "Winnipeg International Airport",
    },
    {
      city: "Hay River",
      code: "YWH",
      name: "Inner Harbour Airport",
    },
    {
      city: "Hay River",
      code: "YWJ",
      name: "Fort Franklin Airport",
    },
    {
      city: "Wabush",
      code: "YWK",
      name: "Wabush Airport",
    },
    {
      city: "Williams Lake",
      code: "YWL",
      name: "Williams Lake Airport",
    },
    {
      city: "St. Anthony",
      code: "YWM",
      name: "Williams Harbour Airport",
    },
    {
      city: "Marathon",
      code: "YWP",
      name: "Webequie Airport",
    },
    {
      city: "Gibsons",
      code: "YWS",
      name: "Green Lake Water Aerodrome",
    },
    {
      city: "Cranbrook",
      code: "YXC",
      name: "Cranbrook Airport",
    },
    {
      city: "Saskatoon",
      code: "YXE",
      name: "John G Diefenbaker International Airport",
    },
    {
      city: "Medicine Hat",
      code: "YXH",
      name: "Medicine Hat Airport",
    },
    {
      city: "Fort St. John",
      code: "YXJ",
      name: "North Peace Airport",
    },
    {
      city: "Rimouski",
      code: "YXK",
      name: "Rimouski Airport",
    },
    {
      city: "Sioux Lookout",
      code: "YXL",
      name: "Sioux Lookout Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YXN",
      name: "Whale Cove Airport",
    },
    {
      city: "Iqaluit",
      code: "YXP",
      name: "Pangnirtung Airport",
    },
    {
      city: "Prince George",
      code: "YXS",
      name: "Prince George Airport",
    },
    {
      city: "Terrace",
      code: "YXT",
      name: "Terrace Airport",
    },
    {
      city: "London",
      code: "YXU",
      name: "London International Airport",
    },
    {
      city: "Abbotsford",
      code: "YXX",
      name: "Abbotsford International Airport",
    },
    {
      city: "Whitehorse",
      code: "YXY",
      name: "Whitehorse International Airport",
    },
    {
      city: "North Bay",
      code: "YYB",
      name: "North Bay Airport",
    },
    {
      city: "Calgary",
      code: "YYC",
      name: "Calgary International Airport",
    },
    {
      city: "Smithers",
      code: "YYD",
      name: "Smithers Airport",
    },
    {
      city: "Fort Nelson",
      code: "YYE",
      name: "Fort Nelson Airport",
    },
    {
      city: "Penticton",
      code: "YYF",
      name: "Penticton Airport",
    },
    {
      city: "Charlottetown",
      code: "YYG",
      name: "Charlottetown Airport",
    },
    {
      city: "Cambridge Bay",
      code: "YYH",
      name: "Taloyoak Airport",
    },
    {
      city: "Sidney",
      code: "YYJ",
      name: "Victoria International Airport",
    },
    {
      city: "Nelson House",
      code: "YYL",
      name: "Lynn Lake Airport",
    },
    {
      city: "Nelson House",
      code: "YYQ",
      name: "Churchill Airport",
    },
    {
      city: "Happy Valley-Goose Bay",
      code: "YYR",
      name: "Goose Bay Airport",
    },
    {
      city: "St. John's",
      code: "YYT",
      name: "St John's International Airport",
    },
    {
      city: "Kapuskasing",
      code: "YYU",
      name: "Kapuskasing Airport",
    },
    {
      city: "Mont-Joli",
      code: "YYY",
      name: "Mont Joli Airport",
    },
    {
      city: "Mississauga",
      code: "YYZ",
      name: "Toronto Lester B Pearson International Airport",
    },
    {
      city: "Yellowknife",
      code: "YZF",
      name: "Yellowknife Airport",
    },
    {
      city: "Chisasibi",
      code: "YZG",
      name: "Salluit Airport",
    },
    {
      city: "Masset",
      code: "YZP",
      name: "Sandspit Airport",
    },
    {
      city: "Sarnia",
      code: "YZR",
      name: "Sarnia Airport",
    },
    {
      city: "Chesterfield Inlet",
      code: "YZS",
      name: "Coral Harbour Airport",
    },
    {
      city: "Gibsons",
      code: "YZT",
      name: "Port Hardy Airport",
    },
    {
      city: "Sept-Îles",
      code: "YZV",
      name: "Sept Iles Airport",
    },
    {
      city: "Trail",
      code: "YZZ",
      name: "Trail Airport",
    },
    {
      city: "Nelson House",
      code: "ZAC",
      name: "York Landing Airport",
    },
    {
      city: "Zadar",
      code: "ZAD",
      name: "Zadar Airport",
    },
    {
      city: "Nagygoricza",
      code: "ZAG",
      name: "Zagreb Airport",
    },
    {
      city: "Dowzdab",
      code: "ZAH",
      name: "Zahedan International Airport",
    },
    {
      city: "Pelchuquin",
      code: "ZAL",
      name: "Pichoy Airport",
    },
    {
      city: "Zamboanga",
      code: "ZAM",
      name: "Zamboanga International Airport",
    },
    {
      city: "Nuremberg",
      code: "ZAQ",
      name: "Bavaria",
    },
    {
      city: "Zhaotong",
      code: "ZAT",
      name: "Zhaotong",
    },
    {
      city: "Zaragoza",
      code: "ZAZ",
      name: "Zaragoza Air Base",
    },
    {
      city: "Basel",
      code: "ZBA",
      name: "Switzerland",
    },
    {
      city: "Bathurst",
      code: "ZBF",
      name: "Bathurst Airport",
    },
    {
      city: "Biloela",
      code: "ZBL",
      name: "Australia",
    },
    {
      city: "Baltimore",
      code: "ZBP",
      name: "",
    },
    {
      city: "Chabahar",
      code: "ZBR",
      name: "Chah Bahar Airport",
    },
    {
      city: "",
      code: "ZCB",
      name: "Skopje Airport",
    },
    {
      city: "Pánuco",
      code: "ZCL",
      name: "Zacatecas Airport",
    },
    {
      city: "Padre Las Casas",
      code: "ZCO",
      name: "Maquehue Airport",
    },
    {
      city: "Basel",
      code: "ZDH",
      name: "Basel SBB station",
    },
    {
      city: "Sydney",
      code: "ZDN",
      name: "Kingsford Smith Airport",
    },
    {
      city: "London",
      code: "ZDU",
      name: "London City Airport",
    },
    {
      city: "Kelsey",
      code: "ZEE",
      name: "Kelsey Airport",
    },
    {
      city: "Zei",
      code: "ZEI",
      name: "",
    },
    {
      city: "",
      code: "ZEJ",
      name: "Satu Mare Airport",
    },
    {
      city: "Masset",
      code: "ZEL",
      name: "Bella Bella Airport",
    },
    {
      city: "Chisasibi",
      code: "ZEM",
      name: "East Main Airport",
    },
    {
      city: "London",
      code: "ZEP",
      name: "",
    },
    {
      city: "Zeu",
      code: "ZEU",
      name: "",
    },
    {
      city: "Chesterfield",
      code: "ZFI",
      name: "England",
    },
    {
      city: "Rennes",
      code: "ZFJ",
      name: "France",
    },
    {
      city: "Hay River",
      code: "ZFM",
      name: "Fort Mcpherson Airport",
    },
    {
      city: "Hay River",
      code: "ZFN",
      name: "Fort Norman Airport",
    },
    {
      city: "Bordeaux",
      code: "ZFQ",
      name: "France",
    },
    {
      city: "Philadelphia",
      code: "ZFV",
      name: "Philadelphia Rail",
    },
    {
      city: "Buffalo",
      code: "ZFZ",
      name: "New York",
    },
    {
      city: "Groton",
      code: "ZGD",
      name: "New London",
    },
    {
      city: "Glasgow",
      code: "ZGG",
      name: "United Kingdom",
    },
    {
      city: "Copenhagen",
      code: "ZGH",
      name: "Copenhagen Main Station",
    },
    {
      city: "Nelson House",
      code: "ZGI",
      name: "Gods River Airport",
    },
    {
      city: "Zhongshan",
      code: "ZGN",
      name: "Zhongshan Ferry Port",
    },
    {
      city: "Gotha",
      code: "ZGO",
      name: "Gotha",
    },
    {
      city: "Havre-St-Pierre",
      code: "ZGS",
      name: "Gethsemani Airport",
    },
    {
      city: "Gaua",
      code: "ZGU",
      name: "Gaua Airport",
    },
    {
      city: "Zhanjiang",
      code: "ZHA",
      name: "Zhanjiang Airport",
    },
    {
      city: "Houston",
      code: "ZHO",
      name: "Bus Station",
    },
    {
      city: "Ottawa",
      code: "ZIF",
      name: "Fallowfield Railway",
    },
    {
      city: "Ziguinchor",
      code: "ZIG",
      name: "Ziguinchor Airport",
    },
    {
      city: "Petatlán",
      code: "ZIH",
      name: "Ixtapa Zihuatanejo International Airport",
    },
    {
      city: "Inverness",
      code: "ZIV",
      name: "Inverness Rail Station",
    },
    {
      city: "Tanjung Pelepas",
      code: "ZJT",
      name: "",
    },
    {
      city: "Winisk",
      code: "ZKE",
      name: "Kaschechewan Airport",
    },
    {
      city: "Havre-St-Pierre",
      code: "ZKG",
      name: "Kegaska Airport",
    },
    {
      city: "Le Mans",
      code: "ZLN",
      name: "Le Mans",
    },
    {
      city: "Manzanillo",
      code: "ZLO",
      name: "Playa de Oro International Airport",
    },
    {
      city: "London",
      code: "ZLS",
      name: "England",
    },
    {
      city: "Havre-St-Pierre",
      code: "ZLT",
      name: "La Tabatiere Airport",
    },
    {
      city: "Albany",
      code: "ZLY",
      name: "Albany International Airport",
    },
    {
      city: "Hamburg",
      code: "ZMB",
      name: "Hamburg Hauptbahnhof",
    },
    {
      city: "Newark",
      code: "ZME",
      name: "New Jersey",
    },
    {
      city: "Milwaukee",
      code: "ZML",
      name: "General Mitchell International Airport",
    },
    {
      city: "Masset",
      code: "ZMT",
      name: "Masset Airport",
    },
    {
      city: "Munich",
      code: "ZMU",
      name: "Bavaria",
    },
    {
      city: "Huangpu",
      code: "ZMY",
      name: "Huangpu Harbour",
    },
    {
      city: "Nanaimo",
      code: "ZNA",
      name: "Nanaimo Harbour Airport",
    },
    {
      city: "Santo Antônio do Içá",
      code: "ZNB",
      name: "Sinop Airport",
    },
    {
      city: "Newman",
      code: "ZNE",
      name: "Newman Airport",
    },
    {
      city: "Santa Elena de Uairén",
      code: "ZNV",
      name: "Santa Elena Airport",
    },
    {
      city: "Stone Town",
      code: "ZNZ",
      name: "Zanzibar Airport",
    },
    {
      city: "Osorno",
      code: "ZOS",
      name: "Canal Bajo Carlos H Siebert Airport",
    },
    {
      city: "Casummit Lake",
      code: "ZPB",
      name: "Sachigo Lake Airport",
    },
    {
      city: "Queenstown",
      code: "ZQN",
      name: "Frankton Airport",
    },
    {
      city: "Zweibrucken",
      code: "ZQW",
      name: "Rheinland-Pfalz",
    },
    {
      city: "Frankfurt",
      code: "ZRB",
      name: "Frankfurt International Airport",
    },
    {
      city: "Richmond",
      code: "ZRD",
      name: "Richmond",
    },
    {
      city: "Kloten",
      code: "ZRH",
      name: "Zurich International Airport",
    },
    {
      city: "Casummit Lake",
      code: "ZRJ",
      name: "Round Lake Airport",
    },
    {
      city: "Lancaster",
      code: "ZRL",
      name: "Lancaster",
    },
    {
      city: "Newark",
      code: "ZRP",
      name: "Pennsylvania Station",
    },
    {
      city: "Hartford",
      code: "ZRT",
      name: "Hartford",
    },
    {
      city: "Boston",
      code: "ZRU",
      name: "",
    },
    {
      city: "Providence",
      code: "ZRV",
      name: "Providence",
    },
    {
      city: "Cockburn Town",
      code: "ZSA",
      name: "San Salvador Airport",
    },
    {
      city: "St Pierre dela Reunion",
      code: "ZSE",
      name: "Réunion",
    },
    {
      city: "Springfield",
      code: "ZSF",
      name: "Springfield MA RR",
    },
    {
      city: "Casummit Lake",
      code: "ZSJ",
      name: "Sandy Lake Airport",
    },
    {
      city: "South Indian Lake",
      code: "ZSN",
      name: "South Indian Lake Airport",
    },
    {
      city: "Tureira",
      code: "ZTA",
      name: "",
    },
    {
      city: "Havre-St-Pierre",
      code: "ZTB",
      name: "Tete-a-la-Baleine Airport",
    },
    {
      city: "Schenectady",
      code: "ZTD",
      name: "",
    },
    {
      city: "Rochester",
      code: "ZTE",
      name: "",
    },
    {
      city: "Stamford",
      code: "ZTF",
      name: "",
    },
    {
      city: "Zante",
      code: "ZTH",
      name: "Zakinthos Airport",
    },
    {
      city: "Humen",
      code: "ZTI",
      name: "Humen Port",
    },
    {
      city: "Princeton",
      code: "ZTJ",
      name: "New Jersey",
    },
    {
      city: "Nelson House",
      code: "ZTM",
      name: "Shamattawa Airport",
    },
    {
      city: "Philadelphia",
      code: "ZTN",
      name: "",
    },
    {
      city: "Boston",
      code: "ZTO",
      name: "",
    },
    {
      city: "Louisville",
      code: "ZTV",
      name: "Louisville International Airport",
    },
    {
      city: "Boston",
      code: "ZTY",
      name: "",
    },
    {
      city: "Utica",
      code: "ZUA",
      name: "Utica",
    },
    {
      city: "Harrisburg",
      code: "ZUG",
      name: "",
    },
    {
      city: "Zhuhai",
      code: "ZUH",
      name: "Zhuhai Airport",
    },
    {
      city: "Wabush",
      code: "ZUM",
      name: "Churchill Falls Airport",
    },
    {
      city: "Zuni",
      code: "ZUN",
      name: "Black Rock Airport",
    },
    {
      city: "New Haven",
      code: "ZVE",
      name: "New Haven",
    },
    {
      city: "Savannakhet",
      code: "ZVK",
      name: "Savannakhet Airport",
    },
    {
      city: "Hanover",
      code: "ZVR",
      name: "Hannover Hauptbahnhof",
    },
    {
      city: "Hampton",
      code: "ZWB",
      name: "Williamsburg Rail",
    },
    {
      city: "",
      code: "ZWE",
      name: "",
    },
    {
      city: "Wilmington",
      code: "ZWI",
      name: "Wilmington Rail",
    },
    {
      city: "Stuttgart",
      code: "ZWS",
      name: "Stuttgart Hauptbahnhof",
    },
    {
      city: "Washington",
      code: "ZWU",
      name: "Union Station",
    },
    {
      city: "Glenview",
      code: "ZWV",
      name: "Illinois",
    },
    {
      city: "Hampton",
      code: "ZWW",
      name: "Newport News",
    },
    {
      city: "Aberdeen",
      code: "ZXA",
      name: "Aberdeen railway station",
    },
    {
      city: "Edinburgh",
      code: "ZXE",
      name: "Waverley station",
    },
    {
      city: "Amsterdam",
      code: "ZYA",
      name: "Amsterdam Central Station",
    },
    {
      city: "Shekou",
      code: "ZYK",
      name: "Shekou Port",
    },
    {
      city: "Sylhet",
      code: "ZYL",
      name: "Osmany Sylhet Airport",
    },
    {
      city: "Nimes",
      code: "ZYN",
      name: "Nimes Rail Station",
    },
    {
      city: "New York",
      code: "ZYP",
      name: "Penn Station",
    },
    {
      city: "Syracuse",
      code: "ZYQ",
      name: "Syracuse",
    },
    {
      city: "Brussels",
      code: "ZYR",
      name: "Brussels Midi Railway Station",
    },
    {
      city: "Antwerp",
      code: "ZYZ",
      name: "Berchem Railway Stn.",
    },
    {
      city: "Teniente R. Marsh",
      code: "TNM",
      name: "Teniente R. Marsh Airport",
    },
  ].filter(
    (item) =>
      item.code.toUpperCase().includes(value?.toUpperCase()) ||
      item.name.toUpperCase().includes(value.toUpperCase()) ||
      item.city.toUpperCase().includes(value.toUpperCase())
  );
};
