import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux2/user/authSlice";

import emiru from "../../images/avatars/emiru.jpg";
import { USER_ROLE_SUPERAGENT } from "../../common/constants";

const UserMenu = () => {
  // const { user } = getUserData();
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div>
      <div
        className="flex flex-row py-0 lg:py-2 items-center hover:bg-gray-200  rounded-xl cursor-pointer"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {user?.firstName === "Emiru" && user?.role === USER_ROLE_SUPERAGENT ? (
          <Avatar
            alt="user icon"
            sx={{ width: 38, height: 40 }}
            size="small"
            src={emiru}
          />
        ) : (
          <Avatar
            alt="user icon"
            sx={{ width: 40, height: 40 }}
            size="small"
            src="http://sterlingtravelandtour.com/images/ad1.jpg"
          />
        )}

        {/* </Avatar> */}
        <p className="font-medium pl-4 text-lg">{user?.firstName}</p>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        <MenuItem onClick={() => onLogout()}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
