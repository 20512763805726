import * as Yup from "yup";

export const PhoneCheckerSchema = () =>
  Yup.object({
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
  });

export const UserSchema = () =>
  Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
    gender: Yup.string().required("Gender is required"),
    role: Yup.string().required("Role is required"),
  });

export const CompanySchema1 = () =>
  Yup.object({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    officeNumber: Yup.string().required("Office Number is required"),
    // .matches(/^(^\+251|^251|^0)?9\d{8}$/, "Phone format is not valid"),
  });

export const CompanySchema2 = () =>
  Yup.object({
    bank: Yup.string().required("Bank is required"),
    deposit: Yup.number().required("Deposit is required"),
    // .min(1000, "Deposit can't be below 0")
    serviceChargeLocal: Yup.number().required(
      "Local Service Charge is required"
    ),
    // serviceChargeInternational: Yup.number().required(
    //   "International Service Charge is required"
    // ),
    // serviceChargeInternationalBusiness: Yup.number().required(
    //   "International Business Service Charge is required"
    // ),
    transactionId: Yup.string()
      // .length(3, "Transaction ID is not valid")
      // .min(10, "Transaction ID is not valid")
      .required("Transaction ID is required"),
    // amount: Yup.number().required("Amount is required"),
    // creditLimit: Yup.number().required("Credit Limit is required"),
  });

export const EnterpriseSchema = () =>
  Yup.object({
    bank: Yup.string().when("method", {
      is: (val) => val === "prePaid",
      then: Yup.string().required("Bank is required"),
      otherwise: Yup.string(),
    }),
    deposit: Yup.number().when("method", {
      is: (val) => val === "prePaid",
      then: Yup.number().required("Deposit is required"),
      otherwise: Yup.number(),
    }),
    method: Yup.string().required("Method is required"),
    serviceChargeLocal: Yup.number().required(
      "Local Service Charge is required"
    ),
    serviceChargeInternational: Yup.number().required(
      "International Service Charge is required"
    ),
    serviceChargeInternationalBusiness: Yup.number().required(
      "International Business Service Charge is required"
    ),
    transactionId: Yup.string().when("method", {
      is: (val) => val === "prePaid",
      then: Yup.string().required("Transaction ID is required"),
      otherwise: Yup.string(),
    }),
  });

export const BillingSchema = () =>
  Yup.object({
    bank: Yup.string().required("Bank is required"),
    deposit: Yup.number()
      .min(1, "Deposit can't be below 1")
      .required("Deposit is required"),
    transaction: Yup.string()
      .min(3, "Transaction ID is not valid")
      .required("Transaction ID is required"),
  });

export const formatPhone = (phone) => {
  if (phone.startsWith("0")) {
    return phone;
  } else {
    return `0${phone}`;
  }
};
