import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Modal } from "react-bootstrap";
import { Tab } from "@headlessui/react";
import axios from "axios";

import API_URL from "../../../redux2";
import { toast } from "react-toastify";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import { WALLET_MINIMUM_AMOUNT } from "../../../common/constants";

const CreditPayment = ({ company }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("wallet");
  const [wallet, setWallet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  // const { isLoading, wallet } = useSelector((state) => state.billing);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      selectedPayment === "wallet" &&
      company?.wallet < creditBalance() + WALLET_MINIMUM_AMOUNT
    ) {
      toast.error("You don't have enough balance in your wallet");
      return;
    }

    const data = {
      paymentMethod: selectedPayment,
      amount: creditBalance(),
    };

    try {
      setIsLoading(true);
      const response = await axios.post(API_URL + "wallet/credit", data, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (selectedPayment === "wallet") {
        console.log(!response.data?.success);
        if (!response.data?.success) {
          toast.error("Payment Failed");
          return;
        }
        toast.success("Payment Successful");
        setOpenModal(false);
        window.location.reload();
      } else {
        setWallet(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const paymentMethods = [
    {
      name: "wallet",
      icon: "https://www.kindpng.com/picc/m/580-5809740_white-wallet-logo-png-wallet-icon-ios-transparent.png",
      title: "Wallet",
    },
    {
      name: "webirr_cbe",
      icon: "https://www.ethiopiatrustfund.org/wp-content/uploads/2018/09/cbe-logo.png",
      title: "CBE",
    },
    {
      name: "webirr_awash",
      icon: "https://play-lh.googleusercontent.com/6W3_GN3BhqJffOg3XrjStJ7ICaQWdn9DD4lid4zPA0N7O0Jz0Ifq4ymCspxaO-QwvYw",
      title: "Awash Bank",
    },
    // {
    //   name: "webirr_abyssinia",
    //   icon: "https://play-lh.googleusercontent.com/9UY26ys1vxxqYPX_zL4BCBjuaCBMoXIf9yQ7RhPEqAoQJVnczlgFpWUCRem1GXzt3A",
    //   title: "Abyssinia Bank",
    // },
    {
      name: "amhara",
      icon: "https://pbs.twimg.com/profile_images/1542232022726447104/dQxN5Fap_400x400.jpg",
      title: "Amhara Bank",
    },
  ];

  function creditBalance() {
    return company?.totalCreditLimit - company?.creditLimit;
  }

  const renderInstructions = () => {
    return (
      <div className="mt-2 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col tracking-wider space-y-1">
          <h1 className="text-xl text-center ">
            Amount:{" "}
            <span className="font-bold ">
              {" "}
              {convertNumbThousand(wallet?.amount)} birr
            </span>
          </h1>
          <h1 className="text-xl text-center capitalize">
            Bank: <span className="font-bold ">{wallet?.paymentMethod}</span>
          </h1>
          <h1 className="text-xl text-center capitalize">
            Payment Reference:{" "}
            {/* <span className="font-bold underline">
              {wallet?.paymentReference}
            </span> */}
            <button
              className="bg-slate-300 text-white px-2 py-1 rounded-lg cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(
                  // airReservation._attributes.LocatorCode
                  wallet.paymentReference
                );
                toast.success("Payment Reference code copied to clipboard");
              }}
            >
              <h1 className="text-xl font-bold flex  ">
                {wallet.paymentReference}
                {/* {airReservation._attributes.LocatorCode} */}
                <button className="mx-2 hover:text-green-800  hover:rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                    />
                  </svg>
                </button>
              </h1>
            </button>
          </h1>
          {wallet?.paymentInstructionsAll?.map((item, index) => (
            <div key={index} className="mt-3">
              <h1 className="text-xl text-center font-semibold underline">
                {item?.type}
              </h1>
              <div key={index} className="flex items-start">
                <div className="flex flex-col">
                  {item?.instructions?.map((instruction, i) => (
                    <span
                      className="text-sm font-semibold text-neutral-900"
                      key={i}
                    >
                      {instruction}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-outline-primary hover:bg-main-700"
            onClick={() => {
              setOpenModal(false);
              setWallet(null);
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  // if (isLoading) return <Loading />;

  return (
    <div>
      <div className="cursor-pointer" onClick={handleClick}>
        + Credit Payment
      </div>
      <Modal
        show={openModal}
        className="modal fade"
        onHide={() => {
          setOpenModal(false);
          setWallet(null);
        }}
        size="lg"
        centered
      >
        <div className="">
          <div className="modal-header bg-primary text-white rounded-t-2xl">
            <h1 className="text-black font-bold  text-xl">Credit Payment</h1>
            <button
              type="button"
              className=" font-bold text-3xl text-white rounded-full shadow-
              xl hover:outline-none border-none hover:ring-2 px-2"
              onClick={() => setOpenModal(false)}
            >
              &times;
            </button>
          </div>
          <div className="modal-body text-black">
            {wallet ? (
              renderInstructions()
            ) : (
              <>
                <div>
                  <h6 className=" pb-2 font-semibold">
                    Select a Bank to pay with:
                  </h6>
                  <Tab.Group>
                    <Tab.List className="flex flex-wrap mt-2 justify-start">
                      {paymentMethods.map((method) => (
                        <Tab as={Fragment}>
                          {({ selected }) => (
                            <button
                              onClick={() => {
                                setSelectedPayment(method.name);
                              }}
                              className={`p-8 rounded-lg focus:outline-none mr-5 mt-2 md:mr-12  ${
                                selected
                                  ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                                  : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                              }`}
                            >
                              <div className="flex flex-col items-center gap-1">
                                <img
                                  src={method.icon}
                                  alt=""
                                  className="w-16 my-2"
                                />
                                {method.title}
                              </div>
                            </button>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </Tab.Group>
                </div>
                <div className="mt-4">
                  <form className="w-full" onSubmit={handleSubmit}>
                    {/* <h6 className=" pb-2 font-semibold">Full Name:</h6>
                    <div className="flex justify-between ">
                      <input
                        id="outlined-basic"
                        label="Full Name"
                        variant="outlined"
                        name="fullName"
                        className="w-1/2 border border-black rounded-md px-4 py-3 mb-4"
                        placeholder="Enter Full Name"
                        size="medium"
                        type="text"
                        required
                      />
                    </div>
                    <h6 className=" pb-2 font-semibold">Phone Number:</h6>
                    <div className="flex justify-between ">
                      <input
                        id="outlined-basic"
                        label="Phone Number"
                        name="phoneNumber"
                        variant="outlined"
                        className="w-1/2 border border-black rounded-md px-4 py-3 mb-4"
                        placeholder="Enter phone number"
                        size="medium"
                        minLength={9}
                        type="number"
                        required
                      />
                    </div> */}
                    <h6 className=" pb-2 font-semibold">
                      Payable Total Credit:
                    </h6>
                    <div className="flex justify-between ">
                      <input
                        id="outlined-basic"
                        label="Amount"
                        name="amount"
                        variant="outlined"
                        className="w-1/2 border border-black rounded-md px-4 py-3 mb-4 font-bold"
                        placeholder="Enter Amount"
                        size="medium"
                        type="number"
                        // required
                        disabled
                        value={creditBalance()}
                      />
                    </div>
                    <LoadingButton
                      loading={isLoading}
                      className={`w-full bg-primary hover:bg-main-700 text-white py-3 px-4 rounded-md focus:outline-none my-1 ${
                        creditBalance() === 0 && "opacity-50 cursor-not-allowed"
                      }`}
                      type="submit"
                      disabled={creditBalance() === 0}
                    >
                      Pay
                    </LoadingButton>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreditPayment;
