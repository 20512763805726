import { LoadingButton } from "@mui/lab";
import { Alert, InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { LoginValidationSchema } from "./Login.util";
import travel from "../../../images/travel.png";
import logo2 from "../../../images/logo.png";
import logo from "../../../images/logo2.png";
import { toast } from "react-toastify";
import { login, reset } from "../../../redux2/user/authSlice";
import ForgetPasswordModal from "../../../components/User/ForgetPassword";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { user, isError, errorMessage, isLoading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
    }

    if (user) {
      navigate("/");
    }

    dispatch(reset());
  }, [user, isError, errorMessage, navigate, dispatch]);

  const handleSubmit = (values, e) => {
    const userData = {
      phone: values.username,
      password: values.password,
    };

    dispatch(login(userData));
  };

  function onClose() {
    setShowModal(false);
  }

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid ">
      {" "}
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="flex justify-center	items-center flex-col pt-lg-40 pt-15">
          <div className="ext-center mb-4 pt-5">
            {/* <img src={logo} alt="" className="w-full h-16 px-5 py-3 " /> */}
            <img src={logo2} alt="" className="w-full h-24 px-5 py-3 " />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
          <p>
            Online airline ticket sales solution.
            <br />
          </p>
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + travel + ")" }}
        ></div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2 bg-main-100">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  <Formik
                    initialValues={{
                      password: "",
                      username: "",
                    }}
                    validationSchema={LoginValidationSchema}
                    onSubmit={handleSubmit}
                    // onSubmit={(values) => dispatch(userActions.login(values))}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-validate">
                          <h3 className="text-center mb-4 text-black font-extrabold text-2xl">
                            Sign in your account
                          </h3>
                          <div className="form-group mb-8 mt-8">
                            <TextField
                              fullWidth
                              id="phone"
                              variant="outlined"
                              name="username"
                              placeholder="Phone number"
                              label="Phone number"
                              value={values.username}
                              onChange={handleChange}
                              error={
                                touched.username && Boolean(errors.username)
                              }
                              helperText={touched.username && errors.username}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +251
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <TextField
                              fullWidth
                              type="password"
                              id="password"
                              variant="outlined"
                              name="password"
                              placeholder="Password"
                              label="Password"
                              value={values.password}
                              onChange={handleChange}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              helperText={touched.password && errors.password}
                            />
                          </div>
                          <div className="form-row d-flex justify-content-between mt-4 mb-2">
                            <div className="form-group mb-3">
                              <div className="custom-control custom-checkbox ml-1">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="basic_checkbox_1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="basic_checkbox_1"
                                >
                                  Remember my preference
                                </label>
                              </div>
                            </div>
                          </div>
                          <div>
                            {isError && (
                              <Alert
                                // variant="outlined"
                                severity="error"
                                className="mb-4"
                              >
                                Login failed
                              </Alert>
                            )}
                          </div>
                          <div className="text-center form-group mb-3 w-full">
                            <LoadingButton
                              loading={isLoading}
                              variant="contained"
                              type="submit"
                              className="btn bg-main btn-block hover:text-black"
                              style={{ backgroundColor: "#FBA82C" }}
                            >
                              Login
                            </LoadingButton>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div className="new-account flex justify-between text-main">
                    <button onClick={() => setShowModal(true)}>
                      Forgot your password?{" "}
                    </button>
                    <p>
                      <Link to="/reset-password">I have Reset Code</Link>
                    </p>
                  </div>
                  {/* <div className="flex flex-row-reverse text-main"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ForgetPasswordModal show={showModal} onClose={onClose} />
    </div>
  );
}

export default Login;
