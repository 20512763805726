import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "./apiRequest";

const initialState = {
  requests: [],
  request: null,
  length: 0,
  isError: false,
  isLoading: false,
  errorMessage: "",
};

// create request
export const createRequest = createAsyncThunk(
  "request/createRequest",
  async (request, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiRequest.createRequest(token, request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateRequestStatus = createAsyncThunk(
  "request/updateRequestStatus",
  async (requestData, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      let res = await apiRequest.updateRequestStatus(token, requestData);
      window.location.reload();
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getRequest = createAsyncThunk(
  "request/getRequest",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiRequest.getRequest(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getRequests = createAsyncThunk(
  "request/getRequests",
  async (query, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiRequest.getRequests(token, query);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getUnseenRequests = createAsyncThunk(
  "request/getUnseenRequests",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiRequest.getUnseenRequests(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateRequestSeen = createAsyncThunk(
  "request/updateRequestSeen",
  async (_, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiRequest.updateRequestSeen(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "request/deleteRequest",
  async (id, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await apiRequest.deleteRequest(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    resetRequest: (state) => {
      state.isError = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [createRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [createRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.request = action.payload;
    },
    [createRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },

    [updateRequestStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateRequestStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.request = action.payload.data;
      state.requests = state.requests?.data?.map((request) =>
        request._id === action.payload.data._id ? action.payload.data : request
      );
    },
    [updateRequestStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },

    [getRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [getRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.request = action.payload.data;
    },
    [getRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },

    [getRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [getRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.requests = action.payload;
    },
    [getRequests.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [getUnseenRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [getUnseenRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.length = action.payload.length;
    },
    [getUnseenRequests.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [updateRequestSeen.pending]: (state) => {
      state.isLoading = true;
    },
    [updateRequestSeen.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.length = 0;
    },
    [updateRequestSeen.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    [deleteRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteRequest.fulfilled]: (state, action) => {
      window.location.reload();
      state.isLoading = false;
      state.requests = state.requests?.data?.filter(
        (request) => request._id !== action.payload.data._id
      );
    },
    [deleteRequest.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { resetRequest } = requestSlice.actions;

export default requestSlice.reducer;
