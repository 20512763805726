import logo from "../../images/logo.png";
import "./contact.css";

const ContactForm = () => {
  return (
    <div className="max-w-3xl mx-auto mb-20">
      <div className="my-4 mx-auto p-4 shadow rounded ">
        <div className="px-5 py-3 flex flex-col justify-center items-center text-lg">
          <img src={logo} alt="" style={{ width: "250px" }} />
          <h1 className="text-xl font-semibold py-2 mt-3">Agency Detail</h1>
          <div className="flex flex-grow gap-4">
            <div className="space-y-2">
              <h2 className="font-semibold">Agency Information:</h2>
              {/* <h3>Sterling Travel and Tour Agency</h3> */}
              <h3>Sterling Travel and Tour</h3>
              <h3>Head Office: Near the National Theater </h3>
              <h3>Addis Ababa</h3>
              <h3>Ethiopia</h3>
            </div>
            <div className="space-y-2 tracking-wide">
              <div>
                <h2 className="font-semibold">Agency Office Phone:</h2>
                <h3>+251 115 511333 </h3>
                <h3>+251 115 514666</h3>
              </div>
              <div className="space-y-2 tracking-wide">
                <div>
                  <h2 className="font-semibold">
                    For your Refund & Exchange Request:{" "}
                  </h2>
                  <h3>+251911307175 - Etsehiwot</h3>
                </div>
                <div>
                  <h2 className="font-semibold">
                    For your Technical Requests:{" "}
                  </h2>
                  <h3>+251799307252 - Natnael</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-2 mx-16 py-3 text-lg">
          <h2 className="font-semibold text-center">Bank Account Details</h2>
          <h4>
            Account Name: <b>Sterling Trading PLC</b>
          </h4>
          <div className="space-y-2">
            <div className="my-3">
              <h3 className="font-semibold">
                Bank Name: Commercial Bank of Ethiopia (CBE)
              </h3>
              <h3>
                Account Number: <u>1000001811707</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Awash Bank</h3>
              <h3>
                Account Number: <u>01304015231500</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Bank of Abyssinia</h3>
              <h3>
                Account Number: <u>115280511</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Wegagen Bank</h3>
              <h3>
                Account Number: <u>1016422010101</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Buna Bank</h3>
              <h3>
                Account Number: <u>2859601000158</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Amhara Bank</h3>
              <h3>
                Account Number: <u>9900024052153</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Dashen Bank</h3>
              <h3>
                Account Number: <u>0032505064001</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Zemen Bank</h3>
              <h3>
                Account Number: <u>1034110035850018</u>
              </h3>
            </div>
            <div className="my-3">
              <h3 className="font-semibold">Bank Name: Dashen Bank</h3>
              <h3 className="font-semibold">Branch: Leghar Branch</h3>
              <h3>
                Dollar Account Number: <u>603290753</u>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
