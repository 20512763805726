import React from "react";

const Heading2 = ({
  className = "",
  heading = "Stays in Tokyo",
  subHeading,
}) => {
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading}
    </div>
  );
};

export default Heading2;
