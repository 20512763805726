import ApexBar2 from "./barChart";
import SpendVsSavingChart from "./spendVSSavingChart";

const SpendVSSaving = ({ title, state, data }) => {
  return (
    <div className="card">
      <div className="card-header border-0 d-sm-flex d-block">
        <div className="me-auto mb-sm-0 mb-3">
          <h4 className="card-title mb-2">{title}</h4>
        </div>
      </div>
      <div className="card-body">
        <div id="reservationChart" className="reservationChart">
          {state === "total" ? (
            <SpendVsSavingChart data={data} />
          ) : (
            <ApexBar2 data={data} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SpendVSSaving;
