import React, { useEffect } from "react";

import { getTopAirlines } from "../../../redux2/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import TopSellsAgencies from "./TopSellsAgencies";
import TopAirlinesSells from "./TopAirlinesSells";
import DashboardCard from "./dashboardCard";
import AverageWeeklyRate from "./averageWeeklyRate";
import SpendVSSaving from "./spendVSSaving";
import AgentsTopDestinations from "./AgentsTopDestinations";

const DashboardItems = ({ query }) => {
  const { airlines, isLoading } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopAirlines(query));
  }, [query, dispatch]);

  return (
    <div>
      <div className="">
        <DashboardCard data={airlines} isLoading={isLoading} />
      </div>
      <div className="row">
        <div className="col-xl-8">
          <SpendVSSaving
            title="Average Daily Ticket Sales Rate"
            state="total"
            data={airlines?.ticketSalesByHour}
          />
        </div>
        <div className="col-xl-4">
          <AverageWeeklyRate
            title="Average Weekly Ticket Sales Rate"
            data={airlines?.ticketSalesByWeek}
          />
        </div>
      </div>

      <div className=" ">
        <TopSellsAgencies title="Total" state="total" query={query} />
      </div>
      <div className=" ">
        <TopAirlinesSells
          title="Total"
          state="total"
          query={query}
          airlines={airlines}
          isLoading={isLoading}
        />
      </div>

      <div className="row mt-4">
        {/* <div className="col-xl-6">
          <SpendVSSaving title="Top Origins" data={airlines?.result?.origin} />
        </div> */}
        <div className="col-xl-12">
          <SpendVSSaving
            title="Top Destinations"
            data={airlines?.result?.destination}
          />
        </div>
      </div>
      <div className="">
        <AgentsTopDestinations query={query} />
      </div>
    </div>
  );
};

export default DashboardItems;
