import React from "react";
import { Modal } from "react-bootstrap";

const ShowFareModal = ({ rule }) => {
  const [openRule, setOpenRule] = React.useState(false);

  const handleShowFareRule = () => {
    setOpenRule(true);
  };

  return (
    <div>
      <div
        className="cursor-pointer dropdown-item"
        onClick={handleShowFareRule}
      >
        {/* <i className="fas fa-info-circle"></i> */}
        Fare Rules
      </div>
      <div className="">
        <Modal
          show={openRule}
          className="modal fade"
          onHide={() => setOpenRule(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-2xl">Fare Rule</Modal.Title>
          </Modal.Header>
          <Modal.Body>16. {rule}</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-lg text-white font-bold"
              onClick={() => setOpenRule(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ShowFareModal;
