import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTopAgencies } from "../../../redux2/dashboard/dashboardSlice";
import convertNumbThousand from "../../../utils/convertNumbThousand";

const AgentsTopDestinations = ({ query }) => {
  const { topAgencies, isLoading } = useSelector((state) => state.dashboard);

  const [showMore, setShowMore] = useState(false);
  const [destination, setDestination] = useState("DXB");

  const dispatch = useDispatch();

  useEffect(() => {
    query.destination = destination;
    dispatch(getTopAgencies(query));
  }, [query, dispatch, destination]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderRows = () => {
    const rows = topAgencies?.agentsTopDestination?.map((agency, index) => (
      <tr role="row" className="odd">
        <td className="sorting_1">
          <div className="flex flex-row gap-2">
            <p>{++index}</p>
            {index === 1 && (
              <span className="mt-1">
                <svg
                  class="w-5 h-5 text-yellow-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </span>
            )}
          </div>
        </td>
        <td>
          <div className="text-black font-semibold capitalize text-sm">
            <h6>{agency?.name}</h6>
          </div>
        </td>
        <td>{destination}</td>
        <td>{convertNumbThousand(agency?.destinationCount)}</td>
      </tr>
    ));

    if (!showMore) {
      return rows?.slice(0, 10); // Show only the first 10 rows
    } else {
      return rows; // Show all rows if showMore is true
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center p-2 mt-4">
        <h2 className="text-2xl font-semibold text-black p-2 ">
          Top Agencies Destination
        </h2>
        <div className="">
          <select
            className="block border text-black text-sm border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white w-72 p-2"
            name="ssr"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          >
            <option value="DXB" selected>
              DXB
            </option>
            <option value="ADD">ADD</option>
            <option value="FRA">FRA</option>
            <option value="BEY">BEY</option>
            <option value="JED">JED</option>
            <option value="NBO">NBO</option>
            <option value="BJR">BJR</option>
            <option value="RUH">RUH</option>
          </select>
        </div>
      </div>
      <div className="table-responsive ">
        <table
          className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer text-gray-700"
          id="example5"
          role="grid"
          aria-describedby="example5_info"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <p>#</p>
              </th>
              <th className="sorting">Agency Name</th>
              <th className="sorting">Destination</th>
              <th className="sorting">Total Count</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <tr role="row" className="odd">
                <td>
                  <div className="flex flex-row gap-2">
                    <div className="h-4 bg-gray-200 rounded w-4"></div>
                  </div>
                </td>
                <td>
                  <div className="text-black font-semibold capitalize text-sm">
                    <div className="h-4 bg-gray-200 rounded w-20"></div>
                  </div>
                </td>
                <td>
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td>
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td className="text-center text-green-500">
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
                <td className="text-center text-green-500">
                  <div className="h-4 bg-gray-200 rounded w-14"></div>
                </td>
              </tr>
            ) : (
              renderRows()
            )}
          </tbody>
        </table>
        {topAgencies?.agentsTopDestination?.length > 10 && (
          <button
            onClick={handleShowMore}
            className="border-1 border-blue-500 text-black  py-2 px-4 rounded-lg"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default AgentsTopDestinations;
